import {
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Flex,
  Spacer,
  Switch,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import { SpecterProducts } from "@prisma/client"
import { Link as RemixLink } from "@remix-run/react"
import {
  HiGlobe,
  HiLockClosed,
  HiOutlineLockOpen,
  HiPlus,
} from "react-icons/hi"

import { LoaderArgs } from "@remix-run/node"
import { motion } from "framer-motion"
import { useMemo } from "react"
import { BsBroadcastPin } from "react-icons/bs"
import {
  ListCreateModal,
  PRODUCTS_WITH_LISTS_AND_SEARCHES,
  SearchesAndListsProducts,
} from "~/components"
import { mapRouteNameToProduct } from "~/components/Filters/schemas"
import { FeedLoadingLogo } from "~/components/Logo/loading"
import { TableComponent } from "~/components/Table"
import {
  GetUserLists,
  GetUserListsByProduct,
} from "~/routes/__protected/api/lists"
import { getUserAvatarSrc } from "~/utils/getUserAvatarSrc"
import { useHiddenSearchesLists } from "~/utils/hooks/useHiddenSearchesLists"
import { useProduct } from "~/utils/hooks/useProduct"
import { useUserLists } from "~/utils/hooks/useUserLists"
import { isOneOf } from "~/utils/isOneOf"
import { PAGE_NOT_FOUND } from "~/utils/responses/errors"
import { ListType } from "../$product"

export const getItemCount = (list: GetUserListsByProduct[number]) => {
  return list._count ?? 0
}

export const VisibilityStatusList = ({
  list,
}: {
  list: GetUserListsByProduct[0]
}): JSX.Element => {
  if (list.isGlobalHub) {
    return (
      <>
        <Icon as={BsBroadcastPin} color="gray.400" />
        <Text fontSize="xs">Recommended by Specter</Text>
      </>
    )
  }

  if (list.isPublic) {
    return (
      <>
        <Icon as={HiGlobe} color="gray.400" />
        <Text fontSize="xs">Shared with Team</Text>
      </>
    )
  }

  if (list.userShares.length > 0) {
    return (
      <>
        <Icon as={HiOutlineLockOpen} color="gray.400" />
        <Text fontSize="xs" mr={3}>
          Privately shared with:
        </Text>
        {list.userShares.map((share) => (
          <Avatar
            ml={-3}
            size="xs"
            key={`${share.listId}_${share.userId}`}
            src={getUserAvatarSrc(share.user.avatar)}
            name={`${share.user.first_name} ${share.user.last_name}`}
          />
        ))}
      </>
    )
  }

  return (
    <>
      <Icon as={HiLockClosed} color="gray.400" />
      <Text fontSize="xs">Private</Text>
    </>
  )
}

export async function loader({ params }: LoaderArgs) {
  if (!params.product) throw PAGE_NOT_FOUND

  const product = mapRouteNameToProduct(params.product)

  if (isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES)) return null

  throw PAGE_NOT_FOUND
}

const UserListDetail = (): JSX.Element => {
  const product = useProduct()
  const listsQuery = useUserLists({ product, infiniteQuery: true })
  const createForm = useDisclosure()
  const hiddenDisclosure = useDisclosure()
  const { hidden: hiddenLists } = useHiddenSearchesLists(ListType.userList)

  const rowDataFetched = useMemo(
    () =>
      listsQuery.data?.pages.flatMap(
        // @ts-ignore
        ({ items }) => items
      ) as unknown as GetUserLists["items"] | undefined,
    [listsQuery.data]
  )

  const rowData = rowDataFetched ?? []

  if (listsQuery.isLoading) {
    return <FeedLoadingLogo />
  }

  if (listsQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>An error occurred loading your lists</AlertTitle>
      </Alert>
    )
  }

  const visibleLists =
    rowData?.filter((list) => {
      if (hiddenDisclosure.isOpen) {
        return hiddenLists.includes(list.id.toString())
      }

      return !hiddenLists.includes(list.id.toString())
    }) ?? []

  const totalHidden =
    rowData?.filter((list) => {
      return hiddenLists.includes(list.id.toString())
    }).length ?? 0

  return (
    <>
      <Flex
        gap={3}
        pb={3}
        pt={3}
        px={6}
        alignItems="center"
        borderBottomWidth={1}
        borderColor="gray.100"
      >
        <Text fontSize="xs" color="gray.500">
          Showing{" "}
          <Text as="span" fontWeight="semibold">
            {visibleLists.length}
          </Text>
          {hiddenDisclosure.isOpen ? " hidden " : " "}
          lists
        </Text>

        <Spacer />

        <Flex alignItems="center" gap={3}>
          <Flex alignItems="center" gap={1}>
            <Text
              fontSize="xs"
              color="gray.400"
              as="label"
              htmlFor="show-hidden"
            >
              Show Hidden Lists
              {totalHidden > 0 && ` (${totalHidden})`}
            </Text>
            <Switch
              id="show-hidden"
              size="sm"
              colorScheme="brand"
              isChecked={hiddenDisclosure.isOpen}
              onChange={() => hiddenDisclosure.onToggle()}
            />
          </Flex>

          {product === SpecterProducts.company && (
            <Button as={RemixLink} to="/import" variant="outline" size="xs">
              Import List
            </Button>
          )}

          <Button
            size="xs"
            onClick={createForm.onOpen}
            rightIcon={<Icon as={HiPlus} />}
            colorScheme="brand"
          >
            Create List
          </Button>
        </Flex>
      </Flex>

      <Flex flexDirection="column" flexGrow={1}>
        <TableComponent
          config={`user-lists.${product as SearchesAndListsProducts}`}
          rowData={visibleLists}
          signalsQuery={listsQuery}
          styleOverrides={{
            "--column-border-color": "transparent",
            "--ag-grid-size": "10px",
            "--ag-header-background-color": "#f9fafa",
            "--ag-row-hover-color": "transparent",
            ".ag-cell:hover::before": {
              content: "none",
            },
            ".ag-cell": {
              cursor: "default !important",
            },
          }}
          agGridProps={{
            headerHeight: 25,
          }}
          disableSelection
          suppressLastInfoRow
        />
      </Flex>

      <ListCreateModal
        isOpen={createForm.isOpen}
        onClose={createForm.onClose}
        product={product}
      />
    </>
  )
}

export const TagNewCRM = ({ count, to }: { count: number; to: string }) => {
  const isNew = count > 0
  return (
    <Box position="relative" zIndex={1} display="inline-flex">
      {isNew && (
        <motion.div
          initial={{ scale: 0.8, opacity: 0.6 }}
          animate={{ scale: 1.6, opacity: 0 }}
          transition={{ duration: 1.6, repeat: Infinity }}
          style={{
            position: "absolute",
            inset: 0,
            zIndex: -1,
          }}
        >
          <Box h="full" bgColor="green.400" rounded="full" />
        </motion.div>
      )}
      <Tag
        as={RemixLink}
        to={to}
        // TODO: Mark as viewed for CRM ?
        // onClick={() => markedAsViewed.mutate()}
        rounded="full"
        variant={isNew ? "solid" : "softOutline"}
        colorScheme={isNew ? "green" : "gray"}
      >
        {count ?? "-"}
      </Tag>
    </Box>
  )
}

export default UserListDetail
