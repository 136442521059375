import { Box, ButtonGroup, Flex, Heading, Text } from "@chakra-ui/react"
import { EntityStatus, SpecterProducts } from "@prisma/client"
import { useLocation } from "@remix-run/react"

import { ReactNode, useMemo } from "react"
import { FlexDivider, FlexOneLine, FlexOneLineProps } from "~/components"
import { Card } from "~/components/Card"
import { useEntityStatus } from "~/utils/hooks/useEntityStatus"
import { isEmpty } from "~/utils/isEmpty"
import { isOneOf } from "~/utils/isOneOf"
import { oneOf } from "~/utils/oneOf"
import { CardBox } from "../CardBox"
import { EntityStatusDot } from "../EntityStatusDot"
import { ActionButtonProps } from "./Buttons"

export type SignalCardProps<P extends SpecterProducts> = {
  picture: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
  postTitle?: ReactNode
  postTitleOverflow?: FlexOneLineProps<string>["overflowIndicator"]
  preButtons?: ReactNode
  actionButtons?: ReactNode
  cardBoxes?: ReactNode
  avatarFullHeight?: boolean
  postDescription?: ReactNode
  postCardBoxes?: ReactNode
  topLeftBadge?: ReactNode
  postActionMenu?: ReactNode
  postActionMenuButtons?: ReactNode
  openInFullScreen?: boolean
  customLink?: string | null
} & ActionButtonProps<P>

export const SignalCard = <P extends SpecterProducts>({
  signal,
  product,
  picture,
  title,
  subtitle,
  description,
  postTitle,
  postTitleOverflow,
  preButtons,
  actionButtons,
  cardBoxes,
  avatarFullHeight,
  postDescription,
  postCardBoxes,
  topLeftBadge,
  postActionMenu,
  postActionMenuButtons,
  openInFullScreen,
  customLink,
}: SignalCardProps<P>) => {
  const location = useLocation()
  const detailURL = `./${signal.id}${location.search}${
    openInFullScreen ? "/f" : ""
  }`

  const entityStatus = useEntityStatus({
    entityId: signal.id,
    product,
    initialValue:
      "entityStatus" in signal
        ? {
            status: oneOf(
              signal.entityStatus?.status,
              Object.values(EntityStatus)
            ),
            updated_at: signal.entityStatus?.updated_at,
          }
        : null,
  })

  const cardBoxesContainer = useMemo(
    () =>
      cardBoxes && (
        <Flex alignItems="flex-end">
          {/* <Grid
            gap={2}
            alignItems="stretch"
            flexGrow={1}
            templateColumns="repeat(auto-fit, minmax(150px, auto))"
            templateRows="min-content"
            justifyContent="start"
          > */}
          {/* //! Haven't found the best way of handling this yet, but to avoid the boxes from overflowing the card, we'll use FlexOneLine for now*/}
          <FlexOneLine gap={2} flexWrap="wrap">
            {cardBoxes}
          </FlexOneLine>
          {/* </Grid> */}
          <Box ml="auto">{postCardBoxes}</Box>
        </Flex>
      ),
    [cardBoxes, postCardBoxes]
  )

  const hasPostActionMenu = postActionMenu || postActionMenuButtons

  return (
    <CardBox
      transition="opacity .2s"
      opacity={entityStatus.status?.status === EntityStatus.disliked ? 0.5 : 1}
    >
      <Card
        display="grid"
        gridTemplateColumns={hasPostActionMenu ? "1fr auto auto" : "1fr"}
        gap={3}
        to={customLink === null ? undefined : customLink ?? detailURL}
        position="relative"
      >
        <Flex direction="column" gap={2} flexGrow={1}>
          <Flex gap={avatarFullHeight ? 4 : 2} alignItems="stretch">
            <Box position="relative">
              {picture}

              <Box
                position="absolute"
                top={0}
                left={0}
                zIndex={1}
                transform="translate(-50%, -50%)"
                rounded="full"
                p="3px"
                bgColor="white"
              >
                <EntityStatusDot
                  portal
                  status={entityStatus.status}
                  teamStatuses={
                    "teamEntityStatuses" in signal
                      ? signal.teamEntityStatuses ?? []
                      : []
                  }
                />
              </Box>
            </Box>

            <Flex direction="column" gap={0.5} flexGrow={1}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
              >
                <FlexOneLine
                  gap={1}
                  alignItems="center"
                  overflowIndicator={postTitleOverflow}
                  position="relative"
                >
                  {!isEmpty(title) && (
                    <Heading as="h1" fontSize="md" fontWeight="bold" maxW={500}>
                      {title}
                    </Heading>
                  )}

                  {!isEmpty(title) && !isEmpty(postTitle) && (
                    <FlexDivider orientation="vertical" />
                  )}

                  {postTitle}
                </FlexOneLine>

                <ButtonGroup size="xs" variant="outline" spacing={1}>
                  {preButtons}

                  {!isEmpty(preButtons) && !isEmpty(actionButtons) && (
                    <FlexDivider orientation="vertical" />
                  )}

                  {actionButtons}
                </ButtonGroup>
              </Flex>

              <Flex justifyContent="space-between" alignItems="center">
                <Box flexGrow={1}>
                  {!isEmpty(subtitle) && (
                    <Heading
                      as="h2"
                      fontSize="sm"
                      fontWeight="semibold"
                      lineHeight={1.4}
                      noOfLines={1}
                    >
                      {subtitle}
                    </Heading>
                  )}
                  {!isEmpty(description) && (
                    <Text fontSize="sm" noOfLines={1} color="gray.500">
                      {description}
                    </Text>
                  )}
                </Box>
                {postDescription}
              </Flex>
              {avatarFullHeight && cardBoxesContainer}
            </Flex>
          </Flex>
          {!avatarFullHeight && cardBoxesContainer}
        </Flex>

        {hasPostActionMenu && (
          <>
            <FlexDivider orientation="vertical" />

            <Flex
              direction="column"
              gap={1.5}
              w="fit-content"
              minW={isOneOf(product, ["people", "talent"]) ? "9rem" : "8rem"}
            >
              {postActionMenuButtons}
              {postActionMenu}
            </Flex>
          </>
        )}

        {topLeftBadge && (
          <Box position="absolute" top={1.5} left={1.5}>
            {topLeftBadge}
          </Box>
        )}
      </Card>
    </CardBox>
  )
}

export * from "./ActionMenu"
export * from "./Buttons"
export * from "./CardBox"
export * from "./GrowthBadge"
