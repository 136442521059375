import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  ModalFooter,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import { SpecterProducts } from "@prisma/client"
import { useState } from "react"
import {
  ListAddedIcon,
  ListAddIcon,
  ProductDetailItem,
  ProductItem,
} from "../SignalCard"
import { IconLandscapeAdd } from "../Icons/landscapeAdd"
import { IconType } from "react-icons"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { cacheKeys } from "~/utils/cacheKeys"
import { ActionOptions } from "~/routes/__protected/api/signals/$product/action-options"
import { titleCase } from "title-case"
import pluralize from "pluralize"
import { IconLandscapeAdded } from "../Icons/landscapeAdded"
import { HiGlobeAlt, HiQuestionMarkCircle } from "react-icons/hi"
import { ListCreateModal } from "../ListCreateModal"
import { useShortcut } from "~/utils/hooks/useShortcut"
import { NavigateShortcutPrompt } from "../NavigateShortcutPrompt"

interface Props<P extends SpecterProducts> {
  product: P
  signals: (ProductItem<P> | ProductDetailItem<P>)[]
}

const ItemIcon = ({ icon, color }: { icon: IconType; color: string }) => {
  return (
    <Center
      boxSize={6}
      bgColor={`${color}.50`}
      rounded="md"
      borderWidth={1}
      borderColor={`${color}.200`}
      color={`${color}.500`}
    >
      <Icon as={icon} w={3} h={3} color={`${color}.500`} />
    </Center>
  )
}

const getItemColor = (option: ActionOptions[number]) => {
  if (option.type === "list" && "list" in option) {
    return option.list.isGlobalHub
      ? "yellow"
      : option.list.isAdded
      ? "green"
      : "gray"
  }

  if (option.type === "landscape" && "landscape" in option) {
    return option.isAdded ? "green" : "brand"
  }

  return "gray"
}

const getItemIcon = (option: ActionOptions[number]) => {
  if (option.type === "list" && "list" in option) {
    return option.list.isGlobalHub
      ? HiGlobeAlt
      : option.list.isAdded
      ? ListAddedIcon
      : ListAddIcon
  }

  if (option.type === "landscape" && "landscape" in option) {
    return option.isAdded ? IconLandscapeAdded : IconLandscapeAdd
  }

  return HiQuestionMarkCircle
}

export const EntityActionModalRootScreen = <P extends SpecterProducts>({
  product,
  signals,
}: Props<P>) => {
  const [search, setSearch] = useState("")
  const [activeIndex, setActiveIndex] = useState(0)
  const createListModal = useDisclosure()
  const queryClient = useQueryClient()
  const toast = useToast()

  const actionOptionsQuery = useQuery<ActionOptions>({
    queryKey: cacheKeys.actionOptions(
      product,
      signals.map((s) => s.id)
    ),
    async queryFn() {
      const req = await fetch(`/api/signals/${product}/action-options`, {
        method: "POST",
        body: JSON.stringify({
          signalId: signals[0].id,
        }),
      })
      const res = await req.json()
      return res
    },
  })

  const filteredOptions = actionOptionsQuery.data?.filter((option) => {
    return option.label.toLowerCase().includes(search.toLowerCase())
  })

  useShortcut(
    "arrowDown",
    () =>
      setActiveIndex((prev) =>
        Math.min((filteredOptions?.length ?? 1) - 1, prev + 1)
      ),
    false,
    [actionOptionsQuery.data, search]
  )
  useShortcut(
    "arrowUp",
    () => setActiveIndex((prev) => Math.max(prev - 1, 0)),
    false,
    [actionOptionsQuery.data, search]
  )
  useShortcut("enter", () => actionMutation.mutate())

  const selectedOption = filteredOptions?.[activeIndex]

  const actionMutation = useMutation(
    async () => {
      const selectedOption = filteredOptions?.[activeIndex]

      if (!selectedOption) {
        return null
      }

      if (selectedOption.type === "list" && "list" in selectedOption) {
        const { id, isAdded } = selectedOption.list

        const req = await fetch(
          `/api/lists/${product}/${id}/${isAdded ? "remove" : "add"}`,
          {
            method: "POST",
            body: isAdded
              ? JSON.stringify([signals[0].id])
              : JSON.stringify({ signalId: signals[0].id }),
          }
        )

        if (!req.ok) {
          throw new Error("Signal is already added to this list")
        }
      }

      if (selectedOption.type === "landscape") {
        const { value } = selectedOption
        const req = await fetch(`/api/landscapes/entry/${value}/`, {
          method: "POST",
          body: JSON.stringify({
            signalId: signals[0].id,
          }),
        })

        if (!req.ok) {
          throw new Error("Signal is already added to this landscape")
        }
      }

      return selectedOption
    },
    {
      async onSuccess(selectedOption) {
        if (!selectedOption) return

        await queryClient.invalidateQueries(
          cacheKeys.actionOptions(
            product,
            signals.map((s) => s.id)
          )
        )
        if (
          selectedOption.type === "landscape" &&
          "landscape" in selectedOption
        ) {
          await queryClient.invalidateQueries(
            cacheKeys.landscapes(selectedOption.landscape.id.toString())
          )
        }

        if (selectedOption.type === "list") {
          await queryClient.invalidateQueries(cacheKeys.userLists({ product }))
        }

        await queryClient.invalidateQueries(cacheKeys.actionOptions(product))

        await queryClient.invalidateQueries(
          cacheKeys.signalAddedToList(signals[0].id)
        )

        toast({
          status: "success",
          title:
            selectedOption.type === "list"
              ? selectedOption.isAdded
                ? "Remove from List"
                : "Added to List"
              : selectedOption.isAdded
              ? "Remove from Landscape"
              : "Added to Landscape",
        })
      },
    }
  )

  return (
    <>
      <Input
        size="lg"
        placeholder="Search Lists and Landscapes..."
        variant="flushed"
        px={5}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box p={4} h={400} overflowY="auto">
        {actionOptionsQuery.isLoading && <Spinner />}

        {filteredOptions?.map((option, i) => {
          const color = getItemColor(option)

          return (
            <>
              {option.type !== filteredOptions?.[i - 1]?.type && (
                <Flex alignItems="center" gap={2} mb={2} mt={3} px={1}>
                  <Text fontSize="xs" fontWeight="medium">
                    {pluralize(titleCase(option.type))}
                  </Text>
                  <Divider flex={1} />
                </Flex>
              )}
              <Flex
                key={option.value}
                onClick={() => actionMutation.mutate()}
                onMouseOver={() => setActiveIndex(i)}
                bgColor={i === activeIndex ? "gray.100" : "transparent"}
                rounded="md"
                p={2}
                cursor="pointer"
                gap={2}
              >
                <ItemIcon icon={getItemIcon(option)} color={color} />
                <Text fontSize="sm" fontWeight="medium" color="gray.600">
                  {option.label}
                </Text>
              </Flex>
            </>
          )
        })}
      </Box>
      <ModalFooter gap={2}>
        <NavigateShortcutPrompt />
        <Spacer />
        <Button
          colorScheme="brand"
          variant="ghost"
          size="sm"
          onClick={createListModal.onOpen}
        >
          Create new List
        </Button>
        {selectedOption && (
          <Button
            colorScheme="brand"
            size="sm"
            variant="solid"
            onClick={() => actionMutation.mutate()}
            isLoading={actionMutation.isLoading}
            isDisabled={actionMutation.isLoading}
          >
            {selectedOption.type === "list"
              ? selectedOption.isAdded
                ? "Remove from List"
                : "Add to List"
              : selectedOption.isAdded
              ? "Remove from Landscape"
              : "Add to Landscape"}
          </Button>
        )}
      </ModalFooter>

      <ListCreateModal
        isOpen={createListModal.isOpen}
        onClose={createListModal.onClose}
        product={product}
      />
    </>
  )
}
