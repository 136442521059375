import {
  ModalContent,
  Button,
  Modal,
  Spacer,
  useDisclosure,
  Box,
  Input,
  Flex,
  Spinner,
  Tabs,
  TabList,
  Tab,
  Tag,
  Text,
  ModalFooter,
  Center,
  Avatar,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import { SpecterProducts } from "@prisma/client"
import { useNavigate } from "@remix-run/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import debounce from "debounce"
import numeral from "numeral"
import { useCallback, useState } from "react"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { MdKeyboardCommandKey, MdSubdirectoryArrowLeft } from "react-icons/md"
import { titleCase } from "title-case"
import { QuickSearchResultsResponse } from "~/routes/__protected/api/quick-search/results"
import { cacheKeys } from "~/utils/cacheKeys"
import { getProductEntityName } from "~/utils/getProductName"
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlags"
import { useQuickSearchResults } from "~/utils/hooks/useQuickSearch"
import { useQuickSearchCounts } from "~/utils/hooks/useQuickSearch"
import { useShortcut } from "~/utils/hooks/useShortcut"
import { CompanyLogo } from "../CompanyLogo"
import { IconCompany } from "../Icons/company"
import { IconInvestor } from "../Icons/investor"
import { IconPeople } from "../Icons/people"
import { MenuItem } from "../MenuItem"
import {
  NavigateShortcutPrompt,
  NavigateTabsPrompt,
} from "../NavigateShortcutPrompt"
import { getSearchUrlFromProduct } from "../UserSearchesPage/utils"
import { useAnalytics } from "~/utils/hooks/useAnalytics"

const getProductFromItem = (item: QuickSearchResultsResponse[number]) => {
  if (item && "id" in item && item.id.startsWith("per_"))
    return SpecterProducts.people
  if (item && "id" in item && item.id.startsWith("inv_"))
    return SpecterProducts.investors
  return SpecterProducts.company
}

const Content = ({ onClose }: { onClose: () => void }) => {
  const [term, setTerm] = useState("")
  const [tabIndex, setTabIndex] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const isPeopleDBEnabled = useFeatureFlag("PEOPLE_DB_ENABLED")
  const maxTabs = isPeopleDBEnabled ? 2 : 1
  const analytics = useAnalytics()

  const getTabFromIndex = (index: number) => {
    return [
      SpecterProducts.company,
      ...(isPeopleDBEnabled ? [SpecterProducts.people] : []),
      SpecterProducts.investors,
    ][index]
  }

  const queryClient = useQueryClient()
  const quickSearchResultsQuery = useQuickSearchResults({
    term,
    tab: getTabFromIndex(tabIndex),
  })
  const quickSearchCountsQuery = useQuickSearchCounts(term)

  const debouncedSetSearchTerm = useCallback(
    debounce((newSearchTerm: string) => {
      setTerm(newSearchTerm)
    }, 250),
    []
  )

  useShortcut(
    "arrowDown",
    () => {
      setSelectedIndex((index) =>
        Math.min((quickSearchResultsQuery.data?.length ?? 1) - 1, index + 1)
      )
    },
    false,
    [quickSearchResultsQuery.data]
  )
  useShortcut(
    "arrowUp",
    () => {
      setSelectedIndex((index) => Math.max(0, index - 1))
    },
    false,
    [quickSearchResultsQuery.data]
  )

  useShortcut(
    "arrowRight",
    () => setTabIndex(Math.min(tabIndex + 1, maxTabs)),
    false,
    [tabIndex]
  )
  useShortcut(
    "arrowLeft",
    () => setTabIndex(Math.max(tabIndex - 1, 0)),
    false,
    [tabIndex]
  )

  const navigate = useNavigate()
  const saveQuickSearchMutation = useMutation({
    mutationFn: async ({
      product,
      id,
    }: {
      product: SpecterProducts
      id?: string
    }) => {
      await fetch("/api/quick-search", {
        method: "POST",
        body: JSON.stringify({ product, id }),
      })
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: cacheKeys.quickSearchResults(""),
      })
    },
  })

  const selectedProduct = getProductFromItem(
    quickSearchResultsQuery.data?.[selectedIndex]
  )

  const performAction = (product: SpecterProducts, id?: string) => {
    onClose()
    navigate(getSearchUrlFromProduct(product) + "/" + id + "/f")
    saveQuickSearchMutation.mutate({ product, id })

    analytics.track(
      "clicked_quick_search_result",
      {
        product: product,
        productId: id,
      },
      { only: ["posthog"] }
    )
  }

  const onAction = () => {
    if (!quickSearchResultsQuery.data?.[selectedIndex]) return

    performAction(
      selectedProduct,
      quickSearchResultsQuery.data?.[selectedIndex]?.id
    )
  }

  useShortcut("enter", onAction, false, [
    quickSearchResultsQuery.data,
    selectedIndex,
  ])

  return (
    <>
      <Box pos="relative">
        <Icon
          as={HiOutlineMagnifyingGlass}
          fontSize="lg"
          color="gray.400"
          position="absolute"
          left={4}
          top="50%"
          transform="translateY(-50%)"
        />

        <Input
          defaultValue={term}
          onChange={(e) => debouncedSetSearchTerm(e.target.value)}
          autoFocus
          placeholder="Search Specter by name or URL for exact matches"
          variant="flushed"
          size="lg"
          px={12}
        />

        {(quickSearchResultsQuery.isFetching ||
          quickSearchCountsQuery.isFetching) && (
          <Center pos="absolute" right={5} h={4} top="50%" mt={-2}>
            <Spinner color="brand.500" />
          </Center>
        )}
      </Box>

      {term.length > 0 && (
        <Flex
          px={6}
          bgColor="gray.50"
          borderBottomWidth={1}
          borderColor="gray.100"
          gap={1}
        >
          <Tabs
            variant="line"
            size="xs"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                Companies{" "}
                <Tag ml={1} variant="subtle" size="xs" color="gray.500">
                  {numeral(quickSearchCountsQuery.data?.companies ?? 0).format(
                    "0,0"
                  )}
                </Tag>
              </Tab>
              {isPeopleDBEnabled && (
                <Tab>
                  People{" "}
                  <Tag ml={1} variant="subtle" size="xs" color="gray.500">
                    {numeral(quickSearchCountsQuery.data?.people ?? 0).format(
                      "0,0"
                    )}
                  </Tag>
                </Tab>
              )}
              <Tab>
                Investors{" "}
                <Tag ml={1} variant="subtle" size="xs" color="gray.500">
                  {numeral(quickSearchCountsQuery.data?.investors ?? 0).format(
                    "0,0"
                  )}
                </Tag>
              </Tab>
            </TabList>
          </Tabs>
        </Flex>
      )}

      <Box h={400} overflow="auto" p={4}>
        {term.length <= 0 && quickSearchResultsQuery.data?.length === 0 && (
          <Center h="full">
            <Box textAlign="center">
              <Flex justifyContent="center" gap={2} mb={4}>
                <IconCompany w={8} h={8} />
                <IconPeople w={8} h={8} />
                <IconInvestor w={8} h={8} />
              </Flex>
              <Text fontWeight="semibold" fontSize="md">
                Search for companies, people, or investors.
              </Text>
              <Text fontSize="xs" color="gray.500">
                Quickly find and view Specter entities.
              </Text>
            </Box>
          </Center>
        )}

        {quickSearchResultsQuery.data?.map((item, index) => {
          const product = getProductFromItem(item)
          return (
            <Flex
              alignItems="center"
              key={item?.id}
              bgColor={selectedIndex === index ? "gray.100" : "white"}
              onMouseOver={() => setSelectedIndex(index)}
              onClick={() => {
                performAction(product, item?.id)
              }}
              p={1}
              gap={2}
              rounded="lg"
              cursor="pointer"
            >
              {item && "domain" in item && (
                <CompanyLogo domain={item.domain} size={6} />
              )}

              {item && "profile_image_url" in item && (
                <Avatar
                  src={item.profile_image_url ?? undefined}
                  w={6}
                  h={6}
                  borderRadius="lg"
                />
              )}

              <Text fontWeight="semibold" fontSize="xs" color="gray.600">
                {item && "name" in item && item.name}
                {item && "first_name" in item && item.first_name + " "}
                {item && "last_name" in item && item.last_name}
              </Text>

              {item && "domain" in item && (
                <Text as="span" fontSize="xs" color="gray.400">
                  {item.domain}
                </Text>
              )}

              <Spacer />

              <Tag colorScheme="brand">{getProductEntityName(product)}</Tag>
            </Flex>
          )
        })}
      </Box>

      <ModalFooter gap={4}>
        <NavigateShortcutPrompt />
        <NavigateTabsPrompt />
        <Spacer />
        <Button
          colorScheme="brand"
          onClick={onAction}
          isDisabled={!quickSearchResultsQuery.data?.[selectedIndex]}
          rightIcon={<Icon as={MdSubdirectoryArrowLeft} />}
        >
          View {titleCase(getProductEntityName(selectedProduct))}
        </Button>
      </ModalFooter>
    </>
  )
}

export const QuickSearch = () => {
  const modalDisclosure = useDisclosure()
  const analytics = useAnalytics()

  const handleOpen = () => {
    console.log("opened_quick_search via click")
    analytics.track(
      "opened_quick_search",
      { via: "click" },
      { only: ["posthog"] }
    )
    modalDisclosure.onOpen()
  }

  useShortcut(
    "k",
    () => {
      console.log("opened_quick_search via cmd-k")
      analytics.track(
        "opened_quick_search",
        { via: "cmd-k" },
        { only: ["posthog"] }
      )
      modalDisclosure.onToggle()
    },
    true
  )

  return (
    <>
      <MenuItem
        mb={4}
        bgColor="white"
        shadow="sm"
        borderWidth={1}
        borderColor="gray.100"
        mx="-1px"
        icon={HiOutlineMagnifyingGlass}
        pr={2}
        onClick={handleOpen}
        shortcut={() => (
          <>
            <Icon as={MdKeyboardCommandKey} />K
          </>
        )}
      >
        Quick Search
      </MenuItem>

      <Modal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        isCentered
        size="xl"
      >
        <ModalContent maxW={800} rounded="2xl" p={1}>
          <Box
            rounded="xl"
            borderWidth={1}
            borderColor="gray.100"
            overflow="hidden"
          >
            {modalDisclosure.isOpen && (
              <Content onClose={modalDisclosure.onClose} />
            )}
          </Box>
        </ModalContent>
      </Modal>
    </>
  )
}
