import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { allTalentSignalStatus } from "~/consts/talentSignals"
import { isOneOf } from "~/utils/isOneOf"
import { getSignalDate } from "~/utils/signalDate"

import { SignalStatusTalent, TalentSignalTitle } from "~/components"
import { Card } from "~/components/Card"
import { isDateValid } from "~/utils/isDateValid"
import { CardBox } from "../CardBox"
import { ColoredTag } from "../ColoredTag"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"

export const TalentSignalHeadlineCard = ({
  signal,
}: {
  signal: GetPeopleByID
}) => {
  if (!("signal_type" in signal)) return null

  return (
    <CardBox>
      <Card borderWidth={1} borderColor="brand.300">
        <Flex alignItems="start">
          <Box flex={1}>
            <Flex gap={1} flexWrap="wrap">
              {signal.signal_type && (
                <ColoredTag color="gray" rounded="full">
                  {signal.signal_type}
                </ColoredTag>
              )}

              {signal.signal_score && (
                <ColoredTag rounded="full" color="gray">
                  Score: {signal.signal_score}/10
                </ColoredTag>
              )}
            </Flex>

            <Heading as="h1" fontSize="md" my={2}>
              <TalentSignalTitle talentSignal={signal} />
            </Heading>

            {isDateValid(signal.signal_date) && (
              <Text fontSize="xs" color="gray.500" fontWeight="semibold">
                {getSignalDate(new Date(signal.signal_date))}
              </Text>
            )}
          </Box>

          <Flex alignItems="center" gap={2}>
            {isOneOf(signal.signal_status, allTalentSignalStatus) && (
              <SignalStatusTalent signalStatus={signal.signal_status} />
            )}

            {signal.out_of_stealth_advantage !== null && (
              <Text color="gray.300" fontSize="xs" fontWeight="semibold">
                {signal.out_of_stealth_advantage} ADV
              </Text>
            )}
          </Flex>
        </Flex>
      </Card>
    </CardBox>
  )
}
