import {
  Menu,
  MenuButton,
  IconButton,
  Tooltip,
  MenuList,
  Image,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
} from "@chakra-ui/react"
import { TbCube } from "react-icons/tb"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import {
  getIntegrationLogo,
  getIntegrationName,
  integrationConfigIsSetup,
  IntegrationMeta,
  ProductWithIntegrations,
} from "~/utils/integrations"
import { MenuItem } from "../MenuItem"
import { Link as RemixLink } from "@remix-run/react"
import { useIntegrationPushMutation } from "~/utils/hooks/useIntegrationPushMutation"
import { Icon } from "~/utils/components/Icon"
import { useState } from "react"
import { HiCog } from "react-icons/hi"
import { formatDistanceToNow } from "date-fns"

interface IntegrationActionProps {
  integration: IntegrationMeta
  singleAction: boolean
  MenuListction?: boolean
  signalIds: string[]
  product: ProductWithIntegrations
  showLabel?: boolean
  isDisabled?: boolean
  onSuccess?: () => void
  integrationInfo?: {
    integrationUpdatedAt?: string | null
    integrationUserName?: string | null
  } | null
}

const IntegrationAction = ({
  integration,
  singleAction = false,
  signalIds,
  product,
  showLabel = false,
  integrationInfo,
  isDisabled = false,
  onSuccess,
}: IntegrationActionProps) => {
  const integrationName = getIntegrationName(integration.integrationKey)
  const [error, setError] = useState<string | null>(null)

  const integrationPush = useIntegrationPushMutation(product, onSuccess)

  const pushSignals = () => {
    integrationPush.mutate({
      integrationKey: integration.integrationKey,
      signalIds,
    })
  }

  const highlightIntegration =
    integrationInfo?.integrationUpdatedAt ||
    (integrationPush.isSuccess && !integrationPush.isLoading)

  const tooltipLabel = integrationInfo?.integrationUpdatedAt
    ? `Last updated ${formatDistanceToNow(
        new Date(integrationInfo.integrationUpdatedAt),
        {
          addSuffix: true,
        }
      )} ${
        integrationInfo.integrationUserName
          ? `by ${integrationInfo.integrationUserName}`
          : ""
      }`
    : `Add to ${integrationName}`

  return (
    <>
      {singleAction && !showLabel && (
        <Tooltip label={tooltipLabel}>
          <IconButton
            flex={1}
            variant="outline"
            colorScheme={highlightIntegration ? "green" : "gray"}
            bgColor={highlightIntegration ? "green.50" : undefined}
            aria-label={`Add to ${integrationName}`}
            icon={
              <Image
                w={4}
                src={getIntegrationLogo(integration.integrationKey)}
              />
            }
            onClick={() => {
              pushSignals()
            }}
            isLoading={integrationPush.isLoading}
            isDisabled={integrationPush.isLoading || isDisabled}
          />
        </Tooltip>
      )}

      {singleAction && showLabel && (
        <Tooltip label={tooltipLabel}>
          <Button
            leftIcon={
              <Image
                w={4}
                src={getIntegrationLogo(integration.integrationKey)}
              />
            }
            onClick={() => {
              pushSignals()
            }}
            colorScheme={highlightIntegration ? "green" : "gray"}
            bgColor={highlightIntegration ? "green.50" : undefined}
            isLoading={integrationPush.isLoading}
            isDisabled={integrationPush.isLoading || isDisabled}
            variant="outline"
          >
            Add to {integrationName}
          </Button>
        </Tooltip>
      )}

      {!singleAction && (
        <MenuItem
          py={1}
          onClick={() => {
            pushSignals()
          }}
          isDisabled={isDisabled || integrationPush.isLoading}
          isLoading={integrationPush.isLoading}
        >
          Add to {integrationName}
        </MenuItem>
      )}

      {error && (
        <Modal isOpen={true} onClose={() => setError(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Integration Error</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box
                p={4}
                bgColor="red.50"
                rounded="lg"
                borderWidth={1}
                borderColor="red.100"
              >
                <Text whiteSpace="break-spaces" fontSize="sm" color="red.500">
                  {error}
                </Text>
              </Box>

              <Button
                as={RemixLink}
                to={`/integrations`}
                mt={4}
                variant="outline"
                leftIcon={<Icon as={HiCog} />}
                size="sm"
                w="full"
              >
                Edit Configuration
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

interface Props {
  signalIds: string[]
  product: ProductWithIntegrations
  showLabel?: boolean
  integrationInfo?: {
    integrationUpdatedAt?: string | null
    integrationUserName?: string | null
  } | null
  isDisabled?: boolean
  onSuccess?: () => void
}

export const IntegrationSignalButton = ({
  signalIds,
  product,
  showLabel = false,
  integrationInfo,
  isDisabled = false,
  onSuccess,
}: Props) => {
  const integrations = useIntegrations()
  const setupIntegrations =
    integrations.data?.integrations.integrationsMeta.filter((i) =>
      integrationConfigIsSetup(i.config?.[product] ?? {})
    )

  if ((setupIntegrations?.length ?? 0) <= 0) {
    if (showLabel) {
      return (
        <Button
          as={RemixLink}
          to="/integrations"
          leftIcon={<Icon as={TbCube} />}
          variant="outline"
          isDisabled={isDisabled}
        >
          Add to CRM
        </Button>
      )
    }

    return (
      <Tooltip label="Add to CRM">
        <IconButton
          flex={1}
          as={RemixLink}
          to="/integrations"
          aria-label="Add to CRM"
          icon={<Icon as={TbCube} />}
          variant="outline"
          isDisabled={isDisabled}
        />
      </Tooltip>
    )
  }

  if (setupIntegrations && setupIntegrations.length === 1) {
    return (
      <IntegrationAction
        singleAction
        integration={setupIntegrations[0]}
        signalIds={signalIds}
        product={product}
        showLabel={showLabel}
        integrationInfo={integrationInfo}
        isDisabled={isDisabled}
        onSuccess={onSuccess}
      />
    )
  }

  return (
    <>
      <Menu>
        {showLabel && (
          <MenuButton
            as={Button}
            leftIcon={<Icon as={TbCube} />}
            variant="outline"
            bgColor={
              integrationInfo?.integrationUpdatedAt ? "green.50" : undefined
            }
            colorScheme={
              integrationInfo?.integrationUpdatedAt ? "green" : "gray"
            }
            isDisabled={isDisabled}
          >
            Add to CRM
          </MenuButton>
        )}

        {!showLabel && (
          <MenuButton
            as={IconButton}
            aria-label="Add to Integration"
            icon={<Icon as={TbCube} />}
            variant="outline"
            bgColor={
              integrationInfo?.integrationUpdatedAt ? "green.50" : undefined
            }
            colorScheme={
              integrationInfo?.integrationUpdatedAt ? "green" : "gray"
            }
            isDisabled={isDisabled}
          />
        )}

        <MenuList zIndex={999} p={2}>
          {setupIntegrations?.map((item) => {
            return (
              <IntegrationAction
                key={item.integrationKey}
                integration={item}
                singleAction={false}
                signalIds={signalIds}
                product={product}
                integrationInfo={integrationInfo}
                isDisabled={isDisabled}
                onSuccess={onSuccess}
              />
            )
          })}
        </MenuList>
      </Menu>
    </>
  )
}
