import {
  Flex,
  Grid,
  GridItem,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import { SpecterProducts } from "@prisma/client"
import { ColDef, Column, GridApi } from "ag-grid-community"
import { useMemo, useState } from "react"
import { HiSortAscending, HiSortDescending } from "react-icons/hi"
import {
  HiArrowTrendingUp,
  HiMiniChevronDoubleLeft,
  HiMiniChevronDoubleRight,
  HiMiniChevronDown,
  HiMiniChevronLeft,
  HiMiniChevronRight,
  HiOutlineEye,
  HiOutlineEyeSlash,
  HiPlus,
} from "react-icons/hi2"
import {
  RiArrowDownLine,
  RiArrowUpDownLine,
  RiArrowUpLine,
} from "react-icons/ri"
import { z } from "zod"
import { Unpacked } from "~/types/global"
import { SIGNAL_SORT_VALIDATION } from "~/utils/db/sorting"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import invariant from "~/utils/invariant"
import { isEmpty } from "~/utils/isEmpty"
import { isOneOf } from "~/utils/isOneOf"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { oneOf } from "~/utils/oneOf"
import { getSignalFieldTitle } from "~/utils/signal"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { isNullish } from "~/utils/values"
import { FlexDivider } from "../FlexDivider"
import { MenuItem } from "../MenuItem"
import { SearchesAndListsProducts, SortRemoveIcon } from "../SignalCard"
import { getColumnId } from "./calculateColumnDefs"
import { GrowthFields } from "./cellRenderers"
import {
  ColumnDef,
  TableViewConfigs,
  TABLE_CONFIG_COLUMN_DEFS,
} from "./columnDefs"
import { getTableSortableFields, TableConfigs } from "./MemoizedTable"

const HIGHLIGHT_COLOR = "brand.500"
export const ADD_COLUMN = "add-column"

export type SignalSort<P extends SpecterProducts> = z.infer<
  (typeof SIGNAL_SORT_VALIDATION)[P]
>

export const signalSortFields = <P extends SpecterProducts>(product?: P) => {
  return isNullish(product)
    ? []
    : (Object.keys(
        SIGNAL_SORT_VALIDATION[product]?.shape ?? {}
      ) as (keyof SignalSort<P>)[])
}
export const useSortValue = (config?: TableConfigs) => {
  const [searchParams] = useSafeSearchParams()

  return useMemo(() => {
    const sortParam = searchParams.get("sort")
    const sort = JSONSafeParse(sortParam)

    if (isNullish(config)) return sort

    if (isOneOf(config, Object.values(SpecterProducts))) {
      return SIGNAL_SORT_VALIDATION[config]?.parse(sort)
    }

    // TODO: Define any - Implement saved searches and user lists
    return sort
  }, [config, searchParams])
}

export interface TableHeaderProps<Config extends TableConfigs> {
  column: Column
  onSort?: () => void
  eGridHeader?: HTMLElement | null
  config: Config
  api: GridApi
  updateColumnDefs: (
    update: (current: TableViewConfigs) => TableViewConfigs
  ) => void
}

const TableHeader = <Config extends TableConfigs>({
  column,
  eGridHeader,
  onSort,
  config,
  api,
  updateColumnDefs,
}: TableHeaderProps<Config>): JSX.Element => {
  const displayName =
    column.getColDef().headerName ?? getSignalFieldTitle(column.getColId())

  const [searchParams, setSearchParams] = useSafeSearchParams()
  const sort = useSortValue(config)

  // const growthFields: GrowthFields<Config> = useMemo(() => {
  //   return column.getColDef()?.["growthFields" as keyof ColDef] ?? []
  // }, [column])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const growthFields: GrowthFields<Config> =
    column.getColDef()?.["growthFields" as keyof ColDef] ?? []

  const product = useProduct()

  const sortableField = useMemo(
    () =>
      oneOf(
        column.getColId(),
        getTableSortableFields(config, product as SearchesAndListsProducts)
      ),
    [column, config, product]
  )

  const sortDirection: "asc" | "desc" | undefined = sortableField
    ? sort?.[sortableField as keyof typeof sort]
    : undefined

  const sortableSubFields = useMemo(
    () =>
      growthFields.map(({ field }) =>
        oneOf(
          field,
          getTableSortableFields(config, product as SearchesAndListsProducts)
        )
      ),
    [growthFields, config, product]
  )

  const sortDirectionSubFields: ("asc" | "desc" | undefined)[] = useMemo(
    () =>
      sortableSubFields.map((field) =>
        field ? sort?.[field as keyof typeof sort] : undefined
      ),
    [sort, sortableSubFields]
  )

  const applySortFactory =
    (
      sortField: string | undefined,
      newDir: "asc" | "desc" | undefined,
      onSort?: () => void
    ) =>
    () => {
      invariant(!!sortField, "Sorting is not allowed on this field")

      const newSearchParams = updateSearchParams(searchParams, {
        sort: { ...(newDir ? { [sortField]: newDir } : {}) },
      })

      setSearchParams(newSearchParams)

      onSort?.()
    }

  eGridHeader?.classList.toggle(
    "ag-header-cell-sorted",
    !!sortDirection || sortDirectionSubFields.some((dir) => !!dir)
  )

  const [headerMenuTab, setHeaderMenuTab] = useState<
    "main" | "add-column" | "growth-columns"
  >("main")

  const headerMenuClosure = useDisclosure()

  const isAddColumn = column.getColId() === ADD_COLUMN

  const hasMenu =
    !!sortableField ||
    !column.getUserProvidedColDef()?.suppressMovable ||
    isAddColumn

  const isOpen = headerMenuClosure.isOpen && hasMenu

  const allGrowthFields = TABLE_CONFIG_COLUMN_DEFS[config]?.find(
    (colDef) => getColumnId(colDef) === column.getColId()
  )?.growthFields

  return (
    <>
      <Popover isOpen={isOpen} onClose={headerMenuClosure.onClose}>
        <PopoverAnchor>
          <Text
            fontSize="xs"
            fontWeight="semibold"
            as="button"
            type="button"
            display="inline-flex"
            position="relative"
            onClick={() => {
              headerMenuClosure.onToggle()
              setHeaderMenuTab(isAddColumn ? "add-column" : "main")
            }}
            _hover={{
              color: HIGHLIGHT_COLOR,
              "& svg": {
                color: HIGHLIGHT_COLOR,
              },
            }}
            px={4}
            // {...(column.getUserProvidedColDef()?.checkboxSelection && {
            //   pl: 0,
            // })}
            gap={1}
            w="full"
            height="40px"
            alignItems="center"
            color={sortDirection === undefined ? "gray.500" : HIGHLIGHT_COLOR}
            overflow="hidden"
          >
            {displayName}
            {sortableField && sortDirection && (
              <Icon
                color={
                  sortDirection === undefined ? "gray.400" : HIGHLIGHT_COLOR
                }
                as={
                  sortDirection === undefined
                    ? RiArrowUpDownLine
                    : sortDirection === "asc"
                    ? RiArrowDownLine
                    : RiArrowUpLine
                }
              />
            )}
            {hasMenu && !isAddColumn && (
              <Icon ml="auto" as={HiMiniChevronDown} color="gray.400" />
            )}
            {isAddColumn && <Icon ml="auto" as={HiPlus} color="gray.400" />}
          </Text>
        </PopoverAnchor>

        {!!allGrowthFields?.length && (
          <Grid
            templateColumns={`repeat(${growthFields.length + 1}, 1fr)`}
            width="100%"
            px="var(--ag-cell-horizontal-padding)"
            py={1}
            position="absolute"
            left={0}
            bottom={0}
            fontSize="xs"
            fontWeight="normal"
          >
            <GridItem />
            {growthFields.map(({ field, timeSpan }, index) => (
              <TableHeaderGrowthField
                key={`${field}-${timeSpan}`}
                config={config}
                field={field}
                timeSpan={timeSpan}
                isSortable={!!sortableSubFields[index]}
                applySort={applySortFactory(
                  sortableSubFields[index] as string | undefined,
                  getNextSortDirection(sortDirectionSubFields[index]),
                  onSort
                )}
              />
            ))}
          </Grid>
        )}
        <Portal>
          <PopoverContent
            w={"fit-content"}
            minW={200}
            mx={10}
            maxH={400}
            overflowY="auto"
          >
            <PopoverBody>
              {headerMenuTab === "main" && (
                <>
                  {sortableField && (
                    <>
                      <MenuItem
                        icon={HiSortDescending} // The icons names are swapped. I know, it's stupid
                        onClick={() => {
                          applySortFactory(
                            sortableField as string,
                            "asc",
                            onSort
                          )()
                          headerMenuClosure.onClose()
                        }}
                        isDisabled={sortDirection === "asc"}
                      >
                        Sort Ascending
                      </MenuItem>

                      <MenuItem
                        icon={HiSortAscending} // The icons names are swapped. I know, it's stupid
                        onClick={() => {
                          applySortFactory(
                            sortableField as string,
                            "desc",
                            onSort
                          )()
                          headerMenuClosure.onClose()
                        }}
                        isDisabled={sortDirection === "desc"}
                      >
                        Sort Descending
                      </MenuItem>

                      <MenuItem
                        icon={SortRemoveIcon}
                        onClick={() => {
                          applySortFactory(
                            sortableField as string,
                            undefined,
                            onSort
                          )()
                          headerMenuClosure.onClose()
                        }}
                        isDisabled={!sortDirection}
                      >
                        Remove Sort
                      </MenuItem>
                    </>
                  )}
                  {sortableField &&
                    !column.getUserProvidedColDef()?.suppressMovable && (
                      <FlexDivider orientation="horizontal" />
                    )}
                  {!column.getUserProvidedColDef()?.suppressMovable && (
                    <>
                      {!!allGrowthFields?.length && (
                        <MenuItem
                          icon={HiArrowTrendingUp}
                          onClick={() => setHeaderMenuTab("growth-columns")}
                        >
                          Growth Metrics
                        </MenuItem>
                      )}
                      <MenuItem
                        icon={HiPlus}
                        onClick={() => setHeaderMenuTab("add-column")}
                      >
                        Add Column
                      </MenuItem>
                      <MenuItem
                        icon={HiOutlineEyeSlash}
                        onClick={() => {
                          api.setColumnsVisible([column.getId()], false)

                          headerMenuClosure.onClose()
                        }}
                      >
                        Remove Column
                      </MenuItem>
                      <FlexDivider orientation="horizontal" />
                      <MenuItem
                        icon={HiMiniChevronLeft}
                        onClick={() => {
                          moveColumn(api, column, "left")
                          headerMenuClosure.onClose()
                        }}
                      >
                        Move Left
                      </MenuItem>
                      <MenuItem
                        icon={HiMiniChevronRight}
                        onClick={() => {
                          moveColumn(api, column, "right")
                          headerMenuClosure.onClose()
                        }}
                      >
                        Move Right
                      </MenuItem>
                      <MenuItem
                        icon={HiMiniChevronDoubleLeft}
                        onClick={() => {
                          moveColumn(api, column, "start")
                          headerMenuClosure.onClose()
                        }}
                      >
                        Move to Start
                      </MenuItem>
                      <MenuItem
                        icon={HiMiniChevronDoubleRight}
                        onClick={() => {
                          moveColumn(api, column, "end")
                          headerMenuClosure.onClose()
                        }}
                      >
                        Move to End
                      </MenuItem>
                    </>
                  )}
                </>
              )}

              {headerMenuTab === "add-column" &&
                coalesceEmptyArray(
                  TABLE_CONFIG_COLUMN_DEFS[config]
                    ?.filter(
                      (colDef) =>
                        (api.getColumnDefs() as ColumnDef<Config>[])?.find(
                          (col) => getColumnId(col) === getColumnId(colDef)
                        )?.hide
                    )
                    .map((colDef) => (
                      <MenuItem
                        key={getColumnId(colDef)}
                        onClick={() => {
                          api.setColumnsVisible([getColumnId(colDef)], true)
                          headerMenuClosure.onClose()
                        }}
                      >
                        {colDef.headerName ??
                          getSignalFieldTitle(getColumnId(colDef))}
                      </MenuItem>
                    )),
                  <Text fontSize="sm" color="gray.300">
                    All columns are visible
                  </Text>
                )}

              {headerMenuTab === "growth-columns" &&
                coalesceEmptyArray(
                  allGrowthFields?.map(({ field }) => {
                    const isVisible = !!growthFields.find(
                      (growthField) => growthField.field === field
                    )

                    return (
                      <MenuItem
                        key={field}
                        onClick={() => {
                          console.log(field)
                          const oldGrowthsVisible = growthFields.map(
                            ({ field }) => field
                          )

                          const newGrowthsVisible = toggleArrayValue(
                            oldGrowthsVisible,
                            field
                          )

                          const newGrowthColumns = allGrowthFields
                            ?.filter(({ field }) =>
                              newGrowthsVisible.includes(field)
                            )
                            .map(({ field }) => field)

                          updateColumnDefs((current) => ({
                            ...current,
                            growthColumns: {
                              ...current.growthColumns,
                              [column.getId()]: newGrowthColumns,
                            },
                          }))

                          setTimeout(() => {
                            api.autoSizeColumns([column.getId()])
                          }, 10)
                        }}
                        icon={isVisible ? HiOutlineEye : HiOutlineEyeSlash}
                        opacity={isVisible ? 1 : 0.5}
                      >
                        {getSignalFieldTitle(field)}
                      </MenuItem>
                    )
                  }),
                  <Text fontSize="sm" color="gray.300">
                    All growth metrics are visible
                  </Text>
                )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

const toggleArrayValue = <T,>(arrayList: T[], arrayValue: T) =>
  arrayList.includes(arrayValue)
    ? arrayList.filter((el) => el !== arrayValue)
    : [...arrayList, arrayValue]

const getNextSortDirection = (currentDirection: "asc" | "desc" | undefined) => {
  return currentDirection === undefined
    ? "desc"
    : currentDirection === "desc"
    ? "asc"
    : undefined
}

const coalesceEmptyArray = <T, C>(
  array: T[] | undefined,
  coalesce: any = []
): T[] | C => {
  if (isNullish(array) || isEmpty(array)) return coalesce

  return array
}

const moveColumn = (
  api: GridApi,
  column: Column,
  direction: "left" | "right" | "start" | "end"
) => {
  const columnDefs = (api?.getColumnDefs() as ColDef[])?.filter(
    (colDef) => !colDef.hide
  )

  if (!columnDefs) return

  const currentIndex = columnDefs.findIndex(
    (colDef: ColDef) => colDef.colId === column.getColId()
  )

  if (isNullish(currentIndex)) return

  const startIndex =
    columnDefs.filter((col: ColDef) => col.pinned === "left").length ?? 0

  const endIndex =
    columnDefs.filter((col: ColDef) => col.pinned !== "right").length - 1

  const targetIndexMap = {
    start: startIndex,
    end: endIndex,
    left: currentIndex - 1,
    right: currentIndex + 1,
  }

  api.moveColumns([column], targetIndexMap[direction])
}

const TableHeaderGrowthField = <Config extends TableConfigs>({
  field,
  timeSpan,
  isSortable,
  applySort,
  config,
}: Unpacked<GrowthFields<Config>> & {
  applySort: () => void
  isSortable?: boolean
  config: Config
}) => {
  const sort = useSortValue(config)

  const product = useProduct()

  const sortableSubField = useMemo(
    () =>
      oneOf(
        field,
        getTableSortableFields(config, product as SearchesAndListsProducts)
      ),
    [field, config, product]
  )
  const sortDirectionSubField: "asc" | "desc" | undefined = sortableSubField
    ? sort?.[sortableSubField as keyof typeof sort]
    : undefined

  return (
    <GridItem
      key={field}
      as={Flex}
      justifyContent="flex-end"
      alignItems="center"
      {...(isSortable && {
        onClick: applySort,
        _hover: {
          cursor: "pointer",
          color: HIGHLIGHT_COLOR,
          "& svg": {
            color: HIGHLIGHT_COLOR,
          },
        },
      })}
      mr={isSortable ? 1 : 4}
    >
      <Text
        fontSize="xx-small"
        color={
          sortDirectionSubField === undefined ? "gray.500" : HIGHLIGHT_COLOR
        }
      >
        {timeSpan}
      </Text>
      <Icon
        ml={1}
        display={isSortable ? "block" : "none"}
        color={
          sortDirectionSubField === undefined ? "gray.400" : HIGHLIGHT_COLOR
        }
        as={
          sortDirectionSubField === undefined
            ? RiArrowUpDownLine
            : sortDirectionSubField === "asc"
            ? RiArrowDownLine
            : RiArrowUpLine
        }
      />
    </GridItem>
  )
}

export default TableHeader
