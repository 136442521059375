import { useIntercom } from "react-use-intercom"
import posthog from "posthog-js"
import { OG_DOMAIN } from "~/consts/users"
import { useEnv } from "./useEnv"
import { useJuneAnalytics } from "./useJuneAnalytics"
import { useUserPermissions } from "./useUserPermissions"

const AnalyticsPlatforms = ["june", "intercom", "posthog"] as const
type AnalyticsPlatform = (typeof AnalyticsPlatforms)[number]

export const useAnalytics = () => {
  const userPermissions = useUserPermissions()
  const june = useJuneAnalytics()
  const intercom = useIntercom()
  const env = useEnv()

  // new event names for posthog
  const newEventNames: Record<string, string> = {
    "Applied a Filter": "applied_filter",
    "Used a Suggested Filter": "used_suggested_filter",
    "Imported List": "imported_list",
    "Signed up": "signed_up",
    "Created List": "created_list",
    "Saved Search": "saved_search",
    "Shared Search": "shared_search",
    "Shared List": "shared_list",
    "Explored Feed": "loaded_feed",
    // eslint-disable-next-line no-useless-computed-key
    ["Export"]: "exported_data",
    "Invite to Team": "invited_users",
    "User edited a Saved Search": "edited_saved_search",
  }

  const track = (
    event: string,
    properties: Record<string, any> = {},
    constraints?:
      | { exclude?: AnalyticsPlatform[]; only?: never }
      | { exclude?: never; only?: AnalyticsPlatform[] }
  ) => {
    const shouldTrack = (platform: AnalyticsPlatform) =>
      constraints?.exclude
        ? !constraints.exclude.includes(platform)
        : constraints?.only
        ? constraints.only.includes(platform)
        : true

    // Track using June (skip OG users)
    if (
      shouldTrack("june") &&
      !userPermissions.data?.email?.includes(OG_DOMAIN)
    ) {
      june?.track(event, properties, {
        context: { groupId: userPermissions.data?.organizationId },
      })
    }

    // Track using Intercom
    if (shouldTrack("intercom") && env.INTERCOM_ENABLED) {
      intercom.trackEvent(event, properties)
    }

    // Track using PostHog (convert event name if mapping exists)
    if (shouldTrack("posthog") && env.POSTHOG_KEY) {
      const posthogEventName = newEventNames[event] || event
      posthog.capture(posthogEventName, properties)
    }
  }

  return { track }
}
