import { EntityStatus, SpecterProducts } from "@prisma/client"
import { useSearchParams } from "@remix-run/react"
import { cacheKeys } from "~/utils/cacheKeys"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { isOneOf } from "~/utils/isOneOf"
import { queryClient } from "~/utils/queryClient"
import { CopyLinkButton } from "../CopyLinkButton"
import { mapProductsToRouteName } from "../Filters/schemas"
import { IntegrationSignalButton } from "../IntegrationSignalButton"
import { ListRemoveModal } from "../ListRemoveModal"
import {
  ActionButtonProps,
  AddManyToListIconButton,
  AddToAffinityIconButton,
  AddToListButton,
  AddToListIconButton,
  ExportSignalsIconButton,
} from "./Buttons"
import { PRODUCTS_WITH_INTEGRATIONS } from "~/utils/integrations"
import { Icon } from "~/utils/components/Icon"
import { IconButton, Tooltip } from "@chakra-ui/react"
import { PiThumbsDownBold, PiThumbsUpBold } from "react-icons/pi"
import { useEntityStatus } from "~/utils/hooks/useEntityStatus"
import { oneOf } from "~/utils/oneOf"
export type SearchesAndListsProducts =
  (typeof PRODUCTS_WITH_LISTS_AND_SEARCHES)[number]

export const PRODUCTS_WITH_LISTS_AND_SEARCHES = [
  SpecterProducts.company,
  SpecterProducts.talent,
  SpecterProducts.stratintel,
  SpecterProducts.people,
  SpecterProducts.investors,
]

type Props<P extends SpecterProducts> = ActionButtonProps<P> & {
  containerRef?: React.RefObject<HTMLElement | null>
  showAll?: boolean
}

export const ActionMenuIcons = <P extends SpecterProducts>({
  product,
  signal,
}: Props<P>) => {
  const integrationsQuery = useIntegrations()

  const disableLegacyAffinity =
    integrationsQuery.data?.integrations.integrationsMeta.some(
      (i) => i.integrationKey === "affinity"
    ) ?? false

  const hasAffinityProductIntegration = {
    company: integrationsQuery.data?.integrations.hasAffinityCompanyList,
    talent: integrationsQuery.data?.integrations.hasAffinityTalentList,
    stratintel: integrationsQuery.data?.integrations.hasAffinityStrategicList,
    investors: false,
    fundingRounds: false,
    acquisition: false,
    ipo: false,
    people: false,
  }

  const legacyAffinitySetup = hasAffinityProductIntegration[product]
  const hasIntegrations = isOneOf(product, PRODUCTS_WITH_INTEGRATIONS)
  const entityStatus = useEntityStatus({
    entityId: signal.id,
    product,
    initialValue:
      "entityStatus" in signal
        ? {
            status:
              oneOf(signal.entityStatus?.status, Object.values(EntityStatus)) ??
              null,
            updated_at: signal.entityStatus?.updated_at ?? null,
          }
        : null,
  })

  return (
    <>
      {isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) && (
        <>
          <Tooltip
            label={
              entityStatus.status?.status === EntityStatus.liked
                ? "You like this"
                : "Like"
            }
          >
            <IconButton
              flex={1.2}
              icon={<Icon as={PiThumbsUpBold} boxSize={3} />}
              aria-label="Like"
              bgColor={
                entityStatus.status?.status === EntityStatus.liked
                  ? "green.50"
                  : undefined
              }
              colorScheme={
                entityStatus.status?.status === EntityStatus.liked
                  ? "green"
                  : undefined
              }
              onClick={() => {
                entityStatus.update({
                  entityId: signal.id,
                  status: EntityStatus.liked,
                })
              }}
            />
          </Tooltip>
          <Tooltip
            label={
              entityStatus.status?.status === EntityStatus.disliked
                ? "You dislike this"
                : "Dislike"
            }
          >
            <IconButton
              flex={1.2}
              icon={<Icon as={PiThumbsDownBold} boxSize={3} />}
              aria-label="Dislike"
              bgColor={
                entityStatus.status?.status === EntityStatus.disliked
                  ? "red.50"
                  : undefined
              }
              colorScheme={
                entityStatus.status?.status === EntityStatus.disliked
                  ? "red"
                  : undefined
              }
              onClick={() => {
                entityStatus.update({
                  entityId: signal.id,
                  status: EntityStatus.disliked,
                })
              }}
            />
          </Tooltip>
        </>
      )}

      <AddToListIconButton product={product} signal={signal} />

      {hasIntegrations && (
        <IntegrationSignalButton
          signalIds={[signal.id]}
          product={product}
          integrationInfo={
            "integrationInfo" in signal ? signal.integrationInfo : undefined
          }
        />
      )}

      {!hasIntegrations && (
        <CopyLinkButton
          url={`/signals/${mapProductsToRouteName(product)}/feed/${
            signal.id
          }/f`}
        />
      )}

      {!disableLegacyAffinity && legacyAffinitySetup && (
        <AddToAffinityIconButton product={product} signal={signal} />
      )}
    </>
  )
}

export const ActionMenu = <P extends SpecterProducts>({
  product,
  signal,
}: Props<P>) => {
  const entityStatus = useEntityStatus({
    entityId: signal.id,
    product,
    initialValue:
      "entityStatus" in signal
        ? {
            status:
              oneOf(signal.entityStatus?.status, Object.values(EntityStatus)) ??
              null,
            updated_at: signal.entityStatus?.updated_at ?? null,
          }
        : null,
  })

  return (
    <>
      {isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) && (
        <>
          <Tooltip
            label={
              entityStatus.status?.status === EntityStatus.liked
                ? "You like this"
                : "Like"
            }
          >
            <IconButton
              icon={<Icon as={PiThumbsUpBold} />}
              aria-label="Like"
              colorScheme={
                entityStatus.status?.status === EntityStatus.liked
                  ? "green"
                  : undefined
              }
              bgColor={
                entityStatus.status?.status === EntityStatus.liked
                  ? "green.50"
                  : undefined
              }
              onClick={() => {
                entityStatus.update({
                  entityId: signal.id,
                  status: EntityStatus.liked,
                })
              }}
            />
          </Tooltip>
          <Tooltip
            label={
              entityStatus.status?.status === EntityStatus.disliked
                ? "You dislike this"
                : "Dislike"
            }
          >
            <IconButton
              icon={<Icon as={PiThumbsDownBold} />}
              aria-label="Dislike"
              colorScheme={
                entityStatus.status?.status === EntityStatus.disliked
                  ? "red"
                  : undefined
              }
              bgColor={
                entityStatus.status?.status === EntityStatus.disliked
                  ? "red.50"
                  : undefined
              }
              onClick={() => {
                entityStatus.update({
                  entityId: signal.id,
                  status: EntityStatus.disliked,
                })
              }}
            />
          </Tooltip>
        </>
      )}

      {isOneOf(product, [
        ...PRODUCTS_WITH_LISTS_AND_SEARCHES,
        SpecterProducts.fundingRounds,
        SpecterProducts.acquisition,
        SpecterProducts.ipo,
      ]) && <AddToListButton product={product} signal={signal} />}

      {isOneOf(product, PRODUCTS_WITH_INTEGRATIONS) && (
        <IntegrationSignalButton
          signalIds={[signal.id]}
          product={product}
          showLabel
          integrationInfo={
            "integrationInfo" in signal ? signal.integrationInfo : null
          }
        />
      )}
    </>
  )
}

export const ActionMenuSelection = <P extends SpecterProducts>({
  product,
  signalIds,
}: {
  product: P
  signalIds: string[]
}) => {
  const [searchParams] = useSearchParams()
  const listId = searchParams.get("listId")

  return (
    <>
      {listId && (
        <ListRemoveModal
          isSelectionMenu
          items={signalIds}
          product={product}
          listId={listId}
          onSuccess={async () => {
            await queryClient.invalidateQueries(
              cacheKeys.userLists({ product })
            )
            await queryClient.invalidateQueries(
              cacheKeys.signals(product, searchParams, ViewMode.Table)
            )
            await queryClient.invalidateQueries(
              cacheKeys.signalsCount({ product, searchParams })
            )
          }}
        />
      )}
      {isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) && (
        <AddManyToListIconButton product={product} signalIds={signalIds} />
      )}

      {isOneOf(product, PRODUCTS_WITH_INTEGRATIONS) && (
        <IntegrationSignalButton signalIds={signalIds} product={product} />
      )}

      <ExportSignalsIconButton product={product} signalIds={signalIds} />
    </>
  )
}
