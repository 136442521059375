import { ColDef, GridApi } from "ag-grid-community"

import {
  ColumnDef,
  TABLE_CONFIG_COLUMN_DEFS,
  TableViewConfigs,
} from "~/components/Table/columnDefs"
import invariant from "~/utils/invariant"
import { TableConfigs } from "./MemoizedTable"
import { ADD_COLUMN } from "./TableHeader"

const DEFAULT_COLUMN_WIDTH = 250

export const getColumnId = (colDef: ColumnDef<any>) =>
  colDef.colId ?? colDef.field

export const calculateColumnDefs = <Config extends TableConfigs>(
  config: Config,
  newTableView: TableViewConfigs,
  api?: GridApi
) => {
  const configColumnDefs = TABLE_CONFIG_COLUMN_DEFS[config]

  invariant(configColumnDefs, `Table view not available for config: ${config}`)

  const currentTableView = newTableView

  const allColumns = [
    // Pinned left
    ...Object.entries(currentTableView.pinned ?? {})
      .filter(([_, dir]) => dir === "left")
      .map(([key]) => key),
    // Normal columns
    ...currentTableView.columns.filter(
      (col) => !(col in (currentTableView.pinned ?? {}))
    ),
    // Pinned right
    ...Object.entries(currentTableView?.pinned ?? {})
      .filter(([_, dir]) => dir === "right")
      .map(([key]) => key),
  ]

  const calculatedColumnDefs = (
    [
      ...allColumns
        .map((field) => {
          const colDef = configColumnDefs.find(
            (col) => getColumnId(col) === field
          )

          if (!colDef) {
            console.error(`Column definition not found for field: ${field}`)
            return null
          }

          // Show only the growth fields visible in the table view
          const visibleGrowthFields = currentTableView.growthColumns?.[field]

          return {
            ...colDef,
            growthFields: colDef.growthFields?.filter(({ field }) =>
              visibleGrowthFields?.includes(field)
            ),
          }
        })
        .filter(Boolean),
      ...configColumnDefs.filter(
        (colDef) => !allColumns.includes(getColumnId(colDef))
      ),
      ...(allColumns.length !== configColumnDefs.length
        ? [
            {
              field: ADD_COLUMN,
              headerName: "Add Column",
              lockPinned: true,
              suppressMovable: true,
              lockPosition: "right",
              resizable: false,
              width: 120,
            },
          ]
        : []),
    ] as ColumnDef<Config>[]
  ).map((columnDef) => {
    const pinned = currentTableView.pinned?.[getColumnId(columnDef)]
    const width = currentTableView.widths?.[getColumnId(columnDef)]

    return {
      ...columnDef,

      // Apply pinned columns
      ...(pinned && { pinned }),

      // Apply column widths
      ...(width
        ? { width }
        : columnDef.suppressAutoSize && {
            width: DEFAULT_COLUMN_WIDTH,
          }),

      // Apply locked properties
      ...(columnDef.baseLockedColumn && {
        pinned: "left",
        lockPinned: true,
        suppressMovable: true,
        lockPosition: true,
      }),

      ...(!allColumns.includes(getColumnId(columnDef)) &&
        getColumnId(columnDef) !== ADD_COLUMN && { hide: true }),
    }
  })

  // ! The setTimeout is to prevent ag-grid complaining about changing the column defs while grid is being drawn
  // @ts-ignore
  setTimeout(
    () => api?.setGridOption("columnDefs", calculatedColumnDefs as ColDef[]),
    0
  )

  return {
    columnDefs: [...calculatedColumnDefs] as ColumnDef<Config>[],
    currentTableView,
  }
}
