import {
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
} from "react-icons/hi"
import { Flex, Kbd, Text } from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"

export const NavigateShortcutPrompt = () => {
  return (
    <Flex alignItems="center" gap={1}>
      <Kbd bgColor="white" fontSize="lg">
        <Icon as={HiChevronUp} />
      </Kbd>
      <Kbd bgColor="white" fontSize="lg">
        <Icon as={HiChevronDown} />
      </Kbd>
      <Text fontSize="xs" fontWeight="medium" color="gray.500" ml={0.5}>
        Navigate
      </Text>
    </Flex>
  )
}

export const NavigateTabsPrompt = () => {
  return (
    <Flex alignItems="center" gap={1}>
      <Kbd bgColor="white" fontSize="lg">
        <Icon as={HiChevronLeft} />
      </Kbd>
      <Kbd bgColor="white" fontSize="lg">
        <Icon as={HiChevronRight} />
      </Kbd>
      <Text fontSize="xs" fontWeight="medium" color="gray.500" ml={0.5}>
        Change tab
      </Text>
    </Flex>
  )
}
