import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from "@chakra-ui/react"
import { HiBriefcase, HiExternalLink } from "react-icons/hi"

import { Link as RemixLink } from "@remix-run/react"
import { CompanyLogo } from "~/components"
import { BoxIcon } from "~/components/BoxIcon"
import { Card } from "~/components/Card"
import { TALENT_SIGNAL_STATUS } from "~/consts/talentSignals"
import { Icon } from "~/utils/components/Icon"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"

interface Props {
  talentSignal: GetPeopleByID
}

export const TalentSignalNewPositionSmallCard = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (!("signal_type" in talentSignal)) return null
  if (talentSignal.current_position_company_name === null) return null

  // const newCompanyName = pickKeyFromKeyValue(talentSignal.NewPosition)
  // const newPosition = pickValueFromKeyValue(talentSignal.NewPosition)
  const isStealth = talentSignal.signal_status === TALENT_SIGNAL_STATUS.STEALTH

  return (
    <Card
      p={4}
      display="flex"
      flexDirection="column"
      gap={2}
      maxW={250}
      minW={150}
    >
      <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
        New Position
      </Text>

      <HStack alignItems="center">
        {talentSignal.current_position_company_website !== null ? (
          <CompanyLogo
            domain={talentSignal.current_position_company_website}
            size={8}
          />
        ) : isStealth ? (
          <Box flex="none">
            <Image
              alt={talentSignal.current_position_company_name}
              src="/stealth-logo.png"
              w={8}
              h={8}
            />
          </Box>
        ) : (
          <BoxIcon icon={HiBriefcase} size={8} />
        )}
        <Flex
          direction="column"
          justifyContent="center"
          fontSize="xs"
          overflow="hidden"
        >
          {talentSignal.current_position_company_website !== null ? (
            <Link
              {...(talentSignal.current_position_specter_company_id
                ? {
                    as: RemixLink,
                    to: `/signals/company/feed/${talentSignal.current_position_specter_company_id}/f`,
                  }
                : {
                    href:
                      talentSignal.current_position_company_website ??
                      undefined,
                    target: "_blank",
                    to: "",
                  })}
              color="brand.500"
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {talentSignal.current_position_company_name}
            </Link>
          ) : (
            <Text
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {talentSignal.current_position_company_name}
            </Text>
          )}

          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {talentSignal.current_position_title}
          </Text>
        </Flex>
      </HStack>
    </Card>
  )
}

export const TalentSignalNewPositionCard = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (!("signal_type" in talentSignal)) return null
  if (talentSignal.current_position_title === null) return null

  // const newCompanyName = pickKeyFromKeyValue(talentSignal.NewPosition)
  // const newPosition = pickValueFromKeyValue(talentSignal.NewPosition)
  const isStealth = talentSignal.signal_status === TALENT_SIGNAL_STATUS.STEALTH

  return (
    <Card p={5}>
      <Heading as="h1" size="sm" mb={2}>
        New Position
      </Heading>

      <HStack alignItems="start">
        {talentSignal.current_position_company_website !== null ? (
          <CompanyLogo domain={talentSignal.current_position_company_website} />
        ) : isStealth ? (
          <Box flex="none">
            <Image alt="Stealth icon" src="/stealth-logo.png" w={10} h={10} />
          </Box>
        ) : (
          <BoxIcon icon={HiBriefcase} />
        )}

        <Box>
          {talentSignal.current_position_company_website !== null ? (
            <Link
              {...(talentSignal.current_position_specter_company_id
                ? {
                    as: RemixLink,
                    to: `/signals/company/feed/${talentSignal.current_position_specter_company_id}/f`,
                  }
                : {
                    href:
                      talentSignal.current_position_company_website ??
                      undefined,
                    target: "_blank",
                    to: "",
                  })}
              color="brand.500"
              fontWeight="semibold"
              mb={1}
            >
              {talentSignal.current_position_company_name}
            </Link>
          ) : (
            <Heading as="h2" size="xs" mb={1}>
              {talentSignal.current_position_company_name}
            </Heading>
          )}

          <Text lineHeight={1} fontSize="sm" mb={1}>
            {talentSignal.current_position_title}
          </Text>

          {/* // TODO: Founded Date?  */}
          {/* {talentSignal.FoundedDate && (
            <Text fontSize="xs" color="gray.500">
              Founded {talentSignal.FoundedDate}
            </Text>
          )} */}

          {/* // TODO: Tagline? */}
          {/* {talentSignal.NewCompanyTagline && (
            <Text fontSize="xs" color="gray.500">
              "{talentSignal.NewCompanyTagline}"
            </Text>
          )} */}

          <ButtonGroup spacing={1} mt={1}>
            {talentSignal.linkedin_url && (
              <Button
                as="a"
                href={talentSignal.linkedin_url}
                target="_blank"
                size="xs"
                variant="outline"
                rightIcon={<Icon as={HiExternalLink} />}
              >
                LinkedIn
              </Button>
            )}

            {talentSignal.current_position_company_website && (
              <Button
                size="xs"
                variant="outline"
                as="a"
                target="_blank"
                href={talentSignal.current_position_company_website}
                rightIcon={<Icon as={HiExternalLink} />}
              >
                Website
              </Button>
            )}
          </ButtonGroup>
        </Box>
      </HStack>

      {/* // TODO: Industry and Tags? */}
      {/* <Flex pt={4} flexWrap="wrap" gap={1}>
        {talentSignal.IndustryOG && (
          <Industry industry={String(talentSignal.IndustryOG)} />
        )}

        {talentSignal.Tags && <Tags tags={talentSignal.Tags?.split(", ")} />}
      </Flex> */}
    </Card>
  )
}
