import { getIntegrationColor, groupEnrichments } from "~/utils/integrations"

import { Enrichments } from "~/routes/__protected/api/integrations/$key/enrichments"
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react"
import { getIntegrationLogo } from "~/utils/integrations"
import { DailyCronCountdownTag } from "../DailyCronCountdownTag"

interface Props {
  integrationEnrichments: Enrichments
}

export const IntegrationEnrichmentsTags = ({
  integrationEnrichments,
}: Props) => {
  return (
    <>
      {Object.values(groupEnrichments(integrationEnrichments)).map(
        (enrichment) => {
          const color = getIntegrationColor(enrichment.integrationKey)
          return (
            <Popover key={enrichment.integrationKey}>
              <PopoverTrigger>
                <IconButton
                  pos="relative"
                  aria-label={enrichment.integrationKey}
                  bgColor={color}
                  variant="outline"
                  size="xs"
                  icon={
                    <>
                      <Box
                        position="absolute"
                        bgColor="green.500"
                        boxSize={2}
                        rounded="full"
                        borderWidth={1}
                        borderColor="white"
                        top={-1}
                        right={-1}
                      />
                      <Image
                        src={getIntegrationLogo(enrichment.integrationKey)}
                        w={4}
                      />
                    </>
                  }
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent p={0} maxW={200} overflow="hidden">
                  <Box
                    textAlign="center"
                    p={1}
                    bgColor="gray.50"
                    borderBottomWidth={1}
                  >
                    <Text fontSize="xs" fontWeight="medium">
                      Next scheduled enrichment
                    </Text>
                  </Box>
                  <Flex justifyContent="center" p={2}>
                    <DailyCronCountdownTag />
                  </Flex>
                </PopoverContent>
              </Portal>
            </Popover>
          )
        }
      )}
    </>
  )
}
