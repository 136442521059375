import {
  Alert,
  AlertIcon,
  AlertTitle,
  Flex,
  Spacer,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Prisma, Queries, QueriesByUser, SpecterProducts } from "@prisma/client"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { SearchesAndListsProducts } from "~/components"
import { FeedLoadingLogo } from "~/components/Logo/loading"
import {
  createSearchSort,
  getSearchUrlFromProduct,
} from "~/components/UserSearchesPage/utils"
import { SavedSearchesIndex } from "~/routes/__protected/api/saved-searches"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { cacheKeys } from "~/utils/cacheKeys"
import { useHiddenSearchesLists } from "~/utils/hooks/useHiddenSearchesLists"
import {
  isSafeURL,
  URLSearchParamsInit,
  useSafeSearchParams,
} from "~/utils/hooks/useSafeSearchParams"
import { ViewMode } from "~/utils/hooks/useViewMode"
import invariant from "~/utils/invariant"
import { relativeUrl } from "~/utils/string/url"
import { hasLength } from "~/utils/values"
import { TableComponent } from "../Table"

export function generateURLFactory({
  searchParams,
  setSearchParams,
  product,
  viewMode,
  query,
  sort,
  search,
  searchId,
}: {
  searchParams?: URLSearchParams
  setSearchParams: (value: URLSearchParamsInit) => void
  product: SpecterProducts
  viewMode: ViewMode
  query: Queries["query"]
  sort?: QueriesByUser["sort"]
  search?: string | null
  searchId?: number
}) {
  return (createQuery: (query: Prisma.JsonValue) => string) => {
    const listId = searchParams?.get("listId")

    const toParams = {
      ...(listId && { listId }),
      ...(query && { query: createQuery(query) }),
      ...(searchId && {
        searchId: String(searchId),
      }),
      ...(sort && { sort: createSearchSort(sort) }),
      ...(search && { search }),
    }

    const isSafe = isSafeURL(toParams)
    const to = relativeUrl(
      getSearchUrlFromProduct(product, viewMode),
      isSafe ? toParams : {}
    )
    const outOfURL = !isSafe ? toParams : {}

    const onNavigate = () => {
      // this will only be set if params are too big
      if (!isSafe) setSearchParams(outOfURL)
    }

    return [to, onNavigate] as const
  }
}

export const SEARCHES_TABLE_PAGE_SIZE = 25

export function SavedSearchesTable({
  product,
}: {
  product: SpecterProducts
}): JSX.Element {
  const hiddenDisclosure = useDisclosure()
  const { hidden: hiddenSearches } = useHiddenSearchesLists(
    ListType.savedSearch
  )
  const [searchParams] = useSafeSearchParams()

  const savedSearchesQuery = useInfiniteQuery<SavedSearchesIndex>(
    cacheKeys.userSavedSearches(product, searchParams.toString()),
    async ({ pageParam = 0 }) => {
      const reqSearchParams = new URLSearchParams({
        product,
        ...(pageParam && { page: pageParam }),
        ...(searchParams.has("sort") && {
          sort: searchParams.get("sort") ?? "",
        }),
        ...(searchParams.has("search") && {
          search: searchParams.get("search") ?? "",
        }),
      })

      const res = await fetch(
        `/api/saved-searches?${reqSearchParams.toString()}`
      )

      invariant(res.ok, "Failed to load searches")

      return await res.json()
    },
    {
      getNextPageParam(result) {
        // No more pages if we haven't got items
        if (result.items) {
          if (!hasLength(result.items)) {
            // Needs to undefined as per
            //https://tanstack.com/query/v4/docs/react/guides/infinite-queries
            return undefined
          }

          // No more pages if the results are _less_ than the FEED_PAGE_SIZE
          if (result.items.length < SEARCHES_TABLE_PAGE_SIZE) {
            return undefined
          }
        }

        return result.page + 1
      },
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnMount: false,
    }
  )

  const rowData = useMemo(
    () =>
      savedSearchesQuery.data?.pages.flatMap(
        // @ts-ignore
        ({ items }) => items
      ) as unknown as SavedSearchesIndex["items"] | undefined,
    [savedSearchesQuery.data]
  )

  if (savedSearchesQuery.isLoading) {
    return <FeedLoadingLogo />
  }

  if (savedSearchesQuery.isError) {
    return (
      <Alert>
        <AlertIcon />
        <AlertTitle>Uh oh! something went wrong</AlertTitle>
      </Alert>
    )
  }

  const visibleSearches =
    rowData?.filter((savedSearch) => {
      if (hiddenDisclosure.isOpen) {
        return hiddenSearches.includes(savedSearch.id.toString())
      }

      return !hiddenSearches.includes(savedSearch.id.toString())
    }) ?? []

  const totalHidden =
    rowData?.filter((savedSearch) => {
      return hiddenSearches.includes(savedSearch.id.toString())
    }).length ?? 0

  return (
    <>
      <Flex
        alignItems="flex-end"
        py={3}
        gap={1}
        px={6}
        borderBottomWidth={1}
        borderColor="gray.100"
      >
        <Text fontSize="xs" color="gray.500">
          Showing{" "}
          <Text as="span" fontWeight="semibold">
            {visibleSearches.length}
          </Text>
          {hiddenDisclosure.isOpen ? " hidden " : " "}
          searches
        </Text>

        <Spacer />

        <Flex gap={1} alignItems="center">
          <Text fontSize="xs" color="gray.400" as="label" htmlFor="show-hidden">
            Show Hidden Searches
            {totalHidden > 0 && ` (${totalHidden})`}
          </Text>
          <Switch
            id="show-hidden"
            size="sm"
            colorScheme="brand"
            isChecked={hiddenDisclosure.isOpen}
            onChange={() => hiddenDisclosure.onToggle()}
          />
        </Flex>
      </Flex>

      <Flex flexDirection="column" flexGrow={1}>
        <TableComponent
          config={`saved-searches.${product as SearchesAndListsProducts}`}
          rowData={visibleSearches}
          signalsQuery={savedSearchesQuery}
          styleOverrides={{
            "--column-border-color": "transparent",
            "--ag-grid-size": "10px",
            "--ag-header-background-color": "#f9fafa",
            "--ag-row-hover-color": "transparent",
            ".ag-cell:hover::before": {
              content: "none",
            },
            ".ag-cell": {
              cursor: "default !important",
            },
          }}
          agGridProps={{
            headerHeight: 25,
          }}
          disableSelection
          suppressLastInfoRow
        />
      </Flex>
    </>
  )
}
