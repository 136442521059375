import { Box, Flex, IconButton, Text, useToast } from "@chakra-ui/react"
import { IntegrationPushStatus, SpecterProducts } from "@prisma/client"
import {
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import invariant from "~/utils/invariant"
import { useAnalytics } from "./useAnalytics"
import { useIntegrationService } from "./useIntegrationService"
import {
  getIntegrationName,
  getIntegrationPushStatusText,
  integrationMetaSchema,
  ProductWithIntegrations,
} from "../integrations"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { ViewMode } from "./useViewMode"
import { z } from "zod"
import { EnvProvider, useEnv } from "./useEnv"
import { HiCheckCircle, HiOutlineX } from "react-icons/hi"
import { Icon } from "../components/Icon"
import { IntegrationPushProgress } from "~/components/IntegrationPushProgress"
import { getProductEntityName } from "../getProductName"
import pluralize from "pluralize"
import { IntegrationProductItem } from "~/routes/__protected/api/signals/$product/integrations"
import { useSafeSearchParams } from "./useSafeSearchParams"
import { useUser } from "@clerk/remix"

const StatusToast = ({
  logId,
  product,
  totalSignals,
  integrationKey,
  onComplete,
}: {
  logId: number
  product: SpecterProducts
  totalSignals: number
  integrationKey: string
  onComplete?: () => void
}) => {
  const integrationService = useIntegrationService()
  const logQuery = useQuery({
    refetchInterval(data) {
      if (data?.status !== IntegrationPushStatus.COMPLETE) {
        return 1000
      }

      return 0
    },
    queryKey: ["integration-push-log", logId],
    queryFn: async () => {
      const req = await integrationService.post("/log", {
        id: logId.toString(),
      })

      const result = z
        .object({
          status: z.nativeEnum(IntegrationPushStatus),
          error: z.string().nullable(),
        })
        .parse(req)

      if (result.status === IntegrationPushStatus.COMPLETE) {
        setTimeout(() => {
          onComplete?.()
        }, 3000)
      }

      return result
    },
  })

  const isComplete = logQuery.data?.status === IntegrationPushStatus.COMPLETE

  return (
    <Box
      bgColor="white"
      rounded="lg"
      px={5}
      py={3}
      borderWidth={1}
      borderColor="gray.100"
      shadow="lg"
      w="320px"
      pos="relative"
    >
      <Flex gap={4} alignItems="center">
        {isComplete && (
          <Icon as={HiCheckCircle} color="brand.500" fontSize="lg" />
        )}
        <Box>
          {!isComplete && (
            <Box mb={2}>
              <IntegrationPushProgress
                status={logQuery.data?.status ?? IntegrationPushStatus.IDLE}
              />
            </Box>
          )}

          <Text fontSize="sm" fontWeight="medium">
            {getIntegrationPushStatusText(
              {
                status: logQuery.data?.status ?? IntegrationPushStatus.IDLE,
                total: totalSignals,
              },
              product
            )}
          </Text>
          {!isComplete && (
            <Text fontSize="xs" color="gray.500">
              This may take a few minutes — check the Integration Activity for
              progress.
            </Text>
          )}
          {logQuery.data?.error && isComplete && (
            <Text fontSize="xs" color="red.500">
              Something caused an error. Review the Integration Activity for
              more information.
            </Text>
          )}

          {!logQuery.data?.error && isComplete && (
            <Text fontSize="xs" color="green.500">
              Your {pluralize(getProductEntityName(product), totalSignals)} have
              been successfully pushed to {getIntegrationName(integrationKey)}.
              Check the integration activity for details.
            </Text>
          )}
        </Box>
      </Flex>
      <IconButton
        size="xs"
        variant="ghost"
        icon={<Icon as={HiOutlineX} />}
        aria-label="Close"
        onClick={() => onComplete?.()}
        pos="absolute"
        isRound
        top={2}
        right={2}
      />
    </Box>
  )
}

interface Params {
  integrationKey: string
  signalIds: string[]
}

export const useIntegrationPushMutation = (
  product: ProductWithIntegrations,
  onSuccess?: () => void
) => {
  const toast = useToast()
  const analytics = useAnalytics()
  const integrationService = useIntegrationService()
  const queryClient = useQueryClient()
  const env = useEnv()
  const [searchParams] = useSafeSearchParams()
  const { user } = useUser()

  const pushMutation = useMutation({
    async mutationFn({ integrationKey, signalIds }: Params) {
      analytics.track("Integration Push", {
        integrationKey,
        product,
      })

      const metaReq = await fetch(`/api/integrations/${integrationKey}/meta`)
      const metaRes = await metaReq.json()
      const integrationMeta = integrationMetaSchema.parse(metaRes)

      const serverSearchParams = new URLSearchParams(searchParams)
      serverSearchParams.set("view", ViewMode.Table)

      const req = await fetch(
        `/api/signals/${mapProductsToRouteName(product)}/integrations`,
        {
          method: "POST",
          body: JSON.stringify({
            ...(signalIds.length > 0 ? { ids: signalIds } : {}),
            ...Object.fromEntries(serverSearchParams.entries()),
          }),
        }
      )

      invariant(req.ok)

      const data: {
        items: IntegrationProductItem<typeof product>[]
      } = await req.json()

      const config = integrationMeta.config?.[product]

      invariant(config, "Config not found")

      const pushLog = await integrationService.post("/push-signals", {
        integrationKey,
        signals: data.items,
        product,
        dataSource: config?.dataSource,
        dataMatching: config?.dataMatching,
        allowCreate: config?.allowCreate,
        fieldMapping: config?.fieldMapping,
        listId: config?.listId,
        userId: user?.id,
      })

      const result = z
        .object({
          id: z.number(),
        })
        .parse(pushLog)

      return {
        logId: result.id,
        totalSignals: data.items.length,
        integrationKey,
      }
    },
    onSuccess({ logId, totalSignals, integrationKey }) {
      onSuccess?.()
      const toastId = toast({
        duration: null,
        position: "top-right",
        render: () => {
          return (
            <QueryClientProvider client={queryClient}>
              <EnvProvider env={env}>
                <StatusToast
                  logId={logId}
                  integrationKey={integrationKey}
                  product={product}
                  totalSignals={totalSignals}
                  onComplete={() => {
                    if (toastId) {
                      toast.close(toastId)
                    }
                  }}
                />
              </EnvProvider>
            </QueryClientProvider>
          )
        },
      })
    },
  })

  return pushMutation
}
