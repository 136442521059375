import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { SEARCH_EXPORT_LIMIT } from "~/consts/signals"
import { ProductSignalFeedItems } from "~/routes/__protected/api/signals/$product"
import { useSafeSearchParams } from "./useSafeSearchParams"

export const useGetSignalIDsMutation = (
  product: SpecterProducts,
  limit: number = SEARCH_EXPORT_LIMIT
) => {
  const [searchParams] = useSafeSearchParams()

  return useMutation({
    mutationFn: async () => {
      const serverSearchParams = new URLSearchParams(searchParams)
      serverSearchParams.set("limit", limit.toString())

      const req = await fetch(
        `/api/signals/${mapProductsToRouteName(product)}`,
        {
          method: "POST",
          body: JSON.stringify(
            Object.fromEntries(serverSearchParams.entries())
          ),
        }
      )

      const data: { items: ProductSignalFeedItems<typeof product> } =
        await req.json()

      return data.items.map((item) => item.id)
    },
  })
}
