import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useNavigate } from "@remix-run/react"
import { useMutation } from "@tanstack/react-query"
import { useFormik } from "formik"
import { HiArrowRight } from "react-icons/hi"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { AITag } from "~/components/AITag"
import { Icon } from "~/utils/components/Icon"
import { getProductSignalSearchParams } from "~/utils/hooks/useProductFilters"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { ColoredTag } from "../ColoredTag"
import { getSortFromParams } from "../SignalSort"
import { formatTextWithHighlights, TaggableInput } from "../TaggableInput"

const SUGGESTIONS = [
  "Customer support tools like @5e3bbd6600c8f4c9669c15eb(Intercom)",
  "AI-powered drug discovery companies with over $50M in funding",
  "Early and growth stage startups that focus on AI for doctors in Europe",
  "Similar companies to @5e3a8f1a040ca7b0c6f05698(Revolut) but focus on DEFI",
  "Seed and early stage companies building AI Agents for sales",
  "Quantum computing in North America that last raised a Series A",
]

const Suggestion = ({
  value,
  onClick,
}: {
  value: string
  onClick: () => void
}) => {
  return (
    <Button
      onClick={onClick}
      variant="dashed"
      w="calc(50% - var(--chakra-space-2))"
      textAlign="left"
      py={2}
      px={3}
      justifyContent="flex-start"
      whiteSpace="normal"
      height="auto"
    >
      <Text as="span">{formatTextWithHighlights(value)}</Text>
    </Button>
  )
}

const formSchema = z.object({
  query: z.string().min(1, "Please enter a query"),
})

const validationSchema = toFormikValidationSchema(formSchema)

type FormValues = z.infer<typeof formSchema>

export const SemanticSearchForm = ({ onSubmit }: { onSubmit?: () => void }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSafeSearchParams()
  const [field, direction] = getSortFromParams(searchParams)
  const initialValues = getProductSignalSearchParams(
    SpecterProducts.company,
    searchParams
  )

  const statusToasts = async () => {
    return new Promise((resolve) => {
      toast({
        title: "Building Query...",
        status: "info",
        duration: 1500,
        onCloseComplete: () => {
          toast({
            title: "Enhancing Search...",
            status: "info",
            duration: 1000,
            onCloseComplete: () => {
              toast({
                title: "Redirecting...",
                status: "info",
                duration: 500,
                onCloseComplete: () => {
                  resolve(true)
                },
              })
            },
          })
        },
      })
    })
  }

  const searchMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      const [response] = await Promise.all([
        fetch("/api/agent-query", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            query: values.query,
            product: SpecterProducts.company,
            existingParams: initialValues,
            sort: field
              ? {
                  [field]: direction,
                }
              : { semanticScore: "desc" },
          }),
        }),
        statusToasts(),
      ])

      return z
        .object({
          redirectUrl: z.string(),
        })
        .parse(await response.json())
    },
    onSuccess: (data) => {
      onSubmit?.()
      navigate(data.redirectUrl)
    },
  })

  const formik = useFormik({
    validationSchema,
    isInitialValid: false,
    initialValues: {
      query: initialValues.semanticSearch?.[0] ?? "",
    },
    onSubmit(values) {
      searchMutation.mutate(values)
    },
  })

  return (
    <Center pos="relative" flex={1} overflow="hidden" zIndex={1} minH="600px">
      <Box
        pos="absolute"
        h={20}
        w="400px"
        maxW="full"
        top={0}
        left="50%"
        transform="translateX(-50%) translateY(-50%)"
        bgGradient="linear(to-l, brand.500, purple.500, red.500, orange.500)"
        filter="blur(48px)"
        rounded="100% / 100%"
        opacity={0.45}
        zIndex={-1}
      />
      <Box textAlign="center" w="660px" maxW="full" px={4}>
        <Center
          bgColor="white"
          rounded="lg"
          w={14}
          h={14}
          boxShadow="0 0 0 6px rgba(0, 0, 0, 0.03), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 -2px 4px 1px rgba(0, 0, 0, 0.03)"
          fontSize="3xl"
          mx="auto"
          overflow="hidden"
          mb={3}
        >
          <AITag size="32px" />
        </Center>
        <Heading fontWeight="bold" fontSize="3xl" mb={1}>
          Smart Search Agent
        </Heading>
        <Text fontSize="sm" color="gray.500" mb={6} maxW="280px" mx="auto">
          Just describe the company you want to find - our AI handles the rest.
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <Flex
            shadow="sm"
            rounded="3xl"
            borderWidth={1}
            borderColor="gray.200"
            alignItems="center"
            pr={2}
          >
            <Box py={4} px={6} flex={1} overflow="hidden" textAlign="left">
              <TaggableInput
                value={formik.values.query}
                onChange={(value) => formik.setFieldValue("query", value)}
                placeholder="What kind of company are you looking for?"
                onSubmit={() => formik.handleSubmit()}
              />
            </Box>

            <IconButton
              type="submit"
              aria-label="Search"
              icon={<Icon as={HiArrowRight} />}
              rounded="full"
              colorScheme="blackAlpha"
              bgColor="black"
              size="sm"
              isLoading={searchMutation.isLoading}
              isDisabled={!formik.isValid || searchMutation.isLoading}
            />
          </Flex>
        </form>

        <Flex gap={2} flexWrap="wrap" mt={4} mr={-2}>
          {SUGGESTIONS.map((suggestion) => (
            <Suggestion
              key={suggestion}
              onClick={() => formik.setFieldValue("query", suggestion)}
              value={suggestion}
            />
          ))}
        </Flex>

        <Flex
          rounded="full"
          borderWidth={1}
          borderStyle="dashed"
          borderColor="gray.200"
          alignItems="center"
          p={0.5}
          display="inline-flex"
          mx="auto"
          bgColor="white"
          my={6}
        >
          <ColoredTag color="brand" size="sm" rounded="full">
            V2.0
          </ColoredTag>
          <Text
            color="gray.400"
            fontSize="xx-small"
            fontWeight="semibold"
            px={2}
          >
            Help shape this feature with your feedback
          </Text>
        </Flex>
      </Box>
    </Center>
  )
}
