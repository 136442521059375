import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useUser } from "@clerk/remix"
import { useMutation } from "@tanstack/react-query"
import { useEnv } from "~/utils/hooks/useEnv"

import { SpecterProducts } from "@prisma/client"
import { useLocation } from "@remix-run/react"
import { useEffect, useState } from "react"
import {
  affinityResponseToastOption,
  buildBodyMessage,
} from "~/components/Affinity/common"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useAffinitySignalCheck } from "~/utils/hooks/useIntegrations"
import invariant from "~/utils/invariant"
import { mapProductsToRouteName } from "../Filters/schemas"

interface Props {
  product: SpecterProducts
  isOpen: boolean
  onClose: () => void
  onSuccess?: () => void
  signalId: string
}

export const AffinityAddSignal = ({
  product,
  isOpen,
  onClose,
  onSuccess,
  signalId,
}: Props): JSX.Element => {
  const toast = useToast()
  const user = useUser()
  const env = useEnv()
  const analytics = useAnalytics()
  const location = useLocation()

  const affinityCheck = useAffinitySignalCheck(product, signalId, !isOpen)

  const [bodyMessage, setBodyMessage] = useState(
    buildBodyMessage(product, affinityCheck?.data?.addedBy, user?.user?.id)
  )

  useEffect(() => {
    setBodyMessage(
      buildBodyMessage(product, affinityCheck?.data?.addedBy, user?.user?.id)
    )
  }, [affinityCheck, product, user])

  const addToAffinityMutation = useMutation(
    async () => {
      invariant(user.user?.id)

      const productRouteName = mapProductsToRouteName(product)

      const response = await fetch(
        `/api/affinity/import-${productRouteName}-signal`,
        {
          method: "POST",
          body: JSON.stringify({
            signalId,
          }),
        }
      )

      if (!response.ok) {
        const body = await response.json()
        return {
          ...affinityResponseToastOption(body),
          duration: 9000,
          isClosable: true,
        }
      }

      const signalURL = `${env.APP_URL}/signals/${productRouteName}/feed/${signalId}${location.search}`

      analytics.track(
        "Add to Affinity",
        {
          product,
          type: "signal",
          signalURL,
          signalId,
        },
        { exclude: ["intercom"] }
      )

      return {
        status: "success",
        title: "Signal successfully added to Affinity",
      }
    },
    {
      onMutate() {
        onClose()
        toast({
          status: "info",
          title: "This signal is being added to Affinity",
        })
      },
      onSuccess(toastMessage) {
        onSuccess?.()
        // @ts-ignore
        toast(toastMessage)
      },
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Add to Affinity</ModalHeader>
        <ModalBody>
          <Text fontSize="sm">{bodyMessage}</Text>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" onClick={onClose} size="sm">
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="brand"
            isLoading={addToAffinityMutation.isLoading}
            onClick={() => addToAffinityMutation.mutate()}
          >
            Add to Affinity
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
