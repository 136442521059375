import { Divider, Flex, Heading, Spacer, Text, VStack } from "@chakra-ui/react"
import { CompanyLogo } from "~/components"
import { Card } from "~/components/Card"
import { Tab } from "~/components/DetailPage/FullScreen"
import {
  CompanyHistoricalMetrics,
  CompanyHistoricalMetricsKey,
} from "~/utils/db/queries/company/types"
import { isNullish } from "~/utils/values"
import { DetailPageHistoricalsChartLayout } from "../DetailPageHistoricalsChartLayout"
import { DetailValueCard } from "../DetailValueCard"

const percentageValueRegex = /\d+(\.\d+)?%/g

export const getHistoricalMetricsValue = ({
  data,
  field,
}: {
  data: CompanyHistoricalMetrics[]
  field: CompanyHistoricalMetricsKey
}) => {
  return data[data.length - 1]?.[field]
}

export const getHistoricalMetricsGrowth = ({
  data,
  field,
  nMonths,
  inPercentage = false,
  inverted = false,
}: {
  data: CompanyHistoricalMetrics[]
  field: CompanyHistoricalMetricsKey
  nMonths: number
  inPercentage?: boolean
  inverted?: boolean
}) => {
  const currentValue = data[data.length - 1]?.[field]

  const previousValue = data[data.length - 1 - nMonths]?.[field]

  if (isNullish(currentValue) || isNullish(previousValue)) return null

  const invertedCoefficient = inverted ? -1 : 1

  if (inPercentage) {
    if (previousValue === 0) {
      // If both 0, no growth
      if (currentValue === 0) return 0

      // If previous is 0, and current is not, it's infinite growth
      return null
    }

    return (
      ((currentValue - previousValue) / previousValue) *
      100 *
      invertedCoefficient
    )
  }

  return (currentValue - previousValue) * invertedCoefficient
}

export const WebsiteStats: Tab<"company">["TabContent"] = ({ signal }) => {
  if (
    signal.webVisits === null &&
    signal.webVisitsMonthlyGrowth3 === null &&
    signal.webVisitsMonthlyGrowth6 === null &&
    signal.webPopularityRank === null &&
    signal.webPopularityRankMonthlyGrowth3 === null &&
    signal.webPopularityRankMonthlyGrowth6 === null
  ) {
    return (
      <Card>
        <Text fontSize="xs" color="gray.400">
          We don't have any web data for this company at this time.
        </Text>
      </Card>
    )
  }

  // 70-80% red (bad), 50-70 yellow (ok) and 30 - 50% green (good)
  const bounceRateChangeColor = signal.webBounceRate
    ? signal.webBounceRate > 70
      ? "red"
      : signal.webBounceRate > 50 && signal.webBounceRate < 70
      ? "yellow.400"
      : "green"
    : undefined

  return (
    <Flex direction="column" gap={10}>
      <DetailPageHistoricalsChartLayout
        chart={{
          label: "Web Visits",
          data: signal.historical_metrics,
          field: "web_visits",
        }}
        chartEmptyMessage="We don’t have web visits data for this company at this time."
      />

      <DetailPageHistoricalsChartLayout
        chart={{
          label: "Web Rank",
          reversed: true,
          yAxisValueFormat: "0,0",
          data: signal.historical_metrics,
          field: "popularity_rank",
        }}
        chartEmptyMessage="We don’t have popularity rank data for this company at this time."
        absoluteGrowths
      />

      <Flex gap={4} flexDir={{ base: "column", md: "row" }}>
        <DetailValueCard
          label="Bounce Rate"
          value={signal.webBounceRate}
          color={bounceRateChangeColor}
          suffix="%"
          badge={{
            value: signal.webBounceRateMonthlyGrowth3,
            inverted: true,
          }}
        />
        <DetailValueCard
          label="Pages Per Visit"
          value={signal.webPagesPerVisit}
          badge={{
            value: signal.webPagesPerVisitMonthlyGrowth3,
            isPercentage: false,
          }}
        />
        <DetailValueCard
          label="Session Duration"
          value={signal.webSessionDuration}
          suffix="s"
          badge={{
            value: signal.webSessionDurationMonthlyGrowth3,
            isPercentage: false,
            suffix: "s",
          }}
        />
      </Flex>

      {/* // ! TEMPORARILY REMOVED ! */}
      {false && (
        <Flex gap={4} flexDir={{ base: "column", md: "row" }}>
          <DetailValueCard
            label="Organic Search Percentage"
            value={signal.webOrganicSearchPercentage}
            color="brand.500"
            suffix="%"
            suppressFormatting
          />

          <DetailValueCard
            label="Paid Search Percentage"
            value={signal.webPaidSearchPercentage}
            color="brand.500"
            suffix="%"
            suppressFormatting
          />
        </Flex>
      )}

      <Flex gap={4} flexDir={{ base: "column", md: "row" }}>
        {signal.webCountryBreakdown !== null && (
          <Card flex={1}>
            <Heading as="h2" color="gray.500" size="xs" mb={2}>
              Top Countries
            </Heading>

            <VStack spacing={1} divider={<Divider />} alignItems="stretch">
              {signal.webCountryBreakdown?.split(";").map((country: string) => {
                const value = country.match(percentageValueRegex)

                return (
                  <Flex key={country}>
                    <Text fontSize="sm">
                      {country.replace(percentageValueRegex, "")}
                    </Text>
                    <Spacer />
                    <Text fontSize="sm" fontWeight="bold">
                      {value}
                    </Text>
                  </Flex>
                )
              })}
            </VStack>
          </Card>
        )}

        {signal.webTrafficSources !== null && (
          <Card flex={1}>
            <Heading as="h2" color="gray.500" size="xs" mb={2}>
              Web Traffic Sources
            </Heading>

            <VStack spacing={1} divider={<Divider />} alignItems="stretch">
              {signal.webTrafficSources?.split(";").map((source: string) => {
                const value = source.match(percentageValueRegex)
                return (
                  <Flex key={source}>
                    <Text fontSize="sm">
                      {source
                        .replaceAll(":", "")
                        .replace(percentageValueRegex, "")}
                    </Text>
                    <Spacer />
                    <Text fontSize="sm" fontWeight="bold">
                      {value}
                    </Text>
                  </Flex>
                )
              })}
            </VStack>
          </Card>
        )}
      </Flex>

      <Flex gap={4} flexDir={{ base: "column", md: "row" }}>
        {/* // ! TEMPORARILY REMOVED ! */}
        {false && signal.socialTrafficBreakdown !== null && (
          <Card flex={1}>
            <Heading as="h2" color="gray.500" size="xs" mb={2}>
              Social Traffic Breakdown
            </Heading>

            <VStack spacing={1} divider={<Divider />} alignItems="stretch">
              {signal.socialTrafficBreakdown
                ?.split(";")
                .map((social: string) => {
                  const value = social.match(percentageValueRegex)
                  const name = social.replace(percentageValueRegex, "")

                  return (
                    <Flex key={social}>
                      <CompanyLogo
                        domain={name.replaceAll(" ", "").toLowerCase() + ".com"}
                        source={undefined}
                        size={5}
                      />
                      <Text fontSize="sm" ml={2}>
                        {name}
                      </Text>
                      <Spacer />
                      <Text fontSize="sm" fontWeight="bold">
                        {value}
                      </Text>
                    </Flex>
                  )
                })}
            </VStack>
          </Card>
        )}

        {/* // ! TEMPORARILY REMOVED ! */}
        {false && signal.webSimilarWebsitesSimilarity !== null && (
          <Card flex={1}>
            <Heading as="h2" color="gray.500" size="xs" mb={2}>
              Similar Websites and Similarity
            </Heading>

            <VStack spacing={1} divider={<Divider />} alignItems="stretch">
              {signal.webSimilarWebsitesSimilarity
                ?.split("; ")
                .map((item: string) => {
                  const [similarDomain, value] = item.split(" ")
                  return (
                    <Flex key={item}>
                      <CompanyLogo
                        domain={similarDomain}
                        source={undefined}
                        size={5}
                      />
                      <Text fontSize="sm" flex={1} ml={2}>
                        {similarDomain}
                      </Text>
                      <Text fontSize="sm" fontWeight="semibold">
                        {value}
                      </Text>
                    </Flex>
                  )
                })}
            </VStack>
          </Card>
        )}
      </Flex>
    </Flex>
  )
}
