import { Box } from "@chakra-ui/react"

export const IconCircleDashed = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 22 22" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.1,1.18c1.26-.24,2.54-.24,3.8,0M12.9,20.82c-1.26.24-2.54.24-3.8,0M16.61,2.72c1.06.72,1.97,1.64,2.69,2.7M1.18,12.9c-.24-1.26-.24-2.54,0-3.8M19.28,16.61c-.72,1.06-1.64,1.97-2.7,2.69M20.82,9.1c.24,1.26.24,2.54,0,3.8M2.72,5.39c.72-1.06,1.64-1.97,2.7-2.69M5.39,19.28c-1.06-.72-1.97-1.64-2.69-2.7"
      />
    </Box>
  )
}
