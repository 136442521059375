import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { IconType } from "react-icons"
import { HiDownload } from "react-icons/hi"
import { HiEllipsisHorizontal } from "react-icons/hi2"
import {
  RiFacebookFill,
  RiGithubFill,
  RiInstagramFill,
  RiLink,
  RiLinkedinFill,
  RiMailSendLine,
  RiTwitterXFill,
} from "react-icons/ri"
import { SiCrunchbase, SiGoogleplay, SiItunes } from "react-icons/si"
import {
  AffinityAddSignal,
  BoxIcon,
  ExportModal,
  ListAddManyModal,
} from "~/components"
import { G2_LOGO_URL } from "~/consts/data3rdParties"
import { mapProductToFeed } from "~/routes/__protected/api/signals/$product"
import { Icon } from "~/utils/components/Icon"
import { GetAcquisitionEntry } from "~/utils/db/acquisitionDB"
import { GetFundingRoundsEntry } from "~/utils/db/fundingRoundsDB"
import { GetInvestorByID } from "~/utils/db/getInvestorById"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"
import { GetStrategicSignalByID } from "~/utils/db/getStrategicSignalByID"
import { GetIPOsEntry } from "~/utils/db/ipoDB"
import {
  CompanySignalFeedItem,
  CompanyTableItem,
  G2Product,
} from "~/utils/db/queries/company/types"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { useSignalAddedToList } from "~/utils/hooks/useSignalAddedToList"
import invariant from "~/utils/invariant"
import { getTextWidth } from "~/utils/string/text-width"
import { ensureAbsoluteLink, normalizeItunesAppUrl } from "~/utils/string/url"
import { EntityActionModal } from "../EntityActionModal"
import { MenuItem } from "../MenuItem"

import { ButtonGroup } from "@chakra-ui/react"

import { useQuery } from "@tanstack/react-query"

import { cacheKeys } from "~/utils/cacheKeys"

import { HiChevronDown } from "react-icons/hi2"
import { PiEnvelopeSimple, PiSuitcaseSimple, PiUser } from "react-icons/pi"
import { EnrichEmailType } from "~/utils/api/emails/fetchEmails"
import { useLocalStorage } from "~/utils/hooks/useLocalStorage"
import { capitalize } from "~/utils/string/format"
import { NOT_FOUND } from "~/utils/responses/code"

export function ButtonLinkedin({
  linkedinUrl,
  showDisableWhenMissing,
  ...props
}: {
  linkedinUrl?: string | null
  showDisableWhenMissing?: boolean
} & Omit<IconButtonProps, "aria-label">) {
  if (linkedinUrl || showDisableWhenMissing) {
    return (
      <Tooltip label="LinkedIn" isDisabled={!linkedinUrl}>
        <IconButton
          aria-label="LinkedIn"
          icon={<Icon as={RiLinkedinFill} />}
          as="a"
          href={linkedinUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          isDisabled={!linkedinUrl}
          {...props}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonTwitter({
  twitterUrl,
  showDisableWhenMissing,
  ...props
}: {
  twitterUrl?: string | null
  showDisableWhenMissing?: boolean
} & Omit<IconButtonProps, "aria-label">) {
  if (twitterUrl || showDisableWhenMissing) {
    return (
      <Tooltip label="Twitter" isDisabled={!twitterUrl}>
        <IconButton
          aria-label="Twitter"
          icon={<Icon as={RiTwitterXFill} />}
          as="a"
          href={twitterUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          isDisabled={!twitterUrl}
          {...props}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonInstagram({
  instagramUrl,
  showDisableWhenMissing,
  ...props
}: {
  instagramUrl?: string | null
  showDisableWhenMissing?: boolean
} & Omit<IconButtonProps, "aria-label">) {
  if (instagramUrl || showDisableWhenMissing) {
    return (
      <Tooltip label="Instagram" isDisabled={!instagramUrl}>
        <IconButton
          aria-label="Instagram"
          icon={<Icon as={RiInstagramFill} />}
          as="a"
          href={instagramUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          isDisabled={!instagramUrl}
          {...props}
        />
      </Tooltip>
    )
  }

  return <></>
}

export const ButtonFacebook = ({
  facebookUrl,
  showDisableWhenMissing,
  ...props
}: {
  facebookUrl?: string | null
  showDisableWhenMissing?: boolean
} & Omit<IconButtonProps, "aria-label">) => {
  if (facebookUrl || showDisableWhenMissing) {
    return (
      <Tooltip label="Facebook" isDisabled={!facebookUrl}>
        <IconButton
          aria-label="Facebook"
          icon={<Icon as={RiFacebookFill} />}
          as="a"
          href={facebookUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          isDisabled={!facebookUrl}
          {...props}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonGithub({
  githubUrl,
  showDisableWhenMissing,
  ...props
}: { githubUrl?: string | null; showDisableWhenMissing?: boolean } & Omit<
  IconButtonProps,
  "aria-label"
>) {
  if (githubUrl || showDisableWhenMissing) {
    return (
      <Tooltip label="GitHub" isDisabled={!githubUrl}>
        <IconButton
          aria-label="GitHub"
          icon={<Icon as={RiGithubFill} />}
          as="a"
          href={githubUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          isDisabled={!githubUrl}
          {...props}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonWebsite({ websiteUrl }: { websiteUrl?: string | null }) {
  if (websiteUrl) {
    return (
      <Tooltip label="Website" isDisabled={!websiteUrl}>
        <IconButton
          aria-label="Website"
          icon={<Icon as={RiLink} />}
          as="a"
          href={ensureAbsoluteLink(websiteUrl)}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonITunes({ itunesUrl }: { itunesUrl?: string | null }) {
  if (itunesUrl) {
    return (
      <Tooltip label="iTunes" isDisabled={!itunesUrl}>
        <IconButton
          aria-label="iTunes"
          icon={<Icon as={SiItunes} />}
          as="a"
          href={itunesUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonGooglePlay({
  googlePlayUrl,
}: {
  googlePlayUrl?: string | null
}) {
  if (googlePlayUrl) {
    return (
      <Tooltip label="Google Play" isDisabled={!googlePlayUrl}>
        <IconButton
          aria-label="Google Play"
          icon={<Icon as={SiGoogleplay} />}
          as="a"
          href={googlePlayUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonEmail({
  email,
  expand = true,
  px = "6px",
  showDisableWhenMissing,
  ...props
}: {
  email?: string | null
  expand?: boolean
  px?: FlexProps["px"]
  showDisableWhenMissing?: boolean
} & Omit<IconButtonProps, "aria-label">) {
  const { isOpen, onOpen } = useDisclosure()
  const toast = useToast()

  const textRef = useRef<HTMLParagraphElement>(null)

  if (!email && !showDisableWhenMissing) return <></>

  const maxWidth = textRef.current
    ? getTextWidth(email ?? "", textRef.current) + 6
    : "200"

  return (
    <Tooltip label={`Copy '${email}'`} isDisabled={!email}>
      <IconButton
        aria-label="Email"
        userSelect="text"
        icon={
          <Flex alignItems="center" px={px}>
            <Icon as={RiMailSendLine} />
            <Flex
              justifyContent="flex-end"
              overflow="hidden"
              width={expand && isOpen ? `${maxWidth}px` : "0px"}
              transition="width 0.2s"
            >
              <Text
                ref={textRef}
                as="span"
                fontSize="xs"
                ml="1"
                fontWeight="normal"
              >
                {email}
              </Text>
            </Flex>
          </Flex>
        }
        onClick={
          email
            ? (e) => {
                e.stopPropagation()

                // Open slider
                onOpen()

                // Copy email to clipboard
                navigator.clipboard.writeText(email)

                // Trigger toast
                toast.closeAll()
                toast({
                  status: "success",
                  title: `Copied '${email}' to clipboard`,
                })
              }
            : undefined
        }
        isDisabled={!email}
        {...props}
      />
    </Tooltip>
  )
}

export function ButtonContactDetails({
  personId,
  personName,
  isCompact,
  size = "sm",
}: {
  personId: string
  personName: string | null
  isCompact?: boolean
  size?: ButtonProps["size"]
}) {
  const toast = useToast()
  const modalDisclosure = useDisclosure({
    onClose() {
      setIsCopied(false)
    },
  })
  const [isCopied, setIsCopied] = useState(false)

  const [defaultEmailTypeLS, setDefaultEmailTypeLS] =
    useLocalStorage<EnrichEmailType | null>(
      "contact-details-default-email-type",
      null
    )

  // undefined => use default value type (saved in localStorage)
  // null => get email without specifying type (any)
  const fetchingType = useRef<EnrichEmailType | null | undefined>(
    defaultEmailTypeLS
  )

  const copyEmailToClipboard = (email: string) => {
    setIsCopied(true)

    navigator.clipboard.writeText(email)

    toast({
      status: "success",
      title: `Copied email to clipboard`,
    })
  }

  const getContactDetailsQuery = useQuery<string, Error>(
    cacheKeys.contactDetails(personId),
    async () => {
      const type =
        fetchingType.current === undefined
          ? defaultEmailTypeLS
          : fetchingType.current

      const req = await fetch(
        `/api/people/${personId}/contact-details${type ? `?type=${type}` : ""}`
      )

      invariant(req.ok, "Failed to get contact details")

      const { email } = await req.json()

      // ! Initially I thought it was a good idea to fetch without type when not found with type
      // ! But we have specific buttons, so it can be confusing
      // // If not found with type, try without
      // if (!email && !isNullish(type)) {
      //   const req = await fetch(`/api/people/${personId}/contact-details`)

      //   email = (await req.json()).email
      // }

      invariant(
        email,
        capitalize(
          `${getEmailTypeLabel(type)} email not found for ${personName}`.trim()
        ),
        { cause: NOT_FOUND }
      )

      // If fetched with a type, set it as the default
      if (fetchingType.current !== undefined)
        setDefaultEmailTypeLS(fetchingType.current)

      copyEmailToClipboard(email)

      return email
    },
    {
      // This prevents the query by default
      // We just want to run it when the button is clicked
      enabled: false,
      onError(error) {
        toast({
          status: "error",
          title: error.message,
        })
      },
      retry: 0,
    }
  )

  const isFetchingType = (type: EnrichEmailType | null | undefined) =>
    getContactDetailsQuery.isFetching && fetchingType.current === type

  // Profile page version
  const getEmailTypeLabel = (type?: EnrichEmailType | null) => {
    if (!type) return ""
    return type === EnrichEmailType.Professional ? "Business" : capitalize(type)
  }

  return (
    <>
      {isCompact ? (
        <Tooltip
          label={
            getContactDetailsQuery.data
              ? `Copy "${getContactDetailsQuery.data}"`
              : `Get Email`
          }
        >
          <IconButton
            variant="outline"
            aria-label="Email"
            userSelect="text"
            size={size}
            icon={<Icon as={RiMailSendLine} />}
            onClick={() => {
              if (!getContactDetailsQuery.data) modalDisclosure.onOpen()
              else copyEmailToClipboard(getContactDetailsQuery.data)
            }}
            {...(getContactDetailsQuery.data && {
              colorScheme: "green",
              bgColor: "green.50",
              _hover: {
                bgColor: "green.100",
              },
            })}
          />
        </Tooltip>
      ) : (
        <ButtonGroup size={size} isAttached variant="outline">
          <Button
            leftIcon={<Icon as={RiMailSendLine} />}
            onClick={() => {
              fetchingType.current = undefined

              if (getContactDetailsQuery.data)
                copyEmailToClipboard(getContactDetailsQuery.data)
              else getContactDetailsQuery.refetch()
            }}
            isLoading={isFetchingType(undefined)}
          >
            {getContactDetailsQuery.data ??
              `Get ${getEmailTypeLabel(defaultEmailTypeLS)} Email`}
          </Button>
          <IconButton
            aria-label="Get email menu"
            icon={<Icon as={HiChevronDown} />}
            onClick={modalDisclosure.onOpen}
          />
        </ButtonGroup>
      )}

      <Modal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            display="flex"
            flexDir="column"
            gap={4}
            alignItems="center"
          >
            <BoxIcon icon={PiEnvelopeSimple} />

            <Text fontSize="sm">
              Get{" "}
              <Text as="span" fontWeight="bold">
                {personName ? `${personName}'s` : ""}
              </Text>{" "}
              email address
            </Text>

            <ButtonGroup size="sm" variant="outline" spacing={2}>
              <Button
                isLoading={isFetchingType(EnrichEmailType.Personal)}
                leftIcon={<Icon as={PiUser} />}
                onClick={() => {
                  fetchingType.current = EnrichEmailType.Personal
                  getContactDetailsQuery.refetch()
                }}
              >
                Personal
              </Button>
              <Button
                isLoading={isFetchingType(EnrichEmailType.Professional)}
                leftIcon={<Icon as={PiSuitcaseSimple} />}
                onClick={() => {
                  fetchingType.current = EnrichEmailType.Professional
                  getContactDetailsQuery.refetch()
                }}
              >
                Business
              </Button>
            </ButtonGroup>

            {getContactDetailsQuery.data ? (
              <Flex direction="column" alignItems="center" gap={2}>
                <Text
                  as="button"
                  cursor="pointer"
                  fontSize="sm"
                  onClick={() => {
                    copyEmailToClipboard(getContactDetailsQuery.data)
                  }}
                  textDecoration="underline"
                  textDecorationColor="gray.300"
                  textUnderlineOffset={2}
                  _hover={{
                    textDecorationColor: "gray.800",
                  }}
                >
                  {getContactDetailsQuery.data}
                </Text>
                <Text fontSize="xx-small" color="gray.300">
                  {isCopied
                    ? "Copied to clipboard!"
                    : "Click email address to copy"}
                </Text>
              </Flex>
            ) : (
              <Text
                fontSize="xs"
                color={getContactDetailsQuery.error ? "red.500" : "gray.300"}
                textAlign="center"
              >
                {getContactDetailsQuery.error ? (
                  getContactDetailsQuery.error.cause === NOT_FOUND ? (
                    `No ${getEmailTypeLabel(fetchingType.current)} email found!`
                  ) : (
                    <>
                      Failed to load email address <br />
                      Please try again later
                    </>
                  )
                ) : (
                  "Click to get email address"
                )}
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export function ButtonG2({ g2Product }: { g2Product?: G2Product | null }) {
  if (!g2Product) return <></>

  return (
    <Tooltip
      label={`${g2Product.product_name} - G2 reviews`}
      isDisabled={!g2Product}
    >
      <IconButton
        aria-label="G2"
        icon={
          <Image
            src={G2_LOGO_URL}
            boxSize="14px"
            filter="saturate(0) brightness(0.7) contrast(4)"
          />
        }
        as="a"
        href={g2Product.g2_link}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      />
    </Tooltip>
  )
}

export function ButtonCrunchbase({
  crunchbaseUrl,
}: {
  crunchbaseUrl?: string | null
}) {
  if (crunchbaseUrl) {
    return (
      <Tooltip label="Crunchbase" isDisabled={!crunchbaseUrl}>
        <IconButton
          aria-label="Crunchbase"
          icon={<Icon as={SiCrunchbase} />}
          as="a"
          href={crunchbaseUrl}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        />
      </Tooltip>
    )
  }

  return <></>
}

export function ButtonDivider() {
  return <Box width="1px" bgColor="gray.300" my={1.5} />
}

export function showDividerStrategicSignal(signal: GetStrategicSignalByID) {
  const websiteIsTwitter = signal.Website?.includes("twitter")
  const websiteIsLinkedIn = signal.Website?.includes("linkedin")

  return (
    signal.LinkedInURL ||
    signal.TwitterURL ||
    websiteIsTwitter ||
    websiteIsLinkedIn ||
    signal?.personalDetail?.githubUrl ||
    signal.personalDetail?.email
  )
}

export type ActionButtonProps<P extends SpecterProducts> = {
  product: P
  signal: ProductItem<P> | ProductDetailItem<P>
}

export type ProductItem<P extends SpecterProducts> = Awaited<
  ReturnType<typeof mapProductToFeed<P>>
>["items"][number]

export type ProductDetailItem<P extends SpecterProducts> = P extends "company"
  ? CompanyTableItem
  : P extends "talent"
  ? GetPeopleByID
  : P extends "stratintel"
  ? GetStrategicSignalByID
  : P extends "investors"
  ? GetInvestorByID
  : P extends "fundingRounds"
  ? GetFundingRoundsEntry
  : P extends "acquisition"
  ? GetAcquisitionEntry
  : P extends "ipo"
  ? GetIPOsEntry
  : P extends "people"
  ? GetPeopleByID
  : never

export const AddToListIconButton = <P extends SpecterProducts>({
  product,
  signal,
}: ActionButtonProps<P>) => {
  const addToList = useDisclosure()
  const { addedToList } = useSignalAddedToList(signal, product)

  return (
    <>
      <Tooltip label={`Add${addedToList ? "ed" : ""} to List`}>
        <IconButton
          flex={1}
          aria-label="Add to List"
          colorScheme={addedToList ? "green" : "gray"}
          bgColor={addedToList ? "green.50" : "transparent"}
          _hover={{
            bgColor: addedToList ? "green.100" : "gray.50",
          }}
          icon={addedToList ? <ListAddedIcon color="green" /> : <ListAddIcon />}
          intercom-target="add-to-list-button"
          onClick={(e) => {
            e.stopPropagation()
            addToList.onOpen()
          }}
        />
      </Tooltip>

      <EntityActionModal
        signals={[signal]}
        isOpen={addToList.isOpen}
        onClose={addToList.onClose}
        product={product}
      />
    </>
  )
}

export const AddToListButton = <P extends SpecterProducts>({
  product,
  signal,
}: ActionButtonProps<P>) => {
  const addToList = useDisclosure()
  const { addedToList } = useSignalAddedToList(signal, product)

  return (
    <>
      <Button
        colorScheme={addedToList ? "green" : "gray"}
        bgColor={addedToList ? "green.50" : "transparent"}
        _hover={{
          bgColor: addedToList ? "green.100" : "gray.50",
        }}
        leftIcon={
          addedToList ? <ListAddedIcon color="green" /> : <ListAddIcon />
        }
        intercom-target="add-to-list-button"
        onClick={(e) => {
          e.stopPropagation()
          addToList.onOpen()
        }}
      >
        {addedToList ? "Added to List" : "Add to List"}
      </Button>

      <EntityActionModal
        signals={[signal]}
        isOpen={addToList.isOpen}
        onClose={addToList.onClose}
        product={product}
      />
    </>
  )
}

export const AddManyToListIconButton = <P extends SpecterProducts>({
  product,
  signalIds,
}: {
  product: P
  signalIds: string[]
}) => {
  const addToList = useDisclosure()

  return (
    <>
      <Tooltip label="Add to List">
        <IconButton
          aria-label="Add to List"
          icon={<ListAddIcon />}
          onClick={(e) => {
            e.stopPropagation()
            addToList.onOpen()
          }}
        />
      </Tooltip>

      <ListAddManyModal
        signalIds={signalIds}
        isOpen={addToList.isOpen}
        onClose={addToList.onClose}
        product={product}
      />
    </>
  )
}

export const ExportSignalsIconButton = <P extends SpecterProducts>({
  product,
  signalIds,
}: {
  product: P
  signalIds: string[]
}) => {
  const analytics = useAnalytics()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const exportSignalsMutation = useMutation(
    async (limit: number) => {
      const req = await fetch(`/api/export-uploaded?limit=${limit}`, {
        method: "POST",
        body: JSON.stringify({
          product,
          signalIds,
        }),
      })

      invariant(req.ok, "Failed to export list")

      analytics.track("Export", {
        product,
        type: "selection",
        signalIds,
        signalCount: signalIds.length,
      })

      return await req.json()
    },
    {
      onMutate() {
        toast({
          status: "info",
          title: "Your export is being created",
          description: "This may take a few minutes.",
        })
      },
      onSuccess(url) {
        if (url) {
          const link = document.createElement("a")
          link.href = url
          link.click()
        }
      },
    }
  )

  return (
    <>
      <Tooltip label="Export signals">
        <IconButton
          aria-label="Export signals"
          icon={<Icon as={HiDownload} />}
          disabled={exportSignalsMutation.isLoading}
          isLoading={exportSignalsMutation.isLoading}
          onClick={(e) => {
            e.stopPropagation()
            onOpen()
          }}
        />
      </Tooltip>

      <ExportModal
        isOpen={isOpen}
        onClose={onClose}
        onExport={exportSignalsMutation.mutate}
        isLoading={exportSignalsMutation.isLoading}
        signalsCount={signalIds.length}
      />
    </>
  )
}

export const AddToAffinityIconButton = <P extends SpecterProducts>({
  product,
  signal,
  isAddedToAffinity,
  onSuccessAddedToAffinity,
}: ActionButtonProps<P> & {
  isAddedToAffinity?: boolean
  onSuccessAddedToAffinity?: () => void
}) => {
  const integrations = useIntegrations()
  const affinityAddModal = useDisclosure()

  const hasAffinityProductIntegration = {
    company: integrations.data?.integrations.hasAffinityCompanyList,
    talent: integrations.data?.integrations.hasAffinityTalentList,
    stratintel: integrations.data?.integrations.hasAffinityStrategicList,
    // TODO: Add Affinity integration for new products
    investors: false,
    fundingRounds: false,
    acquisition: false,
    ipo: false,
    people: false,
  }

  if (!hasAffinityProductIntegration[product]) return null

  return (
    <>
      <Tooltip label="Add to Affinity">
        <IconButton
          aria-label={"Add to Affinity"}
          colorScheme={isAddedToAffinity ? "blue" : "gray"}
          bgColor={isAddedToAffinity ? "rgba(40,79,231,0.1)" : "transparent"}
          icon={<Image src="/affinity-logo.svg" w="10px" />}
          onClick={(e) => {
            e.stopPropagation()
            affinityAddModal.onOpen()
          }}
          intercom-target="add-to-affinity-button"
        />
      </Tooltip>

      <AffinityAddSignal
        product={product}
        isOpen={affinityAddModal.isOpen}
        onClose={affinityAddModal.onClose}
        onSuccess={onSuccessAddedToAffinity}
        signalId={signal.id}
      />
    </>
  )
}

export function ButtonsStrategic({
  signal,
  expandEmail = true,
  iconPx,
}: {
  signal: ProductItem<"stratintel"> | GetStrategicSignalByID
  expandEmail?: boolean
  iconPx?: FlexProps["px"]
}) {
  const websiteAsLinkedIn = signal.Website?.includes("linkedin")
    ? signal.Website
    : null
  const websiteAsTwitter = signal.Website?.includes("twitter")
    ? signal.Website
    : null

  const websiteIsWebsite = !websiteAsLinkedIn && !websiteAsTwitter

  return (
    <>
      <ButtonLinkedin linkedinUrl={signal.LinkedInURL ?? websiteAsLinkedIn} />
      <ButtonTwitter twitterUrl={signal.TwitterURL ?? websiteAsTwitter} />
      {websiteIsWebsite && <ButtonWebsite websiteUrl={signal.Website} />}
      <ButtonGithub githubUrl={signal?.personalDetail?.githubUrl} />
      <ButtonEmail
        email={signal?.personalDetail?.email}
        expand={expandEmail}
        px={iconPx}
      />

      {/* {showDividerStrategicSignal(signal) && <ButtonDivider />} */}
    </>
  )
}

const SOCIALS: {
  label: string
  Icon: IconType
  field: string
  formatter?: (v: string) => string
}[] = [
  { label: "Website", Icon: RiLink, field: "domain" },
  { label: "LinkedIn", Icon: RiLinkedinFill, field: "linkedinUrl" },
  { label: "Twitter", Icon: RiTwitterXFill, field: "twitterUrl" },
  { label: "Instagram", Icon: RiInstagramFill, field: "instagramUrl" },
  { label: "Facebook", Icon: RiFacebookFill, field: "facebookUrl" },
  {
    label: "iTunes",
    Icon: SiItunes,
    field: "itunesUrl",
    formatter: normalizeItunesAppUrl,
  },
  { label: "Google Play", Icon: SiGoogleplay, field: "googlePlayUrl" },
]

export const TableSocialButtons = <P extends SpecterProducts>({
  product,
  signal,
}: ActionButtonProps<P>) => {
  if (product === SpecterProducts.company) {
    const signalCompany = signal as CompanySignalFeedItem | CompanyTableItem
    return (
      <>
        <ButtonWebsite websiteUrl={signalCompany.domain} />
        <ButtonLinkedin
          linkedinUrl={ensureAbsoluteLink(signalCompany.linkedinUrl)}
        />
        <ButtonTwitter
          twitterUrl={ensureAbsoluteLink(signalCompany.twitterUrl)}
        />
        <Popover placement="auto-end" isLazy>
          <PopoverTrigger>
            <IconButton
              aria-label="More"
              icon={<Icon as={HiEllipsisHorizontal} />}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent p={2} maxW={"fit-content"}>
              {SOCIALS.map(
                ({ label, Icon, field, formatter = ensureAbsoluteLink }) =>
                  field in signal &&
                  signal[field as keyof typeof signal] && (
                    <MenuItem
                      key={label}
                      icon={Icon}
                      aria-label={label}
                      to={formatter(
                        signal[field as keyof typeof signal] as string
                      )}
                    >
                      {label}
                    </MenuItem>
                  )
              )}
              {"g2_data" in signal && signal.g2_data?.[0] && (
                <MenuItem
                  key="G2 Product"
                  icon={() => (
                    <Image
                      src={G2_LOGO_URL}
                      boxSize="16px"
                      filter="saturate(0) brightness(0.7) contrast(4)"
                    />
                  )}
                  aria-label="G2 Product"
                  to={ensureAbsoluteLink(signal.g2_data?.[0].g2_link)}
                >
                  G2 Product
                </MenuItem>
              )}
              {/* 

              {signal.crunchbaseUrl && permissions.data?.isAdmin && (
                <IconButton
                  icon={<Icon as={SiCrunchbase} />}
                  aria-label="Crunchbase"
                  as="a"
                  target="_blank"
                  href={ensureAbsoluteLink(signal.crunchbaseUrl)}
                />
              )}
              */}
            </PopoverContent>
          </Portal>
        </Popover>
      </>
    )
  }

  if (product === SpecterProducts.talent) {
    const signalTalent = signal as ProductItem<"talent">
    return (
      <>
        <ButtonLinkedin linkedinUrl={signalTalent.linkedin_url} />
        <ButtonTwitter twitterUrl={signalTalent.twitter_url} />
        <ButtonGithub githubUrl={signalTalent.github_url} />
      </>
    )
  }

  if (product === SpecterProducts.stratintel) {
    const signalStratintel = signal as ProductItem<"stratintel">
    return <ButtonsStrategic signal={signalStratintel} expandEmail={false} />
  }

  if (product === SpecterProducts.investors) {
    const signalInvestor = signal as ProductItem<"investors">
    return (
      <>
        <ButtonWebsite websiteUrl={signalInvestor.domain} />
        <ButtonLinkedin
          linkedinUrl={ensureAbsoluteLink(signalInvestor.linkedinUrl)}
        />
        <ButtonTwitter
          twitterUrl={ensureAbsoluteLink(signalInvestor.twitterUrl)}
        />
      </>
    )
  }

  if (product === SpecterProducts.people) {
    const signalPeople = signal as ProductItem<"people">
    return (
      <>
        <ButtonLinkedin
          linkedinUrl={ensureAbsoluteLink(signalPeople.linkedin_url)}
        />
        <ButtonTwitter
          twitterUrl={ensureAbsoluteLink(signalPeople.twitter_url)}
        />
        <ButtonGithub githubUrl={ensureAbsoluteLink(signalPeople.github_url)} />
      </>
    )
  }

  return <></>
}

export function ListAddedIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7998 2.99999C1.7998 2.84086 1.86302 2.68825 1.97554 2.57573C2.08806 2.46321 2.24067 2.39999 2.3998 2.39999H8.5998C8.75893 2.39999 8.91155 2.46321 9.02407 2.57573C9.13659 2.68825 9.19981 2.84086 9.19981 2.99999C9.19981 3.15912 9.13659 3.31174 9.02407 3.42426C8.91155 3.53678 8.75893 3.59999 8.5998 3.59999H2.3998C2.24067 3.59999 2.08806 3.53678 1.97554 3.42426C1.86302 3.31174 1.7998 3.15912 1.7998 2.99999ZM1.7998 5.99999C1.7998 5.84086 1.86302 5.68825 1.97554 5.57573C2.08806 5.46321 2.24067 5.39999 2.3998 5.39999H6.79976C6.95889 5.39999 7.11151 5.46321 7.22403 5.57573C7.33655 5.68825 7.39977 5.84086 7.39977 5.99999C7.39977 6.15912 7.33655 6.31174 7.22403 6.42426C7.11151 6.53678 6.95889 6.59999 6.79976 6.59999H2.3998C2.24067 6.59999 2.08806 6.53678 1.97554 6.42426C1.86302 6.31174 1.7998 6.15912 1.7998 5.99999ZM1.7998 8.99999C1.7998 8.84086 1.86302 8.68825 1.97554 8.57573C2.08806 8.46321 2.24067 8.39999 2.3998 8.39999H4.9998C5.15893 8.39999 5.31155 8.46321 5.42407 8.57573C5.53659 8.68825 5.5998 8.84086 5.5998 8.99999C5.5998 9.15912 5.53659 9.31174 5.42407 9.42426C5.31155 9.53678 5.15893 9.59999 4.9998 9.59999H2.3998C2.24067 9.59999 2.08806 9.53678 1.97554 9.42426C1.86302 9.31174 1.7998 9.15912 1.7998 8.99999Z"
        fill={color}
      />
      <path
        d="M6.9071 8.05727L8.25914 9.22722C8.35053 9.30631 8.49204 9.27737 8.54506 9.16877L10.3788 5.41225"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export function ListAddIcon({ color = "black" }: { color?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7998 2.99999C1.7998 2.84086 1.86302 2.68825 1.97554 2.57573C2.08806 2.46321 2.24067 2.39999 2.3998 2.39999H8.5998C8.75893 2.39999 8.91155 2.46321 9.02407 2.57573C9.13659 2.68825 9.19981 2.84086 9.19981 2.99999C9.19981 3.15912 9.13659 3.31174 9.02407 3.42426C8.91155 3.53678 8.75893 3.59999 8.5998 3.59999H2.3998C2.24067 3.59999 2.08806 3.53678 1.97554 3.42426C1.86302 3.31174 1.7998 3.15912 1.7998 2.99999ZM1.7998 5.99999C1.7998 5.84086 1.86302 5.68825 1.97554 5.57573C2.08806 5.46321 2.24067 5.39999 2.3998 5.39999H6.79976C6.95889 5.39999 7.11151 5.46321 7.22403 5.57573C7.33655 5.68825 7.39977 5.84086 7.39977 5.99999C7.39977 6.15912 7.33655 6.31174 7.22403 6.42426C7.11151 6.53678 6.95889 6.59999 6.79976 6.59999H2.3998C2.24067 6.59999 2.08806 6.53678 1.97554 6.42426C1.86302 6.31174 1.7998 6.15912 1.7998 5.99999ZM1.7998 8.99999C1.7998 8.84086 1.86302 8.68825 1.97554 8.57573C2.08806 8.46321 2.24067 8.39999 2.3998 8.39999H4.9998C5.15893 8.39999 5.31155 8.46321 5.42407 8.57573C5.53659 8.68825 5.5998 8.84086 5.5998 8.99999C5.5998 9.15912 5.53659 9.31174 5.42407 9.42426C5.31155 9.53678 5.15893 9.59999 4.9998 9.59999H2.3998C2.24067 9.59999 2.08806 9.53678 1.97554 9.42426C1.86302 9.31174 1.7998 9.15912 1.7998 8.99999Z"
        fill={color}
      />
      <path
        d="M8.89063 9.83194V8.08194M8.89063 6.33194V8.08194M8.89063 8.08194H10.6406M8.89063 8.08194H7.14062"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const SortRemoveIcon: IconType = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 30C27.3478 30 24.8043 31.0536 22.9289 32.9289C21.0536 34.8043 20 37.3478 20 40C20 42.6522 21.0536 45.1957 22.9289 47.0711C24.8043 48.9464 27.3478 50 30 50H140C142.652 50 145.196 48.9464 147.071 47.0711C148.946 45.1957 150 42.6522 150 40C150 37.3478 148.946 34.8043 147.071 32.9289C145.196 31.0536 142.652 30 140 30H30ZM30 70C27.3478 70 24.8043 71.0536 22.9289 72.9289C21.0536 74.8043 20 77.3478 20 80C20 82.6522 21.0536 85.1957 22.9289 87.0711C24.8043 88.9464 27.3478 90 30 90H80C82.6522 90 85.1957 88.9464 87.0711 87.0711C88.9464 85.1957 90 82.6522 90 80C90 77.3478 88.9464 74.8043 87.0711 72.9289C85.1957 71.0536 82.6522 70 80 70H30ZM30 110C27.3478 110 24.8043 111.054 22.9289 112.929C21.0536 114.804 20 117.348 20 120C20 122.652 21.0536 125.196 22.9289 127.071C24.8043 128.946 27.3478 130 30 130H70C72.6522 130 75.1957 128.946 77.0711 127.071C78.9464 125.196 80 122.652 80 120C80 117.348 78.9464 114.804 77.0711 112.929C75.1957 111.054 72.6522 110 70 110H30Z" />
      <path d="M161.177 155.269C163.052 157.144 165.596 158.198 168.248 158.198C170.9 158.198 173.444 157.144 175.319 155.269C177.194 153.393 178.248 150.85 178.248 148.198C178.248 145.546 177.194 143.002 175.319 141.127L119.214 85.071C117.338 83.1956 114.795 82.142 112.143 82.142C109.49 82.142 106.947 83.1956 105.072 85.071C103.196 86.9463 102.143 89.4899 102.143 92.142C102.143 94.7942 103.196 97.3378 105.072 99.2131L161.177 155.269Z" />
      <path d="M175.295 99.1883C177.17 97.3129 178.223 94.7693 178.223 92.1172C178.223 89.465 177.17 86.9215 175.294 85.0461C173.419 83.1708 170.876 82.1172 168.223 82.1172C165.571 82.1172 163.028 83.1708 161.152 85.0461L105.097 141.151C103.221 143.027 102.168 145.57 102.168 148.222C102.168 150.875 103.221 153.418 105.097 155.293C106.972 157.169 109.516 158.222 112.168 158.222C114.82 158.222 117.364 157.169 119.239 155.293L175.295 99.1883Z" />
    </svg>
  )
}
