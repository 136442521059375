import { Flex, Image, Link, Tag, Text } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ColDef, ColDefField } from "ag-grid-community"
import numeral from "numeral"
import pluralize from "pluralize"

import {
  CompanyLogo,
  LogosGrid,
  ProductItem,
  SearchesAndListsProducts,
  SignalStatusTalent,
} from "~/components"
import { ScreenshotButton } from "~/components/ScreenshotButton"
import { ButtonGroupTable } from "~/components/SignalCard/ButtonGroupTable"
import {
  CellRenderer,
  CellRendererProps,
  FactoryRenderValueGrowths,
  RenderAcquisitionType,
  RenderArrayTag,
  RenderAwards,
  RenderBoolean,
  RenderCommaString,
  RenderCompanyHighlights,
  RenderCompanyNameAndLogo,
  RenderCompanySize,
  RenderDate,
  RenderDelta,
  RenderDollar,
  RenderEducation,
  RenderExperience,
  RenderFundingType,
  RenderG2Data,
  RenderGrowthStage,
  RenderInternalLink,
  RenderInvestorHighlights,
  RenderInvestors,
  RenderNumber,
  RenderNumberTag,
  RenderNumberTagFire,
  RenderOperatingStatus,
  RenderPeopleNameAndAvatar,
  RenderPercentage,
  RenderPlainText,
  RenderSearchListCreator,
  RenderSeconds,
  RenderSemicolonString,
  RenderSeniority,
  RenderStarredNumber,
  RenderStratintelNameAndAvatar,
  RenderTagDate,
  RenderTalentNameAndAvatar,
  RenderTextTag,
  RenderTitleCase,
  RenderTitleCaseCommaString,
  RenderTrustpilotData,
} from "~/components/Table/cellRenderers"
import { TagIndustry } from "~/components/TagIndustry"
import { allTalentSignalStatus } from "~/consts/talentSignals"
import { GetUserListsByProduct } from "~/routes/__protected/api/lists"
import { SavedSearchesIndex } from "~/routes/__protected/api/saved-searches"
import { TableView } from "~/routes/__protected/api/user/table-views"
import { VisibilityStatusList } from "~/routes/__protected/user/lists/$product/index"
import { getProductEntityName } from "~/utils/getProductName"
import { getSignalFieldTitle } from "~/utils/signal"
import { formatDate } from "~/utils/string/format"
import { isNullish } from "~/utils/values"
import { isOneOf } from "../../utils/isOneOf"
import {
  RenderListName,
  RenderTagNewSignalsList,
  UserListActions,
} from "../UserList/table-renderers"
import {
  RenderSearchName,
  RenderTagNewSignalsSearch,
  SavedSearchActions,
} from "../UserSearchesPage/table-renderes"
import { VisibilityStatus } from "../UserSearchesPage/VisibilityStatus"
import { TableConfigs } from "./MemoizedTable"

export type TableColumnData<Config extends TableConfigs> =
  Config extends SpecterProducts
    ? ProductItem<Config>
    : Config extends `saved-searches.${SearchesAndListsProducts}`
    ? SavedSearchesIndex["items"][number]
    : Config extends `user-lists.${SearchesAndListsProducts}`
    ? GetUserListsByProduct[number]
    : never

export type ColumnDef<
  Config extends TableConfigs,
  TData = TableColumnData<Config>
> = Omit<ColDef<TData>, "field" | "headerName" | "cellRenderer"> & {
  growthFields?: { field: ColDefField<TData>; timeSpan: string }[]
  headerName?: JSX.Element | string
  field: ColDefField<TData> | "social-actions"
  baseLockedColumn?: boolean
  cellRenderer?: CellRenderer<Config>
}

const COMPANY_COLUMN_DEFS: ColumnDef<"company">[] = [
  {
    baseLockedColumn: true,
    field: "name",
    headerName: "Company Name",
    width: 240,
    cellRenderer: RenderCompanyNameAndLogo,
    checkboxSelection: true,
  },
  {
    baseLockedColumn: true,
    resizable: false,
    field: "social-actions",
    headerName: "Social / URLs",
    cellRenderer: ({ data }: CellRendererProps<"company">) => {
      if (!data) return ""

      return <ButtonGroupTable product="company" signal={data} />
    },
  },
  {
    field: "rank",
    headerName: (
      <Flex gap="1" alignItems="center">
        <Image src="/specter-logo.svg" h="15px" />
        Rank
      </Flex>
    ),
    type: "numericColumn",
  },
  {
    field: "growthStage",
    cellRenderer: RenderGrowthStage,
  },
  {
    field: "foundedYear",
    type: "numericColumn",
    cellRenderer: RenderPlainText,
  },
  {
    field: "operatingStatus",
    cellRenderer: RenderOperatingStatus,
  },
  {
    field: "highlights",
    cellRenderer: RenderCompanyHighlights,
    suppressAutoSize: true,
  },
  {
    field: "description",
    suppressAutoSize: true,
  },
  {
    field: "industry",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  {
    field: "subIndustry",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  { field: "hqLocation" },
  { field: "hqRegion" },
  {
    field: "totalFundingAmount",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "lastFundingAmount",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "lastFundingDate",
    cellRenderer: RenderDate,
    type: "numericColumn",
  },
  {
    field: "lastFundingType",
    cellRenderer: RenderTitleCase,
  },
  {
    field: "postMoneyValuation",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "fundingRoundsCount",
    type: "numericColumn",
  },
  {
    field: "annualRevenueEstimate",
    type: "numericColumn",
  },
  {
    field: "investorsCount",
    type: "numericColumn",
  },
  {
    field: "investors",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  {
    field: "companySize",
  },
  {
    field: "founders",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  {
    field: "foundersCount",
    type: "numericColumn",
  },
  {
    field: "webVisits",
    growthFields: [
      { field: "growth_metrics.web_visits_1mo_ratio", timeSpan: "1mo" },
      { field: "growth_metrics.web_visits_3mo_ratio", timeSpan: "3mo" },
      { field: "growth_metrics.web_visits_6mo_ratio", timeSpan: "6mo" },
      { field: "growth_metrics.web_visits_12mo_ratio", timeSpan: "1y" },
      { field: "growth_metrics.web_visits_24mo_ratio", timeSpan: "2y" },
    ],
    cellRenderer: FactoryRenderValueGrowths(),
  },
  {
    field: "webBounceRate",
    growthFields: [{ field: "webBounceRateMonthlyGrowth3", timeSpan: "3mo" }],
    cellRenderer: FactoryRenderValueGrowths({
      RendererValue: RenderPercentage,
      inverted: true,
    }),
  },
  {
    field: "webSessionDuration",
    growthFields: [
      { field: "webSessionDurationMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererValue: RenderSeconds }),
  },
  {
    field: "webPopularityRank",
    growthFields: [
      { field: "growth_metrics.popularity_rank_1mo_diff", timeSpan: "1mo" },
      { field: "growth_metrics.popularity_rank_3mo_diff", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererGrowth: RenderDelta }),
  },
  {
    field: "employeeCount",
    growthFields: [
      { field: "growth_metrics.employee_count_1mo_ratio", timeSpan: "1mo" },
      { field: "growth_metrics.employee_count_3mo_ratio", timeSpan: "3mo" },
      { field: "growth_metrics.employee_count_6mo_ratio", timeSpan: "6mo" },
      { field: "growth_metrics.employee_count_12mo_ratio", timeSpan: "1y" },
      { field: "growth_metrics.employee_count_24mo_ratio", timeSpan: "2y" },
    ],
    cellRenderer: FactoryRenderValueGrowths(),
  },
  {
    field: "linkedinFollowers",
    growthFields: [
      { field: "linkedinFollowersMonthlyGrowth1", timeSpan: "1mo" },
      { field: "linkedinFollowersMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererGrowth: RenderDelta }),
  },
  {
    field: "twitterFollowers",
    growthFields: [
      { field: "twitterFollowersMonthlyGrowth1", timeSpan: "1mo" },
      { field: "twitterFollowersMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererGrowth: RenderDelta }),
  },
  {
    field: "instagramFollowers",
    growthFields: [
      { field: "instagramFollowersMonthlyGrowth1", timeSpan: "1mo" },
      { field: "instagramFollowersMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererGrowth: RenderDelta }),
  },
  {
    field: "instagramFollowing",
    cellRenderer: RenderNumberTag,
  },
  {
    field: "totalAppDownloads",
    growthFields: [
      { field: "totalAppDownloadsMonthlyGrowth1", timeSpan: "1mo" },
      { field: "totalAppDownloadsMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths(),
  },
  {
    field: "itunesRating",
    cellRenderer: RenderStarredNumber,
  },
  {
    field: "itunesReviews",
    growthFields: [
      { field: "itunesReviewsMonthlyGrowth1", timeSpan: "1mo" },
      { field: "itunesReviewsMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererGrowth: RenderDelta }),
  },
  {
    field: "googlePlayRating",
    cellRenderer: RenderStarredNumber,
  },
  {
    field: "googlePlayReviews",
    growthFields: [
      { field: "googlePlayReviewsMonthlyGrowth1", timeSpan: "1mo" },
      { field: "googlePlayReviewsMonthlyGrowth3", timeSpan: "3mo" },
    ],
    cellRenderer: FactoryRenderValueGrowths({ RendererGrowth: RenderDelta }),
  },
  {
    field: "googlePlayInstalls",
    cellRenderer: RenderTextTag,
  },
  {
    field: "g2_data",
    cellRenderer: RenderG2Data,
    minWidth: 200,
  },
  {
    field: "g2_rating_avg",
    cellRenderer: RenderStarredNumber,
    type: "numericColumn",
  },
  {
    field: "g2_total_reviews",
    type: "numericColumn",
  },
  {
    field: "awards",
    cellRenderer: RenderAwards,
    suppressAutoSize: true,
  },
  {
    field: "awardsCount",
    type: "numericColumn",
  },
  {
    field: "trustpilot_data",
    cellRenderer: RenderTrustpilotData,
  },
  {
    field: "trustpilot_data.review_count",
    type: "numericColumn",
  },
]

export type TableViewConfigs = Pick<
  TableView,
  "columns" | "pinned" | "sort" | "widths" | "growthColumns"
> & { config: TableConfigs }

const COMPANY_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.company,
  columns: [
    "name",
    "social-actions",
    "rank",
    "hqRegion",
    "growthStage",
    "highlights",
    "industry",
  ],
  growthColumns: {
    webVisits: [
      "growth_metrics.web_visits_1mo_ratio",
      "growth_metrics.web_visits_6mo_ratio",
      "growth_metrics.web_visits_12mo_ratio",
    ],
    employeeCount: [
      "growth_metrics.employee_count_1mo_ratio",
      "growth_metrics.employee_count_6mo_ratio",
      "growth_metrics.employee_count_24mo_ratio",
    ],
  },
  pinned: {},
  sort: [],
  widths: {},
}

const TALENT_COLUMN_DEFS: ColumnDef<"talent">[] = [
  {
    baseLockedColumn: true,
    field: "full_name",
    width: 220,
    cellRenderer: RenderTalentNameAndAvatar,
    checkboxSelection: true,
  },
  {
    baseLockedColumn: true,
    field: "social-actions",
    headerName: "",
    cellRenderer: ({ data }: CellRendererProps<"talent">) => {
      if (!data) return ""

      return <ButtonGroupTable product="talent" signal={data} />
    },
  },
  { field: "location" },
  { field: "region" },
  {
    field: "signal_date",
    cellRenderer: ({ value }: CellRendererProps<"talent">) => {
      if (isNullish(value)) return null
      return <Text fontSize="xs">{formatDate(new Date(value))}</Text>
    },
  },
  {
    field: "signal_score",
    cellRenderer({ value }: CellRendererProps<"talent">) {
      return (
        <Text color="blue.400" fontSize="xs">
          Score {value}/10
        </Text>
      )
    },
  },
  {
    field: "signal_type",
  },
  {
    field: "signal_status",
    cellRenderer: ({ value }: CellRendererProps<"talent">) => {
      if (!isOneOf(value, allTalentSignalStatus)) return null
      return <SignalStatusTalent signalStatus={value} />
    },
  },
  {
    colId: "serial_founder",
    field: "people_highlights",
    headerName: "Serial Founder",
    cellRenderer: ({ data }: CellRendererProps<"talent">) => {
      if (!data?.people_highlights?.includes("serial_founder")) return null
      return (
        <Tag
          bgColor="#00A8A8"
          variant="solid"
          verticalAlign="text-bottom"
          rounded="full"
        >
          Serial Founder
        </Tag>
      )
    },
  },
  {
    colId: "department",
    field: "experience",
    cellRenderer: ({ data }: CellRendererProps<"talent">) => {
      if (isNullish(data?.experience)) return null

      const department = data?.experience?.find(
        (experience) => experience.is_current
      )?.departments

      return <RenderArrayTag data={data} value={department} />
    },
  },
  {
    field: "seniority",
    minWidth: 170,
    cellRenderer: RenderSeniority,
  },
  {
    field: "years_of_experience",
    cellRenderer: RenderTitleCase,
  },
  {
    field: "past_position_title",
    cellRenderer: RenderSemicolonString,
    suppressAutoSize: true,
  },
  {
    field: "past_position_company_website",
    cellRenderer: ({ value }: CellRendererProps<"talent">) => {
      if (isNullish(value)) return null
      return (
        <Link fontSize="xs" color="brand.500" href={value} target="_blank">
          {value}
        </Link>
      )
    },
  },
  {
    field: "current_position_title",
    suppressAutoSize: true,
  },
  {
    field: "current_position_company_website",
    cellRenderer: ({ value }: CellRendererProps<"talent">) => {
      if (isNullish(value)) return null
      return (
        <Link fontSize="xs" color="brand.500" href={value} target="_blank">
          {value}
        </Link>
      )
    },
  },
  {
    field: "linkedin_url",
    cellRenderer: ({ value }: CellRendererProps<"talent">) => {
      if (isNullish(value)) return null
      return (
        <Link fontSize="xs" color="brand.500" href={value} target="_blank">
          {value}
        </Link>
      )
    },
  },
  // {
  //   field: "FoundedDate",
  //   type: "numericColumn",
  // },
  // {
  //   field: "NewCompanyTagline",
  //   suppressAutoSize: true,
  // },
  // {
  //   field: "IndustryOG",
  //   cellRenderer: RenderSemicolonString,
  //   suppressAutoSize: true,
  // },
  // {
  //   field: "Tags",
  //   cellRenderer: RenderCommaString,
  //   suppressAutoSize: true,
  // },
  {
    field: "out_of_stealth_advantage",
    type: "numericColumn",
  },
  {
    field: "announcement_delay",
    type: "numericColumn",
  },
  {
    field: "talent_last_signal",
    cellRenderer: RenderBoolean,
  },
  {
    field: "skills",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  // {
  //   field: "personalDetail.birthYear",
  //   headerName: "Age",
  //   cellRenderer: RenderAge,
  //   type: "numericColumn",
  // },
  {
    field: "experience",
    colId: "current-experience",
    width: 300,
    cellRenderer: RenderExperience({ isCurrent: true }),
  },
  {
    field: "experience",
    colId: "past-experience",
    width: 300,
    cellRenderer: RenderExperience({ isCurrent: false }),
  },
  {
    field: "education",
    width: 300,
    cellRenderer: RenderEducation,
  },
  {
    field: "languages",
    cellRenderer: RenderCommaString,
  },
  // {
  //   field: "TwitterFollowers",
  //   cellRenderer: ({ value }: CellRendererProps<"talent">) => {
  //     if (isNullish(value)) return null
  //     return <Text fontSize="xs">{numeral(value).format("0.[0]a")}</Text>
  //   },
  // },
  // {
  //   field: "TwitterFFRatio",
  //   cellRenderer({ value }: CellRendererProps<"talent">) {
  //     return numeral(value).format("0.00")
  //   },
  // },
  {
    field: "followers_count",
    cellRenderer: ({ value }: CellRendererProps<"talent">) => {
      if (isNullish(value)) return null
      return <Text fontSize="xs">{numeral(value).format("0.[0]a")}</Text>
    },
  },
]

const TALENT_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.talent,
  columns: [
    "full_name",
    "social-actions",
    "region",
    "signal_date",
    "signal_score",
    "signal_type",
    "new_position",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const STRATINTEL_COLUMN_DEFS: ColumnDef<"stratintel">[] = [
  {
    baseLockedColumn: true,
    field: "Name",
    width: 250,
    cellRenderer: RenderStratintelNameAndAvatar,
    checkboxSelection: true,
  },

  {
    baseLockedColumn: true,
    field: "social-actions",
    headerName: "",
    cellRenderer: ({ data }: CellRendererProps<"stratintel">) => {
      if (!data) return ""

      return <ButtonGroupTable product="stratintel" signal={data} />
    },
  },

  { field: "HQLocation" },
  { field: "HQRegion" },
  {
    field: "SignalDate",
    cellRenderer: ({ value }: CellRendererProps<"stratintel">) => {
      if (isNullish(value)) return null
      return <Text fontSize="xs">{formatDate(new Date(value))}</Text>
    },
  },
  {
    field: "SignalScore",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      return (
        <Text color="blue.400" fontSize="xs">
          Score {value}/10
        </Text>
      )
    },
  },
  { field: "SignalType" },
  { field: "SourceType", cellRenderer: RenderTitleCaseCommaString },
  {
    field: "Logos",
    headerName: "Source Logos",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null
      return <LogosGrid logos={value} nLogos={5} />
    },
  },
  {
    field: "SignalSource",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null

      return (
        <>
          {value.split(",").map((tag: string) => (
            <Tag
              key={tag}
              verticalAlign="text-bottom"
              rounded="full"
              minW="fit-content"
            >
              {tag}
            </Tag>
          ))}
        </>
      )
    },
    suppressAutoSize: true,
  },
  {
    field: "growthStage",
    cellRenderer: RenderGrowthStage,
  },
  {
    field: "Website",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null
      return (
        <Link
          fontSize="xs"
          color="brand.500"
          href={value}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      )
    },
  },
  {
    field: "Description",
    headerName: "Description",
    suppressAutoSize: true,
  },
  {
    field: "IndustryOG",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null

      return (
        <>
          {value.split(";").map((industry: string) => (
            <TagIndustry key={industry.trim()} label={industry.trim()} />
          ))}
        </>
      )
    },
    suppressAutoSize: true,
  },
  {
    field: "Founded",
    type: "numericColumn",
  },
  {
    field: "TotalFundingAmount",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null
      return <Text fontSize="xs">{numeral(value).format("$0,0")}</Text>
    },
    type: "numericColumn",
  },
  {
    field: "LastFundingDate",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null
      return <Text fontSize="xs">{formatDate(new Date(value))}</Text>
    },
  },
  {
    field: "LastFundingAmount",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      if (isNullish(value)) return null
      return <Text fontSize="xs">{numeral(value).format("$0,0")}</Text>
    },
    type: "numericColumn",
  },
  {
    field: "Investors",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  {
    field: "StratintelMulti.numberOfSignals",
    headerName: "Number of Signals",
    growthFields: [
      { field: "StratintelMulti.signalCountPast30d", timeSpan: "30d" },
      { field: "StratintelMulti.signalCountPast90d", timeSpan: "90d" },
      { field: "StratintelMulti.signalCountPast180d", timeSpan: "180d" },
      { field: "StratintelMulti.signalCountPast360d", timeSpan: "360d" },
      { field: "StratintelMulti.signalCountPast720d", timeSpan: "720d" },
    ],
    cellRenderer: FactoryRenderValueGrowths({
      RendererValue: RenderNumberTag,
      RendererGrowth: RenderNumberTagFire,
    }),
  },
  {
    field: "Screenshot",
    cellRenderer({ value }: CellRendererProps<"stratintel">) {
      return <ScreenshotButton screenshot={value} />
    },
  },
]

const STRATINTEL_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.stratintel,
  columns: [
    "Name",
    "social-actions",
    "HQRegion",
    "SignalDate",
    "SignalScore",
    "SignalSource",
    "growthStage",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const INVESTORS_COLUMN_DEFS: ColumnDef<"investors">[] = [
  {
    baseLockedColumn: true,
    field: "name",
    headerName: "Investor Name",
    cellRenderer: RenderInternalLink<"investors">(({ data }) => ({
      to: `./${data?.id}${location.search}`,
      children: (
        <>
          <CompanyLogo
            domain={String(data?.domain ?? "")}
            source={data?.logoUrl || undefined}
            size={6}
          />
          {data?.name}
        </>
      ),
    })),
    checkboxSelection: true,
  },
  {
    baseLockedColumn: true,
    resizable: false,
    field: "social-actions",
    headerName: "Social / URLs",
    cellRenderer: ({ data }: CellRendererProps<"investors">) => {
      if (!data) return ""

      return <ButtonGroupTable product="investors" signal={data} />
    },
  },
  {
    field: "rank",
    headerName: (
      <Flex gap="1" alignItems="center">
        <Image src="/specter-logo.svg" h="15px" />
        Rank
      </Flex>
    ),
    type: "numericColumn",
  },
  {
    field: "companySize",
    cellRenderer: RenderCompanySize,
  },
  {
    field: "status",
    cellRenderer: RenderOperatingStatus,
  },
  {
    field: "HQRegion",
    suppressAutoSize: true,
  },
  {
    field: "HQLocation",
    suppressAutoSize: true,
  },
  { field: "domain" },
  {
    field: "foundedYear",
    type: "numericColumn",
    cellRenderer: RenderPlainText,
  },
  {
    field: "types",
    headerName: "Investor Type",
    cellRenderer: RenderTitleCaseCommaString,
    suppressAutoSize: true,
  },
  {
    field: "description",
    suppressAutoSize: true,
  },
  {
    field: "nExits",
    type: "numericColumn",
  },
  {
    field: "nLeadInvestments",
    type: "numericColumn",
  },
  {
    field: "nInvestments",
    type: "numericColumn",
  },
  {
    field: "nFunds",
    type: "numericColumn",
  },
  {
    field: "stages",
    headerName: "Investment Stage",
    suppressAutoSize: true,
    cellRenderer: RenderFundingType,
  },
  {
    field: "targetIndustries",
    cellRenderer: RenderCommaString,
    suppressAutoSize: true,
  },
  {
    field: "InvestorHighlights",
    cellRenderer: RenderInvestorHighlights,
    suppressAutoSize: true,
  },
]

const INVESTORS_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.investors,
  columns: [
    "name",
    "social-actions",
    "rank",
    "HQLocation",
    "nInvestments",
    "InvestorHighlights",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const FUNDING_ROUNDS_COLUMN_DEFS: ColumnDef<"fundingRounds">[] = [
  {
    baseLockedColumn: true,
    field: "company",
    cellRenderer: RenderInternalLink<"fundingRounds">(({ data }) => ({
      to: `./${data?.company?.id}${location.search}${
        location.search ? "&" : "?"
      }tab=1`,
      children: (
        <>
          <CompanyLogo
            domain={String(
              data?.company?.CompanyData?.Domain ?? data?.company.domain ?? ""
            )}
            size={6}
          />
          {data?.company?.CompanyData?.CompanyName ??
            data?.company.name ??
            data?.companyName}
        </>
      ),
    })),
    checkboxSelection: true,
  },
  {
    field: "fundingType",
    cellRenderer: RenderFundingType,
  },
  {
    field: "raisedAmount",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "preMoneyValuation",
    cellRenderer: RenderDollar,
    type: "numericColumn",
    suppressAutoSize: true,
  },
  {
    field: "postMoneyValuation",
    cellRenderer: RenderDollar,
    type: "numericColumn",
    suppressAutoSize: true,
  },
  {
    field: "announcedOn",
    cellRenderer: RenderDate,
    type: "numericColumn",
  },
  {
    field: "investors",
    cellRenderer: RenderInvestors,
    suppressAutoSize: true,
  },
]

const FUNDING_ROUNDS_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.fundingRounds,
  columns: [
    "company",
    "fundingType",
    "raisedAmount",
    "announcedOn",
    "investors",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const ACQUISITION_COLUMN_DEFS: ColumnDef<"acquisition">[] = [
  // {
  //   "relationsId": "5a4e1eab-552c-c2ac-3129-490b36335430",
  //   "id": "acq_8214e00a22ed225b25d8e344",
  //   "acquirerId": "b6cf6ce1-d06d-4ec0-8e09-9f3e9146f9a2",
  //   "acquirerName": "Gilat Satellite Networks",
  //   "acquiredId": "33d1fefc-d487-488c-b97f-d4d78353bb9e",
  //   "acquiredName": "Stellar Blu Solutions",
  //   "type": "acquisition",
  //   "acquiredOn": "2024-06-17T00:00:00.000Z",
  //   "price": 98000000,
  //   "acquired": {
  //       "id": "63eb90cc1e6a68488a054f7b",
  //       "domain": "stellar-blu.com"
  //   },
  //   "acquirer": {
  //       "id": "5e3bbcfb00c8f4c9669ae9c4",
  //       "domain": "gilat.com"
  //   }
  // },
  {
    baseLockedColumn: true,
    field: "acquired",
    headerName: getSignalFieldTitle("acquiredName"),
    width: 240,
    cellRenderer: RenderInternalLink<"acquisition">(({ data }) => ({
      to: `./${data?.acquired?.id}${location.search}${
        location.search ? "&" : "?"
      }tab=1`,
      children: (
        <>
          <CompanyLogo domain={data?.acquired?.domain ?? ""} size={6} />
          {data?.acquiredName}
        </>
      ),
    })),
    checkboxSelection: true,
  },
  {
    baseLockedColumn: true,
    field: "acquirer",
    headerName: getSignalFieldTitle("acquirerName"),
    width: 240,
    cellRenderer: RenderInternalLink<"acquisition">(({ data }) => ({
      to: `./${data?.acquirer?.id}${location.search}`,
      children: (
        <>
          <CompanyLogo domain={data?.acquirer?.domain ?? ""} size={6} />
          {data?.acquirerName}
        </>
      ),
    })),
  },
  {
    field: "acquiredOn",
    cellRenderer: RenderDate,
    type: "numericColumn",
  },
  {
    field: "acquisitionPrice",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "acquisitionType",
    cellRenderer: RenderAcquisitionType,
  },
]

const ACQUISITION_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.acquisition,
  columns: [
    "acquired",
    "acquirer",
    "acquiredOn",
    "acquisitionPrice",
    "acquisitionType",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const IPO_COLUMN_DEFS: ColumnDef<"ipo">[] = [
  //   {
  //     "relationsId": "111699cd-f804-badf-7aef-9442090734f3",
  //     "id": "ipo_55f4096f82494b24b45eb903",
  //     "companyId": "6b75bbd9-ceba-4cdd-9648-1dc97f5da142",
  //     "companyName": "Tempus",
  //     "stockExchangeSymbol": "nasdaq",
  //     "stockSymbol": "TEM",
  //     "wentPublicOn": "2024-06-14T00:00:00.000Z",
  //     "sharePrice": 37,
  //     "valuationPrice": 6108700000,
  //     "moneyRaised": 410700000,
  //     "company": {
  //         "id": "5e3bc09700c8f4c966a56e2f",
  //         "domain": "tempus.com"
  //     }
  // }
  {
    baseLockedColumn: true,
    field: "companyName",
    headerName: "Company Name",
    width: 240,
    cellRenderer: RenderInternalLink<"ipo">(({ data }) => ({
      to: `./${data?.company?.id}${location.search}`,
      children: (
        <>
          <CompanyLogo domain={data?.company?.domain ?? ""} size={6} />
          {data?.companyName}
        </>
      ),
    })),
    checkboxSelection: true,
  },
  {
    field: "wentPublicOn",
    cellRenderer: RenderDate,
    type: "numericColumn",
  },
  {
    field: "sharePrice",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "valuationPrice",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "moneyRaised",
    cellRenderer: RenderDollar,
    type: "numericColumn",
  },
  {
    field: "stockSymbol",
  },
  {
    field: "stockExchangeSymbol",
    cellRenderer: RenderTitleCase,
  },
]

const IPO_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.ipo,
  columns: [
    "companyName",
    "wentPublicOn",
    "sharePrice",
    "valuationPrice",
    "moneyRaised",
    "stockExchangeSymbol",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const PEOPLE_COLUMN_DEFS: ColumnDef<"people">[] = [
  {
    baseLockedColumn: true,
    field: "full_name",
    width: 220,
    cellRenderer: RenderPeopleNameAndAvatar,
    checkboxSelection: true,
  },
  {
    baseLockedColumn: true,
    field: "social-actions",
    headerName: "",
    cellRenderer: ({ data }: CellRendererProps<"people">) => {
      if (!data) return ""

      return <ButtonGroupTable product="people" signal={data} />
    },
  },
  { field: "tagline", width: 300 },
  { field: "location" },
  { field: "region" },
  {
    field: "experience",
    colId: "current-experience",
    width: 300,
    cellRenderer: RenderExperience({ isCurrent: true }),
  },
  {
    field: "experience",
    colId: "past-experience",
    width: 300,
    cellRenderer: RenderExperience({ isCurrent: false }),
  },
  {
    field: "education",
    width: 300,
    cellRenderer: RenderEducation,
  },
  {
    field: "years_of_experience",
    cellRenderer: RenderNumber,
    type: "numericColumn",
  },
  {
    field: "education_level",
    cellRenderer: RenderTextTag,
  },
]

const PEOPLE_DEFAULT_TABLE_VIEW: TableViewConfigs = {
  config: SpecterProducts.people,
  columns: [
    "full_name",
    "social-actions",
    "tagline",
    "region",
    "current-experience",
    "past-experience",
    "education",
    "years_of_experience",
    "education_level",
  ],
  pinned: {},
  sort: [],
  widths: {},
  growthColumns: {},
}

const SearchActions = ({
  data,
}: {
  data: SavedSearchesIndex["items"][number]
}) => {
  return (
    <SavedSearchActions
      savedSearch={data}
      product={data.type as SearchesAndListsProducts}
    />
  )
}
const SAVED_SEARCHES_COLUMN_DEFS = <P extends SearchesAndListsProducts>(
  product: P
) =>
  [
    {
      baseLockedColumn: true,
      field: "name",
      cellRenderer: RenderSearchName,
      headerName: "Title",
    },
    {
      baseLockedColumn: true,
      field: "social-actions",
      headerName: "",
      valueGetter: (params) => {
        return params.data
      },
      cellRenderer: SearchActions,
    },
    {
      field: "countNewSignals",
      cellRenderer: RenderTagNewSignalsSearch,
      headerName: `New ${pluralize(getProductEntityName(product))}`,
    },
    ...(product === SpecterProducts.company
      ? [
          {
            field: "countNewFundingHighlights",
            cellRenderer: RenderTagNewSignalsSearch,
            headerName: "New Funding Signals",
          },
          {
            field: "countNewGrowthHighlights",
            cellRenderer: RenderTagNewSignalsSearch,
            headerName: "New Growth Signals",
          },
        ]
      : []),
    {
      field: "countAllSignals",
      cellRenderer: RenderTagNewSignalsSearch,
      headerName: `Total ${pluralize(getProductEntityName(product))}`,
    },
    {
      field: "visibility",
      cellRenderer: ({ value }) => <VisibilityStatus visibility={value} />,
    },
    {
      field: "ownedBy",
      headerName: "Creator",
      cellRenderer: RenderSearchListCreator,
    },
    {
      field: "createdAt",
      cellRenderer: RenderTagDate,
      type: "numericColumn",
    },
    {
      field: "modifiedAt",
      cellRenderer: RenderTagDate,
      type: "numericColumn",
    },
  ] as ColumnDef<`saved-searches.${P}`>[]

const SAVED_SEARCHES_DEFAULT_TABLE_VIEW = <P extends SearchesAndListsProducts>(
  product: P
) =>
  ({
    config: `saved-searches.${product}`,
    columns: [
      "name",
      "social-actions",
      "countNewSignals",
      ...(product === SpecterProducts.company
        ? ["countNewFundingHighlights", "countNewGrowthHighlights"]
        : []),
      "countAllSignals",
      "ownedBy",
      "visibility",
      "createdAt",
      "modifiedAt",
    ],
    pinned: {},
    sort: [],
    widths: {},
    growthColumns: {},
  } as TableViewConfigs)

const USER_LISTS_COLUMN_DEFS = <P extends SearchesAndListsProducts>(
  product: P
) =>
  [
    {
      baseLockedColumn: true,
      field: "name",
      cellRenderer: RenderListName,
      headerName: "Title",
    },
    {
      baseLockedColumn: true,
      field: "social-actions",
      headerName: "",
      valueGetter: (params) => {
        return params.data
      },
      cellRenderer: ({ data }) => {
        if (!data) return ""

        return <UserListActions list={data} />
      },
    },
    ...(product === SpecterProducts.company
      ? [
          {
            field: "_countNewFundingSignals",
            cellRenderer: RenderTagNewSignalsList,
            headerName: "New Funding Signals",
          },
          {
            field: "_countNewGrowthSignals",
            cellRenderer: RenderTagNewSignalsList,
            headerName: "New Growth Signals",
          },
        ]
      : []),
    {
      field: "_count",
      cellRenderer: RenderTagNewSignalsList,
      headerName: pluralize(getProductEntityName(product)),
    },
    {
      field: "visibility",
      cellRenderer: ({ data }) => data && <VisibilityStatusList list={data} />,
    },
    {
      field: "ownedBy",
      headerName: "Creator",
      cellRenderer: RenderSearchListCreator,
    },
    {
      field: "createdAt",
      cellRenderer: RenderTagDate,
      type: "numericColumn",
    },
    {
      field: "modifiedAt",
      cellRenderer: RenderTagDate,
      type: "numericColumn",
    },
  ] as ColumnDef<`user-lists.${P}`>[]

const USER_LISTS_DEFAULT_TABLE_VIEW = <P extends SearchesAndListsProducts>(
  product: P
) =>
  ({
    config: `user-lists.${product}`,
    columns: [
      "name",
      "social-actions",
      ...(product === SpecterProducts.company
        ? ["_countNewFundingSignals", "_countNewGrowthSignals"]
        : []),
      "_count",
      "ownedBy",
      "visibility",
      "createdAt",
      "modifiedAt",
    ],
    pinned: {},
    sort: [],
    widths: {},
    growthColumns: {},
  } as TableViewConfigs)

export const TABLE_CONFIG_COLUMN_DEFS: {
  [Config in TableConfigs]: ColumnDef<Config>[]
} = {
  [SpecterProducts.company]: COMPANY_COLUMN_DEFS,
  [SpecterProducts.talent]: TALENT_COLUMN_DEFS,
  [SpecterProducts.stratintel]: STRATINTEL_COLUMN_DEFS,
  [SpecterProducts.investors]: INVESTORS_COLUMN_DEFS,
  [SpecterProducts.fundingRounds]: FUNDING_ROUNDS_COLUMN_DEFS,
  [SpecterProducts.acquisition]: ACQUISITION_COLUMN_DEFS,
  [SpecterProducts.ipo]: IPO_COLUMN_DEFS,
  [SpecterProducts.people]: PEOPLE_COLUMN_DEFS,
  "saved-searches.company": SAVED_SEARCHES_COLUMN_DEFS(SpecterProducts.company),
  "saved-searches.talent": SAVED_SEARCHES_COLUMN_DEFS(SpecterProducts.talent),
  "saved-searches.stratintel": SAVED_SEARCHES_COLUMN_DEFS(
    SpecterProducts.stratintel
  ),
  "saved-searches.people": SAVED_SEARCHES_COLUMN_DEFS(SpecterProducts.people),
  "user-lists.company": USER_LISTS_COLUMN_DEFS(SpecterProducts.company),
  "user-lists.talent": USER_LISTS_COLUMN_DEFS(SpecterProducts.talent),
  "user-lists.stratintel": USER_LISTS_COLUMN_DEFS(SpecterProducts.stratintel),
  "user-lists.people": USER_LISTS_COLUMN_DEFS(SpecterProducts.people),
  "saved-searches.investors": SAVED_SEARCHES_COLUMN_DEFS(
    SpecterProducts.investors
  ),
  "user-lists.investors": USER_LISTS_COLUMN_DEFS(SpecterProducts.investors),
} as const

export const TABLE_CONFIG_DEFAULT_TABLE_VIEW = {
  [SpecterProducts.company]: COMPANY_DEFAULT_TABLE_VIEW,
  [SpecterProducts.talent]: TALENT_DEFAULT_TABLE_VIEW,
  [SpecterProducts.stratintel]: STRATINTEL_DEFAULT_TABLE_VIEW,
  [SpecterProducts.investors]: INVESTORS_DEFAULT_TABLE_VIEW,
  [SpecterProducts.fundingRounds]: FUNDING_ROUNDS_DEFAULT_TABLE_VIEW,
  [SpecterProducts.acquisition]: ACQUISITION_DEFAULT_TABLE_VIEW,
  [SpecterProducts.ipo]: IPO_DEFAULT_TABLE_VIEW,
  [SpecterProducts.people]: PEOPLE_DEFAULT_TABLE_VIEW,
  "saved-searches.company": SAVED_SEARCHES_DEFAULT_TABLE_VIEW(
    SpecterProducts.company
  ),
  "saved-searches.talent": SAVED_SEARCHES_DEFAULT_TABLE_VIEW(
    SpecterProducts.talent
  ),
  "saved-searches.stratintel": SAVED_SEARCHES_DEFAULT_TABLE_VIEW(
    SpecterProducts.stratintel
  ),
  "saved-searches.people": SAVED_SEARCHES_DEFAULT_TABLE_VIEW(
    SpecterProducts.people
  ),
  "user-lists.company": USER_LISTS_DEFAULT_TABLE_VIEW(SpecterProducts.company),
  "user-lists.talent": USER_LISTS_DEFAULT_TABLE_VIEW(SpecterProducts.talent),
  "user-lists.stratintel": USER_LISTS_DEFAULT_TABLE_VIEW(
    SpecterProducts.stratintel
  ),
  "user-lists.people": USER_LISTS_DEFAULT_TABLE_VIEW(SpecterProducts.people),
  "saved-searches.investors": SAVED_SEARCHES_DEFAULT_TABLE_VIEW(
    SpecterProducts.investors
  ),
  "user-lists.investors": USER_LISTS_DEFAULT_TABLE_VIEW(
    SpecterProducts.investors
  ),
} as const satisfies Record<TableConfigs, TableViewConfigs>

export const COLUMNS_WITHOUT_ACTION = ["social-actions", "Screenshot"]
