import { EntityStatus as EntityStatusEnum } from "@prisma/client"
import { titleCase } from "title-case"

import { IconCircleDashed } from "~/components/Icons/circleDashed"
import { IconCircleFilled } from "~/components/Icons/circleFilled"
import { IconCircleProgress } from "~/components/Icons/circleProgress"
import { IconCircleX } from "~/components/Icons/circleX"

export const getEntityStatusIcon = (status?: EntityStatusEnum | null) => {
  switch (status) {
    case EntityStatusEnum.liked:
      return IconCircleFilled
    case EntityStatusEnum.disliked:
      return IconCircleX
    case EntityStatusEnum.viewed:
      return IconCircleProgress
    case EntityStatusEnum.not_viewed:
      return IconCircleDashed
    default:
      return IconCircleDashed
  }
}

export const getEntityStatusColor = (status?: EntityStatusEnum | null) => {
  switch (status) {
    case EntityStatusEnum.liked:
      return "green.500"
    case EntityStatusEnum.disliked:
      return "red.500"
    case EntityStatusEnum.viewed:
      return "yellow.500"
    case EntityStatusEnum.not_viewed:
      return "gray.400"
    default:
      return "gray.400"
  }
}

export const getEntityStatusLabel = (status?: EntityStatusEnum | null) => {
  switch (status) {
    case EntityStatusEnum.not_viewed:
      return "Not Viewed"
    default:
      return titleCase(status ?? "")
  }
}
