import { Box, Tag, Tooltip, BoxProps } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { Link as RemixLink } from "@remix-run/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { motion } from "framer-motion"
import numeral from "numeral"
import { HiArrowPath } from "react-icons/hi2"
import { LastViewedColumn } from "~/routes/__protected/api/saved-searches/$id.mark-as-viewed"
import { cacheKeys } from "~/utils/cacheKeys"
import { Icon } from "~/utils/components/Icon"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { isNullish } from "~/utils/values"

export function TagNewSignal({
  queryId,
  isNew,
  count,
  to,
  product,
  onClick,
  column = "lastViewedAt",
  ...props
}: {
  queryId: number
  isNew: boolean
  count: number
  to?: string
  product: SpecterProducts
  onClick?: () => void
  column?: LastViewedColumn
} & BoxProps) {
  const queryClient = useQueryClient()

  const newSignalEffect = isNew && count > 0

  const [searchParams] = useSafeSearchParams()

  const markedAsViewed = useMutation(async () => {
    await fetch(`/api/saved-searches/${queryId}/mark-as-viewed`, {
      method: "POST",
      body: JSON.stringify({ column }),
    })

    await queryClient.invalidateQueries(
      cacheKeys.userSavedSearches(product, searchParams.toString())
    )
  })

  let tooltipLabel =
    product == SpecterProducts.company
      ? "This value is being calculated. Please check back in a few moments."
      : "The query could not be run"

  const loadingCount = isNullish(count) || count == -1

  return (
    <Box position="relative" zIndex={1} display="inline-flex" {...props}>
      {newSignalEffect && (
        <motion.div
          initial={{ scale: 0.8, opacity: 0.6 }}
          animate={{ scale: 1.6, opacity: 0 }}
          transition={{ duration: 1.6, repeat: Infinity }}
          style={{
            position: "absolute",
            inset: 0,
            zIndex: -1,
          }}
        >
          <Box h="full" bgColor="green.500" rounded="full" />
        </motion.div>
      )}
      <Tag
        as={RemixLink}
        to={to ?? "#"}
        onClick={() => {
          if (newSignalEffect) markedAsViewed.mutate()

          onClick?.()
        }}
        rounded="full"
        variant={newSignalEffect ? "solid" : "softOutline"}
        colorScheme={newSignalEffect ? "green" : "gray"}
      >
        {loadingCount ? (
          <Tooltip label={tooltipLabel}>
            <span>
              <Icon as={HiArrowPath} />
            </span>
          </Tooltip>
        ) : (
          numeral(count).format("0[.]0a")
        )}
      </Tag>
    </Box>
  )
}
