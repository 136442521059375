import { Box, BoxProps, Button, Flex, Heading, Tag } from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"
import { FormikProps } from "formik"
import * as R from "ramda"
import { ReactNode, useRef } from "react"
import { z, ZodFirstPartySchemaTypes, ZodOptional, ZodTuple } from "zod"
import {
  FieldsFromSchema,
  NEW_FEATURED_FILTERS,
  ReactSelect,
} from "~/components"
import {
  CompanyOperator,
  COMPANY_OPERATORS_FIELDS,
  COMPANY_OPERATOR_LABELS,
  LanguageProficiency,
  LANGUAGE_PROFICIENCY_PROPS,
} from "~/consts/signals"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { isKeyOf } from "~/utils/isKeyOf"
import { isOneOf } from "~/utils/isOneOf"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { ObjectMap } from "~/utils/ObjectMap"
import { COMPANY_OPERATOR_BY_FILTER, getSignalFieldTitle } from "~/utils/signal"
import { TypedObjectKeys } from "~/utils/types"
import { isNullish } from "~/utils/values"

export const Dot = ({
  color = "red.400",
}: {
  color?: BoxProps["color"]
}): JSX.Element => <Box bgColor={color} ml={1} w={1} h={1} rounded="full" />

interface AdvancedFilterItemProps<Schema, Value> {
  schema: Schema
  value: Value
  onChange: (value: Value) => void
  onBlur: FormikProps<Value>["handleBlur"]
  product: SpecterProducts
  field: string
  titleInput?: ReactNode
}

export const AdvancedFilterItem = <
  Schema extends ZodFirstPartySchemaTypes,
  Value extends z.infer<Schema>
>({
  schema,
  value,
  onChange,
  onBlur,
  product,
  field,
  titleInput,
}: AdvancedFilterItemProps<Schema, Value>) => {
  const handleChange = (val: Value | null) => {
    if (val === null) {
      onChange(R.omit([field], value) as Value)
    } else {
      onChange({
        ...value,
        [field]: val,
      })
    }
  }
  const currValue = value[field]
  const [searchParams] = useSafeSearchParams()
  const query = JSONSafeParse<Record<string, any>>(searchParams.get("query"))
  const deprecatedFields = getDeprecatedFields(product, query)
  const isDeprecated = deprecatedFields.includes(field)

  const schemaWithoutOptional =
    schema instanceof ZodOptional ? schema.unwrap() : schema

  const isNew = NEW_FEATURED_FILTERS.includes(field)

  const selectRef = useRef<any>(null)

  const isCompanyOperatorField = isOneOf(field, COMPANY_OPERATORS_FIELDS)

  // TODO: This shall be refactored to be more generic
  const isLanguagesField =
    isOneOf(product, ["people", "talent"]) && field === "Languages"

  if (
    (isCompanyOperatorField || isLanguagesField) &&
    schemaWithoutOptional instanceof ZodTuple
  ) {
    const innerSchema = schemaWithoutOptional.items[1]

    const innerValue = {
      ...value,
      ...(currValue?.[1] && { [field]: currValue?.[1] }),
    }
    const currOperator = currValue?.[0]

    const INPUT_WIDTH = isCompanyOperatorField ? 165 : 200
    const INPUT_SCALE = 0.75

    const { options, defaultOperator: defaultOperatorFromSchema } =
      isCompanyOperatorField
        ? COMPANY_OPERATOR_BY_FILTER[field]
        : {
            options: Object.fromEntries(
              ObjectMap(LANGUAGE_PROFICIENCY_PROPS, ([key, { label }]) => [
                key,
                label,
              ])
            ) as Record<LanguageProficiency, string>,
            defaultOperator: "All",
          }

    const defaultOperator: CompanyOperator | LanguageProficiency = isNullish(
      currOperator
    )
      ? defaultOperatorFromSchema ?? Object.keys(options)[0]
      : currOperator

    type CustomOperatorOption = {
      label: string
      value: CompanyOperator | LanguageProficiency
    }

    const getLabel = (value: CompanyOperator | LanguageProficiency) => {
      return isCompanyOperatorField
        ? COMPANY_OPERATOR_LABELS[value as CompanyOperator]
        : LANGUAGE_PROFICIENCY_PROPS[value as LanguageProficiency].label
    }

    const innerTitleInput = (
      <Box
        position="relative"
        flexGrow={1}
        minW={`${INPUT_WIDTH * INPUT_SCALE}px`}
      >
        <ReactSelect<CustomOperatorOption, false>
          ref={selectRef}
          minW={`${INPUT_WIDTH}px`}
          placeholder="Select..."
          defaultValue={{
            label: getLabel(defaultOperator),
            value: defaultOperator,
          }}
          onChange={(option) => {
            // If the filter isn't applied, don't update the value (input only)
            if (!isKeyOf(field, value)) return

            const newOperator = option?.value ?? defaultOperator
            const newValue = [newOperator, currValue?.[1]]

            onChange({
              ...value,
              [field]: newValue,
            } as Value)
          }}
          options={TypedObjectKeys(options).map((op) => ({
            label: getLabel(op),
            value: op,
          }))}
          {...(!isCompanyOperatorField && {
            formatOptionLabel: (data, { context }) => {
              const { label, valueLabel } =
                LANGUAGE_PROFICIENCY_PROPS[data.value as LanguageProficiency]
              return context === "value" && valueLabel ? valueLabel : label
            },
          })}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              position: "absolute",
              transform: `scale(${INPUT_SCALE}) translateY(calc(-50% - 5px))`,
              transformOrigin: "left",
            }),
            menu: (provided) => ({
              ...provided,
              overflow: "visible",
            }),
            menuList: (provided) => ({
              ...provided,
              minW: "2xs",
            }),
            option: (provided, context) => ({
              ...provided,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 0.5,
              fontSize: "xs",
              // Subtitle / Description
              ...(isCompanyOperatorField && {
                _after: {
                  content: `"${
                    COMPANY_OPERATOR_BY_FILTER[field].options[
                      context.data.value as CompanyOperator
                    ]
                  }"`,
                  fontSize: "xx-small",
                  opacity: 0.6,
                },
              }),
            }),
          }}
        />
      </Box>
    )

    const innerOnChange = (val: Value | null) => {
      if (isNullish(val) || isNullish(val[field])) {
        handleChange(null)
        return
      }

      const currentValue = value[field]
      const newValue = [
        currentValue?.[0] ??
          selectRef.current.getValue()?.[0]?.value ??
          defaultOperator,
        val[field],
      ]

      onChange({
        ...val,
        [field]: newValue,
      } as Value)
    }

    return (
      <AdvancedFilterItem
        schema={innerSchema}
        value={innerValue}
        onChange={innerOnChange}
        onBlur={onBlur}
        product={product}
        field={field}
        titleInput={innerTitleInput}
      />
    )
  }

  return (
    <Box key={field} pos="relative">
      <Flex gap={1} alignItems="center" minH="24px" mb={1.5}>
        <Heading
          fontSize="xs"
          as="h4"
          fontWeight="semibold"
          color="gray.500"
          whiteSpace="nowrap"
          alignSelf="flex-end"
        >
          {getSignalFieldTitle(field)}
        </Heading>

        {titleInput}
        {isNew && <Tag colorScheme="brand">New</Tag>}
        {isDeprecated && <Tag colorScheme="orange">Deprecated</Tag>}

        {R.has(field, value) && (
          <Button
            color="red"
            variant="link"
            fontSize="xx-small"
            alignSelf="flex-end"
            onClick={() => handleChange(null)}
          >
            Clear
          </Button>
        )}
      </Flex>

      <FieldsFromSchema
        product={product}
        schema={schema}
        field={field}
        value={currValue}
        onChange={handleChange}
        onBlur={onBlur}
        type="advancedFilters"
      />
    </Box>
  )
}
