import { getProductSignalSearchParams } from "~/utils/hooks/useProductFilters"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ColoredTag } from "../ColoredTag"
import { SemanticSearchModal } from "../SemanticSearchModal"
import { AITag } from "../AITag"
import { HiPencil } from "react-icons/hi"
import { ElementType } from "react"

export const SemanticSearchToggle = () => {
  const product = useProduct()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const allFilters = getProductSignalSearchParams(product, searchParams)
  const value = "semanticSearch" in allFilters ? allFilters.semanticSearch : []
  const modal = useDisclosure()

  const removeSemanticSearch = () => {
    if ("semanticSearch" in allFilters) {
      const { semanticSearch: _, semanticRelevance: __, ...rest } = allFilters
      setSearchParams({
        query: JSON.stringify(rest),
      })
    }
  }

  const toggleExpanded = () => {
    if ("semanticSearch" in allFilters) {
      const [query, parsedQuery, expandedQuery, expandedEnabled] = value ?? []
      setSearchParams({
        query: JSON.stringify({
          ...allFilters,
          semanticSearch: [query, parsedQuery, expandedQuery, !expandedEnabled],
        }),
      })
    }
  }

  if (!("semanticSearch" in allFilters) || !value) return null

  const [_query, parsedQuery, expandedQuery, expandedEnabled] = value

  return (
    <Box>
      <Text
        as="span"
        display="block"
        fontSize="xs"
        color="gray.500"
        bgClip="text"
        bgGradient="linear(to-r, brand.500, purple.500, red.500, orange.500)"
      >
        Ai Agent
        <AITag size="10px" />
      </Text>

      <Popover placement="bottom-start">
        <ColoredTag mt={0.5} color="brand.500" borderStyle="dashed">
          <PopoverTrigger>
            <TagLabel
              cursor="pointer"
              maxW="300px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {parsedQuery}
            </TagLabel>
          </PopoverTrigger>
          <TagCloseButton
            as={HiPencil as ElementType}
            boxSize={3.5}
            onClick={modal.onOpen}
          />
          <TagCloseButton onClick={removeSemanticSearch} ml={0.5} />
        </ColoredTag>

        <PopoverContent rounded="lg" overflow="hidden">
          <Box py={2} px={4}>
            <Text as="span" fontSize="sm" fontWeight="medium">
              Refine Search{" "}
              <Switch
                size="sm"
                colorScheme="brand"
                isChecked={expandedEnabled}
                onChange={toggleExpanded}
                ml={0.5}
              />
            </Text>
            {expandedEnabled && (
              <Text fontSize="xs" color="brand.500" fontWeight="medium">
                We're using a refined version of your search
              </Text>
            )}
            <Text fontSize="xs" color="gray.500" mt={1}>
              "{expandedQuery}"
            </Text>
          </Box>
          <Box
            py={2}
            px={4}
            bgColor="gray.50"
            borderTopWidth={1}
            borderColor="gray.100"
          >
            <Text as="span" fontSize="xs" color="gray.500">
              Based on "{parsedQuery}"
            </Text>
          </Box>
        </PopoverContent>
      </Popover>

      <SemanticSearchModal isOpen={modal.isOpen} onClose={modal.onClose} />
    </Box>
  )
}
