import { EntityStatus, SpecterProducts } from "@prisma/client"
import { subDays } from "date-fns"
import { generateURLFactory } from "~/components/UserSearchesPage/SavedSearchesTable"
import { PRODUCT_VIEW_MODES } from "./hooks/useViewMode"
import { GetUserListsByProduct } from "~/routes/__protected/api/lists"
import { noop } from "./noop"
import { createSearchQuery } from "~/components/UserSearchesPage/utils"

export const MY_CRM_LIST_ID = "MY_CRM"
export const LIKED_LIST_ID = "LIKED"
export const DISLIKED_LIST_ID = "DISLIKED"

export const MOCK_LISTS = [
  {
    id: MY_CRM_LIST_ID,
    name: "My CRM",
    isPublic: true,
    createdAt: null, // TODO: Get the proper date
    modifiedAt: null, // TODO: Get the proper date
    newFundingSignalsLastViewedAt: subDays(new Date(), 32),
    newGrowthSignalsLastViewedAt: subDays(new Date(), 32),
    integrationEnrichments: [],
    searchQuery: {
      inCRM: true,
    },
    isGlobalHub: false,
    userShares: [],
  },
  {
    id: LIKED_LIST_ID,
    name: "Liked",
    isPublic: false,
    createdAt: null, // TODO: Get the proper date
    modifiedAt: null, // TODO: Get the proper date
    newFundingSignalsLastViewedAt: subDays(new Date(), 32),
    newGrowthSignalsLastViewedAt: subDays(new Date(), 32),
    integrationEnrichments: [],
    userShares: [],
    searchQuery: {
      entityStatus: EntityStatus.liked,
    },
    isGlobalHub: false,
  },
  {
    id: DISLIKED_LIST_ID,
    name: "Disliked",
    isPublic: false,
    createdAt: null, // TODO: Get the proper date
    modifiedAt: null, // TODO: Get the proper date
    newFundingSignalsLastViewedAt: subDays(new Date(), 32),
    newGrowthSignalsLastViewedAt: subDays(new Date(), 32),
    integrationEnrichments: [],
    userShares: [],
    searchQuery: {
      entityStatus: EntityStatus.disliked,
    },
    isGlobalHub: false,
  },
]

export const isMockList = (list: GetUserListsByProduct[number]) => {
  return MOCK_LISTS.map((l) => l.id).includes(list.id)
}

export const getMockListsByProduct = (product: SpecterProducts) => {
  return MOCK_LISTS.filter((l) => {
    if (l.id === MY_CRM_LIST_ID) {
      return product === SpecterProducts.company
    }
    return true
  })
}

export const getMockListLink = (
  listId: string,
  product: SpecterProducts,
  query: Record<string, boolean> = {}
) => {
  const mockList = MOCK_LISTS.find((l) => l.id === listId)

  if (!product || !mockList) return "/"

  const generateURL = generateURLFactory({
    setSearchParams: noop,
    product,
    viewMode: PRODUCT_VIEW_MODES[product][0],
    query: {
      ...mockList.searchQuery,
      ...query,
    },
  })

  const [to] = generateURL(createSearchQuery)

  return to
}
