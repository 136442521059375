import { Box } from "@chakra-ui/react"

export const IconCircleProgress = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 22 22" {...props}>
      <circle
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        cx="11"
        cy="11"
        r="10"
      />
      <path fill="currentColor" d="M11,5c3.31,0,6,2.69,6,6s-2.69,6-6,6V5Z" />
    </Box>
  )
}
