import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { CompanyLogo } from "../CompanyLogo"
import { MenuItem } from "../MenuItem"
import { SimpleSelectOptions } from "~/utils/db/options"
import { cacheKeys } from "~/utils/cacheKeys"
import { useRef } from "react"

export const formatTextWithHighlights = (text: string) => {
  return text.split(/(@[\w]+\([^)]+\))/g).map((part, i) => {
    if (part.match(/@[\w]+\([^)]+\)/)) {
      return (
        <>
          <Text
            key={i}
            as="span"
            fontSize="inherit"
            color="brand.500"
            display="inline"
            lineHeight="inherit"
          >
            @{part.match(/\((.*?)\)/)?.[1]}
          </Text>
        </>
      )
    }
    return part
  })
}

interface TaggableInputProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  onSubmit: () => void
  onFocus?: () => void
  onBlur?: () => void
}

const CompanyLookup = ({
  searchValue,
  onChange,
  onClose,
}: {
  searchValue: string
  onChange: (value: string) => void
  onClose: () => void
}) => {
  const inputOptionsQuery = useQuery<SimpleSelectOptions>({
    queryKey: [cacheKeys.inputOptions("company"), searchValue],
    async queryFn() {
      const response = await fetch(
        `/api/input-options/company/company?q=${searchValue}`
      )
      return response.json()
    },
  })

  return (
    <Box p={2} maxH="300px" overflowY="auto">
      {inputOptionsQuery.isLoading && <Spinner />}
      {inputOptionsQuery.isSuccess &&
        inputOptionsQuery.data?.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onChange(`@${option.value}(${option.label})`)
              onClose()
            }}
          >
            <CompanyLogo
              domain={option.domain}
              source={option.logoUrl}
              size={4}
            />
            {option.label}
            <Text fontSize="xs" color="gray.500">
              {option.domain}
            </Text>
          </MenuItem>
        ))}
    </Box>
  )
}

export const TaggableInput = ({
  value,
  onChange,
  placeholder,
  onSubmit,
  onFocus,
  onBlur,
}: TaggableInputProps) => {
  const disclosure = useDisclosure()
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // Get the current word being typed
  const getCursorPosition = () => {
    return textareaRef.current?.selectionStart ?? 0
  }

  const getCurrentWord = (text: string, position: number) => {
    const beforeCursor = text.slice(0, position)
    const words = beforeCursor.split(/\s/)
    return words[words.length - 1]
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newValue = e.target.value
    const cursorPosition = getCursorPosition()
    const currentWord = getCurrentWord(newValue, cursorPosition)

    // Extract all company names from old value
    const oldTags =
      value
        .match(/@[\w]+\(([^)]+)\)/g)
        ?.map((tag) => {
          const match = tag.match(/@[\w]+\(([^)]+)\)/)
          return match
            ? {
                full: tag,
                name: match[1],
              }
            : null
        })
        .filter(Boolean) || []

    // Look for any @CompanyName patterns in new value that match old tags
    oldTags.forEach((tag) => {
      if (!tag) return
      const escapedName = tag.name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      const pattern = new RegExp(`@${escapedName}(?!\\()`, "g")
      newValue = newValue.replace(pattern, tag.full)
    })

    if (currentWord.startsWith("@")) {
      disclosure.onOpen()
    } else {
      disclosure.onClose()
    }

    onChange(newValue)
  }

  const getCurrentSearchValue = () => {
    const cursorPosition = getCursorPosition()
    const currentWord = getCurrentWord(cleanValue, cursorPosition)
    return currentWord.startsWith("@") ? currentWord.slice(1) : ""
  }

  const handleCompanySelect = (tagValue: string) => {
    const cursorPosition = getCursorPosition()
    const beforeCursor = cleanValue.slice(0, cursorPosition)
    const afterCursor = cleanValue.slice(cursorPosition)

    const words = beforeCursor.split(/\s/)
    words.pop() // Remove the @mention being replaced

    const newValue = [...words, tagValue, afterCursor.trimStart()]
      .join(" ")
      .trim()

    onChange(newValue + " ")
  }

  const cleanValue = value.replace(/@[\w]+\(([^)]+)\)/g, "@$1")

  return (
    <Box pos="relative">
      <Popover
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        placement="bottom-start"
        autoFocus={false}
      >
        <PopoverTrigger>
          <Box>
            <Textarea
              ref={textareaRef}
              value={cleanValue}
              onChange={handleInputChange}
              placeholder={placeholder}
              p={0}
              fontSize="sm"
              pos="absolute"
              inset={0}
              rounded="none"
              minHeight={0}
              maxHeight="none"
              color="transparent"
              style={{ caretColor: "black" }}
              zIndex={1}
              resize="none"
              variant="unstyled"
              lineHeight="1.2"
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={(e) => {
                onBlur?.()
                if (e.key === "Enter") {
                  e.preventDefault()
                  disclosure.onClose()
                  onSubmit()
                }
              }}
            />
            <Box whiteSpace="pre-wrap" pointerEvents="none">
              <Text
                color={value ? "black" : "gray.500"}
                fontSize="sm"
                lineHeight="1.2"
              >
                {value ? formatTextWithHighlights(value) : placeholder}
              </Text>
            </Box>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <CompanyLookup
              searchValue={getCurrentSearchValue()}
              onChange={handleCompanySelect}
              onClose={disclosure.onClose}
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}
