import {
  Avatar,
  ButtonGroup,
  Flex,
  Image,
  Tag,
  TagProps,
  Text,
  Tooltip,
} from "@chakra-ui/react"

import { Seniority, SpecterProducts } from "@prisma/client"
import { useLocation } from "@remix-run/react"
import { SignalStatusTalent, TalentSignalTitle } from "~/components"
import { ActionMenuIcons, SignalCard } from "~/components/SignalCard"
import {
  ButtonContactDetails,
  ButtonGithub,
  ButtonLinkedin,
  ButtonTwitter,
} from "~/components/SignalCard/Buttons"
import { TalentSignalNewPositionSmallCard } from "~/components/TalentSignalDetail/NewPositionCard"
import { TalentSignalPastPositionsCardSmall } from "~/components/TalentSignalDetail/PastPositionsCard"
import { PeopleHighlight, PEOPLE_HIGHLIGHTS_PROPS } from "~/consts/signals"
import {
  allTalentSignalStatus,
  allTalentSignalType,
} from "~/consts/talentSignals"
import { GetPeopleByID } from "~/utils/db/getPeopleByID"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { isDateValid } from "~/utils/isDateValid"
import { isOneOf } from "~/utils/isOneOf"
import { getSignalDate } from "~/utils/signalDate"
import { sortPeopleHighlights } from "../PeopleDetailPage/Tabs/About"
import {
  EducationCard,
  HighlightSmallItem,
  NUM_HIGHLIGHTS_TO_SHOW,
} from "../PeopleSignalCard"

interface Props {
  signal: GetPeopleByID
  customLink?: string | null
  withReducedInfo?: boolean
}

export const TalentSignalCard = ({
  signal,
  customLink,
  withReducedInfo,
}: Props) => {
  const location = useLocation()

  if (!("signal_type" in signal)) return null

  const sortedPeopleHighlights = sortPeopleHighlights(
    signal.people_highlights as PeopleHighlight[]
  )

  const detailURL = `./${signal.id}${location.search}`
  const queryAppendChar = location.search ? "&" : "?"

  return (
    <SignalCard
      customLink={customLink}
      product="talent"
      signal={signal}
      picture={
        <Avatar
          src={signal.profile_image_url ?? undefined}
          boxSize={16}
          borderRadius="md"
          icon={<Image src="/specter-blank.jpg"></Image>}
          bg="white"
        />
      }
      title={signal.full_name}
      subtitle={<TalentSignalTitle talentSignal={signal} />}
      description={
        isDateValid(signal.signal_date) ? (
          <Text fontSize="xs" color="gray.400" as="span">
            <Text as="span" fontWeight="semibold">
              {getSignalDate(new Date(signal.signal_date))}
            </Text>{" "}
            -{" "}
            {getSignalLocation({
              location: signal.location,
              region: signal.region,
            })}
          </Text>
        ) : null
      }
      postTitle={
        <>
          {isOneOf(signal.signal_type, allTalentSignalType) && (
            <Tag rounded="full">{signal.signal_type}</Tag>
          )}

          {signal.signal_score && (
            <Tag rounded="full">Score: {signal.signal_score}/10</Tag>
          )}

          {/* // TODO: Add industry */}
          {/* {signal.IndustryOG && (
            <SignalIndustry industry={signal.IndustryOG} maxItems={2} />
          )} */}

          {/* {signal.SerialFounder === "Yes" && (
            <Tag bgColor="#00A8A8" rounded="full" variant="solid" ml={1}>
              Serial Founder
            </Tag>
          )} */}
        </>
      }
      preButtons={
        <Flex>
          {isOneOf(signal.signal_status, allTalentSignalStatus) && (
            <SignalStatusTalent signalStatus={signal.signal_status} />
          )}

          {signal.out_of_stealth_advantage !== null && (
            <Text
              pl={"8px"}
              color="gray.300"
              fontSize="xs"
              fontWeight="semibold"
              whiteSpace="nowrap"
            >
              ({signal.out_of_stealth_advantage} ADV)
            </Text>
          )}
        </Flex>
      }
      actionButtons={
        <>
          <ButtonLinkedin linkedinUrl={signal.linkedin_url} />
          <ButtonTwitter twitterUrl={signal.twitter_url} />
          <ButtonGithub githubUrl={signal.github_url} />
          <ButtonContactDetails
            personId={signal.specter_person_id}
            personName={signal.full_name}
            isCompact
            size="xs"
          />
        </>
      }
      cardBoxes={
        !withReducedInfo && (
          <>
            {/* <CardBox value="Experience:">
              <Flex gap={2}>
                {signal.seniority && (
                  <Tag
                    colorScheme={getSeniorityColor(signal.seniority)}
                    rounded="full"
                    whiteSpace="nowrap"
                  >
                    {formatSeniority(signal.seniority)}
                  </Tag>
                )}
                {signal.YearsOfExperience && (
                  <Tag
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    rounded="full"
                  >
                    {signal.YearsOfExperience}
                  </Tag>
                )}
              </Flex>
            </CardBox> */}

            <TalentSignalNewPositionSmallCard talentSignal={signal} />
            <TalentSignalPastPositionsCardSmall talentSignal={signal} />
            {/* {!withReducedInfo && (
              <TalentSignalEducationSmallCard talentSignal={signal} />
            )} */}
            {!withReducedInfo && signal.education && (
              <EducationCard education={signal.education} />
            )}
          </>
        )
      }
      {...(!withReducedInfo && {
        postActionMenuButtons: (
          <ButtonGroup size="xs" variant="outline" spacing={0} gap={1}>
            <ActionMenuIcons product={SpecterProducts.talent} signal={signal} />
          </ButtonGroup>
        ),
        postActionMenu: !!sortedPeopleHighlights.length && (
          <Flex direction="column" gap={1} position="relative">
            {sortedPeopleHighlights
              .slice(0, NUM_HIGHLIGHTS_TO_SHOW)
              .map((highlight) => {
                return (
                  <HighlightSmallItem
                    key={highlight}
                    highlight={highlight as PeopleHighlight}
                    toTab={(tab) => `${detailURL}${queryAppendChar}tab=${tab}`}
                  />
                )
              })}
            {sortedPeopleHighlights.length > NUM_HIGHLIGHTS_TO_SHOW && (
              <Tooltip
                label={sortedPeopleHighlights
                  .slice(NUM_HIGHLIGHTS_TO_SHOW)
                  .map((h) => {
                    const { shortLabel, label } =
                      PEOPLE_HIGHLIGHTS_PROPS[h as PeopleHighlight]
                    return shortLabel ?? label
                  })
                  .join(", ")}
                fontSize="xs"
              >
                <Text
                  fontSize="x-small"
                  color="gray.400"
                  alignSelf="center"
                  position="absolute"
                  top="calc(100% + 3px)"
                >
                  and {sortedPeopleHighlights.length - NUM_HIGHLIGHTS_TO_SHOW}{" "}
                  more
                </Text>
              </Tooltip>
            )}
          </Flex>
        ),
      })}
    />
  )
}

const SENIORITY_COLORS: Record<Seniority, TagProps["colorScheme"]> = {
  [Seniority.Entry]: "green",
  [Seniority.MidLevel]: "cyan",
  [Seniority.Director]: "linkedin",
  [Seniority.Executive]: "blue",
}

const SENIORITY_COLORS_RAW: Record<string, TagProps["colorScheme"]> = {
  Entry: "green",
  "Mid-Level": "cyan",
  Director: "linkedin",
  Executive: "blue",
  "Entry Level": "green",
  "Mid Level": "cyan",
  "Senior Level": "linkedin",
  "Executive Level": "blue",
}

export const getSeniorityColor = (
  seniority: string
): TagProps["colorScheme"] => {
  if (isOneOf(seniority, Object.values(Seniority))) {
    return SENIORITY_COLORS[seniority]
  }

  return SENIORITY_COLORS_RAW[seniority] ?? "red"
}
