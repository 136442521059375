import { Button, Flex, Spacer, Text } from "@chakra-ui/react"
import { Location, useLocation, Link as RemixLink } from "@remix-run/react"
import { PiTableBold } from "react-icons/pi"
import { PiListBold } from "react-icons/pi"
import { PRODUCT_VIEW_MODES } from "~/utils/hooks/useViewMode"
import { getPeriodOfDate } from "~/utils/datetime"
import { useBgColor } from "~/utils/hooks/useBgColor"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { useViewMode } from "~/utils/hooks/useViewMode"
import { isNullish } from "~/utils/values"
import { generateURLFactory } from "../UserSearchesPage/SavedSearchesTable"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useProduct } from "~/utils/hooks/useProduct"
import { createSearchQuery } from "../UserSearchesPage/utils"
import { Icon } from "~/utils/components/Icon"

type Route = string | { [key: string]: Route }

const PAGE_TITLES: Exclude<Route, string> = {
  "/": "Home",
  "/account": "Account Settings",
  "/import": "Import List",
  "/admin": {
    "/debug": "Debug",
    "/permissions": "Access Management",
    "/global-hub": "Global Hub",
    "/companies": "Add Companies",
  },
  "/signals": {
    "/strategic": "Investors",
    "/talent": "People",
    "/company": "Companies",
    "/investors": "Investors",
    "/funding-rounds": "Funding Rounds",
    "/acquisition": "Acquisitions",
    "/ipo": "IPOs",
    "/people": "People",
  },
  "/user": {
    "/lists": {
      "/company": "Company",
    },
    "/searches": {
      "/company": "Company",
    },
  },
  "/team": "Team Settings",
  "/api-console": "API Console",
}

export const NEW_FEATURED_ROUTES: string[] = []
export const BETA_FEATURED_ROUTES: string[] = ["/signals/people"]

export const HEADER_HEIGHT = 50

const ViewModeToggle = () => {
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const sort = searchParams.get("sort") ?? ""
  const searchId = searchParams.get("searchId")
  const viewMode = useViewMode()
  const product = useProduct()
  const generateURLFeed = generateURLFactory({
    searchParams,
    setSearchParams,
    product,
    viewMode: ViewMode.Feed,
    query: JSON.parse(searchParams.get("query") || "null"),
    sort,
    searchId: searchId ? Number(searchId) : undefined,
    search: searchParams.get("search"),
  })

  const generateURLTable = generateURLFactory({
    searchParams,
    setSearchParams,
    product,
    viewMode: ViewMode.Table,
    query: JSON.parse(searchParams.get("query") || "null"),
    sort,
    searchId: searchId ? Number(searchId) : undefined,
    search: searchParams.get("search"),
  })

  const [toFeed, onNavigateFeed] = generateURLFeed(createSearchQuery)
  const [toTable, onNavigateTable] = generateURLTable(createSearchQuery)

  return (
    <Flex
      rounded="lg"
      overflow="hidden"
      p={0.5}
      gap={0.5}
      bgColor="white"
      borderWidth={1}
      borderColor="gray.200"
      shadow="sm"
    >
      <Button
        leftIcon={<Icon as={PiListBold} />}
        variant={viewMode === "feed" ? "solid" : "ghost"}
        colorScheme={viewMode === "feed" ? "brand" : undefined}
        as={RemixLink}
        size="xs"
        to={toFeed}
        onClick={() => {
          onNavigateFeed()
        }}
      >
        Feed
      </Button>
      <Button
        leftIcon={<Icon as={PiTableBold} />}
        variant={viewMode === "table" ? "solid" : "ghost"}
        colorScheme={viewMode === "table" ? "brand" : undefined}
        as={RemixLink}
        size="xs"
        to={toTable}
        intercom-target="view-table"
        onClick={() => {
          onNavigateTable()
        }}
      >
        Table
      </Button>
    </Flex>
  )
}

const getTabs = (pathname: string) => {
  const isStrategicOrInvestors =
    pathname.includes("/signals/strategic") ||
    pathname.includes("/signals/investors")

  const isStrategicOrInvestorsLists =
    pathname.includes("/user/lists/strategic") ||
    pathname.includes("/user/lists/investors")

  const isStrategicOrInvestorsSearch =
    pathname.includes("/user/searches/strategic") ||
    pathname.includes("/user/searches/investors")

  const isTalentOrPeople =
    pathname.includes("/signals/talent") || pathname.includes("/signals/people")

  const isTalentOrPeopleLists =
    pathname.includes("/user/lists/talent") ||
    pathname.includes("/user/lists/people")

  const isTalentOrPeopleSearch =
    pathname.includes("/user/searches/talent") ||
    pathname.includes("/user/searches/people")

  if (isTalentOrPeople) {
    return [
      {
        label: "All People",
        link: "/signals/people",
      },
      {
        label: "Live Signals",
        link: "/signals/talent",
      },
    ]
  }

  if (isTalentOrPeopleLists) {
    return [
      {
        label: "People",
        link: "/user/lists/people",
      },
      {
        label: "Signals",
        link: "/user/lists/talent",
      },
    ]
  }

  if (isTalentOrPeopleSearch) {
    return [
      {
        label: "People",
        link: "/user/searches/people",
      },
      {
        label: "Signals",
        link: "/user/searches/talent",
      },
    ]
  }

  if (isStrategicOrInvestors) {
    return [
      {
        label: "All Investors",
        link: "/signals/investors",
      },
      {
        label: "Live Signals",
        link: "/signals/strategic",
      },
    ]
  }

  if (isStrategicOrInvestorsLists) {
    return [
      {
        label: "Investors",
        link: "/user/lists/investors",
      },
      {
        label: "Signals",
        link: "/user/lists/strategic",
      },
    ]
  }

  if (isStrategicOrInvestorsSearch) {
    return [
      {
        label: "Investors",
        link: "/user/searches/investors",
      },
      {
        label: "Signals",
        link: "/user/searches/strategic",
      },
    ]
  }
  return []
}

export const Header = () => {
  const bgColor = useBgColor()
  const location = useLocation()
  const permissions = useUserPermissions()
  const firstName = permissions.data?.first_name
    ? `, ${permissions.data.first_name}`
    : ""

  const product = useProduct({ enforce: false })

  const pageTitle =
    location.pathname === "/"
      ? `Good ${getPeriodOfDate(new Date())}${firstName}`
      : getPageTitle(location)

  const isFullScreen = location.pathname.endsWith("/f")
  const tabs = getTabs(location.pathname)

  return (
    <Flex
      as="header"
      className="grid-area-header"
      position="sticky"
      top={0}
      transition="all 0.2s"
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.100"
      px={6}
      h={`${HEADER_HEIGHT}px`}
      bg={bgColor}
      zIndex={200}
      borderTopRadius="xl"
      gap={2}
    >
      {location.pathname.startsWith("/user/lists") && (
        <>
          <Text fontSize="sm" fontWeight="semibold">
            My Lists
          </Text>
          {pageTitle && <Text color="gray.200">/</Text>}
        </>
      )}

      {location.pathname.startsWith("/user/searches") && (
        <>
          <Text fontSize="sm" fontWeight="semibold">
            Saved Searches
          </Text>
          {pageTitle && <Text color="gray.200">/</Text>}
        </>
      )}

      {pageTitle && (
        <Text fontSize="sm" fontWeight="semibold">
          {pageTitle}
        </Text>
      )}

      {tabs.length > 1 && (
        <>
          <Text color="gray.200">/</Text>
          <Flex
            gap={0.5}
            p={0.5}
            rounded="lg"
            bgColor="white"
            borderWidth={1}
            borderColor="gray.200"
            shadow="sm"
          >
            {tabs.map((tab, i) => (
              <Button
                key={i}
                as={RemixLink}
                to={tab.link}
                size="xs"
                variant={
                  location.pathname.includes(tab.link) ? "solid" : "ghost"
                }
                colorScheme={
                  location.pathname.includes(tab.link) ? "brand" : undefined
                }
              >
                {tab.label}
              </Button>
            ))}
          </Flex>
        </>
      )}

      <Spacer />

      {product &&
        location.pathname.includes("/signals") &&
        PRODUCT_VIEW_MODES[product].length > 1 &&
        !isFullScreen && <ViewModeToggle />}
    </Flex>
  )
}

export const getPageTitle = (location: Location): string => {
  const path = location.pathname

  if (path === "/") {
    return PAGE_TITLES["/"] as string
  }

  const pathParts = path.split("/").filter((part) => part !== "")

  return getFromTree(PAGE_TITLES, pathParts, "")
}

const getFromTree = (
  tree: Exclude<Route, string>,
  pathParts: string[],
  fallback: string
): string => {
  const [part, ...rest] = pathParts

  if (isNullish(part)) {
    return fallback
  }

  const nextTree = tree[`/${part}`]

  if (isNullish(nextTree)) {
    return fallback
  }

  if (typeof nextTree === "string") {
    return nextTree
  }

  const newFallback =
    typeof nextTree["/"] === "string" ? nextTree["/"] : fallback

  return getFromTree(nextTree, rest, newFallback)
}
