import { Avatar, Text } from "@chakra-ui/react"
import { BsBroadcastPin } from "react-icons/bs"
import {
  HiGlobe,
  HiGlobeAlt,
  HiLockClosed,
  HiOutlineLockOpen,
} from "react-icons/hi"
import { SavedSearchVisibility } from "~/routes/__protected/api/saved-searches"
import { Icon } from "~/utils/components/Icon"
import { getUserAvatarSrc } from "~/utils/getUserAvatarSrc"

export function VisibilityStatus({
  visibility,
}: {
  visibility: SavedSearchVisibility
}): JSX.Element {
  if (visibility.type == "globalHubAdmin") {
    return (
      <>
        <Icon as={HiGlobeAlt} color="gray.400" />
        <Text fontSize="xs">{visibility.status}</Text>
      </>
    )
  }

  if (visibility.type == "globalHubUser") {
    return (
      <>
        <Icon as={BsBroadcastPin} color="gray.400" />
        <Text fontSize="xs">{visibility.status}</Text>
      </>
    )
  }

  if (visibility.type == "teamShare") {
    return (
      <>
        <Icon as={HiGlobe} color="gray.400" />
        <Text fontSize="xs">{visibility.status}</Text>
      </>
    )
  }

  if (visibility.type == "privateShare") {
    return (
      <>
        <Icon as={HiOutlineLockOpen} color="gray.400" />
        <Text fontSize="xs" mr={3}>
          Privately shared with:
        </Text>
        {visibility.sharedWith?.map((share) => (
          <Avatar
            ml={-3}
            size="xs"
            key={share.id}
            src={getUserAvatarSrc(share.avatar)}
            name={share.name}
          />
        ))}
      </>
    )
  }

  return (
    <>
      <Icon as={HiLockClosed} color="gray.400" />
      <Text fontSize="xs">Private</Text>
    </>
  )
}
