import {
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react"
import { EntityStatus } from "@prisma/client"
import { HiChevronDown } from "react-icons/hi"
import { Icon } from "~/utils/components/Icon"
import { MenuItem } from "../MenuItem"
import {
  getEntityStatusColor,
  getEntityStatusIcon,
  getEntityStatusLabel,
} from "~/utils/entityStatus"
import { getProductSignalSearchParams } from "~/utils/hooks/useProductFilters"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useProduct } from "~/utils/hooks/useProduct"
import { useMemo } from "react"
import { omit } from "ramda"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { IconCircleAll } from "../Icons/circleAll"

export const EntityStatusSelect = () => {
  const product = useProduct()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const disclosure = useDisclosure()

  const filters = useMemo(
    () => getProductSignalSearchParams(product, searchParams),
    [product, searchParams]
  )

  const status = "entityStatus" in filters ? filters.entityStatus : null
  const buttonIcon = status ? getEntityStatusIcon(status) : IconCircleAll
  const buttonColor = getEntityStatusColor(status)
  const [baseColor] = buttonColor?.split(".") ?? []

  const updateQuery = (status: EntityStatus | null | "not-viewed") => {
    const filtersWithoutStatus = omit(["entityStatus"], filters)
    const updatedSearchParams = updateSearchParams(searchParams, {
      query: {
        ...filtersWithoutStatus,
        ...(status ? { entityStatus: status } : {}),
      },
    })

    setSearchParams(updatedSearchParams)

    disclosure.onClose()
  }

  return (
    <Popover
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      onOpen={disclosure.onOpen}
    >
      <PopoverTrigger>
        <Button
          flex="none"
          shadow="none"
          variant="outline"
          borderStyle={status ? "solid" : "dashed"}
          rightIcon={<Icon as={HiChevronDown} />}
          leftIcon={
            <Icon
              as={buttonIcon}
              color={buttonColor ?? "gray.400"}
              boxSize={3.5}
            />
          }
          colorScheme={baseColor}
          bgColor={baseColor + ".50"}
        >
          {status ? getEntityStatusLabel(status) : "Status"}
        </Button>
      </PopoverTrigger>
      <PopoverContent minW="0px" w="fit-content" rounded="xl" p={2}>
        <PopoverArrow />
        <MenuItem onClick={() => updateQuery(null)}>
          <Icon as={IconCircleAll} color="gray.400" />
          All Statuses
        </MenuItem>
        {[
          EntityStatus.not_viewed,
          EntityStatus.viewed,
          EntityStatus.liked,
          EntityStatus.disliked,
        ].map((status) => (
          <MenuItem
            key={status}
            onClick={() => {
              updateQuery(status)
            }}
          >
            <Icon
              as={getEntityStatusIcon(status)}
              color={getEntityStatusColor(status)}
            />
            {getEntityStatusLabel(status)}
          </MenuItem>
        ))}
      </PopoverContent>
    </Popover>
  )
}
