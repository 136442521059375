import {
  ModalContent,
  Modal,
  useDisclosure,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Text,
  ModalCloseButton,
  ModalOverlay,
  Box,
} from "@chakra-ui/react"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { useProduct } from "~/utils/hooks/useProduct"
import {
  integrationConfigIsSetup,
  getIntegrationLogo,
  getIntegrationName,
  PRODUCTS_WITH_INTEGRATIONS,
} from "~/utils/integrations"
import { IntegrationSignalButton } from "../IntegrationSignalButton"
import { Link as RemixLink } from "@remix-run/react"
import { Icon } from "~/utils/components/Icon"
import { TbCube } from "react-icons/tb"
import { getProductEntityName } from "~/utils/getProductName"
import pluralize from "pluralize"
import { isOneOf } from "~/utils/isOneOf"
import { useSignalsQueryCount } from "~/utils/hooks/useSignalsQuery"
import numeral from "numeral"
import { SEARCH_EXPORT_LIMIT } from "~/consts/signals"

export const IntegrationPushSearchButton = () => {
  const modalDisclosure = useDisclosure()
  const product = useProduct()
  const signalsCountQuery = useSignalsQueryCount(product)

  const integrations = useIntegrations()
  const setupIntegrations =
    integrations.data?.integrations.integrationsMeta.filter((i) =>
      integrationConfigIsSetup(i.config?.[product] ?? {})
    )

  const hasSingleIntegration =
    setupIntegrations && setupIntegrations.length === 1
  const integration = hasSingleIntegration ? setupIntegrations[0] : null
  const integrationName = integration
    ? getIntegrationName(integration.integrationKey)
    : null

  return (
    <>
      {(setupIntegrations?.length ?? 0) <= 0 ? (
        <Button
          as={RemixLink}
          to="/integrations"
          leftIcon={<Icon as={TbCube} />}
          variant="outline"
          flex="none"
        >
          Add to CRM
        </Button>
      ) : hasSingleIntegration ? (
        <Button
          leftIcon={
            <Image
              w={4}
              src={getIntegrationLogo(integration?.integrationKey ?? "")}
            />
          }
          onClick={modalDisclosure.onOpen}
          variant="outline"
          flex="none"
        >
          Add to {integrationName}
        </Button>
      ) : (
        <Button
          leftIcon={<Icon as={TbCube} />}
          variant="outline"
          onClick={modalDisclosure.onOpen}
          flex="none"
        >
          Add to CRM
        </Button>
      )}

      <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {hasSingleIntegration ? `Add to ${integrationName}` : "Add to CRM"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm">
              You're about to push the first{" "}
              {numeral(
                Math.min(
                  SEARCH_EXPORT_LIMIT,
                  signalsCountQuery.data?.results ?? 0
                )
              ).format("0,0")}{" "}
              {pluralize(getProductEntityName(product))} to{" "}
              {integrationName ?? "CRM"}. Continue?
            </Text>

            <Box
              p={4}
              bgColor="brand.50"
              rounded="lg"
              borderWidth={1}
              borderColor="brand.100"
              mt={4}
            >
              <Text fontSize="sm" color="brand.500">
                This action may affect your {integrationName ?? "CRM"} API
                usage, potentially reaching caps or consuming credits.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" onClick={modalDisclosure.onClose}>
              Cancel
            </Button>
            {isOneOf(product, PRODUCTS_WITH_INTEGRATIONS) && (
              <IntegrationSignalButton
                showLabel
                signalIds={[]}
                product={product}
                onSuccess={modalDisclosure.onClose}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
