import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import invariant from "~/utils/invariant"

import { SpecterProducts } from "@prisma/client"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { FEED_PAGE_SIZE } from "~/consts/signals"
import { ProductSignalFeedItems } from "~/routes/__protected/api/signals/$product"
import { ProductSignalCount } from "~/routes/__protected/api/signals/$product/count"
import { AffinityContextValue } from "~/utils/context"
import { hasLength } from "~/utils/values"
import { cacheKeys } from "../cacheKeys"
import { PermissionAccessError } from "../permissionAccessError"
import { ViewMode } from "./useViewMode"
import { getProductSignalSearchParams } from "./useProductFilters"
import { useMemo } from "react"

export const useSignalsQuery = <P extends SpecterProducts>(
  product: P,
  view: ViewMode = ViewMode.Table,
  limit: number = FEED_PAGE_SIZE
) => {
  const [searchParams] = useSafeSearchParams()
  const filters = useMemo(
    () => getProductSignalSearchParams(product, searchParams),
    [product, searchParams]
  )
  const staleTime = "entityStatus" in filters ? 0 : 5 * 60 * 1000 // 5 minutes

  return useInfiniteQuery<{
    items: ProductSignalFeedItems<P>
    affinity: AffinityContextValue
    page: number
  }>(
    cacheKeys.signals(product, searchParams, view, limit),
    async ({ pageParam = 0 }) => {
      const serverSearchParams = new URLSearchParams(searchParams)
      serverSearchParams.set("page", pageParam ?? 0)
      serverSearchParams.set("view", view)
      serverSearchParams.set("limit", limit.toString())

      const req = await fetch(
        `/api/signals/${mapProductsToRouteName(product)}`,
        {
          method: "POST",
          body: JSON.stringify(
            Object.fromEntries(serverSearchParams.entries())
          ),
        }
      )

      if (req.status === 403) {
        throw PermissionAccessError
      }

      invariant(req.ok, `Failed to load ${product} signals`)

      const data = await req.json()

      return data
    },
    {
      getNextPageParam(result) {
        // No more pages if we haven't got items
        if (result.items) {
          if (!hasLength(result.items)) {
            // Needs to undefined as per
            //https://tanstack.com/query/v4/docs/react/guides/infinite-queries
            return undefined
          }

          // No more pages if the results are _less_ than the FEED_PAGE_SIZE
          if (result.items.length < FEED_PAGE_SIZE) {
            return undefined
          }
        }

        return result.page + 1
      },
      staleTime,
      refetchOnMount: false,
    }
  )
}

export const useSignalsQueryCount = (product: SpecterProducts) => {
  const [searchParams] = useSafeSearchParams()
  const filters = useMemo(
    () => getProductSignalSearchParams(product, searchParams),
    [product, searchParams]
  )
  const staleTime = "entityStatus" in filters ? 0 : 5 * 60 * 1000 // 5 minutes

  return useQuery<ProductSignalCount>(
    cacheKeys.signalsCount({ product, searchParams }),
    async () => {
      const req = await fetch(
        `/api/signals/${mapProductsToRouteName(product)}/count`,
        {
          method: "POST",
          body: JSON.stringify(Object.fromEntries(searchParams.entries())),
        }
      )

      if (req.status === 403) {
        throw PermissionAccessError
      }

      invariant(req.ok, `Failed to load ${product} signals`)

      return req.json()
    },
    {
      staleTime,
    }
  )
}
