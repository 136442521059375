import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useLocation } from "@remix-run/react"
import { ReactNode, useEffect, useState } from "react"
import {
  HiCode,
  HiExternalLink,
  HiOutlineCash,
  HiOutlineSparkles,
  HiOutlineUserGroup,
} from "react-icons/hi"
import { IoGitNetworkSharp } from "react-icons/io5"

import {
  HiMiniSignal,
  HiOutlineBuildingOffice2,
  HiOutlineChevronDoubleUp,
  HiOutlineGlobeAlt,
  HiOutlineMap,
  HiOutlineSquare3Stack3D,
  HiOutlineStar,
  HiOutlineUsers,
} from "react-icons/hi2"
import { MdQuestionMark } from "react-icons/md"
import { TbCube } from "react-icons/tb"
import { useIntercom } from "react-use-intercom"
import { PRODUCT_VIEW_MODES } from "~/utils/hooks/useViewMode"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { MenuItem, MenuItemProps } from "../MenuItem"
import {
  SidebarAccordion,
  SidebarAccordionIcon,
  SidebarAccordionLine,
  SidebarAccordionPanel,
} from "../SidebarAccordion"

import { MdOutlineShowChart } from "react-icons/md"
import { noop } from "~/utils/noop"
import { generateURLFactory } from "../UserSearchesPage/SavedSearchesTable"
import { createSearchQuery } from "../UserSearchesPage/utils"
import { PiGoogleChromeLogo } from "react-icons/pi"
import { HEADER_HEIGHT } from "../Header"
import { IconCompany } from "../Icons/company"
import { IconType } from "react-icons"
import { IconPeople } from "../Icons/people"
import { IconInvestor } from "../Icons/investor"
import { IconTransaction } from "../Icons/transaction"
import { mapProductsToRouteName } from "../Filters/schemas"
import { AccessTag } from "./AccessTag"
import { QuickSearch } from "../QuickSearch"
import { ColoredTag } from "../ColoredTag"
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlags"
import { Icon } from "~/utils/components/Icon"

export const PRODUCT_ICONS: Record<SpecterProducts, IconType> = {
  [SpecterProducts.company]: HiOutlineBuildingOffice2,
  [SpecterProducts.talent]: HiOutlineUserGroup,
  [SpecterProducts.stratintel]: HiMiniSignal,
  [SpecterProducts.investors]: HiOutlineCash,
  [SpecterProducts.fundingRounds]: MdOutlineShowChart,
  [SpecterProducts.acquisition]: MdOutlineShowChart,
  [SpecterProducts.ipo]: MdOutlineShowChart,
  [SpecterProducts.people]: HiOutlineUsers,
}

interface SectionHeadingProps {
  children?: ReactNode
}

interface SidebarAccordionTriggerProps extends MenuItemProps {
  to?: string
}

const SidebarAccordionTrigger = ({
  children,
  to,
  ...props
}: SidebarAccordionTriggerProps) => {
  return (
    <MenuItem {...props} to={to}>
      {children}
      <SidebarAccordionIcon />
    </MenuItem>
  )
}

const SectionHeading = ({
  children,
}: SectionHeadingProps): JSX.Element | null => {
  return (
    <Text fontSize="xx-small" color="gray.400" px={1.5} pt={4}>
      {children}
    </Text>
  )
}

const UserMenuIcon = ({ isOpen }: { isOpen?: boolean }) => {
  return (
    <Box transform={`rotateX(${isOpen ? "180deg" : 0})`} transition="all 0.2s">
      <svg width="14" height="14" viewBox="0 0 12 12" fill="none">
        <path
          d="M3.66211 3.87417L6.00052 1.74834L8.33894 3.87417"
          stroke="#3E3F42"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.66211 8.12584L6.00052 10.2517L8.33894 8.12584"
          stroke="#AAAAAA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  )
}

export const SIDE_NAV_WIDTH_OPEN = 265

export const SideNavigation = (): JSX.Element => {
  const permissions = useUserPermissions()
  const menuDisclosure = useDisclosure()
  const intercom = useIntercom()
  const [environment, setEnvironment] = useState("production")

  const [, setSearchParams] = useSafeSearchParams()
  const analytics = useAnalytics()

  const urlFactory = generateURLFactory({
    product: SpecterProducts.company,
    viewMode: PRODUCT_VIEW_MODES[SpecterProducts.company][0],
    query: { inCRM: true },
    setSearchParams: noop,
  })
  const [inCRMUrl] = urlFactory((query) => createSearchQuery(query))

  const location = useLocation()

  useEffect(() => {
    if (global.window.location.href.includes("staging")) {
      setEnvironment("staging")
    }

    if (global.window.location.href.includes("localhost")) {
      setEnvironment("localhost")
    }

    menuDisclosure.onClose()
  }, [location])

  const isSpecterAPIEnabled = useFeatureFlag("SPECTER_API_ENABLED")

  return (
    <Flex
      className="grid-area-nav"
      flexDir="column"
      as="nav"
      h="100dvh"
      pos="sticky"
      top={0}
      w={SIDE_NAV_WIDTH_OPEN}
      overflow="auto"
      flex="none"
      bgColor="gray.50"
    >
      <Popover
        placement="bottom-start"
        isOpen={menuDisclosure.isOpen}
        onClose={menuDisclosure.onClose}
        onOpen={menuDisclosure.onOpen}
      >
        <PopoverTrigger>
          <Flex
            gap={2}
            alignItems="center"
            as="button"
            borderBottomWidth={1}
            borderColor="gray.100"
            h={HEADER_HEIGHT}
            px={3}
            flex="none"
            mb={4}
          >
            <PopoverAnchor>
              <Center boxSize="26px" overflow="hidden" rounded="lg">
                <Image
                  w="full"
                  src={
                    permissions.data?.organization?.logoUrl ??
                    `/logo?domain=${permissions.data?.organization?.domainPrimary}`
                  }
                  fallback={
                    <Box
                      boxSize="20px"
                      rounded="md"
                      borderWidth={1}
                      borderStyle="dashed"
                    />
                  }
                />
              </Center>
            </PopoverAnchor>
            <Heading
              as="span"
              fontSize="md"
              fontWeight="semibold"
              maxW={120}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {permissions.data?.organization?.name ?? "Specter"}
            </Heading>
            <Spacer />
            <UserMenuIcon isOpen={menuDisclosure.isOpen} />
          </Flex>
        </PopoverTrigger>

        <Portal>
          <PopoverContent
            zIndex={999}
            shadow="none !important"
            maxW={200}
            p={2}
          >
            <MenuItem to="/account">Account Settings</MenuItem>

            {permissions.data?.organizationId && (
              <>
                <MenuItem to="/team">Team Settings</MenuItem>
                {isSpecterAPIEnabled && (
                  <MenuItem to="/api-console">API Console</MenuItem>
                )}
                <MenuItem to="/team?invite=true">Invite Team</MenuItem>
              </>
            )}

            {permissions.data?.isAdmin && (
              <>
                <Text mt={4} px={1} color="gray.400" fontSize="xs">
                  Admin
                </Text>
                <MenuItem
                  to={
                    environment === "staging"
                      ? "//app.tryspecter.com"
                      : "//app.staging.tryspecter.com"
                  }
                >
                  Go to {environment === "staging" ? "Production" : "Staging"}
                </MenuItem>
                <MenuItem to="/admin/global-hub">Global Hub</MenuItem>
                <MenuItem to="/admin/permissions">Access Management</MenuItem>
                <MenuItem to="/admin/data-management">Data Management</MenuItem>
                <MenuItem to="/admin/debug">Debug</MenuItem>
              </>
            )}

            <Divider my={2} />
            <MenuItem to="/logout">Logout</MenuItem>
          </PopoverContent>
        </Portal>
      </Popover>

      <Flex flexDir="column" gap="1px" px={3}>
        <QuickSearch />

        <MenuItem
          to={`/signals/company/${PRODUCT_VIEW_MODES["company"][0]}`}
          activePath="/signals/company"
          icon={IconCompany}
          onClick={() => {
            setSearchParams({})

            analytics.track(
              "Explored Feed",
              {
                product: SpecterProducts.company,
              },
              { exclude: ["posthog"] }
            ) // we're tracking this server-side for PH
          }}
        >
          Companies
        </MenuItem>

        <SidebarAccordion openPaths={["/signals/talent", "/signals/people"]}>
          <SidebarAccordionTrigger
            icon={IconPeople}
            to={`/signals/talent/${PRODUCT_VIEW_MODES["talent"][0]}`}
          >
            People
          </SidebarAccordionTrigger>
          <SidebarAccordionPanel>
            <SidebarAccordionLine />
            <Flex pl={6} py={1} flexDir="column" gap={0.5}>
              <MenuItem
                to={`/signals/${mapProductsToRouteName(
                  SpecterProducts.talent
                )}/${PRODUCT_VIEW_MODES[SpecterProducts.talent][0]}`}
                activePath="/signals/talent"
                onClick={() => setSearchParams({})}
              >
                Talent Signals
              </MenuItem>
              <MenuItem
                to={`/signals/${mapProductsToRouteName(
                  SpecterProducts.people
                )}/${PRODUCT_VIEW_MODES[SpecterProducts.people][0]}`}
                activePath="/signals/people"
                onClick={() => setSearchParams({})}
              >
                People DB
              </MenuItem>
            </Flex>
          </SidebarAccordionPanel>
        </SidebarAccordion>

        <SidebarAccordion
          openPaths={["/signals/investors", "/signals/strategic"]}
        >
          <SidebarAccordionTrigger
            icon={IconInvestor}
            to={`/signals/strategic/${PRODUCT_VIEW_MODES["stratintel"][0]}`}
          >
            Investors
          </SidebarAccordionTrigger>
          <SidebarAccordionPanel>
            <SidebarAccordionLine />
            <Flex pl={6} py={1} flexDir="column" gap={0.5}>
              <MenuItem
                to={`/signals/strategic/${PRODUCT_VIEW_MODES["stratintel"][0]}`}
                activePath="/signals/strategic"
                onClick={() => setSearchParams({})}
              >
                Strategic Signals
              </MenuItem>
              <MenuItem
                to={`/signals/investors/${PRODUCT_VIEW_MODES["investors"][0]}`}
                activePath="/signals/investors"
                onClick={() => setSearchParams({})}
              >
                Investor DB
              </MenuItem>
            </Flex>
          </SidebarAccordionPanel>
        </SidebarAccordion>

        <SidebarAccordion
          openPaths={[
            "/signals/funding-rounds",
            "/signals/acquisition",
            "/signals/ipo",
          ]}
        >
          <SidebarAccordionTrigger
            icon={IconTransaction}
            to={`/signals/funding-rounds/${PRODUCT_VIEW_MODES["fundingRounds"][0]}`}
          >
            Transactions
          </SidebarAccordionTrigger>
          <SidebarAccordionPanel>
            <SidebarAccordionLine />
            <Flex pl={6} py={1} flexDir="column" gap={0.5}>
              <MenuItem
                to={`/signals/funding-rounds/${PRODUCT_VIEW_MODES["fundingRounds"][0]}`}
                activePath="/signals/funding-rounds"
                onClick={() => setSearchParams({})}
              >
                Funding Rounds
              </MenuItem>
              <MenuItem
                to={`/signals/acquisition/${PRODUCT_VIEW_MODES["acquisition"][0]}`}
                activePath="/signals/acquisition"
                onClick={() => setSearchParams({})}
              >
                Acquisitions
              </MenuItem>
              <MenuItem
                to={`/signals/ipo/${PRODUCT_VIEW_MODES["ipo"][0]}`}
                activePath="/signals/ipo"
                onClick={() => setSearchParams({})}
              >
                IPOs
              </MenuItem>
            </Flex>
          </SidebarAccordionPanel>
        </SidebarAccordion>

        <SectionHeading>My Specter</SectionHeading>

        <SidebarAccordion openPaths={["/user/searches"]}>
          <SidebarAccordionTrigger
            icon={HiOutlineGlobeAlt}
            to="/user/searches/company"
          >
            Saved Searches
          </SidebarAccordionTrigger>
          <SidebarAccordionPanel>
            <SidebarAccordionLine />
            <Flex pl={6} py={1} flexDir="column" gap={0.5}>
              <MenuItem
                to="/user/searches/company"
                activePath="/user/searches/company"
                onClick={() => setSearchParams({})}
              >
                Company Searches
              </MenuItem>
              <MenuItem
                to="/user/searches/talent"
                activePath="/user/searches/talent"
                onClick={() => setSearchParams({})}
              >
                People Searches
              </MenuItem>
              <MenuItem
                to="/user/searches/strategic"
                activePath="/user/searches/strategic"
                onClick={() => setSearchParams({})}
              >
                Investor Searches
              </MenuItem>
            </Flex>
          </SidebarAccordionPanel>
        </SidebarAccordion>

        <SidebarAccordion openPaths={["/user/lists"]}>
          <SidebarAccordionTrigger
            icon={HiOutlineStar}
            to="/user/lists/company"
          >
            Lists
          </SidebarAccordionTrigger>
          <SidebarAccordionPanel>
            <SidebarAccordionLine />
            <Flex pl={6} py={1} flexDir="column" gap={0.5}>
              <MenuItem
                to="/user/lists/company"
                activePath="/user/lists/company"
                onClick={() => setSearchParams({})}
              >
                Company Lists
              </MenuItem>
              <MenuItem
                to="/user/lists/talent"
                activePath="/user/lists/talent"
                onClick={() => setSearchParams({})}
              >
                People Lists
              </MenuItem>
              <MenuItem
                to="/user/lists/strategic"
                activePath="/user/lists/strategic"
                onClick={() => setSearchParams({})}
              >
                Investor Lists
              </MenuItem>
            </Flex>
          </SidebarAccordionPanel>
        </SidebarAccordion>

        <MenuItem
          to="/user/landscapes"
          activePath="/user/landscapes"
          icon={HiOutlineMap}
        >
          Landscapes
        </MenuItem>

        <MenuItem
          icon={HiOutlineChevronDoubleUp}
          to="/import"
          activePath="/import"
        >
          Import List
        </MenuItem>

        <SectionHeading>CRM & Network</SectionHeading>

        <MenuItem to={inCRMUrl} icon={HiOutlineSquare3Stack3D}>
          My CRM
        </MenuItem>

        <MenuItem
          icon={IoGitNetworkSharp}
          to="/network-tracking"
          activePath="/network-tracking"
        >
          My Network
        </MenuItem>

        <SectionHeading>Tools</SectionHeading>

        <MenuItem icon={HiOutlineSparkles} to="/agent" activePath="/agent">
          AI Agent
        </MenuItem>

        <MenuItem
          icon={PiGoogleChromeLogo}
          to="https://chromewebstore.google.com/detail/specter/kipcjommpnpeopogndnghceedpbnefpo"
        >
          Chrome Extension
        </MenuItem>

        <MenuItem icon={TbCube} to="/integrations" activePath="/integrations">
          Integrations
        </MenuItem>

        {isSpecterAPIEnabled && permissions.data?.organizationId && (
          <MenuItem
            icon={HiCode}
            to="/api-console"
            activePath="/api-console"
            isNew
          >
            API Console
          </MenuItem>
        )}
      </Flex>

      <Spacer />

      <Flex px={2} alignItems="center" gap={1} pb={4}>
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="Help"
              icon={<Icon as={MdQuestionMark} />}
              isRound
              variant="outline"
              size="xs"
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent ml={3} p={2} maxW={180}>
              <MenuItem to="https://intercom.help/specter-labs/en/">
                Help Center <Icon as={HiExternalLink} />
              </MenuItem>
              <MenuItem to="https://intercom.help/specter-labs/en/articles/9158768-data-catalogue">
                Data Catalogue <Icon as={HiExternalLink} />
              </MenuItem>
              <MenuItem onClick={intercom.show}>Talk to Us</MenuItem>
            </PopoverContent>
          </Portal>
        </Popover>

        <Spacer />

        <AccessTag />

        {permissions.data?.isAdmin && environment !== "production" && (
          <ColoredTag color="orange">{environment}</ColoredTag>
        )}
      </Flex>
    </Flex>
  )
}
