import { omit } from "ramda"

export const PROGRAMMATIC_FILTERS = [
  "new",
  "HasNewHighlights",
  "HasNewFundingHighlights",
  "HasNewGrowthHighlights",
  "entityStatus",
]

export const cleanProgmaticFiltersFromQuery = (query: Record<string, any>) => {
  return omit(PROGRAMMATIC_FILTERS, query)
}
