import {
  Box,
  Flex,
  Heading,
  Image,
  ImageProps,
  SimpleGrid,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import numeral from "numeral"
import { Fragment } from "react"
import {
  HiOutlineCube,
  HiOutlineInformationCircle,
  HiStar,
} from "react-icons/hi2"
import { BoxIcon, BoxImage, FlexDivider } from "~/components"
import { Card } from "~/components/Card"
import { Tab } from "~/components/DetailPage/FullScreen"
import { ScreenshotModal } from "~/components/ScreenshotModal"
import {
  G2_COLOR,
  G2_LOGO_URL,
  getTrustPilotURL,
  TRUSTPILOT_COLOR,
  TRUSTPILOT_LOGO_URL,
} from "~/consts/data3rdParties"
import {
  CompanyTableItem,
  G2Product,
  TrustpilotData,
} from "~/utils/db/queries/company/types"
import { hasLength, isNullish } from "~/utils/values"
import { DetailValueCard } from "../DetailValueCard"
import { Patents } from "./Patents"
import { TabSubsectionTitle } from "./SubsectionTitle"

export const Product: Tab<"company">["TabContent"] = ({ signal }) => {
  return (
    <Flex direction="column" gap={8}>
      <Box as="section">
        <TabSubsectionTitle
          title={
            <>
              <Image src={G2_LOGO_URL} boxSize="16px" />
              G2 Reviews
            </>
          }
        />
        <G2Reviews signal={signal} />
      </Box>
      <Box as="section">
        <TabSubsectionTitle
          title={
            <>
              <Image src={TRUSTPILOT_LOGO_URL} boxSize="16px" />
              Trustpilot Reviews
            </>
          }
        />
        <TrustpilotReviews signal={signal} />
      </Box>
      <Box as="section">
        <TabSubsectionTitle title="Patents" />
        <Patents signal={signal} />
      </Box>
      {hasLength(signal.certifications_mapped) && (
        <Box as="section">
          <TabSubsectionTitle
            title={`Certifications (${
              signal.certifications_mapped?.length ?? 0
            })`}
          />
          <Card>
            {signal.certifications_mapped?.map((certification) => (
              <Tag rounded="full" key={certification}>
                {certification}
              </Tag>
            ))}
          </Card>
        </Box>
      )}
      <Box as="section">
        <TabSubsectionTitle title="Tech Stack" />
        <TechStack signal={signal} />
      </Box>
    </Flex>
  )
}

const TechStack = ({ signal }: { signal: CompanyTableItem }) => {
  if (
    isNullish(signal.itSpend) &&
    isNullish(signal.technologiesCount) &&
    !hasLength(signal.technologies)
  ) {
    return (
      <Card>
        <Text fontSize="xs" color="gray.400">
          We don't have any tech stack data for this company at this time.
        </Text>
      </Card>
    )
  }

  return (
    <SimpleGrid
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      gap={4}
      mb={10}
    >
      <DetailValueCard label="IT Spend" value={signal.itSpend} prefix="$" />
      <DetailValueCard
        label="Number of Technologies"
        value={signal.technologiesCount}
      />

      {hasLength(signal.technologies) && (
        <Card flex={1} gridColumn="span 2">
          <Heading as="h2" color="gray.500" size="xs" mb={3}>
            Active Technologies
          </Heading>

          <Flex flexWrap="wrap" gap={1} mb={4}>
            {signal.technologies?.map((technology: string) => (
              <Tag key={technology} rounded="full">
                {technology}
              </Tag>
            ))}
          </Flex>
        </Card>
      )}
    </SimpleGrid>
  )
}

const G2Reviews = ({ signal }: { signal: CompanyTableItem }) => {
  if (isNullish(signal.g2_data))
    return (
      <Card>
        <Text fontSize="xs" color="gray.400">
          We don't have any G2 products or reviews for this company at this
          time.
        </Text>
      </Card>
    )

  return (
    <SimpleGrid
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      gap={4}
      mb={10}
    >
      {signal.g2_data?.map((product) => {
        const {
          product_name,
          product_logo,
          rating,
          reviews,
          star_distribution,
          g2_link,
        } = product

        return (
          <ProductReviewItem
            key={product_name}
            name={product_name ?? ""}
            reviewCount={reviews ?? 0}
            starDistribution={star_distribution}
            logoUrl={product_logo ?? ""}
            fallbackLogoUrl={signal.logoUrl}
            href={g2_link}
            ratingAvg={rating ?? 0}
            barColor={G2_COLOR}
          />
        )
      })}
    </SimpleGrid>
  )
}

export const ProductReviewItem = ({
  name,
  logoUrl,
  fallbackLogoUrl,
  ratingAvg,
  reviewCount,
  starDistribution,
  href,
  barColor,
  ratingTooltip,
}: {
  name: string
  logoUrl?: string
  fallbackLogoUrl?: string
  ratingAvg?: number
  reviewCount: number
  starDistribution: Record<string, number> | null
  href: string
  barColor?: string
  ratingTooltip?: string
}): JSX.Element => {
  const starDistributionFormatted = starDistribution
    ? formatTrustpilotReviews(starDistribution)
    : null

  return (
    <Card
      p={4}
      {...{ as: "a", href, target: "_blank" }}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Flex alignItems="center" gap={4}>
        {logoUrl ? (
          <BoxImage
            src={logoUrl}
            imageProps={{
              w: "70%",
              h: "70%",
              objectFit: "contain",
            }}
            fallbackSrc={fallbackLogoUrl}
          />
        ) : (
          <BoxIcon icon={HiOutlineCube} />
        )}

        <Heading
          as="h2"
          size="xs"
          display="inline-flex"
          alignItems="center"
          gap={1}
        >
          {name}
        </Heading>

        <Flex
          flexGrow={1}
          gap={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Text
            fontSize="xs"
            color="gray.400"
            className="reviews"
            whiteSpace="nowrap"
          >
            ({reviewCount ?? 0} reviews)
          </Text>

          <Flex alignItems="center">
            <Icon as={HiStar} color="yellow.500" mb="1px" />
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={isNullish(ratingAvg) ? "gray.300" : undefined}
            >
              {!isNullish(ratingAvg) ? numeral(ratingAvg).format("0.0") : "0.0"}
            </Text>
            {ratingTooltip && (
              <Tooltip label={ratingTooltip} fontSize="xs">
                <Text
                  as="span"
                  display="inline-flex"
                  alignItems="center"
                  ml={1}
                  color="gray.400"
                >
                  <Icon as={HiOutlineInformationCircle} boxSize={4} />
                </Text>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
      <FlexDivider orientation="horizontal" color="gray.100" py={0} />
      <RatingDistribution
        starDistribution={starDistributionFormatted}
        reviews={reviewCount}
        barColor={barColor}
      />
    </Card>
  )
}

export const G2Medal = ({
  medal_image,
  boxSize = "56px",
}: {
  medal_image: string
  boxSize?: ImageProps["boxSize"]
}): JSX.Element => {
  const modal = useDisclosure()

  return (
    <>
      <Image
        onClick={(e) => {
          e.stopPropagation()
          modal.onOpen()
        }}
        src={medal_image}
        boxSize={boxSize}
        cursor="pointer"
      />
      <ScreenshotModal
        screenshot={medal_image}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
      />
    </>
  )
}

const TrustpilotReviews = ({ signal }: { signal: CompanyTableItem }) => {
  if (isNullish(signal.trustpilot_data))
    return (
      <Card>
        <Text fontSize="xs" color="gray.400">
          We don't have any Trustpilot reviews for this company at this time.
        </Text>
      </Card>
    )

  const { name, review_count, review_aggregate, logo_url, reviews_average } =
    signal.trustpilot_data

  return (
    <SimpleGrid
      templateColumns={{ base: "1fr", md: "1fr 1fr" }}
      gap={4}
      mb={10}
    >
      <ProductReviewItem
        name={name ?? ""}
        reviewCount={review_count ?? 0}
        starDistribution={review_aggregate}
        logoUrl={logo_url ?? ""}
        fallbackLogoUrl={signal.logoUrl}
        href={getTrustPilotURL(signal.domain)}
        ratingAvg={reviews_average ?? undefined}
        barColor={TRUSTPILOT_COLOR}
        ratingTooltip="The TrustScore isn't just a simple average of all reviews. It's based on multiple factors like the age and number of reviews, and more."
      />
    </SimpleGrid>
  )
}

export const RatingDistribution = ({
  starDistribution,
  reviews,
  neutralColors,
  barColor = G2_COLOR,
}: {
  starDistribution:
    | G2Product["star_distribution"]
    | TrustpilotData["review_aggregate"]
  reviews: number
  neutralColors?: boolean
  barColor?: string
}) => {
  if (isNullish(starDistribution)) return null

  const starDistributionFormatted = formatTrustpilotReviews(starDistribution)

  return (
    <SimpleGrid
      templateColumns="auto auto 1fr auto"
      alignItems="center"
      gap={0.5}
      justifyItems="flex-end"
      minW="150px"
    >
      {Object.entries(starDistributionFormatted)
        .reverse()
        ?.map(([star, count]) => (
          <Fragment key={`star_${star}`}>
            <Text justifySelf="center" fontSize="x-small" lineHeight="10px">
              {star}
            </Text>
            <Icon
              as={HiStar}
              color={neutralColors ? "white" : "yellow.500"}
              mb="1px"
              boxSize="10px"
            />
            <Box
              justifySelf="start"
              w={`${(count / reviews) * 100}%`}
              opacity={`${50 + (count / reviews) * 50}%`}
              h="5px"
              bgColor={neutralColors ? "white" : barColor}
              borderRadius="full"
              ml={1}
            />
            <Text fontSize="x-small" lineHeight="10px" pl={2} color="gray.400">
              ({count})
            </Text>
          </Fragment>
        ))}
    </SimpleGrid>
  )
}

export const getProductMedals = (g2_data?: G2Product[] | null) => {
  return [
    ...new Set(
      g2_data?.map(({ medal_image }) => medal_image).filter(Boolean) ?? []
    ),
  ]
}

const formatTrustpilotReviews = (starDistribution: Record<string, number>) => ({
  1: starDistribution["1"] ?? starDistribution.one ?? 0,
  2: starDistribution["2"] ?? starDistribution.two ?? 0,
  3: starDistribution["3"] ?? starDistribution.three ?? 0,
  4: starDistribution["4"] ?? starDistribution.four ?? 0,
  5: starDistribution["5"] ?? starDistribution.five ?? 0,
})

export const getWeightedAverageRating = (
  starDistribution: Record<string, number>
) => {
  const totalReviews = Object.values(starDistribution).reduce(
    (acc, count) => acc + count,
    0
  )

  const NUMBERS = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
  }

  const totalStars = Object.entries(starDistribution).reduce(
    (acc, [star, count]) => {
      const stars = Number(star) || NUMBERS[star as keyof typeof NUMBERS]
      return acc + stars * count
    },
    0
  )

  return numeral(totalStars / totalReviews).format("0.0")
}
