import { nameSearchStatement } from "~/utils/db/sql/utils"

type CompanyQuickSearchResult = {
  id: string
  domain: string
  name: string
  logo_url: string
}

export async function companyQuickSearch(
  term: string,
  limit: number = 50
): Promise<CompanyQuickSearchResult[]> {
  const results = await prisma.$queryRaw<
    { signal: CompanyQuickSearchResult }[]
  >`
      SELECT JSONB_BUILD_OBJECT(
             'id', "Specter - ID",
             'domain', "Domain",
             'name', "Company Name",
             'logo_url', company_logo_url("Domain")
      ) as signal
      FROM company_data cd
      INNER JOIN company_data_search cds ON cds.company_id = cd."Specter - ID"
      LEFT JOIN company_success_score css ON css.company_id = cd.id 
      WHERE ${nameSearchStatement("cds.name_search", term, false)}
      order by (2 * least((ts_rank(cds.name_search, websearch_to_tsquery('simple', ${term}), 2) / 0.05), 1) *
                (success_score * 2) /
                greatest((least((ts_rank(cds.name_search, websearch_to_tsquery('simple', ${term}), 2) / 0.05), 1) +
                (success_score * 2)), 0.00001))
      desc
      LIMIT ${limit}
  `
  return results.map((r) => r.signal)
}
