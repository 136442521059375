import { titleCase } from "title-case"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ColoredTag } from "../ColoredTag"

export function AccessTag() {
  const permissions = useUserPermissions()

  const { isAdmin, organization } = permissions.data ?? {}

  const isTrial =
    organization?.companyTrial ||
    organization?.talentTrial ||
    organization?.stratintelTrial
  const isPremium =
    organization?.company || organization?.talent || organization?.stratintel

  const userTag = isAdmin
    ? "ADMIN"
    : isPremium
    ? "PREMIUM"
    : isTrial
    ? "TRIAL"
    : "AWAITING ACCESS"

  const USER_TAG_COLORS = {
    ADMIN: "purple",
    PREMIUM: "brand",
    TRIAL: "green",
    "TRIAL EXPIRED": "orange",
    "AWAITING ACCESS": "gray",
  }

  if (permissions.isLoading) {
    return null
  }

  return (
    <ColoredTag color={USER_TAG_COLORS[userTag]} size="sm">
      {titleCase(userTag.toLowerCase())}
    </ColoredTag>
  )
}
