import { Avatar, Center, Flex, Text } from "@chakra-ui/react"

interface Props {
  logos: string[]
  nLogos?: number
}

const MAX_LOGOS = 8

export const LogosGrid = ({
  logos,
  nLogos = MAX_LOGOS,
}: Props): JSX.Element => {
  return (
    <Flex pl={2} pointerEvents="none">
      {logos.slice(0, nLogos).map((src, i) => {
        const logo = src.includes("http") ? src : undefined
        return <Avatar src={logo} key={i} ml={-2} size="sm" />
      })}

      {logos.length > nLogos && (
        <Center
          bgColor="gray.100"
          rounded="full"
          w={8}
          h={8}
          ml={-2}
          zIndex={0}
        >
          <Text fontSize="xs" fontWeight="semibold">
            +{logos.length - nLogos}
          </Text>
        </Center>
      )}
    </Flex>
  )
}
