import {
  Box,
  Flex,
  Heading,
  HeadingProps,
  keyframes,
  Text,
  TextProps,
  useTheme,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import numeral from "numeral"
import { FaMinus } from "react-icons/fa"
import { HiArrowRight } from "react-icons/hi2"
import { RiArrowDownLine, RiArrowUpLine } from "react-icons/ri"
import { Card, CardProps } from "~/components/Card"
import { clipPathFrame } from "~/utils/clipPathFrame"
import { getGrowthColor } from "~/utils/getGrowthColor"
import { isNullish } from "~/utils/values"
import { formatGrowth } from "./DetailPageHistoricalsChartLayout"

import { getGrowthOperator } from "./utils"

export type DetailValueCardProps = {
  label: string | JSX.Element
  value: string | number | JSX.Element | null
  color?: string
  isGrowth?: boolean
  prefix?: string | JSX.Element
  suffix?: string
  suppressOperator?: boolean
  suppressFormatting?: boolean

  badge?: {
    value: number | null
    render?: (value: number | null) => JSX.Element | string
    inverted?: boolean
    isPercentage?: boolean
    suffix?: string
  }

  labelProps?: HeadingProps
  valueProps?: TextProps

  onClick?: CardProps["onClick"]
  children?: JSX.Element

  withHighlight?: boolean
} & Omit<CardProps, "prefix">

export const DetailValueCard = ({
  label,
  value,
  color,
  isGrowth,
  prefix = "",
  suffix = "",
  suppressOperator,
  suppressFormatting,
  badge,
  labelProps,
  valueProps,
  onClick,
  children,

  withHighlight,
  ...props
}: DetailValueCardProps): JSX.Element => {
  if (isNullish(value)) return <></>

  const format = (value: string | number | null): string | number | null => {
    if (suppressFormatting) return `${value}${suffix}`

    return `${
      isGrowth && !suppressOperator ? getGrowthOperator(Number(value)) : ""
    }${numeral(value).format("0,[00].[00]a")}${suffix}`
  }

  const valueColor =
    color ?? (isGrowth ? getGrowthColor(Number(value)) : undefined)

  return (
    <Card
      {...(onClick && {
        cursor: "pointer",
        onClick,
        as: "button",
        textAlign: "left",
      })}
      flexGrow={1}
      position="relative"
      sx={{ breakInside: "avoid" }}
      {...(withHighlight && { borderColor: "brand.100" })}
      {...props}
    >
      {withHighlight && <EnergyLine />}
      <Flex alignItems="center" justifyContent="space-between">
        <Flex direction="column">
          <Heading as="h2" color="gray.500" size="xs" mb={2} {...labelProps}>
            {label}
          </Heading>

          <Text
            fontWeight="extrabold"
            fontSize={{ base: "md", lg: "3xl" }}
            lineHeight={1}
            color={valueColor}
            {...valueProps}
          >
            {prefix}
            {typeof value === "object" ? value : format(value) ?? "-"}
          </Text>
        </Flex>
        {props.to && <Icon as={HiArrowRight} color="gray.400" />}
      </Flex>

      {badge &&
        !isNullish(badge?.value) &&
        (() => {
          const growthColor = getGrowthColor(
            Number(badge?.value),
            badge.inverted
          )

          const growthArrow =
            growthColor === "black"
              ? FaMinus
              : // @ts-ignore
              (growthColor === "green") ^ !!badge.inverted
              ? RiArrowUpLine
              : RiArrowDownLine

          return (
            <Text
              fontSize="xs"
              color="gray.500"
              display="inline-flex"
              alignItems="center"
              gap={1}
            >
              <Icon as={growthArrow} color={growthColor} />{" "}
              <Text as="span" color={growthColor}>
                {isNullish(badge?.value)
                  ? "N/A"
                  : formatGrowth(badge.value, {
                      isPercentage: badge.isPercentage ?? true,
                    })}
                {badge.suffix}
              </Text>{" "}
              vs last 3 months
            </Text>
          )
        })()}
      {children}
    </Card>
  )
}

export const EnergyLine = ({
  color = "#4745ca",
  borderRadius = 5,
}: {
  color?: string
  borderRadius?: number
}) => {
  const rotatingAnim = keyframes`
    from {
        rotate: 0deg;
    } 
    to {
        rotate: 360deg;
    }
  `

  const theme = useTheme()

  const colorParts = color.split(".")

  if (colorParts.length === 2) {
    color = theme.colors[colorParts[0]][colorParts[1]]
  }

  return (
    <Box
      overflow="hidden"
      position="absolute"
      inset="0"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        right: "50%",
        left: "-25%",
        background: `linear-gradient(transparent, ${color}, transparent)`,
        animation: `${rotatingAnim} linear infinite 3s`,
        transformOrigin: "right",
      }}
      clipPath={clipPathFrame({ borderRadius })}
    />
  )
}
