import { cacheKeys } from "~/utils/cacheKeys"

import { useQuery } from "@tanstack/react-query"

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { Link as RemixLink } from "@remix-run/react"
import {
  HiOutlineBookOpen,
  HiOutlineBuildingOffice,
  HiOutlineRocketLaunch,
} from "react-icons/hi2"
import { Card } from "~/components/Card"
import { ColoredTag } from "~/components/ColoredTag"
import { CompanyLogo } from "~/components/CompanyLogo"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { FlexDivider } from "~/components/FlexDivider"
import { FlexNLines } from "~/components/FlexOneLine"
import { NotificationBadge } from "~/components/NotificationBadge"
import { sortPeopleHighlights } from "~/components/PeopleDetailPage/Tabs/About"
import { HighlightSmallItem } from "~/components/PeopleSignalCard"
import {
  ButtonContactDetails,
  ButtonCrunchbase,
  ButtonGithub,
  ButtonLinkedin,
  ButtonTwitter,
} from "~/components/SignalCard"
import {
  createSearchQuery,
  getSearchUrlFromProduct,
} from "~/components/UserSearchesPage/utils"
import {
  DEPARTMENT_ICONS,
  PeopleDepartment,
  PeopleHighlight,
  PEOPLE_DEPARTMENTS,
  PEOPLE_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import {
  GetTeamBreakdownResponse,
  TeamMember,
} from "~/routes/__protected/api/company/$id/team-breakdown"
import { Icon } from "~/utils/components/Icon"
import { CompanyTableItem } from "~/utils/db/queries/company/types"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { ensureAbsoluteLink, relativeUrl } from "~/utils/string/url"
import { isNullish } from "~/utils/values"
import { TabSubsectionTitle } from "./SubsectionTitle"
import { useRef, useState } from "react"

export const useTeamBreakdownQuery = (
  signal: CompanyTableItem,
  department?: PeopleDepartment
) => {
  return useQuery<GetTeamBreakdownResponse>({
    queryKey: cacheKeys.teamBreakdown(signal.id, department),
    async queryFn() {
      const req = await fetch(
        `/api/company/${signal.id}/team-breakdown${
          department ? `?department=${encodeURIComponent(department)}` : ""
        }`
      )

      return await req.json()
    },
    refetchOnMount: false,
  })
}

export const TeamBreakdown = ({ signal }: { signal: CompanyTableItem }) => {
  const [department, setDepartment] = useState<PeopleDepartment | undefined>(
    undefined
  )
  const [loadingDepartment, setLoadingDepartment] = useState<
    PeopleDepartment | undefined
  >(undefined)

  const teamBreakdownDataRef = useRef<GetTeamBreakdownResponse | null>(null)

  const teamBreakdownQuery = useTeamBreakdownQuery(signal, department)

  if (teamBreakdownQuery.data) {
    teamBreakdownDataRef.current = teamBreakdownQuery.data

    if (loadingDepartment) setLoadingDepartment(undefined)
  }

  if (teamBreakdownQuery.isLoading && !teamBreakdownDataRef.current) {
    return <Spinner />
  }

  if (!teamBreakdownDataRef.current) {
    return null
  }

  const { founders, employees, departmentsCount } = teamBreakdownDataRef.current

  const toViewAll = relativeUrl(
    getSearchUrlFromProduct(SpecterProducts.people, ViewMode.Feed),
    {
      query: createSearchQuery({
        Companies: ["Current", ["OR", [signal.id]]],
      }),
    }
  )

  return (
    <>
      {!!founders.length && (
        <>
          <TabSubsectionTitle title="Founders" mb={0} mt={4} />
          <SimpleGrid
            gap={3}
            templateColumns="repeat(auto-fill, minmax(500px, 1fr))"
          >
            {founders.map((person) => (
              <PersonCard key={person.specter_person_id} person={person} />
            ))}
          </SimpleGrid>
        </>
      )}

      {(!!employees.length || department !== null) && (
        <>
          <TabSubsectionTitle title="Team Breakdown" mb={0} mt={4} />

          <Flex gap={1} flexWrap="wrap">
            <Button
              size="xs"
              variant={department === undefined ? "solid" : "outline"}
              border="1px solid transparent"
              colorScheme={department === undefined ? "brand" : undefined}
              onClick={() => setDepartment(undefined)}
            >
              All
            </Button>
            <FlexDivider orientation="vertical" />
            {PEOPLE_DEPARTMENTS.map(
              (dep) =>
                !!departmentsCount[dep] && (
                  <Button
                    key={dep}
                    size="xs"
                    variant={dep === department ? "solid" : "outline"}
                    border="1px solid transparent"
                    colorScheme={dep === department ? "brand" : undefined}
                    onClick={() => {
                      setDepartment(dep)
                      setLoadingDepartment(dep)
                    }}
                    isLoading={loadingDepartment === dep}
                  >
                    {dep} ({departmentsCount[dep]})
                  </Button>
                )
            )}
          </Flex>
          <SimpleGrid
            gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
            gap={3}
          >
            {employees.map((person) => (
              <PersonCard key={person.specter_person_id} person={person} />
            ))}
          </SimpleGrid>
        </>
      )}

      <Button
        variant="solid"
        as={RemixLink}
        to={toViewAll}
        alignSelf="center"
        colorScheme="brand"
        // target={isEmbed ? "_blank" : undefined}
      >
        View All
      </Button>
    </>
  )
}

export const PeopleDepartmentIcon = ({
  department,
}: {
  department: PeopleDepartment
}) => {
  const departmentIcon = DEPARTMENT_ICONS[department]

  if (!departmentIcon) return null

  return (
    <Tooltip label={department}>
      <ColoredTag
        color="gray"
        size="sm"
        p={0}
        px="auto"
        display="inline-flex"
        justifyContent="center"
        w="fit-content"
      >
        <Icon as={departmentIcon} boxSize={3} />
      </ColoredTag>
    </Tooltip>
  )
}

const PersonCard = ({ person }: { person: TeamMember }) => {
  const pastCompany = person.experience?.find((exp) => !exp.is_current)
  const education = person.education?.find((exp) => !exp.is_current)
  const permissions = useUserPermissions()

  const sortedHighlights = sortPeopleHighlights(
    (person.highlights ?? []) as PeopleHighlight[]
  )

  return (
    <Card
      p={3}
      display="flex"
      flexDirection="column"
      gap={3}
      key={person.specter_person_id}
      {...(person.is_founder && {
        borderColor: "brand.100",
        // bgColor: "brand.50",
      })}
    >
      <Flex gap={3} alignItems="center">
        <Box position="relative" alignSelf="start">
          {person.is_founder && (
            <NotificationBadge
              value={<Icon as={HiOutlineRocketLaunch} />}
              fontWeight="bold"
              color="brand.300"
              fontSize={"xs"}
              boxSize={6}
              placement="bottom-right"
            />
          )}
          <Avatar
            src={person.profile_image_url ?? undefined}
            boxSize={person.is_founder ? 14 : 12}
            borderRadius="md"
            icon={<Image borderRadius="md" src="/specter-blank.jpg" />}
            bg="white"
            position="relative"
          />
        </Box>

        <Flex
          direction="column"
          justifyContent="center"
          overflow="hidden"
          fontSize="sm"
          flexGrow={1}
        >
          {!!person.specter_person_id || !!person.linkedin_url ? (
            <Link
              color="brand.500"
              {...(person.specter_person_id
                ? {
                    as: RemixLink,
                    to: `/signals/${mapProductsToRouteName(
                      SpecterProducts.people
                    )}/feed/${person.specter_person_id}/f`,
                    target: "_blank",
                  }
                : {
                    href: ensureAbsoluteLink(person.linkedin_url),
                    target: "_blank",
                    to: "",
                  })}
              onClick={(e) => e.stopPropagation()}
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              display="inline-flex"
              alignItems="center"
              gap={1}
              position="relative"
            >
              <Text as="span" mr={1}>
                {person.full_name}
              </Text>
              {person.departments?.map((department) => (
                <PeopleDepartmentIcon
                  key={department}
                  department={department}
                />
              ))}
            </Link>
          ) : (
            <Text
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              display="inline-flex"
              alignItems="center"
              gap={1}
              position="relative"
            >
              {person.full_name}
              {person.departments?.map((department) => (
                <PeopleDepartmentIcon
                  key={department}
                  department={department}
                />
              ))}
            </Text>
          )}
          <Text
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            fontSize="xs"
          >
            {person.title}
          </Text>
        </Flex>
        {(person.linkedin_url ||
          person.twitter_url ||
          person.github_url ||
          (permissions.data?.isAdmin && person.cb_url)) && (
          <Flex gap={1} alignSelf="flex-start">
            {person.specter_person_id && (
              <ButtonContactDetails
                personId={person.specter_person_id}
                personName={person.full_name}
                isCompact={!person.is_founder}
                size="xs"
              />
            )}
            <ButtonGroup
              size="xs"
              variant="outline"
              gap={1}
              justifyItems="end"
              spacing={0}
              alignItems="center"
            >
              <ButtonLinkedin
                linkedinUrl={ensureAbsoluteLink(person.linkedin_url)}
              />
              <ButtonTwitter twitterUrl={person.twitter_url} />
              <ButtonGithub githubUrl={person.github_url} />
              {permissions.data?.isAdmin && (
                <ButtonCrunchbase crunchbaseUrl={person.cb_url} />
              )}
            </ButtonGroup>
          </Flex>
        )}
      </Flex>

      {person.is_founder && (
        <SimpleGrid
          gap={2}
          gridTemplateColumns="1fr auto 1fr"
          alignItems="center"
        >
          <Flex direction="column" flexGrow={1}>
            {pastCompany && (
              <Flex gap={1} mt={1} alignItems="center">
                <Text
                  fontSize="xs"
                  color="gray.500"
                  display="inline-flex"
                  gap={1}
                  alignItems="center"
                  whiteSpace="nowrap"
                >
                  <Icon as={HiOutlineBuildingOffice} /> Past company:
                </Text>

                <CompanyLogo domain={pastCompany.domain} size={5} />

                {!isNullish(pastCompany.company_linkedin_url) ||
                !isNullish(pastCompany.specter_company_id) ? (
                  <Link
                    {...(pastCompany.specter_company_id
                      ? {
                          as: RemixLink,
                          to: `/signals/company/feed/${pastCompany.specter_company_id}/f`,
                        }
                      : {
                          href: ensureAbsoluteLink(
                            pastCompany.company_linkedin_url
                          ),
                          target: "_blank",
                          to: "",
                        })}
                    // href={company_linkedin_url}
                    // target="_blank"
                    color="brand.700"
                    fontWeight="semibold"
                    fontSize="xs"
                    whiteSpace="nowrap"
                  >
                    {pastCompany.company_name}
                  </Link>
                ) : (
                  <Text fontSize="xs" whiteSpace="nowrap">
                    {pastCompany.company_name}
                  </Text>
                )}
              </Flex>
            )}

            {education && (
              <Flex gap={1} mt={1} alignItems="center">
                <Text
                  fontSize="xs"
                  color="gray.500"
                  display="inline-flex"
                  gap={1}
                  alignItems="center"
                >
                  <Icon as={HiOutlineBookOpen} />
                </Text>
                {education.school_linkedin_url !== null ? (
                  <Link
                    href={education.school_linkedin_url}
                    target="_blank"
                    color="brand.700"
                    fontWeight="semibold"
                    fontSize="xs"
                    whiteSpace="nowrap"
                  >
                    {education.school_name}
                  </Link>
                ) : (
                  <Text fontSize="xs" whiteSpace="nowrap">
                    {education.school_name}
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
          <FlexDivider orientation="vertical" />
          {/* {person.is_founder && !!sortedHighlights.length && (
          <Flex gap={1} flexWrap="wrap">
            {sortedHighlights.map((highlight) => (
              <HighlightSmallItem key={highlight} highlight={highlight} />
            ))}
          </Flex>
        )} */}
          {!!sortedHighlights.length && (
            <FlexNLines
              numberOfLines={1}
              gap={1}
              data={sortedHighlights}
              overflowIndicator={(overflowingCount, data) => {
                const labels = data.map(
                  (h) => PEOPLE_HIGHLIGHTS_PROPS[h as PeopleHighlight].label
                )

                return (
                  <Tooltip label={labels.map((h) => h).join(", ")}>
                    <Button
                      size="xs"
                      fontSize="x-small"
                      variant="link"
                      as={RemixLink}
                      to={`#`}
                      alignSelf="center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      +{overflowingCount}
                    </Button>
                  </Tooltip>
                )
              }}
            >
              {sortedHighlights.map((highlight) => (
                <HighlightSmallItem key={highlight} highlight={highlight} />
              ))}
            </FlexNLines>
          )}
        </SimpleGrid>
      )}
    </Card>
  )
}
