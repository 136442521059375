import {
  Image,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  Box,
  VStack,
  Flex,
  Spacer,
  Divider,
  ModalFooter,
} from "@chakra-ui/react"
import { MenuItem } from "../MenuItem"
import {
  getIntegrationEnrichmentTypeLabel,
  getIntegrationLogo,
  getIntegrationName,
} from "~/utils/integrations"
import { IntegrationEnrichmentType, SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cacheKeys } from "~/utils/cacheKeys"
import { Card } from "../Card"
import { useIntegrationEnrichments } from "~/utils/hooks/useIntegrations"
import { TbCube } from "react-icons/tb"
import { Link } from "@remix-run/react"
import { MY_CRM_LIST_ID } from "~/utils/lists"

interface SearchProps {
  integrationKey?: string
  searchId: number
  listId: undefined
  product: SpecterProducts
}
interface ListProps {
  integrationKey?: string
  listId: string
  searchId: undefined
  product: SpecterProducts
}

type Props = SearchProps | ListProps

export const IntegrationEnrichmentForm = ({
  integrationKey,
  listId,
  searchId,
  product,
}: Props) => {
  const modalDisclosure = useDisclosure()
  const queryClient = useQueryClient()
  const integrationEnrichmentsQuery = useIntegrationEnrichments(integrationKey)

  const getEnrichment = (type: IntegrationEnrichmentType) => {
    return integrationEnrichmentsQuery.data?.find((enrichment) => {
      if (listId === MY_CRM_LIST_ID) {
        return (
          enrichment.enrichmentType === type &&
          enrichment.integrationKey === integrationKey &&
          enrichment.listId === null &&
          enrichment.searchId === null
        )
      }

      return (
        enrichment.enrichmentType === type &&
        enrichment.integrationKey === integrationKey &&
        ((listId ? enrichment.listId === listId : false) ||
          (searchId ? enrichment.searchId === searchId : false))
      )
    })
  }
  const createMutation = useMutation({
    mutationFn: async (enrichmentType: IntegrationEnrichmentType) => {
      const existingEnrichment = getEnrichment(enrichmentType)

      let res

      if (existingEnrichment) {
        res = await fetch("/api/integrations/recurring-enrichment", {
          method: "DELETE",
          body: JSON.stringify({
            enrichmentId: existingEnrichment.id,
          }),
        })
      } else {
        res = await fetch("/api/integrations/recurring-enrichment", {
          method: "POST",
          body: JSON.stringify({
            integrationKey,
            listId: listId ?? null,
            searchId: searchId ?? null,
            enrichmentType,
          }),
        })
      }

      if (!res.ok) {
        throw new Error("Failed to alter enrichment")
      }

      return res.json()
    },
    async onSuccess() {
      await queryClient.invalidateQueries(
        cacheKeys.integrationEnrichments(integrationKey)
      )

      queryClient.invalidateQueries(cacheKeys.userSavedSearches(product))
      queryClient.invalidateQueries(cacheKeys.userLists({ product }))
    },
  })

  const handleEnrichmentToggle = (type: IntegrationEnrichmentType) => {
    createMutation.mutate(type)
  }

  const EnrichmentItem = ({ type }: { type: IntegrationEnrichmentType }) => {
    const enrichment = getEnrichment(type)

    return (
      <Flex key={type} alignItems="center">
        <Text fontSize="xs" fontWeight="medium" pl={2}>
          {getIntegrationEnrichmentTypeLabel(type, product)}
          {type !== IntegrationEnrichmentType.new && " Signals"}
        </Text>
        <Spacer />
        <Button
          size="xs"
          colorScheme={enrichment ? "red" : undefined}
          variant={enrichment ? "solid" : "outline"}
          onClick={() => handleEnrichmentToggle(type)}
          isLoading={
            createMutation.isLoading && createMutation.variables === type
          }
          isDisabled={
            createMutation.isLoading && createMutation.variables === type
          }
        >
          {enrichment ? "Unsubscribe" : "Subscribe"}
        </Button>
      </Flex>
    )
  }

  const logo = integrationKey && getIntegrationLogo(integrationKey)

  return (
    <>
      <MenuItem
        onClick={modalDisclosure.onOpen}
        icon={integrationKey ? () => <Image src={logo} w={4} /> : TbCube}
      >
        Schedule Enrichment
      </MenuItem>

      <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Schedule Enrichment</ModalHeader>
          <ModalCloseButton />

          {integrationKey && (
            <ModalBody>
              <Text fontSize="sm" color="gray.600" mb={2}>
                Choose which updates from this search should be automatically
                pushed to{" "}
                {integrationKey
                  ? getIntegrationName(integrationKey)
                  : "your CRM"}
                .
              </Text>

              <Card p={2}>
                <VStack spacing={2} align="stretch" divider={<Divider />}>
                  {searchId && (
                    <EnrichmentItem type={IntegrationEnrichmentType.new} />
                  )}
                  {product === SpecterProducts.company && (
                    <EnrichmentItem
                      type={IntegrationEnrichmentType.new_growth_highlights}
                    />
                  )}
                  {product === SpecterProducts.company && (
                    <EnrichmentItem
                      type={IntegrationEnrichmentType.new_funding_highlights}
                    />
                  )}
                </VStack>
              </Card>

              <Box
                p={4}
                bgColor="brand.50"
                rounded="lg"
                borderWidth={1}
                borderColor="brand.100"
                mt={4}
              >
                <Text fontSize="sm" color="brand.500">
                  Note: Scheduled enrichments may affect your{" "}
                  {getIntegrationName(integrationKey)}
                  API usage, potentially reaching caps or using credits.
                </Text>
              </Box>
            </ModalBody>
          )}

          {!integrationKey && (
            <>
              <ModalBody>
                <Text fontSize="sm" color="gray.600" mb={2}>
                  Looks like you haven't set up a required integration for this{" "}
                  {searchId ? "search" : "list"}. Please configure your
                  integration before scheduling an enrichment.
                </Text>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="brand" as={Link} to="/integrations">
                  Go to Integrations
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
