import {
  AsyncCreatableProps,
  AsyncCreatableSelect,
  AsyncProps,
  AsyncSelect,
  chakraComponents,
  CreatableProps,
  CreatableSelect,
  GroupBase,
  MultiValue,
  MultiValueProps,
  MultiValueRemoveProps,
  OnChangeValue,
  Props,
  Select,
  SelectInstance,
} from "chakra-react-select"
import * as R from "ramda"
import {
  forwardRef,
  ReactElement,
  Ref,
  RefAttributes,
  useCallback,
} from "react"
import { HiNoSymbol, HiPlus, HiXMark } from "react-icons/hi2"
import { EXCLUDE_PREFIX } from "~/consts/signals"
import { Icon } from "~/utils/components/Icon"
import { isNullish } from "~/utils/values"

type CustomOption = { label: string; value: string }

export const CustomMultiValueRemove = (allowExclude?: boolean) => {
  const CustomMultiValueRemoveReturn = <
    Option = unknown,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    props: MultiValueRemoveProps<Option, IsMulti, Group>
  ): ReactElement | null => {
    const { data, selectProps } = props
    const { value } = data as CustomOption

    const isExcluded = value.startsWith(EXCLUDE_PREFIX)

    const onClickCustom = useCallback(() => {
      if (isNullish(selectProps) || !Array.isArray(selectProps.value)) return

      const newOption = {
        ...data,
        value: isExcluded
          ? value.replace(EXCLUDE_PREFIX, "")
          : `${EXCLUDE_PREFIX}${value}`,
      }

      const newValue: MultiValue<Option> = selectProps.value.map((option) =>
        option.value === value ? newOption : option
      )

      selectProps.onChange(newValue as OnChangeValue<Option, IsMulti>, {
        action: "select-option",
        option: newOption,
      })
    }, [selectProps, data, isExcluded, value])

    return (
      <>
        {allowExclude && (
          <chakraComponents.MultiValueRemove
            {...props}
            innerProps={{ ...props.innerProps, onClick: onClickCustom }}
          >
            <Icon as={isExcluded ? HiPlus : HiNoSymbol} boxSize={3.5} />
          </chakraComponents.MultiValueRemove>
        )}
        <chakraComponents.MultiValueRemove {...props}>
          <Icon as={HiXMark} />
        </chakraComponents.MultiValueRemove>
      </>
    )
  }

  return CustomMultiValueRemoveReturn
}

export const CustomMultiValue = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  ...props
}: MultiValueProps<Option, IsMulti, Group>): ReactElement => {
  const { data, selectProps } = props
  const { value } = data as CustomOption

  return (
    <chakraComponents.MultiValue
      {...props}
      selectProps={{
        ...selectProps,
        chakraStyles: {
          ...selectProps.chakraStyles,
          multiValue: (...args) => ({
            ...selectProps.chakraStyles?.multiValue?.(...args),
            ...(value.startsWith(EXCLUDE_PREFIX) && {
              bgColor: "red.200",
              textDecoration: "line-through",
            }),
          }),
        },
      }}
    />
  )
}

const CustomReactSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  {
    allowExclude,
    chakraStyles,
    ...props
  }: Props<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
      minW?: string
      allowExclude?: boolean
    },
  ref: Ref<SelectInstance<Option, IsMulti, Group>>
): ReactElement => {
  return (
    <Select
      ref={ref}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
        container: (provided) => ({
          ...provided,
          borderColor: "red",
          borderRadius: "0.5rem !important",
        }),
      }}
      menuPortalTarget={global.document?.body}
      menuPlacement="auto"
      menuPosition="fixed"
      size="sm"
      chakraStyles={{
        multiValue: (provided) => ({
          ...provided,
          whiteSpace: "pre",
        }),
        valueContainer: (provided) => ({
          ...provided,
          maxHeight: "300px",
          overflowY: "auto",
        }),
        indicatorSeparator() {
          return {
            display: "none",
          }
        },
        dropdownIndicator(provided) {
          return {
            ...provided,
            bg: "transparent",
            width: "auto",
            pl: 0,
            pr: 3,
          }
        },
        crossIcon(provided) {
          return {
            ...provided,
            color: "gray.400",
          }
        },
        container: (provided, ...args) => ({
          ...provided,
          cursor: "pointer",
          // @ts-ignore
          minW: props.minW,
          ...(chakraStyles?.container && {
            ...chakraStyles.container(provided, ...args),
          }),
        }),
        ...R.omit(["container"], chakraStyles),
      }}
      components={{
        MultiValueRemove: CustomMultiValueRemove(allowExclude),
        MultiValue: CustomMultiValue,
      }}
      {...props}
    />
  )
}

const CustomCreatableSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  {
    allowExclude,
    chakraStyles,
    ...props
  }: CreatableProps<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
      minW?: string
      allowExclude?: boolean
    },
  ref: Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  return (
    <CreatableSelect
      ref={ref}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
        container: (provided) => ({
          ...provided,
          borderColor: "red",
          borderRadius: "0.5rem !important",
        }),
      }}
      menuPortalTarget={global.document?.body}
      menuPlacement="auto"
      menuPosition="fixed"
      size="sm"
      chakraStyles={{
        multiValue: (provided) => ({
          ...provided,
          whiteSpace: "pre",
        }),
        valueContainer: (provided) => ({
          ...provided,
          maxHeight: "300px",
          overflowY: "auto",
        }),
        indicatorSeparator() {
          return {
            display: "none",
          }
        },
        dropdownIndicator(provided) {
          return {
            ...provided,
            bg: "transparent",
            width: "auto",
            pl: 0,
            pr: 3,
          }
        },
        crossIcon(provided) {
          return {
            ...provided,
            color: "gray.400",
          }
        },
        container: (provided, ...args) => ({
          ...provided,
          cursor: "pointer",
          // @ts-ignore
          minW: props.minW,
          ...(chakraStyles?.container && {
            ...chakraStyles.container(provided, ...args),
          }),
        }),
        ...R.omit(["container"], chakraStyles),
      }}
      components={{
        MultiValueRemove: CustomMultiValueRemove(allowExclude),
        MultiValue: CustomMultiValue,
      }}
      {...props}
    />
  )
}

const CustomAsyncCreatableSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  {
    allowExclude,
    chakraStyles,
    ...props
  }: AsyncCreatableProps<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
      minW?: string
      allowExclude?: boolean
    },
  ref: Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  return (
    <AsyncCreatableSelect
      ref={ref}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
        container: (provided) => ({
          ...provided,
          borderColor: "red",
          borderRadius: "0.5rem !important",
        }),
      }}
      menuPortalTarget={global.document?.body}
      menuPlacement="auto"
      menuPosition="fixed"
      size="sm"
      chakraStyles={{
        multiValue: (provided) => ({
          ...provided,
          whiteSpace: "pre",
        }),
        valueContainer: (provided) => ({
          ...provided,
          maxHeight: "300px",
          overflowY: "auto",
        }),
        indicatorSeparator() {
          return {
            display: "none",
          }
        },
        dropdownIndicator(provided) {
          return {
            ...provided,
            bg: "transparent",
            width: "auto",
            pl: 0,
            pr: 3,
          }
        },
        crossIcon(provided) {
          return {
            ...provided,
            color: "gray.400",
          }
        },
        container: (provided, ...args) => ({
          ...provided,
          cursor: "pointer",
          // @ts-ignore
          minW: props.minW,
          ...(chakraStyles?.container && {
            ...chakraStyles.container(provided, ...args),
          }),
        }),
        ...R.omit(["container"], chakraStyles),
      }}
      components={{
        MultiValueRemove: CustomMultiValueRemove(allowExclude),
        MultiValue: CustomMultiValue,
      }}
      {...props}
    />
  )
}

const CustomAsyncSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  {
    allowExclude,
    chakraStyles,
    ...props
  }: AsyncProps<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
      minW?: string
      allowExclude?: boolean
    },
  ref: Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  return (
    <AsyncSelect
      ref={ref}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
      }}
      menuPortalTarget={global.document?.body}
      size="sm"
      chakraStyles={{
        multiValue: (provided) => ({ ...provided, whiteSpace: "pre" }),
        indicatorSeparator() {
          return {
            display: "none",
          }
        },
        dropdownIndicator(provided) {
          return {
            ...provided,
            bg: "transparent",
            width: "auto",
            pl: 0,
            pr: 3,
          }
        },
        crossIcon(provided) {
          return {
            ...provided,
            color: "gray.400",
          }
        },
        noOptionsMessage: (provided) => ({
          ...provided,
          color: "gray.300",
          fontSize: "xs",
        }),
        loadingMessage: (provided) => ({
          ...provided,
          color: "gray.300",
          fontSize: "xs",
        }),
        menuList: (provided, { selectProps, options }) => {
          return {
            ...provided,
            display: "flex",
            flexDirection: "column",
            // If select has options but input is empty, show a hint that there's more if the user types
            ...(!selectProps.inputValue &&
              options.length > 0 && {
                _after: {
                  content: `'${
                    props.noOptionsMessage?.(selectProps) ??
                    "Type to see options..."
                  }'`,
                  color: "gray.400",
                  fontSize: "xs",
                  mt: 2,
                  alignSelf: "center",
                },
              }),
          }
        },
        ...chakraStyles,
      }}
      components={{
        MultiValueRemove: CustomMultiValueRemove(allowExclude),
        MultiValue: CustomMultiValue,
      }}
      {...props}
    />
  )
}

export const CreatableReactSelect = forwardRef(
  CustomCreatableSelect
) as typeof CustomCreatableSelect

export const ReactSelect = forwardRef(
  CustomReactSelect
) as typeof CustomReactSelect

export const AsyncCreatableReactSelect = forwardRef(
  CustomAsyncCreatableSelect
) as typeof CustomAsyncCreatableSelect

export const AsyncReactSelect = forwardRef(
  CustomAsyncSelect
) as typeof CustomAsyncSelect
export * from "./ComposedMenuSelect"
