import { Icon } from "~/utils/components/Icon"
import {
  Avatar,
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
  Text,
} from "@chakra-ui/react"
import { EntityStatus as EntityStatusEnum } from "@prisma/client"
import {
  getEntityStatusIcon,
  getEntityStatusColor,
  getEntityStatusLabel,
} from "~/utils/entityStatus"
import { ColoredTag } from "../ColoredTag"
import { make_timeago } from "~/utils/timeago"

interface Props {
  portal?: boolean
  status: {
    status?: EntityStatusEnum | null
    updated_at?: string | null
  } | null
  teamStatuses: {
    user: {
      first_name?: string | null
      last_name?: string | null
      avatar?: string | null
    }
    status?: EntityStatusEnum | null
    updated_at?: string | null
  }[]
}

const timeago = make_timeago()

const TeamStatuses = ({
  teamStatuses,
  status,
}: Pick<Props, "teamStatuses"> & { status: EntityStatusEnum }) => {
  if (teamStatuses.length <= 0) return null

  return (
    <Box pb={1.5}>
      <Flex alignItems="center" gap={1} pb="1px">
        <Icon
          as={getEntityStatusIcon(status)}
          boxSize={3}
          mt="-1px"
          color={getEntityStatusColor(status)}
        />
        <Text fontSize="xs" color={getEntityStatusColor(status)}>
          {getEntityStatusLabel(status)}
        </Text>
      </Flex>

      {teamStatuses.map((s, i) => {
        return (
          <Flex key={i} alignItems="flex-start" pl={3} pb="1px">
            <Box
              boxSize={2}
              borderBottomLeftRadius="3px"
              borderColor="gray.300"
              borderBottomWidth={1}
              borderLeftWidth={1}
            />
            <Avatar src={s.user.avatar ?? ""} boxSize={4} mr={1} />
            <Text fontSize="xs" color="gray.400" alignSelf="center">
              {s.user.first_name} {s.user.last_name?.[0].toUpperCase()}.{" "}
              <Tag
                size="xs"
                as="span"
                rounded="full"
                bgColor="white"
                borderWidth={1}
                borderColor="gray.200"
                fontSize="xx-small"
                px={1.5}
                py={0.5}
                color="gray.400"
              >
                {timeago.format(new Date(s.updated_at ?? ""))}
              </Tag>
            </Text>
          </Flex>
        )
      })}
    </Box>
  )
}

export const EntityStatusDot = ({
  status,
  teamStatuses = [],
  portal = false,
}: Props) => {
  const icon = getEntityStatusIcon(status?.status)
  const color = getEntityStatusColor(status?.status)
  const Root = portal ? Portal : Box

  return (
    <Popover trigger="hover" placement="bottom-start" offset={[-9, -10]}>
      <Box rounded="full" color={color} pos="relative" overflow="hidden">
        <PopoverTrigger>
          <Box
            top={0}
            left={0}
            w={0}
            h={0}
            _after={{
              content: '""',
              pos: "absolute",
              inset: 0,
              cursor: "pointer",
            }}
          />
        </PopoverTrigger>
        <Icon as={icon} display="block" boxSize="18px" />
      </Box>

      <Root>
        <PopoverContent
          p={0}
          rounded="xl"
          minW="0px"
          w="fit-content"
          overflow="hidden"
          zIndex={200000}
        >
          <Flex gap={1.5} alignItems="center" p={2}>
            <Icon
              as={icon}
              display="block"
              boxSize="18px"
              color={color}
              cursor="pointer"
            />

            <Box h={3} w="1px" bgColor="gray.200" />

            <ColoredTag
              fontSize="xs"
              color={getEntityStatusColor(status?.status)}
            >
              {!status?.status && "Not viewed yet"}
              {status?.status === EntityStatusEnum.viewed && "You viewed this"}
              {status?.status === EntityStatusEnum.liked && "You liked this"}
              {status?.status === EntityStatusEnum.disliked &&
                "You disliked this"}
              {status?.updated_at &&
                ` ${timeago.format(new Date(status.updated_at))}`}
            </ColoredTag>
          </Flex>

          <Box
            pt={1}
            px={3}
            bgColor="gray.50"
            borderTopWidth={1}
            borderColor="gray.200"
          >
            {[
              EntityStatusEnum.liked,
              EntityStatusEnum.disliked,
              EntityStatusEnum.viewed,
            ].map((status) => {
              return (
                <TeamStatuses
                  key={status}
                  status={status}
                  teamStatuses={teamStatuses.filter((s) => s.status === status)}
                />
              )
            })}

            {teamStatuses.length <= 0 && (
              <Text fontSize="xs" color="gray.400" pb={1.5}>
                Your team hasn't interacted
                <br />
                with this yet
              </Text>
            )}
          </Box>
        </PopoverContent>
      </Root>
    </Popover>
  )
}
