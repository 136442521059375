import { getProductSignalSearchParams } from "~/utils/hooks/useProductFilters"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { Box, Link, Text } from "@chakra-ui/react"
import { SemanticRelevanceEnum } from "~/utils/signal"
import { Values } from "~/utils/values"

export const SemanticRelevance = () => {
  const product = useProduct()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const allFilters = getProductSignalSearchParams(product, searchParams)
  const value =
    "semanticRelevance" in allFilters
      ? allFilters.semanticRelevance
      : SemanticRelevanceEnum.standard

  const updateSearch = (
    semanticRelevance: Values<typeof SemanticRelevanceEnum>
  ) => {
    setSearchParams({
      ...searchParams,
      query: JSON.stringify({
        ...allFilters,
        semanticRelevance,
      }),
    })
  }

  if (!("semanticSearch" in allFilters) && !("semanticRelevance" in allFilters))
    return null

  return (
    <>
      {value === SemanticRelevanceEnum.standard && (
        <Text fontSize="xs" color="gray.500">
          Not quite right? Try{" "}
          <Link
            color="brand.500"
            onClick={() => updateSearch(SemanticRelevanceEnum.strict)}
          >
            Precise
          </Link>{" "}
          or{" "}
          <Link
            color="brand.500"
            onClick={() => updateSearch(SemanticRelevanceEnum.broad)}
          >
            Broad
          </Link>
        </Text>
      )}

      {value === SemanticRelevanceEnum.strict && (
        <Text fontSize="xs" color="gray.500">
          Results too specific? Try{" "}
          <Link
            color="brand.500"
            onClick={() => updateSearch(SemanticRelevanceEnum.standard)}
          >
            Standard
          </Link>
        </Text>
      )}

      {value === SemanticRelevanceEnum.broad && (
        <Text fontSize="xs" color="gray.500">
          Results too broad? Try{" "}
          <Link
            color="brand.500"
            onClick={() => updateSearch(SemanticRelevanceEnum.strict)}
          >
            Precise
          </Link>
        </Text>
      )}

      <Box w="1px" h={4} bgColor="gray.200" />
    </>
  )
}
