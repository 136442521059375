import { Box } from "@chakra-ui/react"

export const IconCircleX = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 22 22" {...props}>
      <circle
        fill="none"
        strokeWidth="2"
        stroke="currentColor"
        cx="11"
        cy="11"
        r="10"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14,8l-6,6M8,8l6,6"
      />
    </Box>
  )
}
