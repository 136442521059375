import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Box,
  ModalCloseButton,
} from "@chakra-ui/react"
import { SemanticSearchForm } from "../SemanticSearchForm"

export const SemanticSearchModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalBody p={1}>
          <Box
            borderWidth={1}
            borderColor="gray.100"
            rounded="lg"
            overflow="hidden"
          >
            <ModalCloseButton zIndex={10} />
            <SemanticSearchForm onSubmit={onClose} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
