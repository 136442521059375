import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import numeral from "numeral"
import pluralize from "pluralize"
import { useState } from "react"
import { HiDownload } from "react-icons/hi"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { SEARCH_EXPORT_LIMIT } from "~/consts/signals"
import { Icon } from "~/utils/components/Icon"
import { getProductEntityName } from "~/utils/getProductName"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import invariant from "~/utils/invariant"
import { isNullish } from "~/utils/values"
import { Card } from "../Card"
import { ToggleButton } from "../Dashboard/ToggleButton"
import { TrialModal } from "../TrialModal"

interface Props {
  product: SpecterProducts
  signalsCount: number | null
}

export const SignalExport = ({ product, signalsCount }: Props): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const analytics = useAnalytics()
  const env = useEnv()
  const [searchParams] = useSafeSearchParams()

  const productRouteName = mapProductsToRouteName(product)

  const exportMutation = useMutation<string | null, unknown, number>(
    async (limit: number) => {
      const req = await fetch(
        `/api/signals/${productRouteName}/export?limit=${limit}`,
        {
          method: "POST",
          body: JSON.stringify(Object.fromEntries(searchParams.entries())),
        }
      )

      invariant(req.ok, "Failed to run your export request")

      const url = new URL(window.location.href)
      // If query is too big, search URL will not show the query params.
      const searchURL = `${env.APP_URL}/signals/${productRouteName}/feed${url.search}`

      analytics.track("Export", {
        product,
        type: "search",
        searchURL,
        query: searchParams.toString(),
        signalCount: Math.min(signalsCount ?? 0, limit),
      })

      const blob = await req.blob()

      const blobUrl = URL.createObjectURL(blob)

      return blobUrl
    },
    {
      onMutate() {
        onClose()
        toast({
          status: "info",
          title: "Your export is being created",
          description: "This may take a few minutes.",
        })
      },
      onSuccess(url) {
        if (url) {
          const link = document.createElement("a")
          link.href = url
          link.click()
        }
      },
    }
  )

  return (
    <>
      <Button
        flex="none"
        variant="outline"
        leftIcon={<Icon as={HiDownload} />}
        disabled={exportMutation.isLoading}
        isLoading={exportMutation.isLoading}
        onClick={onOpen}
        intercom-target="export"
      >
        Export
      </Button>

      <ExportModal
        isOpen={isOpen}
        onClose={onClose}
        onExport={exportMutation.mutate}
        isLoading={exportMutation.isLoading}
        signalsCount={signalsCount}
      />
    </>
  )
}

export const ExportModal = ({
  isOpen,
  onClose,
  onExport,
  isLoading,
  signalsCount,
}: {
  isOpen: boolean
  onClose: () => void
  onExport: (limit: number) => void
  isLoading: boolean
  signalsCount: number | null
}) => {
  const permissionsQuery = useUserPermissions()

  const isTrial = Boolean(
    permissionsQuery.data?.organization?.companyTrial ||
      permissionsQuery.data?.organization?.stratintelTrial ||
      permissionsQuery.data?.organization?.talentTrial
  )

  const isAdmin = permissionsQuery.data?.isAdmin
  const [adminExportLimit, setAdminExportLimit] = useState(SEARCH_EXPORT_LIMIT)

  const exportLimit = isAdmin ? adminExportLimit : SEARCH_EXPORT_LIMIT

  const maxExportCountFormatted = numeral(exportLimit).format("0,0")
  const signalsCountFormatted = numeral(signalsCount).format("0,0")

  const product = useProduct()
  const entityName = pluralize(getProductEntityName(product))
    .toLowerCase()
    .replaceAll(/ipos/g, "IPOs")

  return (
    <>
      <TrialModal
        product="Exports"
        isOpen={isTrial && isOpen}
        onClose={onClose}
      />

      <Modal isOpen={!isTrial && isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Export Results</ModalHeader>
          <ModalBody>
            <Text fontSize="sm" fontWeight="medium" mb={2}>
              You're exporting{" "}
              {isNullish(signalsCount) ? (
                <Spinner size="xs" />
              ) : signalsCount > exportLimit ? (
                `${maxExportCountFormatted} out of ${signalsCountFormatted}`
              ) : (
                signalsCountFormatted
              )}{" "}
              results.
            </Text>
            <Text fontSize="xs" color="gray.500">
              {`Export is limited to the first ${numeral(exportLimit).format(
                "0,0"
              )} results. If you'd like to
        export all results, please refine your search by including
        additional search filter(s).`}
            </Text>

            {isAdmin && (
              <Card
                p={2}
                px={3}
                pb={3}
                mt={2}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Text
                  as="span"
                  color="brand.500"
                  fontWeight="semibold"
                  fontSize="xs"
                >
                  Hey Admin!
                </Text>{" "}
                <Flex alignItems="center" gap={1} fontSize="xs">
                  Set limit to
                  <ToggleButton
                    groupProps={{ colorScheme: "brand" }}
                    buttonProps={{ fontSize: "smaller", px: 1, h: 5 }}
                    value={String(adminExportLimit)}
                    options={[
                      String(SEARCH_EXPORT_LIMIT),
                      "5000",
                      "10000",
                      "50000",
                    ]}
                    onChange={(newLimit) => {
                      setAdminExportLimit(Number(newLimit))
                    }}
                    format={(limit) => numeral(limit).format("0,0")}
                  />
                  {entityName}
                </Flex>
                <Text fontSize="xx-small" color="gray.400">
                  {`It may take longer to load...`}
                </Text>
              </Card>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button
                size="sm"
                variant="outline"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="brand"
                variant="solid"
                onClick={() => onExport(exportLimit)}
                disabled={isLoading}
                isLoading={isLoading}
              >
                Start export
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
