import { CSSObject } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { UseInfiniteQueryResult } from "@tanstack/react-query"

import { AgGridReact, AgGridReactProps } from "ag-grid-react"
import { useCallback, useMemo, useRef, useState } from "react"

import { FeedDataError, PermissionsError } from "~/components/Errors"
import { FeedLoadingLogo } from "~/components/Logo/loading"
import { ProductSignalFeedItems } from "~/routes/__protected/api/signals/$product"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { hasAccessToFeed } from "~/utils/hasAccessToFeed"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useSignalsQuery } from "~/utils/hooks/useSignalsQuery"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { isOneOf } from "~/utils/isOneOf"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { noop } from "~/utils/noop"
import { isPermissionAccessError } from "~/utils/permissionAccessError"
import { ClientSide } from "../ClientSide"
import { DeprecatedFieldsAlert } from "../DeprecatedFieldsAlert"
import { TableColumnData } from "./columnDefs"
import { MemoizedTable, TableConfigs } from "./MemoizedTable"

type Props<Config extends TableConfigs> = {
  config: Config
  rowData: any[] | undefined
  signalsQuery: UseInfiniteQueryResult<any>
  styleOverrides?: CSSObject
  agGridProps?: Omit<AgGridReactProps, "columnDefs">
  disableSelection?: boolean
  suppressLastInfoRow?: boolean
  isSelectable?: boolean
  setIsSelectable?: (isSelectable: boolean) => void
  selectedIds?: string[]
  setSelectedIds?: (selectedIds: string[]) => void
}

export default function ProductTable({
  isSelectable = false,
  setIsSelectable = noop,
  selectedIds = [],
  setSelectedIds = noop,
}: {
  isSelectable?: boolean
  setIsSelectable?: (isSelectable: boolean) => void
  selectedIds: string[]
  setSelectedIds: (selectedIds: string[]) => void
}) {
  const product = useProduct()
  const signalsQuery = useSignalsQuery(product, ViewMode.Table)

  const rowData = useMemo(
    () =>
      signalsQuery.data?.pages.flatMap(
        // @ts-ignore
        ({ items }) => items
      ) as unknown as ProductSignalFeedItems<typeof product> | undefined,
    [signalsQuery.data]
  )
  return (
    <ClientSide>
      <TableComponent
        config={product}
        rowData={rowData}
        signalsQuery={signalsQuery}
        isSelectable={isSelectable}
        setIsSelectable={setIsSelectable}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </ClientSide>
  )
}

export const TableComponent = <Config extends TableConfigs>({
  config,
  rowData,
  signalsQuery,
  styleOverrides,
  agGridProps,
  disableSelection,
  suppressLastInfoRow,
  setIsSelectable,
  setSelectedIds,
}: Props<Config>): JSX.Element => {
  const [searchParams] = useSafeSearchParams()
  const userPermissions = useUserPermissions()

  const [_isDirty, setIsDirty] = useState(false)

  const markTableDirty = useCallback(() => {
    setIsDirty(true)
  }, [])

  const agGridRef = useRef<AgGridReact | null>(null)

  const updateSelectedRows = (rows: TableColumnData<Config>[]) => {
    setSelectedIds?.(rows.map((row) => `${row.id}`))
    setIsSelectable?.(true)
  }

  if (userPermissions.isLoading) {
    return <FeedLoadingLogo />
  }

  if (
    isOneOf(config, Object.values(SpecterProducts)) &&
    !hasAccessToFeed(userPermissions.data, config)
  ) {
    return <PermissionsError product={config} />
  }

  if (signalsQuery.isError) {
    if (
      isOneOf(config, Object.values(SpecterProducts)) &&
      isPermissionAccessError(signalsQuery.error)
    ) {
      return <PermissionsError product={config} />
    }

    const query = JSONSafeParse<Record<string, any>>(searchParams.get("query"))
    const deprecatedFields = isOneOf(config, Object.values(SpecterProducts))
      ? getDeprecatedFields(config, query)
      : []

    if (deprecatedFields.length > 0) {
      return (
        <DeprecatedFieldsAlert
          deprecatedFields={deprecatedFields}
          query={query}
        />
      )
    }

    return <FeedDataError />
  }

  return (
    <MemoizedTable
      config={config}
      rowData={rowData}
      onChange={markTableDirty}
      updateSelectedRows={updateSelectedRows}
      agGridRef={agGridRef}
      hasNextPage={signalsQuery.hasNextPage}
      isFetchingNextPage={signalsQuery.isFetchingNextPage}
      fetchNextPage={signalsQuery.fetchNextPage}
      isLoading={signalsQuery.isLoading}
      styleOverrides={styleOverrides}
      agGridProps={agGridProps}
      disableSelection={disableSelection}
      suppressLastInfoRow={suppressLastInfoRow}
    />
  )
}
