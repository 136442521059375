import { SpecterProducts } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"
import invariant from "~/utils/invariant"

import { AddedToList } from "~/routes/__protected/api/lists/$product/added-to-list/$signalId"
import { cacheKeys } from "../cacheKeys"
import { GetStrategicSignalByID } from "../db/getStrategicSignalByID"
import { ProductDetailItem, ProductItem } from "~/components"

export const useSignalAddedToList = <P extends SpecterProducts>(
  signal: ProductItem<P> | ProductDetailItem<P>,
  product: P
) => {
  const addedToListQuery = useQuery<AddedToList>(
    cacheKeys.signalAddedToList(signal.id),
    async () => {
      const res = await fetch(
        `/api/lists/${product}/added-to-list/${signal.id}`
      )

      invariant(res.ok, "Failed to load signal list status")

      return await res.json()
    },
    {
      refetchOnMount: false,
      initialData() {
        // addedToList may be precomputed on the signal
        if ("addedToList" in signal) {
          return {
            isAddedToList: signal.addedToList,
          }
        }

        const detailSignal = signal as GetStrategicSignalByID

        if (detailSignal?.lists?.length) {
          return {
            isAddedToList: true,
          }
        } else {
          return {
            isAddedToList: false,
          }
        }
      },
    }
  )

  return {
    addedToList: addedToListQuery.data?.isAddedToList ?? false,
  }
}
