import { Text, TextProps } from "@chakra-ui/react"
import { ReactNode } from "react"
import { useBgColor } from "~/utils/hooks/useBgColor"

interface Props extends TextProps {
  value: number | boolean | string | ReactNode
  color?: TextProps["bgColor"]
  placement?: "top-right" | "bottom-right" | "top-left" | "bottom-left"
}

const placementMap = {
  "top-right": {
    top: 0,
    right: 0,
    transform: "translate(40%, -40%)",
  },
  "bottom-right": {
    bottom: 0,
    right: 0,
    transform: "translate(30%, 30%)",
  },
  "top-left": {
    top: 0,
    left: 0,
    transform: "translate(-40%, -40%)",
  },
  "bottom-left": {
    bottom: 0,
    left: 0,
    transform: "translate(-30%, 30%)",
  },
}

export const NotificationBadge = ({
  value,
  color = "red.600",
  placement = "top-right",
  ...props
}: Props): JSX.Element | null => {
  const bgColor = useBgColor()

  if ((typeof value === "number" && value <= 0) || !value) {
    return null
  }

  const valueString = typeof value === "boolean" ? "" : value

  const placementProps = placementMap[placement]
  return (
    <Text
      as="span"
      position="absolute"
      // top={0}
      // right={0}
      // transform="translate(40%, -40%)"
      {...placementProps}
      zIndex={10}
      pointerEvents="none"
      bgColor={color}
      px="2px"
      minW={4}
      minH={4}
      textAlign="center"
      borderWidth={2}
      borderColor={bgColor}
      rounded="full"
      fontSize="0.55rem"
      color="white"
      fontWeight="semibold"
      display="inline-flex"
      lineHeight={1.1}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {valueString}
    </Text>
  )
}
