import { useEffect } from "react"

export const useShortcut = (
  keys: string,
  callback: () => void,
  useCmd: boolean = false,
  deps: any[] = []
) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    // Check if the pressed key combination matches the shortcut
    const isCmd = event.metaKey || event.ctrlKey
    const pressedKey = event.key.toLowerCase()
    const targetKey = keys.toLowerCase()

    if ((useCmd ? isCmd : true) && pressedKey === targetKey) {
      event.preventDefault()
      event.stopPropagation()
      callback()
    }
  }

  // Add event listener when component mounts
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, deps)

  return handleKeyDown
}
