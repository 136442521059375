import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import {
  AcquisitionType,
  FundingType,
  GrowthStage,
  Investor,
} from "@prisma/client"
import { ColDefField, ICellRendererParams } from "ag-grid-community"
import { format } from "date-fns"
import numeral from "numeral"
import { HiLibrary, HiOutlineCalendar, HiStar } from "react-icons/hi"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import {
  CompanyLogo,
  getSeniorityColor,
  getStratintelHotness,
  NotificationBadge,
  OperatingStatusInfo,
  SearchesAndListsProducts,
  TagIndustry,
} from "~/components"
import { ColoredTag } from "~/components/ColoredTag"
import { getGrowthOperator } from "~/components/CompanySignalDetailPage/utils"
import {
  ACQUISITION_TYPE,
  companyGrowthBadgeTheme,
  COMPANY_HIGHLIGHTS_GROUPS,
  COMPANY_HIGHLIGHTS_PROPS,
  COMPANY_SIZE_OPTIONS,
  FUNDING_TYPE,
  GROWTH_STAGE_LABEL,
  INVESTOR_HIGHLIGHTS_PROPS,
  strategicGrowthBadgeTheme,
} from "~/consts/signals"
import { Icon } from "~/utils/components/Icon"

import { Link as RemixLink } from "@remix-run/react"
import TimeAgo from "javascript-time-ago"
import { ReactNode, useMemo } from "react"
import { HiFire } from "react-icons/hi2"
import { TRUSTPILOT_LOGO_URL } from "~/consts/data3rdParties"
import { currentYear } from "~/utils/datetime"
import { Position } from "~/utils/db/peopleDBSchemas"
import {
  CompanyAward,
  G2Product,
  TrustpilotData,
} from "~/utils/db/queries/company/types"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { isDateValid } from "~/utils/isDateValid"
import { OperatingStatus } from "~/utils/signal"
import {
  sortCompanyHighlights,
  sortInvestorHighlights,
} from "~/utils/sortHighlights"
import { formatDate } from "~/utils/string/format"
import { formatMoney, formatSeniority } from "~/utils/string/formatters"
import { isNullish } from "~/utils/values"
import { RatingDistribution } from "../CompanySignalDetailPage/Tabs/Product"
import { TableColumnData } from "./columnDefs"
import { TableConfigs } from "./MemoizedTable"

export type CellRendererProps<Config extends TableConfigs> = Pick<
  ICellRendererParams<TableColumnData<Config>>,
  "value" | "data" | "colDef" | "column"
>

export type CellRenderer<Config extends TableConfigs> = (
  props: CellRendererProps<Config>
) => JSX.Element | string | null

const timeAgo = new TimeAgo("en-GB")

export function RenderBoolean<Config extends TableConfigs>({
  value,
}: Pick<CellRendererProps<Config>, "value">) {
  if (isNullish(value)) return null
  return value ? "Yes" : "No"
}

export function RenderNumberTag<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      height="fit-content"
      minW="fit-content"
    >
      {numeral(value).format("0,[00]a").toUpperCase()}
    </Tag>
  )
}

export function RenderNumberTagFire<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  const isOnFire = value > 1
  return (
    <Tag
      variant={"subtle"}
      rounded="full"
      verticalAlign="text-bottom"
      height="fit-content"
      colorScheme={isOnFire ? "green" : "gray"}
      minW="fit-content"
    >
      {numeral(value).format("0,[00]a").toUpperCase()}
    </Tag>
  )
}

export function RenderNumber<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return <Text fontSize="xs">{numeral(value).format("0,0")}</Text>
}

export function RenderArray<Config extends TableConfigs>({
  value,
  data,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>

  // Guard against a possible string for backwards compat
  if (typeof value == "string") {
    return RenderText({ value, data })
  }

  const newValue = (value as unknown as Array<any>).join(", ")
  return RenderText({ value: newValue, data })
}

export function RenderArrayTag<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>

  // Guard against a possible string for backwards compat
  const valueToRender = typeof value == "string" ? [value] : [...new Set(value)]

  return (
    <>
      {(valueToRender as unknown as Array<any>).map((val) => (
        <Tag
          key={val}
          verticalAlign="text-bottom"
          rounded="full"
          pr="4px"
          minW="fit-content"
        >
          {val}
        </Tag>
      ))}
    </>
  )
}

export function RenderSeconds<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      minW="fit-content"
    >
      {numeral(value).format("0,[00]a").toUpperCase()}s
    </Tag>
  )
}

export function RenderDecimal<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      minW="fit-content"
    >
      {numeral(value).format("0.[00]a")}
    </Tag>
  )
}

export function RenderDelta<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      colorScheme={value <= 0 ? "red" : "green"}
      minW="fit-content"
    >
      {getGrowthOperator(Number(value))}
      {numeral(value).format("0,[00]a").toUpperCase()}
    </Tag>
  )
}

export function RenderPercentageDelta<Config extends TableConfigs>({
  value,
  invert = false,
}: CellRendererProps<Config> & { invert?: boolean }) {
  if (isNullish(value)) return <></>

  function color(value: number) {
    if ((value > 0 && !invert) || (value < 0 && invert)) return "green"
    if ((value < 0 && !invert) || (value > 0 && invert)) return "red"
    return "gray"
  }

  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      colorScheme={color(Number(value))}
      minW="fit-content"
    >
      {getGrowthOperator(Number(value))}
      {numeral(Number(value)).format("0,[00]a").toUpperCase()}%
    </Tag>
  )
}

export function RenderPercentageDeltaInverted<Config extends TableConfigs>({
  value,
  data,
}: CellRendererProps<Config>) {
  return RenderPercentageDelta({ value, data, invert: true })
}

export function RenderPercentage<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>

  return <Text fontSize="xs">{value}%</Text>
}

export function RenderPercentageTag<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>

  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      colorScheme="purple"
      minW="fit-content"
    >
      {value}%
    </Tag>
  )
}

export function RenderStarredNumber<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return ""
  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      colorScheme="yellow"
      minW="fit-content"
    >
      <Icon as={HiStar} /> {numeral(value).format("0.[0]a").toUpperCase()}
    </Tag>
  )
}

export function RenderTitleCaseCommaString<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return ""

  if (Array.isArray(value)) {
    return (
      <RenderStringSeparated
        value={value.map((v) => titleCase(sentenceCase(v))).join(";")}
        separator={";"}
      />
    )
  }

  return (
    <RenderStringSeparated
      value={titleCase(sentenceCase(String(value)))}
      separator={","}
    />
  )
}

export function RenderTitleCase<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return ""
  return <Text fontSize="xs">{titleCase(sentenceCase(String(value)))}</Text>
}

export function RenderOperatingStatus<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return (
    <OperatingStatusInfo
      operatingStatus={value as OperatingStatus}
      noBlinking
    />
  )
}

export function RenderDate<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value) || !isDateValid(value)) return <></>

  return <Text fontSize="xs">{formatDate(new Date(value))}</Text>
}

export function RenderMonth<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value) || !isDateValid(value)) return <></>
  // @ts-ignore
  return <Text fontSize="xs">{format(new Date(value), "MMMM")}</Text>
}

export function RenderDollar<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return <Text fontSize="xs">{formatMoney(value)}</Text>
}

export function RenderText<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>

  if (!isNaN(+value)) {
    return (
      <Text fontSize="xs" as="span">
        {numeral(Number(value)).format("0,0")}
      </Text>
    )
  }

  return (
    <Text fontSize="xs" as="span">
      {value}
    </Text>
  )
}

export function RenderPlainText<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>

  return (
    <Text fontSize="xs" as="span">
      {value}
    </Text>
  )
}

export function RenderTextTag<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return (
    <Tag
      variant="outline"
      rounded="full"
      verticalAlign="text-bottom"
      minW="fit-content"
    >
      {value}
    </Tag>
  )
}

export function RenderTitleCaseTag<Config extends TableConfigs>({
  value,
  data,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return <></>
  return <RenderTextTag value={titleCase(sentenceCase(value))} data={data} />
}

function RenderStringSeparated({
  value,
  separator,
}: {
  value: string
  separator: string
}) {
  return (
    <>
      {String(value)
        .split(separator)
        .map((industry) => (
          <TagIndustry key={industry.trim()} label={industry.trim()} />
        ))}
    </>
  )
}

export function RenderSemicolonString<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return ""
  return <RenderStringSeparated value={String(value)} separator={";"} />
}

export function RenderTimeAgo<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (!value) return ""
  return timeAgo.format(new Date(value))
}

export function RenderCommaString<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return ""

  if (Array.isArray(value)) {
    return <RenderStringSeparated value={value.join(";")} separator={";"} />
  }

  return <RenderStringSeparated value={String(value)} separator={","} />
}

export function RenderAge<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return ""
  return <Text fontSize="xs">{currentYear - Number(value)}</Text>
}

export function RenderGrowthStage<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  const color =
    companyGrowthBadgeTheme[value as keyof typeof companyGrowthBadgeTheme] ??
    strategicGrowthBadgeTheme[value as keyof typeof strategicGrowthBadgeTheme]

  return (
    <Flex h="full" alignItems="center">
      <ColoredTag color={color}>
        {GROWTH_STAGE_LABEL[value as GrowthStage]}
      </ColoredTag>
    </Flex>
  )
}

export function RenderAwards<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return null

  return value.map(
    ({ award_year, award_name, award_details, logo_url }: CompanyAward) => (
      <Tag
        key={`${award_name}-${award_year}-${award_details}`}
        rounded="full"
        verticalAlign="text-bottom"
        minW="fit-content"
      >
        <Image src={logo_url ?? ""} w="12px" />
        <Text as="span" fontWeight="semibold" ml={1}>
          {award_name} - {award_year}
        </Text>
        : {award_details}
      </Tag>
    )
  )
}

export function RenderG2Data<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return null

  return value.map(
    ({
      product_id,
      product_name,
      product_logo,
      rating,
      reviews,
      star_distribution,
    }: G2Product) => (
      <Tooltip
        p={4}
        isDisabled={isNullish(rating)}
        label={
          <RatingDistribution
            starDistribution={star_distribution}
            reviews={reviews ?? 0}
          />
        }
        key={product_id}
      >
        <Tag
          rounded="full"
          verticalAlign="text-bottom"
          mr={1}
          display="inline-flex"
          gap={1}
          minW="fit-content"
        >
          <Image src={product_logo ?? ""} w="12px" />
          {product_name}
          {!isNullish(rating) && (
            <Flex alignItems="center">
              <Icon as={HiStar} color="yellow.500" mb="1px" />
              {rating}
            </Flex>
          )}
        </Tag>
      </Tooltip>
    )
  )
}

export function RenderTrustpilotData<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return null

  const { name, logo_url, review_count, review_aggregate, reviews_average } =
    value as TrustpilotData

  return (
    <Tooltip
      p={4}
      isDisabled={isNullish(review_aggregate)}
      label={
        <RatingDistribution
          starDistribution={review_aggregate}
          reviews={review_count ?? 0}
        />
      }
      key={name}
    >
      <Tag
        rounded="full"
        verticalAlign="text-bottom"
        mr={1}
        display="inline-flex"
        gap={1}
        minW="fit-content"
      >
        <Image
          src={logo_url ?? ""}
          w="12px"
          onError={(e) => (e.currentTarget.src = TRUSTPILOT_LOGO_URL)}
        />
        {name}
        {!isNullish(reviews_average) && (
          <Flex alignItems="center">
            <Icon as={HiStar} color="yellow.500" mb="1px" />
            {reviews_average ?? "-"}
          </Flex>
        )}
      </Tag>
    </Tooltip>
  )
}

export function RenderCompanyHighlights({
  value,
  data,
}: CellRendererProps<"company">) {
  if (isNullish(value) || !Array.isArray(value)) return null

  return (
    <>
      {sortCompanyHighlights(value)?.map((highlight) => {
        if (!COMPANY_HIGHLIGHTS_PROPS[highlight]) return null

        const { group, label } = COMPANY_HIGHLIGHTS_PROPS[highlight]
        const { colorScheme } = COMPANY_HIGHLIGHTS_GROUPS[group]

        const isNew = !!(
          data &&
          data.newHighlights &&
          data.newHighlights.includes(highlight)
        )

        const highlightProps = { label, colorScheme, isNew }

        return <RenderHighlight key={highlight} {...highlightProps} />
      })}
    </>
  )
}

export function RenderInvestorHighlights({
  value,
}: CellRendererProps<"investors">) {
  if (isNullish(value) || !Array.isArray(value)) return null

  return (
    <>
      {sortInvestorHighlights(value)?.map(({ highlight, isNew }) => {
        if (!INVESTOR_HIGHLIGHTS_PROPS[highlight]) return null

        const { colorScheme, label } = INVESTOR_HIGHLIGHTS_PROPS[highlight]
        const highlightProps = { label, colorScheme, isNew }

        return <RenderHighlight key={highlight} {...highlightProps} />
      })}
    </>
  )
}

function RenderHighlight({
  label,
  colorScheme,
  isNew,
}: {
  label: string
  colorScheme: string
  isNew: boolean
}) {
  return (
    <Tag
      rounded="full"
      colorScheme={colorScheme}
      verticalAlign="text-bottom"
      whiteSpace="nowrap"
      position="relative"
      minW="min-content"
    >
      {isNew && <NotificationBadge value="!" color={`${colorScheme}.400`} />}
      {label}
    </Tag>
  )
}

export function RenderSeniority<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (value) {
    return (
      <Tag
        verticalAlign="text-bottom"
        colorScheme={getSeniorityColor(value)}
        rounded="full"
        minW="fit-content"
      >
        {formatSeniority(value)}
      </Tag>
    )
  }
  return ""
}

export function RenderFundingType<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (!value) return ""

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((v) => (
          <TagIndustry key={v} label={FUNDING_TYPE[v as FundingType]} />
        ))}
      </>
    )
  }

  return <TagIndustry label={FUNDING_TYPE[value as FundingType]} />
}

export function RenderAcquisitionType<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (!value) return ""

  return <TagIndustry label={ACQUISITION_TYPE[value as AcquisitionType]} />
}

export function RenderCompanySize<Config extends TableConfigs>({
  value,
  data,
}: CellRendererProps<Config>) {
  if (!value) return ""

  return (
    <RenderText
      value={COMPANY_SIZE_OPTIONS[value as keyof typeof COMPANY_SIZE_OPTIONS]}
      data={data}
    />
  )
}

export function RenderInvestors<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value) || !value.length) return null

  return value.map(({ investor }: { investor: Investor }) => (
    <Tag
      key={investor.id}
      verticalAlign="text-bottom"
      rounded="full"
      as={RemixLink}
      to={`/signals/investors/feed/${investor.id}/f`}
      minW="fit-content"
    >
      {investor.name}
    </Tag>
  ))
}

export type GrowthFields<Config extends TableConfigs> = {
  field: ColDefField<TableColumnData<Config>>
  timeSpan: string
}[]

type FactoryRenderValueGrowthsProps<Config extends TableConfigs> = {
  RendererValue?: ({ value }: CellRendererProps<Config>) => JSX.Element
  RendererGrowth?: ({ value }: CellRendererProps<Config>) => JSX.Element
  inverted?: boolean
}

export function getDeepValue<T = any>(
  data: Record<string, any>,
  field?: string
) {
  if (!field) return undefined
  return field.split(".").reduce((acc, curr) => acc?.[curr], data) as T
}

export function FactoryRenderValueGrowths<Config extends TableConfigs>({
  RendererValue,
  RendererGrowth,
  inverted,
}: FactoryRenderValueGrowthsProps<Config> = {}) {
  return function RenderValueGrowths({
    data,
    colDef,
  }: CellRendererProps<Config>) {
    if (isNullish(data)) return null

    const value = getDeepValue<number | null>(data, colDef?.field)

    if (isNullish(value)) return null

    const RenderValue = RendererValue ?? RenderNumber
    const RenderGrowth = RendererGrowth ?? RenderPercentageDelta

    const growths: GrowthFields<Config> =
      colDef?.["growthFields" as keyof typeof colDef] ?? []

    return (
      <Grid
        templateColumns={`repeat(${growths.length + 1}, 1fr)`}
        columnGap={2}
        rowGap={0}
        height="100%"
      >
        <GridItem
          as={Flex}
          justifyContent="flex-end"
          alignItems="center"
          minW="40px"
        >
          <RenderValue value={value} data={data} />
        </GridItem>
        {growths.map(({ field, timeSpan }) => {
          let value = getDeepValue<number | null>(data, field)

          if (!isNullish(value) && field.endsWith("_ratio"))
            value = (value - 1) * 100

          return (
            <GridItem
              key={`${field}-${timeSpan}`}
              as={Flex}
              justifyContent="flex-end"
              alignItems="center"
              minW="40px"
            >
              <RenderGrowth<Config>
                value={value}
                invert={inverted}
                data={data}
              />
            </GridItem>
          )
        })}
      </Grid>
    )
  }
}

export function RenderInternalLink<Config extends TableConfigs>(
  getProps: (cell: CellRendererProps<Config>) => {
    to: string
    children: ReactNode
  }
) {
  const Renderer = ({ data, value }: CellRendererProps<Config>) => {
    if (isNullish(data)) return <></>

    const { to, children } = getProps({ data, value })

    return (
      <Link
        fontSize="xs"
        display="flex"
        gap={2}
        alignItems="center"
        as={RemixLink}
        to={to}
        onClick={(e) => e.stopPropagation()}
        overflow="hidden"
        flexGrow={1}
        px={2}
        _hover={{
          textDecoration: "underline",
          _after: {
            content: '"↗"',
            position: "absolute",
            right: 2,
            top: "50%",
            transform: "translateY(-50%)",
            w: "24px",
            h: "24px",
            lineHeight: "24px",
            textAlign: "center",
            border: "1px solid",
            borderColor: "gray.200",
            borderRadius: "md",
            bgColor: "white",
            _hover: {
              bgColor: "red",
            },
          },
        }}
      >
        {children}
      </Link>
    )
  }

  return Renderer
}

export function RenderCompanyNameAndLogo({
  data,
  value,
}: CellRendererProps<"company">) {
  const [searchParams] = useSafeSearchParams()

  const { hotFactor, hotCount } = getStratintelHotness(
    data?.hot_companies,
    searchParams
  )

  const Renderer = useMemo(
    () =>
      RenderInternalLink<"company">(({ data }) => ({
        to: `./${data?.id}${location.search}`,
        children: (
          <>
            <CompanyLogo
              domain={data?.domain ?? ""}
              source={data?.logoUrl || undefined}
              size={6}
            />

            {data?.name}
          </>
        ),
      })),
    []
  )

  return (
    <Flex alignItems="center" height="100%" gap={1}>
      <Renderer data={data} value={value} />
      {hotFactor && !!hotCount && (
        <Tooltip
          label={`Increased Investor Interest\n${hotCount} Signals in the last ${hotFactor} days`}
          whiteSpace="pre"
        >
          <Tag
            display="inline-flex"
            alignItems="center"
            colorScheme="orange"
            fontSize="xs"
            fontWeight="semibold"
            verticalAlign="text-bottom"
            minW="fit-content"
          >
            <Icon as={HiFire} color="orange.500" mb="1px" />
            {hotCount}
          </Tag>
        </Tooltip>
      )}
    </Flex>
  )
}

export function RenderTalentNameAndAvatar({
  data,
  value,
}: CellRendererProps<"talent">) {
  const Renderer = useMemo(
    () =>
      RenderInternalLink<"talent">(({ data }) => ({
        to: `./${data?.id}${location.search}`,
        children: (
          <>
            <Avatar
              src={data?.profile_image_url ?? undefined}
              icon={<Image borderRadius="full" src="/specter-blank.jpg" />}
              size="sm"
              verticalAlign="middle"
              mr={1}
            />
            {data?.full_name}
          </>
        ),
      })),
    []
  )

  return <Renderer data={data} value={value} />
}

export function RenderPeopleNameAndAvatar({
  data,
  value,
}: CellRendererProps<"people">) {
  const Renderer = useMemo(
    () =>
      RenderInternalLink<"people">(({ data }) => ({
        to: `./${data?.id}${location.search}`,
        children: (
          <>
            <Avatar
              src={data?.profile_image_url ?? undefined}
              icon={<Image borderRadius="full" src="/specter-blank.jpg" />}
              size="sm"
              verticalAlign="middle"
              mr={1}
            />
            {data?.full_name}
          </>
        ),
      })),
    []
  )

  return <Renderer data={data} value={value} />
}

export function RenderStratintelNameAndAvatar({
  data,
  value,
}: CellRendererProps<"stratintel">) {
  const [searchParams] = useSafeSearchParams()

  const { hotFactor, hotCount } = getStratintelHotness(
    data?.StratintelMulti,
    searchParams
  )

  const Renderer = useMemo(
    () =>
      RenderInternalLink<"stratintel">(({ data }) => ({
        to: `./${data?.id}${location.search}`,
        children: (
          <>
            {data?.SignalType === "Company" && data?.Website && (
              <CompanyLogo domain={data.Website} size={8} />
            )}
            {data?.SignalType === "Talent" && (
              <Avatar
                src={data?.Screenshot ?? undefined}
                w={8}
                h={8}
                borderRadius="full"
              />
            )}
            {data?.Name}
            {hotFactor && (
              <Tooltip
                label={`Featured ${hotCount} times\nIn the last ${hotFactor} days`}
                whiteSpace="pre"
              >
                <Box display="inline-block" ml="auto" mt="0.2rem">
                  <Tag
                    display="inline-flex"
                    alignItems="center"
                    colorScheme="orange"
                    fontSize="xs"
                    fontWeight="semibold"
                    verticalAlign="text-bottom"
                    minW="fit-content"
                  >
                    <Icon as={HiFire} color="orange.500" mb="1px" />
                    {hotCount}
                  </Tag>
                </Box>
              </Tooltip>
            )}
          </>
        ),
      })),
    [hotCount, hotFactor]
  )

  return <Renderer data={data} value={value} />
}

export function RenderTagDate<Config extends TableConfigs>({
  value,
}: CellRendererProps<Config>) {
  if (isNullish(value)) return null

  return (
    <Flex
      display="inline-flex"
      rounded="full"
      borderWidth={1}
      borderColor="gray.100"
      alignItems="center"
      gap={1}
      px={1.5}
      fontSize="xx-small"
      py={0.5}
    >
      <Icon as={HiOutlineCalendar} color="gray.400" />
      <Text flex="none" fontWeight="medium" lineHeight="normal">
        {format(new Date(value), "MMM d, yyyy")}
      </Text>
    </Flex>
  )
}

export function RenderExperience({ isCurrent }: { isCurrent: boolean }) {
  const Renderer = ({ data }: CellRendererProps<"people" | "talent">) => {
    const positions: Position[] = data?.experience ?? []

    return (
      <>
        {positions
          .filter((position) =>
            isCurrent ? position.is_current : !position.is_current
          )
          .map((position) => {
            const { specter_company_id, company_name, domain, title } = position

            return (
              <Tag
                display="inline-flex"
                key={specter_company_id ?? company_name}
                gap={1}
                minW="min-content"
                borderRadius="full"
              >
                <CompanyLogo domain={domain ?? ""} size={4} />
                <Text as="span" fontWeight="bold">
                  {company_name}
                </Text>
                ·<Text as="span">{title}</Text>
              </Tag>
            )
          })}
      </>
    )
  }

  return Renderer
}

export function RenderEducation({
  data,
}: CellRendererProps<"people" | "talent">) {
  const education = data?.education ?? []

  return (
    <>
      {education.map((item) => {
        const { school_name, logo_url, degree, field_of_study } = item

        return (
          <Tag
            display="inline-flex"
            key={school_name ?? school_name}
            gap={1}
            minW="min-content"
            borderRadius="full"
          >
            <CompanyLogo source={logo_url} fallbackIcon={HiLibrary} size={4} />
            <Text as="span" fontWeight="bold">
              {school_name}
            </Text>
            ·
            <Text as="span">
              {degree} {degree && field_of_study && "·"} {field_of_study}
            </Text>
          </Tag>
        )
      })}
    </>
  )
}

export const RenderSearchListCreator = <
  Config extends
    | `saved-searches.${SearchesAndListsProducts}`
    | `user-lists.${SearchesAndListsProducts}`
>({
  value,
  data: _data,
}: CellRendererProps<Config>) => {
  return (
    <Text
      fontSize="xs"
      as="span"
      display="inline-flex"
      alignItems="center"
      gap={1}
    >
      {value}
    </Text>
  )
}
