import {
  Avatar,
  Button,
  ButtonGroup,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react"

import { GrowthStage, SpecterProducts } from "@prisma/client"
import { Link as RemixLink, useLocation } from "@remix-run/react"
import { format } from "date-fns"
import numeral from "numeral"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import {
  LastFundingStatus,
  SignalStatusCompany,
  SignalStatusStrategic,
  StrategicSignalTitle,
} from "~/components"
import { LogosGrid } from "~/components/LogosGrid"
import { ActionMenuIcons, SignalCard } from "~/components/SignalCard"
import { ButtonsStrategic, ProductItem } from "~/components/SignalCard/Buttons"
import { CardBox } from "~/components/SignalCard/CardBox"
import { StrategicGrowthBadge } from "~/components/SignalCard/GrowthBadge"
import { SignalIndustry } from "~/components/SignalIndustry"
import { allStrategicSignalType } from "~/consts/strategicSignals"
import { CompanySignalFeedItem } from "~/utils/db/queries/company/types"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import invariant from "~/utils/invariant"
import { isDateValid } from "~/utils/isDateValid"
import { isOneOf } from "~/utils/isOneOf"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { getSignalDate } from "~/utils/signalDate"
import { isNullish } from "~/utils/values"
import { ColoredTag } from "../ColoredTag"
interface Props {
  signal: ProductItem<"stratintel">
  customLink?: string | null
  withReducedInfo?: boolean
}

function TalentTypeCardBoxes({ signal }: Props) {
  return (
    <>
      <CardBox title="Founded" value={signal.Founded ?? "N/A"}>
        <StrategicGrowthBadge
          growthStage={signal.growthStage || GrowthStage.pre_launch}
        />
      </CardBox>
      <CardBox
        title="Total Funding"
        value={
          (signal.TotalFundingAmount ?? 0) > 0
            ? `$${numeral(signal.TotalFundingAmount).format("0,0a")}`
            : "N/A"
        }
      >
        <LastFundingStatus
          lastFundingAmount={signal.LastFundingAmount}
          lastFundingDate={signal.LastFundingDate}
          totalFundingAmount={signal.TotalFundingAmount}
        />
      </CardBox>
      {signal.Investors && (
        <CardBox title="Investors">
          {isNullish(signal.Investors) ? (
            <Text fontSize="sm" color="gray.500">
              No investor information found.
            </Text>
          ) : (
            <Flex gap={1}>
              {signal.Investors.split(",")
                .slice(0, 2)
                .map((investor: string) => (
                  <ColoredTag key={investor} color="gray.500">
                    {investor}
                  </ColoredTag>
                ))}
              {signal.Investors.split(",").length > 2 && (
                <Text fontSize="xs" fontWeight={"semibold"}>
                  +{signal.Investors.split(",").length - 2}
                </Text>
              )}
            </Flex>
          )}
        </CardBox>
      )}
    </>
  )
}

function CompanyTypeCardBoxes({ signal, withReducedInfo }: Props) {
  return (
    <>
      {signal.growthStage && (
        <CardBox title="Founded" value={signal.Founded ?? "N/A"}>
          <StrategicGrowthBadge growthStage={signal.growthStage} />
        </CardBox>
      )}
      <CardBox
        title="Total Funding"
        value={
          (signal.TotalFundingAmount ?? 0) > 0
            ? `$${numeral(signal.TotalFundingAmount).format("0,0a")}`
            : "N/A"
        }
      >
        <LastFundingStatus
          lastFundingAmount={signal.LastFundingAmount}
          lastFundingDate={signal.LastFundingDate}
          totalFundingAmount={signal.TotalFundingAmount}
        />
      </CardBox>
      {(signal.LastFundingAmount || signal.LastFundingDate) && (
        <CardBox
          title="Last Funding"
          value={
            isDateValid(signal.LastFundingDate)
              ? format(new Date(signal.LastFundingDate), "yyyy/MM/dd")
              : "N/A"
          }
        >
          <SignalStatusCompany
            lastFundingDate={signal.LastFundingDate}
            lastFundingAmount={signal.LastFundingAmount}
          />
        </CardBox>
      )}
      {!withReducedInfo && signal.Investors && (
        <CardBox title="Investors">
          {isNullish(signal.Investors) ? (
            <Text fontSize="sm" color="gray.500">
              No investor information found.
            </Text>
          ) : (
            <Flex gap={1} minW="fit-content">
              {signal.Investors.split(",")
                .slice(0, 2)
                .map((investor: string) => (
                  <ColoredTag
                    key={investor}
                    color="gray.500"
                    whiteSpace="nowrap"
                  >
                    {investor}
                  </ColoredTag>
                ))}
              {signal.Investors.split(",").length > 2 && (
                <Text fontSize="xs" fontWeight={"semibold"}>
                  +{signal.Investors.split(",").length - 2}
                </Text>
              )}
            </Flex>
          )}
        </CardBox>
      )}
    </>
  )
}

export const StrategicSignalCard = ({
  signal,
  customLink,
  withReducedInfo,
}: Props): JSX.Element => {
  const location = useLocation()
  const isEmbed = location.pathname.includes("/embed")

  const [searchParams] = useSafeSearchParams()

  const hasLogos = signal.Logos.length > 0

  const { hotFactor, hotCount } = getStratintelHotness(
    signal.StratintelMulti,
    searchParams
  )

  return (
    <SignalCard
      customLink={customLink}
      product="stratintel"
      signal={signal}
      picture={
        <Avatar
          src={signal.Screenshot ?? undefined}
          boxSize={withReducedInfo ? 20 : 40}
          borderRadius="xl"
        />
      }
      title={
        hasLogos ? (
          <LogosGrid
            logos={signal.Logos}
            nLogos={withReducedInfo ? 3 : undefined}
          />
        ) : null
      }
      postTitle={
        <>
          {!!signal.SourceType?.length && (
            <>
              <ColoredTag key={signal.SourceType[0]} color="brand.500">
                {titleCase(sentenceCase(signal.SourceType[0]))}
              </ColoredTag>
              {signal.SourceType.length > 1 && (
                <Button
                  as={RemixLink}
                  to={`./${signal.id}${location.search}`}
                  variant="link"
                  fontSize="xs"
                  minW="fit-content"
                  whiteSpace="nowrap"
                  target={isEmbed ? "_blank" : undefined}
                >
                  <Tooltip
                    label={signal.SourceType.slice(1)
                      .map((v) => titleCase(sentenceCase(v)))
                      .join(", ")}
                    fontSize="xs"
                  >
                    <Text as="span">+{signal.SourceType.length - 1}</Text>
                  </Tooltip>
                </Button>
              )}
            </>
          )}

          {signal.SignalScore && (
            <ColoredTag color="brand.500">
              Score: {signal.SignalScore}/10
            </ColoredTag>
          )}

          {signal.IndustryOG && (
            <SignalIndustry industry={signal.IndustryOG} maxItems={2} />
          )}
        </>
      }
      subtitle={
        <StrategicSignalTitle
          signal={signal}
          nSources={withReducedInfo ? 4 : undefined}
        />
      }
      description={
        signal.SignalDate ? (
          <Text fontSize="xs" color="gray.400" as="span">
            <Text as="span" fontWeight="semibold">
              {getSignalDate(signal.SignalDate)}
            </Text>{" "}
            -{" "}
            {getSignalLocation({
              location: signal.HQLocation,
              region: signal.HQRegion,
            })}
          </Text>
        ) : null
      }
      preButtons={
        isOneOf(signal.SignalType, allStrategicSignalType) && (
          <SignalStatusStrategic signalType={signal.SignalType} />
        )
      }
      actionButtons={<ButtonsStrategic signal={signal} />}
      postActionMenuButtons={
        <ButtonGroup size="xs" variant="outline" spacing={0} gap={1} pt={1}>
          <ActionMenuIcons
            product={SpecterProducts.stratintel}
            signal={signal}
          />
        </ButtonGroup>
      }
      avatarFullHeight
      cardBoxes={
        !withReducedInfo && (
          <>
            {signal.SignalType === "Talent" ? (
              <TalentTypeCardBoxes signal={signal} />
            ) : (
              <CompanyTypeCardBoxes
                signal={signal}
                withReducedInfo={withReducedInfo}
              />
            )}
            {hotFactor && !withReducedInfo && (
              <CardBox
                title={`🔥 Featured`}
                value={`${hotCount} times`}
                suppressColon
                withHighlight
              >
                <Text
                  as="h2"
                  color="gray.500"
                  fontSize="xs"
                  whiteSpace="nowrap"
                >
                  In the last {hotFactor} days
                </Text>
              </CardBox>
            )}
          </>
        )
      }
    />
  )
}

export function getStratintelHotness(
  stratintelMulti:
    | ProductItem<"stratintel">["StratintelMulti"]
    | CompanySignalFeedItem["hot_companies"]
    | undefined,
  searchParams: URLSearchParams
) {
  const FAILED_RETURN = { hotFactor: "", hotCount: 0 }

  if (isNullish(stratintelMulti)) return FAILED_RETURN

  let hotnessFactors

  if ("signalCountPast90d" in stratintelMulti) {
    hotnessFactors = {
      "90": stratintelMulti?.signalCountPast90d ?? 1,
      "180": stratintelMulti?.signalCountPast180d ?? 1,
      "360": stratintelMulti?.signalCountPast360d ?? 1,
      "720": stratintelMulti?.signalCountPast720d ?? 1,
    } as const
  } else if ("signal_count_past_90_days" in stratintelMulti) {
    hotnessFactors = {
      "90": stratintelMulti?.signal_count_past_90_days ?? 1,
      "180": stratintelMulti?.signal_count_past_180_days ?? 1,
      "360": stratintelMulti?.signal_count_past_360_days ?? 1,
      "720": stratintelMulti?.signal_count_past_720_days ?? 1,
    } as const
  } else return FAILED_RETURN

  const query = JSONSafeParse<{ HotSignals?: string; HotCompanies?: string }>(
    searchParams.get("query")
  )

  if (
    typeof query.HotSignals === "string" ||
    typeof query.HotCompanies === "string"
  ) {
    const hotFactor =
      typeof query.HotSignals === "string"
        ? query.HotSignals.match(/(\d+)/g)?.[0]
        : typeof query.HotCompanies === "string"
        ? query.HotCompanies.match(/(\d+)/g)?.[0]
        : ""

    invariant(
      isOneOf(hotFactor, Object.keys(hotnessFactors)),
      "Invalid format of time-frame for Hot Signals"
    )

    return {
      hotFactor,
      hotCount: hotnessFactors[hotFactor as keyof typeof hotnessFactors] ?? 1,
    }
  }

  const [hotFactor, hotCount] =
    Object.entries(hotnessFactors).find(([_, count]) => count > 1) ?? []

  return { hotFactor, hotCount }
}
