import { EntityStatus, SpecterProducts } from "@prisma/client"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import { z } from "zod"
import { cacheKeys } from "../cacheKeys"
import invariant from "../invariant"

export const useEntityStatusViewed = (
  product: SpecterProducts,
  entityId: string
) => {
  const entityStatusMutation = useEntityStatusMutation(product)

  useEffect(() => {
    entityStatusMutation.mutate({
      entityId,
      status: EntityStatus.viewed,
    })
  }, [entityId])
}

export const useEntityStatusMutation = (product: SpecterProducts) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      entityId,
      status,
    }: {
      entityId: string
      status: EntityStatus
    }) => {
      const existingStatus = queryClient.getQueryData<{
        status: EntityStatus
        updated_at: string
      }>(cacheKeys.entityStatus(product, entityId))

      const newStatus =
        existingStatus?.status === status ? EntityStatus.viewed : status

      if (
        status === EntityStatus.viewed &&
        (existingStatus?.status === EntityStatus.liked ||
          existingStatus?.status === EntityStatus.disliked ||
          existingStatus?.status === EntityStatus.viewed)
      ) {
        return null
      }

      queryClient.setQueryData(cacheKeys.entityStatus(product, entityId), {
        status: newStatus,
        updated_at: new Date().toISOString(),
      })

      const req = await fetch(`/api/entity-status/${product}/${entityId}`, {
        method: "POST",
        body: JSON.stringify({
          status: newStatus,
        }),
      })

      invariant(req.ok, "Failed to update entity status")
    },
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.userLists({ product }))
    },
  })
}

export const useEntityStatusQuery = ({
  entityId,
  initialValue,
  product,
}: {
  entityId: string
  initialValue: {
    status?: EntityStatus | null
    updated_at?: string | null
  } | null
  product: SpecterProducts
}) => {
  return useQuery(
    cacheKeys.entityStatus(product, entityId),
    async () => {
      const req = await fetch(`/api/entity-status/${product}/${entityId}`)

      invariant(req.ok, "Failed to fetch entity status")

      const data = await req.json()

      return z
        .object({
          status: z.nativeEnum(EntityStatus),
          updated_at: z.string(),
        })
        .parse(data)
    },
    {
      initialData: initialValue,
      staleTime: Infinity,
    }
  )
}

export const useEntityStatus = ({
  entityId,
  initialValue,
  product,
}: {
  entityId: string
  initialValue: {
    status?: EntityStatus | null
    updated_at?: string | null
  } | null
  product: SpecterProducts
}) => {
  const query = useEntityStatusQuery({ entityId, initialValue, product })
  const mutation = useEntityStatusMutation(product)

  return {
    status: query.data,
    isLoading: query.isLoading,
    update: mutation.mutate,
    isUpdating: mutation.isLoading,
  }
}
