import { Button, Text, Tooltip } from "@chakra-ui/react"
import { Link as RemixLink } from "@remix-run/react"
import { TagIndustry } from "~/components/TagIndustry"

export function SignalIndustry({
  industry,
  maxItems = Infinity,
  detailURL,
}: {
  industry: string | string[]
  maxItems?: number
  detailURL?: string
}) {
  if (!industry || industry.length === 0) {
    return (
      <Text fontSize="xs" color="gray.400">
        N/A
      </Text>
    )
  }

  const separator = "; "
  let parts: string[]

  if (Array.isArray(industry)) {
    parts = industry
  } else {
    parts = industry.split(separator) || []
  }

  const IndustriesOverflow = ({ overflowCount }: { overflowCount: number }) => (
    <Button
      as={RemixLink}
      to={detailURL ?? "#"}
      variant="link"
      fontSize="xs"
      minW="fit-content"
      whiteSpace="nowrap"
    >
      <Tooltip label={parts.slice(-overflowCount).join(", ")} fontSize="xs">
        <Text as="span">+{overflowCount}</Text>
      </Tooltip>
    </Button>
  )

  return (
    <>
      {parts
        .sort()
        .slice(0, maxItems) // * Only show the first N industries
        .map((i: string) => (
          <TagIndustry key={i} label={i} />
        ))}
      {parts.length > maxItems && (
        <IndustriesOverflow
          key="industries_overflow"
          overflowCount={parts.length - maxItems}
        />
      )}
    </>
  )
}
