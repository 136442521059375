import { Box, Flex, FlexProps, Heading, Spacer, Text } from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import numeral from "numeral"
import { FaMinus } from "react-icons/fa"
import { RiArrowDownLine, RiArrowUpLine } from "react-icons/ri"
import { Card } from "~/components/Card"
import { CompanyHistoricalMetrics } from "~/utils/db/queries/company/types"
import { getGrowthColor } from "~/utils/getGrowthColor"
import { isNullish } from "~/utils/values"
import { CardBox } from "../CardBox"
import {
  ChartOverTimeHistoricals,
  ChartOverTimeHistoricalsProps,
} from "../ChartOverTime/ChartOverTimeHistoricals"

import {
  getHistoricalMetricsGrowth,
  getHistoricalMetricsValue,
} from "./Tabs/Web"

export type DetailPageHistoricalsChartLayoutProps = {
  chart: Omit<ChartOverTimeHistoricalsProps, "data"> & {
    data: CompanyHistoricalMetrics[] | null
  }
  chartEmptyMessage?: string
  absoluteGrowths?: boolean
  prefixedDataItem?: {
    label: React.ReactNode
    value: number | null
    prefix?: React.ReactNode
  }
}

export const DetailPageHistoricalsChartLayout = ({
  prefixedDataItem,
  chart,
  chartEmptyMessage,
  absoluteGrowths = false,
}: DetailPageHistoricalsChartLayoutProps) => {
  const { data } = chart

  const chartNotEmpty =
    data !== null && data.some((item) => item[chart.field] !== null)

  if (isNullish(data)) {
    return (
      <CardBox>
        <Card>
          <Text fontSize="sm" fontWeight="semibold">
            {chartEmptyMessage}
          </Text>
        </Card>
      </CardBox>
    )
  }

  const baseValue = getHistoricalMetricsValue({
    data,
    field: chart.field,
  })

  const growth1Month = getHistoricalMetricsGrowth({
    data,
    field: chart.field,
    nMonths: 1,
    inPercentage: !absoluteGrowths,
  })

  const growth3Month = getHistoricalMetricsGrowth({
    data,
    field: chart.field,
    nMonths: 3,
    inPercentage: !absoluteGrowths,
  })

  const growth6Month = getHistoricalMetricsGrowth({
    data,
    field: chart.field,
    nMonths: 6,
    inPercentage: !absoluteGrowths,
  })

  const growth12Month = getHistoricalMetricsGrowth({
    data,
    field: chart.field,
    nMonths: 12,
    inPercentage: !absoluteGrowths,
  })

  const growth24Month = getHistoricalMetricsGrowth({
    data,
    field: chart.field,
    nMonths: 24,
    inPercentage: !absoluteGrowths,
  })

  const PADDING = 6
  return (
    <CardBox flexGrow={1}>
      <Card pl={0} p={PADDING} position="relative">
        {chartNotEmpty ? (
          <Flex direction="column" gap={2} pb={8}>
            <Flex gap={6} alignItems="center">
              {prefixedDataItem && (
                <BaseValueAnd1MonthGrowth
                  label={prefixedDataItem.label}
                  baseValue={prefixedDataItem.value}
                  pl={PADDING}
                  alignSelf="flex-start"
                  valuePrefix={prefixedDataItem.prefix}
                />
              )}
              <BaseValueAnd1MonthGrowth
                label={chart.label}
                baseValue={baseValue}
                growth1Month={growth1Month}
                pl={PADDING}
                isPercentage={!absoluteGrowths}
                isInverted={chart.reversed}
              />
              <Spacer />
              <GrowthValue
                growth={growth3Month}
                label="3m growth"
                isPercentage={!absoluteGrowths}
                isInverted={chart.reversed}
              />

              <GrowthValue
                growth={growth6Month}
                label="6m growth"
                isPercentage={!absoluteGrowths}
                isInverted={chart.reversed}
              />

              <GrowthValue
                growth={growth12Month}
                label="1y growth"
                isPercentage={!absoluteGrowths}
                isInverted={chart.reversed}
              />

              <GrowthValue
                growth={growth24Month}
                label="2y growth"
                isPercentage={!absoluteGrowths}
                isInverted={chart.reversed}
              />
            </Flex>

            <Box>
              <Box sx={{ "@media print": { visibility: "hidden" } }}>
                <ChartOverTimeHistoricals {...chart} data={data} />
              </Box>
              <Box
                position="absolute"
                inset={0}
                top={5}
                visibility="hidden"
                maxW="500px"
                sx={{ "@media print": { visibility: "visible" } }}
              >
                <ChartOverTimeHistoricals {...chart} data={data} />
              </Box>
            </Box>
          </Flex>
        ) : (
          <Text fontSize="sm" fontWeight="semibold" pl={4}>
            {chartEmptyMessage}
          </Text>
        )}
      </Card>
    </CardBox>
  )
}

export const formatGrowth = (
  growth: number,
  options: { isPercentage?: boolean; integerOnly?: boolean }
) => {
  if (Math.abs(growth) === Infinity)
    return `∞${options.isPercentage ? "%" : ""}`

  return (
    numeral(Math.abs(growth)).format(
      options.isPercentage
        ? growth > 100 || options.integerOnly
          ? "0"
          : "0.[00]"
        : "0,[00].[00]a"
    ) + (options.isPercentage ? "%" : "")
  )
}

const BaseValueAnd1MonthGrowth = ({
  label,
  baseValue,
  growth1Month,
  isPercentage,
  isInverted,
  valuePrefix,
  ...props
}: {
  label: React.ReactNode
  baseValue: number | null
  growth1Month?: number | null
  isPercentage?: boolean
  isInverted?: boolean
  valuePrefix?: React.ReactNode
} & FlexProps) => {
  const growthColor = getGrowthColor(Number(growth1Month), isInverted)

  const growthArrow =
    growthColor === "black"
      ? FaMinus
      : growthColor === "green"
      ? RiArrowUpLine
      : RiArrowDownLine

  return (
    <Flex direction="column" {...props}>
      <Heading as="h2" color="gray.500" size="xs" mb={2}>
        {label}
      </Heading>

      <Text
        fontWeight="extrabold"
        fontSize={{ base: "md", lg: "3xl" }}
        lineHeight={1}
      >
        {valuePrefix}
        {isNullish(baseValue)
          ? "N/A"
          : numeral(baseValue).format("0,[00].[00]a")}
      </Text>

      {!isNullish(growth1Month) && (
        <Text
          fontSize="xs"
          color="gray.500"
          display="inline-flex"
          alignItems="center"
          gap={1}
        >
          <Icon as={growthArrow} color={growthColor} />{" "}
          <Text as="span" color={growthColor}>
            {isNullish(growth1Month)
              ? "N/A"
              : formatGrowth(growth1Month, { isPercentage })}
          </Text>{" "}
          vs last month
        </Text>
      )}
    </Flex>
  )
}

const GrowthValue = ({
  growth,
  label,
  isPercentage,
  isInverted,
}: {
  growth: number | null
  label: string
  isPercentage?: boolean
  isInverted?: boolean
}) => {
  if (growth === null) return null

  const growthColor = getGrowthColor(Number(growth), isInverted)

  const growthArrow =
    growthColor === "black"
      ? FaMinus
      : growthColor === "green"
      ? RiArrowUpLine
      : RiArrowDownLine

  return (
    <Flex direction="column" alignSelf="center">
      <Text fontSize="md" display="inline-flex" alignItems="center" gap={1}>
        <Icon as={growthArrow} color={growthColor} />{" "}
        <Text as="span" fontWeight="semibold">
          {formatGrowth(growth, { isPercentage, integerOnly: true })}
        </Text>{" "}
      </Text>

      <Text fontSize="xs" color="gray.500">
        {label}
      </Text>
    </Flex>
  )
}
