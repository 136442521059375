import { TagProps } from "@chakra-ui/react"
import { ColoredTag } from "~/components/ColoredTag"

export function TagIndustry({
  label,
  hideOverflow = true,
  ...props
}: {
  label: string
  hideOverflow?: boolean
} & Omit<TagProps, "color" | "size">) {
  return (
    <ColoredTag
      color="gray"
      verticalAlign="text-bottom"
      overflowX={hideOverflow ? "hidden" : "visible"}
      whiteSpace="nowrap"
      minW="fit-content"
      {...props}
    >
      {label}
    </ColoredTag>
  )
}
