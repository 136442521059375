import { Box } from "@chakra-ui/react"

export const IconCircleFilled = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 22 22" {...props}>
      <circle
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        cx="11"
        cy="11"
        r="10"
      />
      <circle fill="currentColor" cx="11" cy="11" r="6" />
    </Box>
  )
}
