import { Box, Button, ButtonGroup } from "@chakra-ui/react"
import numeral from "numeral"
import { SEARCH_EXPORT_LIMIT } from "~/consts/signals"
import { useGetSignalIDsMutation } from "~/utils/hooks/useGetSignalIDsMutation"
import { useProduct } from "~/utils/hooks/useProduct"
import { isOneOf } from "~/utils/isOneOf"
import { ColoredTag } from "../ColoredTag"
import {
  ActionMenuSelection,
  PRODUCTS_WITH_LISTS_AND_SEARCHES,
} from "../SignalCard"

export const SelectionMenu = ({
  selectedIds,
  setSelectedIds,
}: {
  selectedIds: string[]
  setSelectedIds: (ids: string[]) => void
}) => {
  const product = useProduct()
  const getFirst1K = useGetSignalIDsMutation(product)

  return (
    <>
      <ColoredTag color="brand.500" whiteSpace="nowrap">
        {numeral(selectedIds.length).format("0,0")} selected
      </ColoredTag>

      <Box w="1px" mx={1} h={5} bgColor="gray.200" />

      {selectedIds.length < SEARCH_EXPORT_LIMIT && (
        <>
          <Button
            variant="outline"
            onClick={async () => {
              const ids = await getFirst1K.mutateAsync()
              setSelectedIds(ids)
            }}
            isLoading={getFirst1K.isLoading}
            isDisabled={getFirst1K.isLoading}
          >
            Select first {numeral(SEARCH_EXPORT_LIMIT).format("0a")}
          </Button>

          <Box w="1px" mx={1} h={5} bgColor="gray.200" />
        </>
      )}

      {selectedIds.length > 0 && (
        <>
          <ButtonGroup
            spacing={1}
            size="sm"
            variant="outline"
            alignItems="center"
            justifyContent="flex-end"
          >
            <SelectionMenuActionButtons selectedIds={selectedIds} />
          </ButtonGroup>

          <Box w="1px" mx={1} h={5} bgColor="gray.200" />
        </>
      )}
    </>
  )
}

const SelectionMenuActionButtons = ({
  selectedIds,
}: {
  selectedIds: string[]
}) => {
  const product = useProduct()

  if (isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES)) {
    return <ActionMenuSelection product={product} signalIds={selectedIds} />
  }

  return null
}
