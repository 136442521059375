import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormik } from "formik"
import { CreateListByProduct } from "~/routes/__protected/api/lists/$product/create"
import { cacheKeys } from "~/utils/cacheKeys"
import invariant from "~/utils/invariant"

import { z } from "zod"
import { ADD_MANY_TO_LIST_LIMIT } from "~/consts/signals"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useUserLists } from "~/utils/hooks/useUserLists"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { FlexDivider } from "../FlexDivider"
import { Fragment } from "react"
import { useHiddenSearchesLists } from "~/utils/hooks/useHiddenSearchesLists"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { isMockList } from "~/utils/lists"

const addManyToListSchema = z.object({
  newListName: z.string(),
  listSelected: z.string().optional(),
})
type FormValues = z.infer<typeof addManyToListSchema>

interface ListFormProps {
  product: SpecterProducts
  signalIds?: string[]
  onSuccess: () => void
  onCancel: () => void
}

const ListsForm = ({
  product,
  signalIds,
  onSuccess,
  onCancel,
}: ListFormProps): JSX.Element => {
  const toast = useToast()
  const listsQuery = useUserLists({ product })
  const queryClient = useQueryClient()
  const user = useUserPermissions()
  const analytics = useAnalytics()

  const [searchParams] = useSafeSearchParams()
  const { hidden: hiddenLists } = useHiddenSearchesLists(ListType.userList)

  const options = listsQuery.data
    ?.filter((list) => !list.isGlobalHub || user.data?.isAdmin)
    ?.filter(
      (list) => !hiddenLists.includes(list.id.toString()) && !isMockList(list)
    )
    ?.map((list) => ({
      label: `${list.isGlobalHub ? "🌐 " : ""}${list.name}`,
      value: list.id,
    }))

  const createListMutation = useMutation<CreateListByProduct, unknown, string>(
    async (name) => {
      const req = await fetch(`/api/lists/${product}/create`, {
        method: "POST",
        body: JSON.stringify({ name, isPublic: false }),
      })

      invariant(req.ok, "Failed to create new list")

      return await req.json()
    },
    {
      async onSuccess(data) {
        await queryClient.invalidateQueries(cacheKeys.userLists({ product }))

        analytics.track("Created List", {
          product,
          listId: data.id,
          name: data.name,
        })

        formik.resetForm()
        addManyToListMutation.mutate({ listId: data.id, listName: data.name })
      },
    }
  )

  const addManyToListMutation = useMutation<
    { listName: string | undefined; signalAddedIds: string[] },
    unknown,
    { listId: string; listName?: string }
  >(
    async ({ listId, listName }) => {
      const req = await fetch(`/api/lists/${product}/${listId}/add-many`, {
        method: "POST",
        body: JSON.stringify(
          signalIds
            ? { signalIds }
            : { searchParams: Object.fromEntries(searchParams.entries()) }
        ),
      })

      if (!req.ok) {
        throw new Error("Signal is already added to this list")
      }

      listName ??= listsQuery.data?.find((list) => list.id === listId)?.name

      const signalAddedIds: string[] = await req.json()

      analytics.track(
        signalIds ? "Save imported into List" : "Save search as List",
        {
          product,
          listName,
          listId,
          signalIds: signalAddedIds,
          count: signalAddedIds.length,
        },
        { exclude: ["posthog"] }
      )

      analytics.track(
        "created_list",
        {
          creation_type: signalIds ? "import" : "saved_search",
          product,
          listId,
          name: listName,
          signalIds: signalAddedIds,
          count: signalAddedIds.length,
        },
        { only: ["posthog"] }
      )

      return { listName, signalAddedIds }
    },
    {
      async onSuccess({ listName, signalAddedIds }) {
        signalAddedIds.forEach(async (signalId) => {
          queryClient.invalidateQueries(cacheKeys.signalAddedToList(signalId))

          await queryClient.invalidateQueries(cacheKeys.userLists({ product }))
        })
        formik.resetForm()

        toast({
          status: "success",
          title: `Signals added to list '${listName}'`,
        })
        onSuccess()
      },
    }
  )

  const formik = useFormik<FormValues>({
    initialValues: {
      newListName: "",
      listSelected: undefined,
    },
    onSubmit(values) {
      if (values.newListName) createListMutation.mutate(values.newListName)
      else if (values.listSelected)
        addManyToListMutation.mutate({ listId: values.listSelected })
    },
  })

  return (
    <ModalContent>
      <ModalCloseButton />
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader>Add to List</ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={1}>
          <Text fontSize="xs" mb={2} color="gray.400">
            Note: Maximum of {ADD_MANY_TO_LIST_LIMIT} signals
          </Text>
          {options ? (
            <RadioGroup isDisabled={!!formik.values.newListName}>
              {options.map(({ label, value }, index) => (
                <Fragment key={`list-option-${value}`}>
                  {index !== 0 && <FlexDivider orientation="horizontal" />}
                  <Radio
                    name="listSelected"
                    key={value}
                    value={value}
                    onChange={formik.handleChange}
                  >
                    {label}
                  </Radio>
                </Fragment>
              ))}
            </RadioGroup>
          ) : (
            <Flex alignItems="center" gap={1} mt={3}>
              <Text fontSize="sm">Loading lists</Text>
              <Spinner />
            </Flex>
          )}

          <FormControl isInvalid={!!formik.errors.newListName} mt={5}>
            <FormLabel htmlFor="newListName">Create new List</FormLabel>
            <Input
              id="newListName"
              value={formik.values.newListName}
              onChange={formik.handleChange}
              name="newListName"
            />
            <FormErrorMessage>{formik.errors.newListName}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button size="sm" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="brand"
            type="submit"
            isLoading={
              createListMutation.isLoading || addManyToListMutation.isLoading
            }
            disabled={
              !formik.isValid ||
              (formik.values.newListName === "" &&
                !formik.values.listSelected) ||
              createListMutation.isLoading ||
              addManyToListMutation.isLoading
            }
          >
            {formik.values.listSelected && !formik.values.newListName
              ? "Add to List"
              : "Create List"}
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  )
}

interface Props {
  isOpen: boolean
  onClose: () => void
  product: SpecterProducts
  onSuccess?: () => void
  signalIds?: string[]
}

export const ListAddManyModal = ({
  isOpen,
  onClose,
  onSuccess,
  product,
  signalIds,
}: Props): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ListsForm
        product={product}
        onSuccess={() => {
          onClose()
          if (onSuccess) onSuccess()
        }}
        onCancel={onClose}
        signalIds={signalIds}
      />
    </Modal>
  )
}
