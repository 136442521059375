import {
  Button,
  Center,
  Flex,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import { SpecterProducts } from "@prisma/client"
import { Link as RemixLink } from "@remix-run/react"
import { HiGlobeAlt, HiUserRemove } from "react-icons/hi"
import invariant from "~/utils/invariant"

import { useUser } from "@clerk/remix"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  HiEllipsisHorizontal,
  HiOutlineBars4,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2"
import {
  EditList,
  ListDelete,
  ListShare,
  SearchesAndListsProducts,
} from "~/components"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { ListLandscapeImport } from "~/components/ListLandscapeImport"
import { MenuItem } from "~/components/MenuItem"
import { PromoteGlobalHub } from "~/components/UserList/PromoteGlobalHub"
import { Reassign } from "~/components/UserList/Reassign"
import { TagNewSignalsList } from "~/components/UserList/TagNewSignal"
import { HideListOrSearch } from "~/components/UserSearchesPage/HideListOrSearch"
import { GetUserListsByProduct } from "~/routes/__protected/api/lists"
import { cacheKeys } from "~/utils/cacheKeys"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ListType } from "~/routes/__protected/user/lists/$product"

import { CellRendererProps } from "~/components/Table/cellRenderers"
import { getItemCount } from "~/routes/__protected/user/lists/$product/index"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { IntegrationEnrichmentForm } from "../IntegrationEnrichmentForm"
import {
  integrationConfigIsSetup,
  productSupportsIntegrations,
} from "~/utils/integrations"
import { IntegrationEnrichmentsTags } from "../IntegrationEnrichmentsTags"
import {
  DISLIKED_LIST_ID,
  getMockListLink,
  isMockList,
  LIKED_LIST_ID,
  MY_CRM_LIST_ID,
} from "~/utils/lists"
import { PiThumbsDownBold } from "react-icons/pi"
import { PiThumbsUpBold } from "react-icons/pi"

const isNewFields = {
  _countNewFundingSignals: "HasNewFundingHighlights",
  _countNewGrowthSignals: "HasNewGrowthHighlights",
} as const

export function RenderListName({
  data: list,
  value,
}: CellRendererProps<`user-lists.${SearchesAndListsProducts}`>) {
  if (!list) return <></>

  const listItemLink = isMockList(list)
    ? getMockListLink(list.id, list.type)
    : `/signals/${mapProductsToRouteName(list.type)}/feed?listId=${list.id}`

  const baseColor =
    list.id === MY_CRM_LIST_ID
      ? "brand"
      : list.id === LIKED_LIST_ID
      ? "green"
      : list.id === DISLIKED_LIST_ID
      ? "red"
      : list.isGlobalHub
      ? "yellow"
      : "gray"

  return (
    <Flex alignItems="center" height="100%" gap={3}>
      <Center
        w={8}
        h={8}
        bgColor={baseColor + ".50"}
        color={baseColor + ".500"}
        fontSize="md"
        rounded="lg"
        borderWidth={1}
        borderColor={baseColor + ".100"}
      >
        <Icon
          as={
            list.id === MY_CRM_LIST_ID
              ? HiOutlineSquare3Stack3D
              : list.id === LIKED_LIST_ID
              ? PiThumbsUpBold
              : list.id === DISLIKED_LIST_ID
              ? PiThumbsDownBold
              : list.isGlobalHub
              ? HiGlobeAlt
              : HiOutlineBars4
          }
        />
      </Center>
      <Link
        as={RemixLink}
        to={listItemLink}
        fontWeight="semibold"
        fontSize="sm"
        color="gray.800"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        flexShrink={1}
        display="block"
      >
        {value}
      </Link>
    </Flex>
  )
}

export function RenderTagNewSignalsList({
  data: list,
  column,
}: CellRendererProps<`user-lists.${SearchesAndListsProducts}`>) {
  const columnId = column?.getColId() as string | undefined

  if (!list || !columnId) return <></>

  const listItemLink = isMockList(list)
    ? getMockListLink(list.id, list.type)
    : `/signals/${mapProductsToRouteName(list.type)}/feed?listId=${list.id}`

  const isNewField = isNewFields[columnId as keyof typeof isNewFields]

  return (
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      flexGrow={1}
    >
      {columnId === "_count" ? (
        <Tag
          rounded="full"
          as={RemixLink}
          to={listItemLink}
          variant="softOutline"
          minW="fit-content"
        >
          {getItemCount(list)}
        </Tag>
      ) : (
        <TagNewSignalsList list={list} product={list.type} field={isNewField} />
      )}
    </Flex>
  )
}

const RemoveAccessButton = ({
  listId,
  product,
}: {
  listId: string
  product: SpecterProducts
}): JSX.Element => {
  const modal = useDisclosure()
  const { user } = useUser()
  const queryClient = useQueryClient()
  const toast = useToast()

  const accessMutation = useMutation(
    async () => {
      invariant(user?.id)

      const req = await fetch(
        `/api/lists/${product}/${listId}/share/${user.id}`,
        {
          method: "DELETE",
        }
      )

      invariant(req.ok)
    },
    {
      async onSuccess() {
        await queryClient.refetchQueries(cacheKeys.userLists({ product }))
        modal.onClose()

        toast({
          status: "success",
          title: "Access has been removed for this list",
        })
      },
    }
  )

  return (
    <>
      <MenuItem icon={HiUserRemove} onClick={modal.onOpen}>
        Remove Access
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Access</ModalHeader>
          <ModalBody>
            <Text fontSize="sm">
              Are you sure you want to remove your access to this list?
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" onClick={modal.onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => accessMutation.mutate()}
              isLoading={accessMutation.isLoading}
              disabled={accessMutation.isLoading}
            >
              Yes, remove access
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export function UserListActions({
  list,
}: {
  list: GetUserListsByProduct[number]
}) {
  const permissions = useUserPermissions()
  const queryClient = useQueryClient()

  const isOwner = permissions.data?.userId === list.user?.userId
  const hasOwnerAccess = isOwner || permissions.data?.isAdmin
  const hasBeenShared = !isOwner && !list.isPublic && !list.isGlobalHub
  const integrations = useIntegrations()

  const product = list.type as SearchesAndListsProducts

  const setupIntegrations =
    integrations.data?.integrations.integrationsMeta.filter((i) =>
      integrationConfigIsSetup(i.config?.[product] ?? {})
    )

  const supportsIntegrations = productSupportsIntegrations(product)

  if (isMockList(list)) {
    return null
  }

  return (
    <Flex
      gap={1}
      alignItems="center"
      h="full"
      justifyContent="flex-end"
      minW="full"
    >
      <IntegrationEnrichmentsTags
        integrationEnrichments={list.integrationEnrichments}
      />

      <Popover placement="auto-end">
        <PopoverTrigger>
          <IconButton
            aria-label="More"
            size="xs"
            variant="outline"
            icon={<Icon as={HiEllipsisHorizontal} />}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent p={2} maxW={230}>
            {hasOwnerAccess && (
              <>
                <EditList listId={list.id} product={product} />
                <ListShare
                  listId={list.id}
                  product={product}
                  onClose={() => {
                    queryClient.invalidateQueries(
                      cacheKeys.userLists({ product })
                    )
                  }}
                />
              </>
            )}

            <HideListOrSearch id={list.id} type={ListType.userList} />

            {hasOwnerAccess && (
              <ListDelete listId={list.id} product={product} />
            )}

            {hasBeenShared && (
              <RemoveAccessButton listId={list.id} product={product} />
            )}

            <ListLandscapeImport listId={list.id} product={product} />

            {supportsIntegrations &&
              setupIntegrations?.map((i) => {
                return (
                  <IntegrationEnrichmentForm
                    key={i.integrationKey}
                    integrationKey={i.integrationKey}
                    product={product}
                    listId={list.id}
                    searchId={undefined}
                  />
                )
              })}

            {supportsIntegrations && (setupIntegrations?.length ?? 0) <= 0 && (
              <IntegrationEnrichmentForm
                product={product}
                listId={list.id}
                searchId={undefined}
              />
            )}

            {hasOwnerAccess && permissions.data?.isAdmin && (
              <>
                <Text color="gray.400" pt={2} fontSize="sm">
                  Admin
                </Text>
                <PromoteGlobalHub
                  listType={ListType.userList}
                  id={list.id}
                  isGlobalHub={list.isGlobalHub}
                  product={product}
                />
                <Reassign
                  listType={ListType.userList}
                  id={list.id}
                  product={product}
                />
              </>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  )
}
