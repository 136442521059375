import {
  Box,
  Button,
  Flex,
  IconButton,
  PopoverContent,
  Popover,
  PopoverTrigger,
  Spacer,
  useDisclosure,
  useToast,
  PopoverArrow,
  Tag,
  DrawerBody,
  DrawerContent,
  Drawer,
} from "@chakra-ui/react"
import { Icon } from "~/utils/components/Icon"
import { SpecterProducts } from "@prisma/client"
import { useLocation, useOutlet } from "@remix-run/react"
import { useEffect, useMemo, useState } from "react"

import { LoaderArgs } from "@remix-run/node"
import * as Sentry from "@sentry/remix"
import { useMutation } from "@tanstack/react-query"
import { equals } from "ramda"
import { HiOutlineBars4, HiOutlineMagnifyingGlass } from "react-icons/hi2"
import {
  ClientSide,
  PRODUCTS_WITH_LISTS_AND_SEARCHES,
  ResultCount,
  SaveSearch,
  SignalSortMenu,
  SIDE_NAV_WIDTH_OPEN,
  SignalExport,
} from "~/components"
import { AffinityFeedMenu } from "~/components/Affinity/AffinityFeedMenu"
import { FeedDataError } from "~/components/Errors"
import { AppliedFilters } from "~/components/Filters/AppliedFilters"
import { PRODUCT_ROUTE_NAMES } from "~/components/Filters/schemas"
import { cacheKeys } from "~/utils/cacheKeys"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { countAppliedSignalFilters } from "~/utils/countAppliedSignalFilters"
import { hasAccessToFeed } from "~/utils/hasAccessToFeed"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { useIntersectionObserver } from "~/utils/hooks/useIntersectionObserver"
import { useListDetailsQuery } from "~/utils/hooks/useListDetailsQuery"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useSavedSearchQuery } from "~/utils/hooks/useSavedSearchQuery"
import {
  useSignalsQuery,
  useSignalsQueryCount,
} from "~/utils/hooks/useSignalsQuery"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { useViewMode, ViewMode } from "~/utils/hooks/useViewMode"
import invariant from "~/utils/invariant"
import { isOneOf } from "~/utils/isOneOf"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { cleanProgmaticFiltersFromQuery } from "~/utils/progmaticFilters"
import { queryClient } from "~/utils/queryClient"
import { PAGE_NOT_FOUND } from "~/utils/responses/errors"
import { HiGlobeAlt } from "react-icons/hi"
import { useUser } from "@clerk/remix"
import PeopleDBComingSoon from "~/components/PeopleDetailPage/Components/PeopleDBComingSoon"
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlags"
import { MdOutlineFilterList } from "react-icons/md"
import { AdvancedFiltersModal } from "~/components/Filters/AdvancedFilters/AdvancedFiltersModal"
import { HEADER_HEIGHT } from "~/components/Header"
import { SearchInput } from "~/components/SearchInput"
import { getSearchPlaceholder, pathHasSearchInput } from "~/utils/search"
import { SuggestedSearches } from "~/components/Filters/SuggestedSearches"
import Feed, { useDetailPageGoBack } from "~/components/Feed"
import ProductTable from "~/components/Table"
import { SelectionMenu } from "~/components/Table/SelectionMenu"
import { IntegrationPushSearchButton } from "~/components/IntegrationPushSearchButton"
import { EntityStatusSelect } from "~/components/EntityStatusSelect"

export async function loader({ params }: LoaderArgs) {
  if (!isOneOf(params.product, Object.keys(PRODUCT_ROUTE_NAMES)))
    throw PAGE_NOT_FOUND

  return null
}

export const useInfiniteSignalsQuery = (
  product: SpecterProducts,
  viewMode: ViewMode = ViewMode.Feed
) => {
  const signalsQuery = useSignalsQuery(product, viewMode)

  const observerRef = useIntersectionObserver<HTMLDivElement>(
    () =>
      !signalsQuery.isFetchingNextPage &&
      signalsQuery.hasNextPage &&
      signalsQuery.fetchNextPage()
  )

  return { ...signalsQuery, observerRef }
}

const SignalsPage = (): JSX.Element => {
  const analytics = useAnalytics()
  const [isSelectable, setIsSelectable] = useState(false)

  const viewMode = useViewMode()
  const isFeed = viewMode === ViewMode.Feed

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const toast = useToast()
  const { user } = useUser()
  const product = useProduct()
  const permissions = useUserPermissions()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const countQuery = useSignalsQueryCount(product)
  const Outlet = useOutlet()

  const integrationsQuery = useIntegrations()
  const disableLegacyAffinity =
    !!integrationsQuery.data?.integrations.integrationsMeta.find(
      (i) => i.integrationKey === "affinity"
    )

  const goBack = useDetailPageGoBack()

  const advancedFilters = useDisclosure()
  const savedSearchQuery = useSavedSearchQuery()
  const listQuery = useListDetailsQuery()

  const rootQuery = cleanProgmaticFiltersFromQuery(
    JSONSafeParse<Record<string, any>>(searchParams.get("query"))
  )
  const sort = searchParams.get("sort") ?? ""
  const searchId = searchParams.get("searchId")
  const searchTerm = searchParams.get("search") ?? ""
  const [showSearch, setShowSearch] = useState(!!searchTerm)
  const location = useLocation()

  const hasSearchInput = pathHasSearchInput(location.pathname)

  useEffect(() => {
    const deprecatedFields = getDeprecatedFields(product, rootQuery)
    if (deprecatedFields.length > 0 && !advancedFilters.isOpen) {
      advancedFilters.onOpen()
    }
  }, [])

  useEffect(() => {
    setSelectedIds([])
    setIsSelectable(false)
  }, [product, viewMode])

  useEffect(() => {
    setShowSearch(false)
  }, [product])

  // * In case we decide to show for big searches:
  // * "This search doesn't fit the URL. Make sure to "Save Search" to be able to share with your team."
  // * This is the boolean for that:

  // const isBigSearch =
  //   Array.from(searchParams.entries()).length > 0 &&
  //   `?${searchParams.toString()}` !== location.search

  const totalAppliedFilters = useMemo(
    () => countAppliedSignalFilters(product, searchParams),
    [product, searchParams]
  )
  const totalAppliedFiltersNonProgmatic = useMemo(
    () => countAppliedSignalFilters(product, searchParams, true),
    [product, searchParams]
  )
  const totalAppliedFiltersWithoutHidden = useMemo(
    () => countAppliedSignalFilters(product, searchParams, false, true),
    [product, searchParams]
  )

  const clearFilters = () => {
    setSearchParams({})
  }

  const updateSavedSearch = useMutation(
    async () => {
      invariant(searchId, "No search to update")

      const res = await fetch(`/api/saved-searches/${searchId}`, {
        method: "post",
        body: JSON.stringify({
          query: JSON.stringify(rootQuery),
          sort,
        }),
      })

      invariant(res.ok, "Failed to edit saved search")

      analytics.track("User edited a Saved Search", {
        searchId,
        product,
        query: rootQuery,
        sort,
      })

      fetch(`/api/saved-searches/${searchId}/update-counts`, {
        method: "post",
      }).then(() => {
        queryClient.invalidateQueries(cacheKeys.userSavedSearch(searchId))
        queryClient.invalidateQueries(cacheKeys.userSavedSearches(product))
      })
    },
    {
      async onError() {
        toast({
          status: "error",
          title:
            "We were unable to update your Saved Search filter. Please try again later.",
        })
      },
      async onSuccess() {
        if (searchId) {
          await queryClient.invalidateQueries(
            cacheKeys.userSavedSearch(searchId)
          )
        }
        await queryClient.invalidateQueries(
          cacheKeys.userSavedSearches(product)
        )
        await queryClient.invalidateQueries(
          cacheKeys.globalHubSearches(product)
        )

        toast({
          status: "success",
          title: "Updated Saved Search filters",
        })
      },
    }
  )

  const showUpdateSearchButton =
    savedSearchQuery.data?.userId === user?.id &&
    savedSearchQuery.data &&
    typeof savedSearchQuery.data.queries.query === "object" &&
    !equals(
      cleanProgmaticFiltersFromQuery(savedSearchQuery.data.queries.query ?? {}),
      rootQuery
    )

  const isPeopleDBEnabled = useFeatureFlag("PEOPLE_DB_ENABLED")

  if (product === "people" && !isPeopleDBEnabled) {
    return <PeopleDBComingSoon />
  }

  return (
    <Flex as="main" direction="column" flexGrow={1}>
      <Flex
        bgColor="white"
        position="sticky"
        top={HEADER_HEIGHT}
        zIndex={110}
        py={1.5}
        px={6}
        borderBottomWidth={1}
        borderColor="gray.100"
        gap={1}
        alignItems="center"
      >
        {listQuery.isSuccess && listQuery.data !== null && <ListNameTag />}

        {savedSearchQuery.isSuccess && savedSearchQuery.data !== null && (
          <SearchNameTag />
        )}

        <Popover
          placement="top-start"
          isOpen={advancedFilters.isOpen}
          onClose={advancedFilters.onClose}
        >
          <PopoverTrigger>
            <Button
              flex="none"
              onClick={() => advancedFilters.onOpen()}
              variant="dashed"
              leftIcon={<Icon as={MdOutlineFilterList} color="gray.400" />}
            >
              Add Filter{" "}
              {totalAppliedFiltersNonProgmatic > 0 && (
                <Tag colorScheme="brand" size="xs" ml={2} mr={-2}>
                  {totalAppliedFiltersNonProgmatic}
                </Tag>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            mt={-0.5}
            w={`calc(100vw - ${SIDE_NAV_WIDTH_OPEN + 100}px)`}
            borderWidth={0}
            bgColor="transparent"
          >
            <Box
              shadow="lg"
              rounded="xl"
              borderWidth={1}
              bgColor="white"
              position="relative"
              zIndex={-1}
            >
              <PopoverArrow bg="gray.50" />
              <Box rounded="xl" overflow="hidden">
                {advancedFilters.isOpen && (
                  <ClientSide>
                    <AdvancedFiltersModal
                      product={product}
                      isOpen={advancedFilters.isOpen}
                      onClose={() => {
                        advancedFilters.onClose()
                      }}
                    />
                  </ClientSide>
                )}
              </Box>
            </Box>
          </PopoverContent>
        </Popover>

        <SignalSortMenu product={product} />

        <EntityStatusSelect />

        {totalAppliedFiltersWithoutHidden > 0 && (
          <>
            <Box w="1px" h={5} bgColor="gray.200" mx={0.5} />
            <Button
              onClick={clearFilters}
              colorScheme="red"
              variant="ghost"
              bgColor="red.50"
              flex="none"
            >
              Clear Filters
            </Button>
          </>
        )}

        {showUpdateSearchButton && !isSelectable && (
          <Button
            flex="none"
            isLoading={updateSavedSearch.isLoading}
            onClick={() => {
              updateSavedSearch.mutate()
            }}
            colorScheme="brand"
          >
            Update Search
          </Button>
        )}

        {!savedSearchQuery.data &&
          !listQuery.data &&
          totalAppliedFiltersNonProgmatic > 0 &&
          !savedSearchQuery.isInitialLoading &&
          !showUpdateSearchButton &&
          isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) &&
          !isSelectable && <SaveSearch product={product} />}

        {totalAppliedFiltersWithoutHidden <= 0 && !isSelectable && (
          <>
            <Box w="1px" h={5} bgColor="gray.200" mx={0.5} />
            <SuggestedSearches product={product} />
          </>
        )}

        <Spacer />

        {!showSearch && hasSearchInput && (
          <IconButton
            flex="none"
            aria-label="Search"
            icon={<Icon as={HiOutlineMagnifyingGlass} />}
            onClick={() => {
              setShowSearch(true)
            }}
            variant="outline"
          />
        )}

        {showSearch && (
          <SearchInput
            placeholder={getSearchPlaceholder(location.pathname)}
            onChange={(term) => {
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                search: term,
              })
            }}
            onClear={() => {
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                search: "",
              })
              setShowSearch(false)
            }}
            defaultValue={searchTerm}
          />
        )}

        {hasSearchInput && <Box w="1px" h={5} bgColor="gray.200" mx={0.5} />}

        {hasAccessToFeed(permissions.data, product) && !isSelectable && (
          <>
            {totalAppliedFilters > 0 && !disableLegacyAffinity && (
              <AffinityFeedMenu product={product} />
            )}

            <IntegrationPushSearchButton />

            <SignalExport
              product={product}
              signalsCount={countQuery.data?.results ?? null}
            />
          </>
        )}

        {hasAccessToFeed(permissions.data, product) && isSelectable && (
          <SelectionMenu
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        )}

        {isSelectable && (
          <Button
            variant="outline"
            flex="none"
            colorScheme={isSelectable ? "red" : undefined}
            bgColor={isSelectable ? "red.50" : undefined}
            borderColor={isSelectable ? "red.50" : undefined}
            onClick={() => {
              if (isSelectable) {
                setSelectedIds([])
              }
              setIsSelectable(!isSelectable)
            }}
          >
            {isSelectable ? "Cancel" : "Select"}
          </Button>
        )}
      </Flex>

      <Flex
        px={6}
        py={1.5}
        mb="-1px"
        bgColor="gray.50"
        borderBottomWidth={1}
        borderColor="gray.100"
        alignItems="flex-end"
        gap={2}
      >
        <AppliedFilters product={product} advancedFilters={advancedFilters} />
        <ResultCount product={product} countQuery={countQuery} />
      </Flex>

      <Flex
        {...(isFeed && { pb: 9, px: 5 })}
        flexDirection="column"
        flexGrow={1}
      >
        {isFeed ? (
          <Feed
            isSelectable={isSelectable}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        ) : (
          <ProductTable
            isSelectable={isSelectable}
            setIsSelectable={setIsSelectable}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        )}
      </Flex>

      <Drawer isOpen={Outlet !== null} onClose={goBack}>
        <DrawerContent maxW={`calc(100vw - ${SIDE_NAV_WIDTH_OPEN + 16}px)`}>
          <DrawerBody p={0}>{Outlet}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export const ListNameTag = () => {
  const listQuery = useListDetailsQuery()

  if (!listQuery.data) return null

  return (
    <Flex
      alignItems="center"
      gap={1}
      flex="none"
      bgColor={listQuery.data.isGlobalHub ? "yellow.50" : "brand.50"}
      rounded="lg"
      borderWidth={1}
      borderStyle="dashed"
      borderColor={listQuery.data.isGlobalHub ? "yellow.500" : "brand.500"}
      px={2}
      py={1}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      fontSize="xs"
      fontWeight="medium"
      color={listQuery.data.isGlobalHub ? "yellow.700" : "brand.500"}
    >
      <Icon as={listQuery.data.isGlobalHub ? HiGlobeAlt : HiOutlineBars4} />
      {listQuery.data.name}
    </Flex>
  )
}

export const SearchNameTag = () => {
  const savedSearchQuery = useSavedSearchQuery()

  if (!savedSearchQuery.data) return null

  return (
    <Flex
      alignItems="center"
      gap={1}
      flex="none"
      bgColor={savedSearchQuery.data.isGlobalHub ? "yellow.50" : "brand.50"}
      rounded="lg"
      borderWidth={1}
      borderStyle="dashed"
      borderColor={
        savedSearchQuery.data.isGlobalHub ? "yellow.500" : "brand.500"
      }
      px={2}
      py={1}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      fontSize="xs"
      fontWeight="medium"
      color={savedSearchQuery.data.isGlobalHub ? "yellow.700" : "brand.500"}
    >
      <Icon
        as={
          savedSearchQuery.data.isGlobalHub
            ? HiGlobeAlt
            : HiOutlineMagnifyingGlass
        }
      />
      {savedSearchQuery.data.name}
    </Flex>
  )
}

export const ErrorBoundary = ({ error }: { error: Error }) => {
  console.error(error)
  Sentry.captureException(error)
  return <FeedDataError />
}

export default SignalsPage
