import { Box } from "@chakra-ui/react"

export const IconCircleAll = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 22 22" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M11,1c5.52,0,10,4.48,10,10,0,2.5-.93,4.91-2.62,6.75M1.5,7.88c-.32.97-.49,1.98-.5,3M1.83,15c.56,1.29,1.39,2.45,2.43,3.4M3.64,4.24c.28-.3.58-.59.89-.86M7.64,20.42c2.49.89,5.24.75,7.63-.38"
      />
      <path
        fill="currentColor"
        d="M11,4.88v6h-6c0,3.31,2.69,6,6,6s6-2.69,6-6-2.69-6-6-6Z"
      />
    </Box>
  )
}
