import { IconButton, useToast } from "@chakra-ui/react"
import copy from "copy-to-clipboard"
import { useState } from "react"
import { HiCheck, HiLink } from "react-icons/hi"
import { Icon } from "~/utils/components/Icon"

interface Props {
  url: string
}

export const CopyLinkButton = ({ url }: Props): JSX.Element => {
  const toast = useToast()
  const [copied, isCopied] = useState(false)

  const onCopy = () => {
    const path = global.window.location.origin + url

    copy(path)
    isCopied(true)

    toast({
      status: "info",
      title: "Link copied to clipboard",
    })
  }

  return (
    <IconButton
      aria-label="Copy link"
      icon={<Icon as={copied ? HiCheck : HiLink} />}
      onClick={onCopy}
      variant="outline"
    />
  )
}
