import { SpecterProducts } from "@prisma/client"
import * as R from "ramda"
import { HIDDEN_FILTERS } from "~/components/Filters/AppliedFilters"
import { getProductSignalSearchParams } from "./hooks/useProductFilters"
import { PROGRAMMATIC_FILTERS } from "./progmaticFilters"

export const countAppliedSignalFilters = (
  product: SpecterProducts,
  searchParams: URLSearchParams,
  nonProgmaticOnly = false,
  withoutHidden = false
): number => {
  const allFilters = getProductSignalSearchParams(product, searchParams)
  const appliedFilters = R.reject(R.isEmpty, allFilters)

  if (nonProgmaticOnly) {
    return Object.keys(R.omit(PROGRAMMATIC_FILTERS, appliedFilters)).length
  }

  if (withoutHidden) {
    return Object.keys(R.omit(HIDDEN_FILTERS, appliedFilters)).length
  }

  return Object.keys(appliedFilters).length
}
