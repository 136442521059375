import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useFormik } from "formik"
import { useState } from "react"
import { HiPlus, HiShare } from "react-icons/hi"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { ReactSelect } from "~/components/ReactSelect"
import { GetSearchDetails } from "~/routes/__protected/api/saved-searches/$id.detail"
import { GetShareableMembers } from "~/routes/__protected/api/team/share-members"
import { cacheKeys } from "~/utils/cacheKeys"
import { getUserAvatarSrc } from "~/utils/getUserAvatarSrc"
import { useProduct } from "~/utils/hooks/useProduct"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import invariant from "~/utils/invariant"
import { relativeUrl } from "~/utils/string/url"
import { mapProductsToRouteName } from "../Filters/schemas"
import { createSearchQuery } from "../UserSearchesPage/utils"
import { MenuItem } from "../MenuItem"
import { Icon } from "~/utils/components/Icon"

interface SearchShareFormProps {
  queryId: number
}

const formSchema = z.object({
  userId: z.string(),
})

const validationSchema = toFormikValidationSchema(formSchema)

const SearchShareForm = ({ queryId }: SearchShareFormProps): JSX.Element => {
  const [showingAddForm, showAddForm] = useState(false)
  const queryClient = useQueryClient()
  const toast = useToast()
  const userPermissions = useUserPermissions()
  const analytics = useAnalytics()
  const env = useEnv()
  const product = useProduct()

  const searchDetailQuery = useQuery<GetSearchDetails>(
    cacheKeys.userSearchDetail(queryId),
    async () => {
      const req = await fetch(`/api/saved-searches/${queryId}/detail`)

      invariant(req.ok, "Failed to load search sharing settings")

      return await req.json()
    },
    {
      onError() {
        toast({
          status: "error",
          title: "Failed to load search",
        })
      },
    }
  )

  const teamMembersQuery = useQuery(
    cacheKeys.shareMembers,
    async () => {
      const req = await fetch("/api/team/share-members")

      invariant(req.ok)

      const data: GetShareableMembers = await req.json()

      return data.filter((user) => user.userId !== userPermissions.data?.userId)
    },
    {
      enabled: userPermissions.isSuccess,
    }
  )

  const removeShareMutation = useMutation<unknown, unknown, string>(
    async (userId) => {
      const req = await fetch(
        `/api/saved-searches/${queryId}/share/${userId}`,
        {
          method: "DELETE",
        }
      )

      invariant(req.ok)
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(cacheKeys.userSearchDetail(queryId))
        toast({
          status: "success",
          title: "Search sharing settings updated",
        })
      },
    }
  )

  const publicMutation = useMutation<unknown, unknown, boolean>(
    async (isPublic) => {
      const req = await fetch(`/api/saved-searches/${queryId}`, {
        method: "POST",
        body: JSON.stringify({
          isPublic,
          name: searchDetailQuery.data?.name,
        }),
      })

      invariant(req.ok, "Failed to update search settings")

      return isPublic
    },
    {
      async onSuccess(isPublic) {
        await queryClient.invalidateQueries(cacheKeys.userSearchDetail(queryId))
        toast({
          status: "success",
          title: "Search sharing settings updated",
        })

        invariant(searchDetailQuery.data)

        // If unshared, don't track
        if (!isPublic) return

        const toParams = {
          query: createSearchQuery(searchDetailQuery.data.queries.query),
          searchId: String(searchDetailQuery.data?.id),
        }

        const searchURL = `${env.APP_URL}/signals/${mapProductsToRouteName(
          product
        )}/feed`

        analytics.track("Shared Search", {
          searchId: queryId,
          searchURL: relativeUrl(searchURL, toParams),
          name: searchDetailQuery.data?.name,
          sharedWith: "Team",
        })
      },
    }
  )

  const shareMutation = useMutation<unknown, unknown, string>(
    async (userId) => {
      const req = await fetch(`/api/saved-searches/${queryId}/share`, {
        method: "POST",
        body: JSON.stringify({
          userId,
        }),
      })

      invariant(
        req.ok,
        "This search has already been shared with this team member"
      )

      return userId
    },
    {
      async onSuccess(userId) {
        await queryClient.invalidateQueries(cacheKeys.userSearchDetail(queryId))
        showAddForm(false)
        toast({
          status: "success",
          title: "Search sharing settings updated",
        })
        shareFormik.resetForm()

        invariant(searchDetailQuery.data)

        const toParams = {
          query: createSearchQuery(searchDetailQuery.data.queries.query),
          searchId: String(searchDetailQuery.data?.id),
        }

        const searchURL = `${env.APP_URL}/signals/${mapProductsToRouteName(
          product
        )}/feed`

        analytics.track("Shared Search", {
          searchId: queryId,
          searchURL: relativeUrl(searchURL, toParams),
          name: searchDetailQuery.data?.name,
          sharedWith: userId,
        })
      },
    }
  )

  const publicFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isPublic: searchDetailQuery.data?.isPublic ?? false,
    },
    onSubmit(values) {
      publicMutation.mutate(values.isPublic)
    },
  })

  const shareFormik = useFormik({
    validationSchema,
    initialValues: {
      userId: "",
    },
    onSubmit(values) {
      shareMutation.mutate(values.userId)
    },
  })

  if (searchDetailQuery.isLoading || searchDetailQuery.isError) {
    return (
      <ModalContent>
        <Center py={8}>
          <Spinner />
        </Center>
      </ModalContent>
    )
  }

  const selectedUser = teamMembersQuery.data?.find(
    (user) => user.userId === shareFormik.values.userId
  )

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>Share "{searchDetailQuery.data?.name}"</ModalHeader>
      <ModalBody pb={8}>
        <FormControl isInvalid={!!publicFormik.errors.isPublic}>
          <FormLabel htmlFor="isPublic">
            Allow anyone in your team to view this search:
          </FormLabel>
          <Switch
            id="isPublic"
            colorScheme="green"
            isChecked={publicFormik.values.isPublic}
            disabled={publicMutation.isLoading}
            onChange={(e) => {
              publicFormik.setFieldValue("isPublic", e.currentTarget.checked)
              publicFormik.handleSubmit()
            }}
            name="isPublic"
          />
          <FormErrorMessage>{publicFormik.errors.isPublic}</FormErrorMessage>
        </FormControl>

        {!publicFormik.values.isPublic && (
          <Box pt={3}>
            <FormLabel>
              Privately sharing this search with selected members of your team:
            </FormLabel>

            <Flex flexWrap="wrap" gap={1} alignItems="center">
              {searchDetailQuery.data?.queryShares.map((share) => (
                <Tag key={share.uuid} rounded="full" p={0}>
                  <Avatar
                    src={getUserAvatarSrc(share.user.avatar)}
                    size="xs"
                    name={`${share.user.first_name} ${share.user.last_name}`}
                    mr={1}
                  />
                  <TagLabel>
                    {share.user.first_name} {share.user.last_name}
                  </TagLabel>
                  <TagCloseButton
                    mx={1}
                    onClick={() => removeShareMutation.mutate(share.userId)}
                  />
                </Tag>
              ))}

              {!showingAddForm && (
                <IconButton
                  icon={<Icon as={HiPlus} />}
                  isRound
                  aria-label="Add team member"
                  onClick={() => showAddForm(true)}
                />
              )}

              {removeShareMutation.isLoading && <Spinner />}
            </Flex>

            {showingAddForm && (
              <form onSubmit={shareFormik.handleSubmit}>
                <Flex gap={2} mt={4}>
                  <Box flex={1}>
                    <ReactSelect
                      placeholder="Select a new team member to add..."
                      isSearchable
                      options={teamMembersQuery.data?.map((user) => ({
                        label: `${user.first_name} ${user.last_name} (${user.email})`,
                        value: user.userId,
                      }))}
                      value={
                        selectedUser
                          ? {
                              label: `${selectedUser.first_name} ${selectedUser.last_name} (${selectedUser.email})`,
                              value: shareFormik.values.userId,
                            }
                          : null
                      }
                      onChange={(option) => {
                        if (option?.value) {
                          shareFormik.setFieldValue("userId", option.value)
                        }
                      }}
                    />
                  </Box>

                  <Button
                    variant="outline"
                    size="sm"
                    type="submit"
                    disabled={!shareFormik.isValid || shareMutation.isLoading}
                    isLoading={shareMutation.isLoading}
                  >
                    Add
                  </Button>
                </Flex>
              </form>
            )}
          </Box>
        )}
      </ModalBody>
    </ModalContent>
  )
}

interface Props {
  queryId: number
  onClose: () => void
}

export const ShareShavedSearch = ({ queryId, onClose }: Props): JSX.Element => {
  const modal = useDisclosure({
    onClose,
  })

  return (
    <>
      <MenuItem icon={HiShare} onClick={modal.onOpen}>
        Share
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <SearchShareForm queryId={queryId} />
      </Modal>
    </>
  )
}
